import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Grid } from '@mui/material';

import { AppSetting, getAppSettings, sendImageToPipeline } from '../../../../../setup/api/appSettings';
import { useFetch } from '../../../../../setup/hooks/fetch.hook';
import { alertPayload, useFetchWithAlert } from '../../../../../setup/hooks/useFetchWithAlert';
import { Datagrid } from '../../../../../ui/components/Datagrid/Datagrid';
import { renderTestsRow } from '../styleGuideV2/helpers';

export type AppSettingName = 'CONTACT_NUMBER_INT' | 'CONTACT_NUMBER_DK' | 'CONTACT_EMAIL';

export const AppSettingsList: FC = () => {
  const intl = useIntl();
  const { request, isLoading, abortController } = useFetch();
  const handlePostRequest = useFetchWithAlert(alertPayload(intl.formatMessage({ id: 'APP.SETTINGS.ALERT' }))).request;
  const [appSettingList, setAppSettingList] = useState<AppSetting[]>([]);
  const [contactNumberInt, setContactNumberInt] = useState<string>('');
  const [contactEmail, setContactEmail] = useState<string>('');
  const [contactNumberDk, setContactNumberDk] = useState<string>('');

  const appSettingsListHeaders: [string, string][] = [
    ['name', intl.formatMessage({ id: 'APP.SETTINGS.NAME' })],
    ['value', intl.formatMessage({ id: 'APP.SETTINGS.VALUE' })],
    ['valueType', intl.formatMessage({ id: 'APP.SETTINGS.TYPE' })],
  ];

  const fetchAppVersions = () => {
    request(getAppSettings, abortController.signal).then(appSettings => {
      setAppSettingList(appSettings);
    });
  };

  const putAppSetting = (item: AppSetting, value: string | boolean) =>
    handlePostRequest(sendImageToPipeline, { ...item, value }).then(() => {
      fetchAppVersions();
    });

  const toggleSendImageToPipeline = (item: AppSetting) => {
    // Necessary to convert the a string "true" | "false" to boolean
    // eslint-disable-next-line eqeqeq
    const parsedValue = item.value == 'true';

    putAppSetting(item, !parsedValue);
  };

  const changeContactInformation = <T extends AppSettingName>(valueName: T, value: string) => {
    const actions = {
      CONTACT_NUMBER_INT: () => setContactNumberInt(value),
      CONTACT_NUMBER_DK: () => setContactNumberDk(value),
      CONTACT_EMAIL: () => setContactEmail(value),
    };

    actions[valueName]();
  };

  const saveContactInformation = (item: AppSetting) => {
    const value: Record<AppSettingName, string> = {
      CONTACT_NUMBER_INT: contactNumberInt,
      CONTACT_NUMBER_DK: contactNumberDk,
      CONTACT_EMAIL: contactEmail,
    };

    putAppSetting(item, value[item.name]);
  };

  useEffect(() => {
    fetchAppVersions();
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <Grid container padding={4} className="card">
      <Grid xs={12} marginTop={3}>
        <Datagrid<AppSetting>
          loading={isLoading}
          data={appSettingList}
          headings={appSettingsListHeaders}
          renderRow={renderTestsRow}
          useToggleFunction={toggleSendImageToPipeline}
          useTextFieldFunction={changeContactInformation}
          saveTextFieldFunction={saveContactInformation}
        />
      </Grid>
    </Grid>
  );
};
