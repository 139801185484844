function getCSSVariableValue(variableName: string): string {
  let hex = getComputedStyle(document.documentElement).getPropertyValue(variableName);

  if (hex && hex.length > 0) {
    hex = hex.trim();
  }

  return hex;
}
function getElementIndex(element: HTMLElement): number {
  if (element.parentNode) {
    const c = element.parentNode.children;

    for (let i = 0; i < c.length; i++) {
      if (c[i] === element) {
        return i;
      }
    }
  }
  return -1;
}
export { getCSSVariableValue, getElementIndex };
