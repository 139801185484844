import React from 'react';
import { useIntl } from 'react-intl';

import { PageTitle } from '../../../_carswip/layout/core';
import { UserRole } from '../../../constants';
import { User } from '../../../ui/helpers';

import { AccountInfo } from './AccountInfo';
import { CreateDealership } from './CreateDealership';

export const CreateAccountWrapper: React.FC = () => {
  const intl = useIntl();
  const user = User();

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.CUSTOMERS' })}</PageTitle>
      {user.userRole === UserRole.ADMIN ? <CreateDealership adminPermissions /> : <AccountInfo />}
    </>
  );
};
