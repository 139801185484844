import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Button, TextField } from '@mui/material';

import { reloadTableFromModal } from '../../../setup/redux/actions';
import { MainGridContainer } from '../MainGridContainer/MainGridContainer';
import { ModalCarswip } from '../Modal';

interface IProps {
  handleDelete: (itemId: string, hash?: string) => Promise<unknown>;
  itemId: string;
  entity: string;
  entityName?: string;
  subTitle?: string;
}
/**
 * Modal for delete confirmation, this modal request the secret hash to allow deletion
 * @param props.handleDelete callback that includes the DELETE function request to the backend
 * @param props.itemId should include the id of the object
 *
 */
export const ConfirmDeletionModal: React.FC<IProps> = ({ handleDelete, itemId, entity, entityName, subTitle }) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const modalToggle = () => {
    setIsOpen(false);
  };
  const dispatch = useDispatch();
  const [hash, setHash] = useState<string>();

  return (
    <>
      <Button
        className="action-button"
        variant="contained"
        color="error"
        style={{
          cursor: 'default',
          textTransform: 'uppercase',
        }}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <FormattedMessage id="ACTION.DELETE" />
      </Button>
      <ModalCarswip open={isOpen} onClose={modalToggle} title={intl.formatMessage({ id: 'ORDER.DELETE.CONFIRM' })}>
        <MainGridContainer>
          <h2 className="fw-bolder fs-3x text-gray-700 mb-10">
            <FormattedMessage id="ACTION.DELETE.WARNING" values={{ entity: entityName }} />
          </h2>
          <div className="fw-bold fs-3 text-gray-400 mb-15">
            <FormattedMessage id="ACTION.DELETE.WARNING.SUBTITLE" values={{ entity, br: <br /> }} />
          </div>
          <div className="fw-bold fs-3 text-gray-400 mb-15">{subTitle}</div>
          <div className="fw-bold fs-3 text-gray-400 mb-15">
            <TextField
              fullWidth
              label={
                entityName ? (
                  <FormattedMessage id="ACTION.DELETE.SAFEGUARD" values={{ entity }} />
                ) : (
                  <FormattedMessage id="ACTION.DELETE.SAFEGUARD.HASH" />
                )
              }
              variant="standard"
              onChange={event => {
                setHash(event.target.value);
              }}
            />
          </div>

          <Button
            className="action-button"
            variant="contained"
            color="error"
            style={{
              textTransform: 'uppercase',
            }}
            onClick={async () => {
              if (entityName && entityName !== hash) {
                return alert(intl.formatMessage({ id: 'ACTION.DELETE.INVALID' }));
              }

              handleDelete(itemId, hash);
              dispatch(reloadTableFromModal({ reload: true }, entity));
              setIsOpen(false);
            }}
          >
            <FormattedMessage id="ACTION.DELETE" />
          </Button>
          <Button
            className="action-button ms-3"
            variant="outlined"
            color="primary"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <FormattedMessage id="ACTION.CANCEL" />
          </Button>
        </MainGridContainer>
      </ModalCarswip>
    </>
  );
};
