import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Field, FieldAttributes, FieldProps } from 'formik';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface SelectFieldProps<T> extends FieldAttributes<any> {
  name: string;
  options: T[];
  keyName: string;
}

type NecessaryFields = {
  id?: string;
};

export const SelectFieldFromObjects = <P extends NecessaryFields>({
  name,
  options,
  keyName,
  ...rest
}: SelectFieldProps<P>) => (
  <Field name={name}>
    {({ field }: FieldProps) => (
      <FormControl fullWidth>
        <Select id={name} name={name} value={field.value} onChange={field.onChange} onBlur={field.onBlur} {...rest}>
          {options.map(option => (
            <MenuItem key={option.id} value={option.id} children={option[keyName as keyof P]} />
          ))}
        </Select>
      </FormControl>
    )}
  </Field>
);
interface SelectFieldProps1<T> extends FieldAttributes<any> {
  name: string;
  options: T[];
}

export const SelectFieldFromArray = <P extends string | number>({
  name,
  options,
  value,
  ...rest
}: SelectFieldProps1<P>) => (
  <Field name={name}>
    {({ field }: FieldProps) => (
      <FormControl fullWidth>
        <Select
          id={name}
          name={name}
          required
          value={value ?? field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          {...rest}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option} children={option} />
          ))}
        </Select>
      </FormControl>
    )}
  </Field>
);
