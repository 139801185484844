import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MailIcon from '@mui/icons-material/Mail';
import { colors } from '@mui/material';
import { Location } from 'history';

import { UserRole } from '../../../constants';
import { User } from '../../../ui/helpers';

const createNavItem = (location: Location, pathName: string, buttonName: string) => (
  <li className="nav-item">
    <Link
      className={`nav-link text-active-primary me-6 ${location.pathname === pathName ? 'active' : ''}`}
      to={pathName}
    >
      {buttonName}
    </Link>
  </li>
);

/**
 * Add navigation items on the admin nav (tags, angles, style guides, etc) and user information
 */
export const AdminHeader: FC = () => {
  const intl = useIntl();
  const location = useLocation();
  const user = User();

  return (
    <div className="card mb-5 mb-xl-10">
      <div className="card-body pt-9 pb-0">
        <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
          <div className="flex-grow-1">
            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center mb-2 me-2">
                  <a className="text-gray-800 text-hover-primary fs-2 fw-bolder me-2">{user?.userRole}</a>-
                  <div className=" fw-bold fs-6 pe-2 ms-2">
                    <LocationOnIcon style={{ fill: colors.grey[400] }} /> {user?.displayName}
                  </div>
                  <div className="fw-bold fs-6 pe-2 ms-2">
                    <MailIcon style={{ fill: colors.grey[400] }} /> {user?.email}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex overflow-auto h-55px">
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
            {createNavItem(location, '/admin/style-guides-v2', 'Style Guides V2')}
            {user?.userRole === UserRole.ADMIN && (
              <>
                {createNavItem(location, '/admin/car-types', intl.formatMessage({ id: 'MENU.ADMIN.CAR_TYPES' }))}
                {createNavItem(location, '/admin/angles', intl.formatMessage({ id: 'MENU.ADMIN.ANGLES' }))}
                {createNavItem(location, '/admin/app-versions', intl.formatMessage({ id: 'MENU.ADMIN.VERSIONS' }))}
                {createNavItem(location, '/admin/tags', intl.formatMessage({ id: 'MENU.ADMIN.TAGS' }))}
                {createNavItem(location, '/admin/banners', intl.formatMessage({ id: 'MENU.ADMIN.BANNERS' }))}
                {createNavItem(location, '/admin/app-settings', intl.formatMessage({ id: 'MENU.ADMIN.SETTINGS' }))}
                {createNavItem(
                  location,
                  '/admin/order-delivery-message',
                  intl.formatMessage({ id: 'MENU.ADMIN.ORDER' }),
                )}
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};
