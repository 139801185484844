import { FC, FormEvent, useEffect, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import { Auth } from 'aws-amplify';
import { Formik, FormikProps, FormikValues } from 'formik';
import * as Yup from 'yup';

import { RootState } from '../../../../setup';
import { getMe } from '../../../../setup/api/auth';
import { useFetch } from '../../../../setup/hooks/fetch.hook';
import * as auth from '../../../../setup/redux/auth/AuthRedux';
import { actions } from '../../../../setup/redux/auth/AuthRedux';
import { LoginForm } from '../../../components/auth/LoginForm';
import { VerifyNewPasswordForm } from '../../../components/auth/VerifyNewPasswordForm';
import { getErrorMessage } from '../errorHandler';
import { AWSSignInResponse } from '../interfaces';

export const loginSchema = (intl: IntlShape) =>
  Yup.object().shape({
    password: Yup.string().min(6, `${intl.formatMessage({ id: 'AUTH.VALIDATION.MIN_LENGTH_FIELD' })} 6`),
    email: Yup.string()
      .email(
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.WRONG_EMAIL_FORMAT' },
          { field: intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' }) },
        ),
      )
      .min(3, `${intl.formatMessage({ id: 'AUTH.VALIDATION.MIN_LENGTH_FIELD' })} 3`)
      .max(100, `${intl.formatMessage({ id: 'AUTH.VALIDATION.MAX_LENGTH_FIELD' })} 100`),
  });

type SignInButtonProps = {
  isLoading: boolean;
  text: string;
  submitForm: (e?: FormEvent<HTMLFormElement>) => void;
};

const SignInButton: FC<SignInButtonProps> = ({ isLoading, text, submitForm }) => (
  <Grid item xs={12}>
    {isLoading ? (
      <LinearProgress color="inherit" />
    ) : (
      <Button
        variant="contained"
        disabled={false}
        fullWidth
        color="primary"
        sx={{ minWidth: '100%' }}
        style={{
          marginTop: '0.75rem',
          width: '100%',
          marginBottom: '0.75rem',
        }}
        type="submit"
        onClick={() => submitForm()}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            submitForm();
          }
        }}
      >
        {text}
      </Button>
    )}
  </Grid>
);

export const Login: FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [userDataToResetPassword, setUserDataToResetPassword] = useState<any>(false);
  const [isLoading, setIsLoading] = useState(false);

  const authenticationValid = useSelector((state: RootState) => state.auth.accessToken);
  const { request } = useFetch();

  const requestUser = () => {
    dispatch(actions.showError(false));
    setError(undefined);
    request(getMe).then(data => {
      if (data) {
        setIsSignedIn(true);
        dispatch(actions.fulfillUser(data.result));
      } else if (!data) {
        setError(data);
      }
    });
  };

  const handleAuthStateChange = () => {
    Auth.currentUserInfo().then(userInfo => {
      if (userInfo && userInfo.attributes) {
        // Require check as this bounces on logout
        Auth.currentSession().then(data => dispatch(auth.actions.login(data.getAccessToken().getJwtToken())));
      }
    });
  };

  const verifyPassword = (user: AWSSignInResponse, values: FormikValues) => {
    Auth.completeNewPassword(
      // The Cognito User Object
      user,
      // The new password
      values.newPassword,
    )
      .then(() => handleAuthStateChange())
      .catch(error => {
        setIsLoading(false);
        setError(getErrorMessage(error.message));
      });
  };

  const handleSignIn = (values: FormikValues) => {
    setIsLoading(true);

    Auth.signIn(values.email.trim(), values.password)
      .then(user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setIsLoading(false);
          return setUserDataToResetPassword(user);
        }

        // Redirect to dashboard or protected page

        return handleAuthStateChange();
      })
      .catch(error => {
        setIsLoading(false);
        setError(getErrorMessage(error.message));
      });
  };

  useEffect(() => {
    if (authenticationValid && isSignedIn !== true) {
      requestUser();
    }
  }, [authenticationValid]);

  useEffect(() => {
    if (error) {
      dispatch(actions.showError(true));
    }
  }, [error]);

  useEffect(() => {
    if (userDataToResetPassword) {
      dispatch(actions.showError(false));
      setError(undefined);
    }
  }, [userDataToResetPassword]);

  return (
    <div className="authentication-box">
      {error && (
        <Alert severity="error" className="my-5" style={{ alignItems: 'center' }}>
          <FormattedMessage id={error} />
        </Alert>
      )}
      <Formik
        validationSchema={loginSchema(intl)}
        onSubmit={values => {
          setIsLoading(true);
          if (userDataToResetPassword) {
            return verifyPassword(userDataToResetPassword, values);
          }
          handleSignIn(values);
        }}
        validateOnMount
        validateOnChange
        initialValues={{
          autofocus: true,
          email: '',
          password: '',
        }}
      >
        {(props: FormikProps<any>) => (
          <>
            {userDataToResetPassword ? <VerifyNewPasswordForm {...props} /> : <LoginForm {...props} />}
            <SignInButton
              isLoading={isLoading}
              text={intl.formatMessage({
                id: userDataToResetPassword ? 'AUTH.RESET' : 'AUTH.SIGN_IN',
              })}
              submitForm={props.handleSubmit}
            />
          </>
        )}
      </Formik>
    </div>
  );
};
