import { FC } from 'react';

type HeaderProps = {
  headers: { key: string; label: string }[];
};

export const Headers: FC<HeaderProps> = ({ headers }) => (
  <thead>
    <tr>
      {headers.map(x => (
        <th key={x.key} className="grid-header">
          {x.label}
        </th>
      ))}
    </tr>
  </thead>
);
