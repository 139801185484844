import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = { reloadOrderInformation: false };

const currentOrderInformationSlice = createSlice({
  name: 'CURRENT_ORDER_INFORMATION',
  initialState,
  reducers: {
    reloadOrderInformation: (state, action: PayloadAction<boolean>) => {
      state.reloadOrderInformation = action.payload;
    },
  },
});

export const { reloadOrderInformation } = currentOrderInformationSlice.actions;

export default currentOrderInformationSlice.reducer;
