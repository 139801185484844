import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ManIcon from '@mui/icons-material/Man';

import { getUserStatistics } from '../../../setup/api';
import { getChildAccountStatistics } from '../../../setup/api/statistics';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { ChildAccountFilter } from '../../../ui/components/Filters/ChildAccountFilter';
import { CarsPerSalesmanOrDealerhip } from '../statistics/CarsPerSalesmanOrDealerhip';
import { CarsPerStyleGuideChart } from '../statistics/CarsPerStyleGuideChart';
import { StatisticsForTotalCount } from '../statistics/StatisticsForTotalCount';
import { UserOrderDashboard } from '../statistics/UserOrderDashboard';

interface IProps {
  userId: string;
}
/**
 * Management dashboard loads all the child account information and filters statistics by child account
 */
export const ManagementDashboard: FC<IProps> = ({ userId }) => {
  const intl = useIntl();
  const { request } = useFetch();
  const [account, setAccount] = useState<string>(userId);
  const [userStatistics, setUserStatistics] = useState<{
    salesManCount: string;
    imagesCount: string;
    ordersCount: string;
  }>();
  const [carPerSalesMan, setCarPerSalesMan] = useState<boolean>(false);

  const handleChange = (
    _event: React.SyntheticEvent<Element, Event>,
    newValue: { name: string; id: string } | null,
  ) => {
    if (newValue?.name === 'All') {
      setCarPerSalesMan(false);
      request(getChildAccountStatistics, userId, 'statistics').then(data => {
        setUserStatistics(data);
      });
      setAccount(userId);
    } else {
      if (newValue) {
        setAccount(newValue?.id);
      }
      setCarPerSalesMan(true);
      request(getUserStatistics, newValue?.id).then(data => {
        setUserStatistics(data);
      });
    }
  };

  useEffect(() => {
    if (userId) {
      request(getChildAccountStatistics, userId, 'statistics').then(data => {
        setUserStatistics(data);
      });
    }
  }, [userId]);

  return (
    <>
      <div className="row g-5 g-xl-8">
        <div className="col-xxl-2 d-flex align-items-center text-dark fw-bolder my-1 fs-3">
          <FormattedMessage id="DASHBOARD.MANAGER.TITLE" />
        </div>
        <div className="col-xxl-4">{userId && <ChildAccountFilter getAccount={handleChange} userId={userId} />}</div>
      </div>
      <div className="row g-5 g-xl-8" style={{ height: '500px' }}>
        <div className="col-8">
          <UserOrderDashboard className="card-xl-stretch mb-xl-8" iconMUI={<DirectionsCarIcon />} userId={account} />
        </div>
        <div className="col-4">
          {userStatistics && (
            <div className="row">
              <StatisticsForTotalCount
                className="card-xl-stretch mb-xl-8"
                iconMUI={<DirectionsCarIcon color="action" />}
                color="white"
                iconColor="primary"
                title={userStatistics.ordersCount}
                description={intl.formatMessage({ id: 'DASHBOARD.CARS' })}
              />
              <StatisticsForTotalCount
                className="card-xl-stretch mb-xl-8"
                iconMUI={<ManIcon color="action" />}
                color="warning"
                iconColor="white"
                title={userStatistics.salesManCount}
                description={intl.formatMessage({ id: 'DASHBOARD.SALESMEN' })}
              />
            </div>
          )}
        </div>
      </div>
      <div className="row g-5 g-xl-8">
        <div className="col-12">
          <CarsPerStyleGuideChart userId={account} />
        </div>
      </div>
      <div className="row g-5 g-xl-8">
        <div className="col-12">
          {carPerSalesMan ? (
            <CarsPerSalesmanOrDealerhip
              className="card-xxl-stretch-30 mb-5 mb-xl-8"
              title={intl.formatMessage({ id: 'DASHBOARD.CARS_RATIO_SALESMEN' })}
              loadSalesMen
              userId={account}
            />
          ) : (
            <CarsPerSalesmanOrDealerhip
              className="card-xxl-stretch-30 mb-5 mb-xl-8"
              title={intl.formatMessage({ id: 'DASHBOARD.CARS_RATIO_DEALERSHIP' })}
              userId={account}
            />
          )}
        </div>
      </div>
    </>
  );
};
