import { blue, cyan, green, grey, indigo, orange, purple, red, yellow } from '@mui/material/colors';

export const customButtonColors = {
  green: green[500],
  yellow: yellow[600],
  grey: grey[500],
  orange: orange[500],
  red: red[500],
  blue: blue[500],
  cyan: cyan[500],
  indigo: indigo[500],
  deep_green: green[900],
  purple: purple[500],
};
