import { FC } from 'react';

export const Content: FC = ({ children }) => (
  <div
    id="kt_content_container"
    className="col-12"
    style={{
      justifyContent: 'center',
      flex: 'auto',
      margin: '2% 7%',
    }}
  >
    {/* <div style={{ boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)' }}> */}
    {children}
    {/* </div> */}
  </div>
);
