import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../../setup';
import { deleteAppVersion } from '../../../../../setup/api';
import { getAppVersions } from '../../../../../setup/api/appVersion';
import { useFetch } from '../../../../../setup/hooks/fetch.hook';
import { Datagrid } from '../../../../../ui/components/Datagrid/Datagrid';
import { stringifyValue } from '../../../../../ui/helpers/DataGridUtils';
import { CreateAppVersionModal } from '../../../../components/appVersions/CreateAppVersionModal';

const renderRow = (headings: Array<Array<string>>, item: { [key: string]: string }) =>
  headings.map(([key]) => (
    /*
     * If (key.toString() === 'remove') {
     *     return (
     *         <button
     *             type='button'
     *             className='btn btn-primary'
     *             style={{ zIndex: 1000 }}
     *             onClick={() => {
     *                 deleteAppVersion(item['id']).then(() => {
     *                     dispatch(reloadTable({ reload: true }, 'appversion'));
     *                 });
     *             }}
     *         >
     *         Delete
     *         </button>
     *     );
     * }
     */
    <td key={key}>{stringifyValue(item[key])}</td>
  ));

const AppVersionList: FC = () => {
  const intl = useIntl();
  const { request, isLoading } = useFetch();
  const [appVersionList, setAppVersionList] = useState([]);

  const appVersionListHeaders: [string, string][] = [
    ['versionString', intl.formatMessage({ id: 'VERSION' })],
    ['platform', intl.formatMessage({ id: 'VERSION.PLATFORM' })],
    ['needsDatabaseDeleteOnMobile', intl.formatMessage({ id: 'VERSION.DB' })],
    ['isTheRequiredMinimumVersionCurrently', intl.formatMessage({ id: 'VERSION.MIN' })],
  ];

  const fetchAppVersions = () => {
    request(getAppVersions).then(appVersionResponse => {
      setAppVersionList(appVersionResponse);
    });
  };
  const reloadValue = useSelector((state: RootState) => state.reloadTableModal);

  useEffect(() => {
    fetchAppVersions();
  }, []);

  useEffect(() => {
    if (reloadValue?.appversion?.reload) {
      fetchAppVersions();
    }
  }, [reloadValue]);

  return (
    <div className="content d-flex flex-column flex-column-fluid">
      <div className="card">
        <div className="card-header border-0 pt-6">
          <div className="card-title">
            <div className="d-flex align-items-center position-relative my-1" />
          </div>
          <div className="card-toolbar">
            <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
              <CreateAppVersionModal button />
            </div>
          </div>
        </div>
        <div className="card-body pt-10">
          <Datagrid
            loading={isLoading}
            data={appVersionList}
            headings={appVersionListHeaders}
            renderRow={renderRow}
            handleDelete={deleteAppVersion}
            deletable
            modalURL="admin/app-version"
          />
        </div>
      </div>
    </div>
  );
};

export { AppVersionList };
