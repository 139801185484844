import { ImageType } from 'react-images-uploading';

export const maxWidth = 4032;
export const maxHeight = 3024;
export const maxSize: number = 8 * 1024 * 1024; // 8 MiB

function shouldResizeImage(image: ImageType): boolean {
  const fileSize = image.src.length * 0.75;

  return image.width > maxWidth || image.height > maxHeight || fileSize > maxSize;
}

function calculateDimensions(img: HTMLImageElement): { width: number; height: number } {
  let { width, height } = img;

  if (width > maxWidth || height > maxHeight) {
    if (width > height) {
      height *= maxWidth / width;
      width = maxWidth;
    } else {
      width *= maxHeight / height;
      height = maxHeight;
    }
  }

  return { width, height };
}

function setCanvasSize(canvas: HTMLCanvasElement, width: number, height: number): void {
  canvas.width = width;
  canvas.height = height;
}

function drawImageOnCanvas(
  context: CanvasRenderingContext2D,
  img: HTMLImageElement,
  width: number,
  height: number,
): void {
  context.drawImage(img, 0, 0, width, height);
}

function createResizedImagePropertiesFromBlob(image: ImageType, blob: Blob): { file: File } {
  const lastDotIndex = image.file?.name.lastIndexOf('.');
  const fileNameWithoutExtension = lastDotIndex && lastDotIndex > 0 ? image.file?.name.slice(0, lastDotIndex) : 'image';
  const resizedFile = new File([blob], `${fileNameWithoutExtension}.jpg`, { type: 'image/jpeg' });

  return { file: resizedFile };
}

async function createResizedImageProperties(
  canvas: HTMLCanvasElement,
  image: ImageType,
  quality: number,
): Promise<{ file: File }> {
  const blob = await new Promise<Blob | null>(resolve => {
    canvas.toBlob(blob => resolve(blob), 'image/jpeg', quality);
  });

  if (!blob) {
    throw new Error('Failed to create blob from canvas');
  }

  const { file } = createResizedImagePropertiesFromBlob(image, blob);

  return { file };
}

async function tryResizeImage(canvas: HTMLCanvasElement, image: ImageType, quality: number): Promise<ImageType> {
  const { file } = await createResizedImageProperties(canvas, image, quality);
  const outputDataUrl = canvas.toDataURL('image/jpeg', quality);
  const resizedImage = { ...image, file, data_url: outputDataUrl };

  const fileSize = resizedImage.data_url.length * 0.75; // Approximate file size in bytes

  if (fileSize > maxSize && quality > 0.1) {
    return tryResizeImage(canvas, image, quality - 0.1);
  }

  return resizedImage;
}

export async function resizeImage(image: ImageType): Promise<ImageType> {
  const img = new Image();

  img.src = image.data_url;

  if (!shouldResizeImage(img)) {
    image.width = img?.width ? Math.round(img.width) : 1280;
    image.height = img?.height ? Math.round(img.height) : 720;
    return image;
  }

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d') as CanvasRenderingContext2D;

  img.onerror = () => {
    throw new Error('Failed to load image');
  };

  const resizedImage = await new Promise<ImageType>((resolve, reject) => {
    img.onload = async () => {
      const { width, height } = calculateDimensions(img);

      setCanvasSize(canvas, width, height);
      drawImageOnCanvas(context, img, width, height);
      try {
        const outputImage = await tryResizeImage(canvas, image, 1.0);

        outputImage.width = Math.round(width);
        outputImage.height = Math.round(height);
        resolve(outputImage);
      } catch (error) {
        reject(error);
      }
    };
  });

  return resizedImage;
}
