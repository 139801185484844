import styled from '@emotion/styled';

export const TitleTag = styled('a')({
  color: '#181c32 !important',
  transition: 'color 0.2s ease, background-color 0.2s ease',
  boxShadow: '0px 0px 20px 0px rgba(76, 87, 125, 0.02)',
  '@media (min-width: 1200px)': {
    fontSize: 'calc(1.26rem + 0.12vw) !important',
  },
  fontWeight: '600 !important',
});
