// eslint-disable-next-line simple-import-sort/imports
import './MultipleImageView.css';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import React, { FC, useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory, useParams } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';
import { getSingleOrder } from '../../../../setup/api';
import { useFetch } from '../../../../setup/hooks/fetch.hook';
import { pickStyleStatusColor } from '../../../../setup/helpers/pickStyleStatusColor';
import { addAllSelectedImages, addSelectedImages, removeSelectedImages } from '../../../../setup/redux/actions';

import { RootState } from '../../../../setup';
import { handleImageOnLoadError } from '../../../../setup/helpers/handleImageOnloadIfError';
import { OptimisationStatus } from '../../../../constants';
import { formatDetectedAngle } from '../../../../ui/helpers/helpers';

type IProps = {
  orderInfo: IOrder;
};

/**
 * This component is the second view for the {@link UserRole.ADMIN } && {@link UserRole.QA} and allows the user to select multiple images from the gallery
 *  this selected images are send to redux and often used by re pushing, download and deletion components
 * @param props.orderInfo receive from parent the order information
 * @returns  gallery of images from a specific order, ordered by position DESC
 */
export const MultipleImageView: FC<IProps> = ({ orderInfo }) => {
  const { id } = useParams<{ id: string; imageId: string }>();
  const { request } = useFetch();
  const history = useHistory();
  const dispatch = useDispatch();

  const [order, setOrder] = useState<IOrder>(orderInfo);
  const [selectedImages, setSelectedImages] = React.useState<IImage[]>([]);
  const imageSelector = useSelector((state: RootState) => state.imageSelectors?.imageSelector);
  const reloadOrderInfo = useSelector((state: RootState) => state?.currentOrderInformation?.reloadOrderInformation);

  const getOrderInfo = (id: string) => {
    request(getSingleOrder, id).then(data => {
      setOrder(data);
    });
  };

  const isImageOptimized = (mediaOrigin: string) => mediaOrigin === 'optimized';

  const handleImageSelector = () => {
    const filteredImageByOptimizationStatus = order.images.filter((image: IImage) => {
      if (imageSelector === 'Original' || imageSelector === 'Optimized') {
        return image.mediaOrigin === imageSelector.toLowerCase();
      }
      if (imageSelector === 'Enhanced') {
        return image.optimisedVia === 'enhancer' && image.mediaOrigin === 'optimized';
      }
      if (imageSelector === 'Bypassed') {
        return image.optimisedVia === 'bypass' && image.mediaOrigin === 'optimized';
      }
      if (imageSelector === 'Featured') {
        return image.optimisedVia === 'FEATURE_IMAGE';
      }

      return image.optimisedVia === 'full' && image.mediaOrigin === 'optimized';
    });
    const imageIds = filteredImageByOptimizationStatus.map((image: any) => image?.id);

    setSelectedImages(filteredImageByOptimizationStatus);
    dispatch(addAllSelectedImages(imageIds));
  };

  useEffect(() => {
    if (order) {
      handleImageSelector();
    }
  }, [imageSelector]);

  useEffect(
    () =>
      // Clean up the listener when the component unmounts
      history.listen(() => {
        getOrderInfo(id);
        setSelectedImages(prev => [...prev]);
      }),
    [history],
  );

  useEffect(() => {
    getOrderInfo(id);
  }, [reloadOrderInfo]);

  return (
    <div
      className="row  d-flex multipleImageUser align-items-center "
      style={{
        marginLeft: '3em',
        marginRight: '1.4em',
        paddingBottom: '1.4em',
      }}
    >
      {order?.images?.map((image: any, index: number) => (
        <div className="col-4 justify-content-between">
          {selectedImages.find(x => x.id === image.id) ? (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                const arr = selectedImages.filter((item: any) => item.id !== image.id);

                dispatch(removeSelectedImages(image.id));
                setSelectedImages(arr);
              }}
            >
              <CheckCircleIcon />
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                dispatch(addSelectedImages(image.id));
                setSelectedImages([...selectedImages, image]);
              }}
            >
              <CircleIcon />
            </button>
          )}
          {image?.mediaOrigin && (
            <div className="imageType">
              <p className={isImageOptimized(image?.mediaOrigin) ? 'badge badge-primary' : 'badge badge-secondary'}>
                {`${image.position} - ${image.mediaOrigin.toUpperCase()} ${
                  image.detectedAngle ? `- ${formatDetectedAngle(image.detectedAngle)}` : ``
                }`}
              </p>
            </div>
          )}
          <LazyLoadImage
            src={order?.images[index]?.thumbnailUrl ?? undefined}
            className="img-fluid loadedImage"
            onClick={() => {
              history.push(`/order/${order.id}/select/${image.id}`);
              setSelectedImages(prev => [...prev]);
            }}
            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleImageOnLoadError(e, index, order)}
          />

          {image.approved === false && image.optimisationStatus === OptimisationStatus.REJECTED && (
            <span className={`imageApproval ${pickStyleStatusColor(false)}`}>
              <FormattedMessage id="ORDER.VIEW.IMG.MULTIPLE.ERROR" />
            </span>
          )}
          {image.optimisationStatus !== OptimisationStatus.REJECTED && (
            <span className={`imageApproval ${pickStyleStatusColor(image.approved)}`}>
              <FormattedMessage
                id={image && image.approved ? 'ORDER.VIEW.IMG.APPROVED' : 'ORDER.VIEW.IMG.REQ_EDITION'}
              />
            </span>
          )}
        </div>
      ))}
    </div>
  );
};
