import config from 'react-global-configuration';

import { AppSettingName } from '../../app/pages/admin/views/appSettings/AppSettingsList';

import { generateRequestOptions } from './helpers';

export type AppSetting = {
  name: AppSettingName;
  value: string;
  valueType: string;
};
export const getAppSettings = (abort: AbortSignal): Promise<Response> => {
  const url = `${config.get('ApiUrl').Rest}/appSettings`;

  return fetch(url, generateRequestOptions('GET', abort));
};

export const sendImageToPipeline = (appSettingsPayload: AppSetting): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/appSettings/sendImagesToTestPipeline`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(appSettingsPayload),
    mode: 'cors',
    credentials: 'include',
  });
