import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';

import { RootState } from '../../../setup';
import { deleteSelectedImages, getImageById } from '../../../setup/api';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { reloadOrderInformation } from '../../../setup/redux/reducers/currentOrderInformationReducer';
import { MainGridContainer } from '../../../ui/components/MainGridContainer/MainGridContainer';
import { ModalCarswip } from '../../../ui/components/Modal';

export const DeleteModal: React.FC<{ button?: boolean }> = ({ button }) => {
  const [anchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const selectedImages = useSelector((state: RootState) => state.images.images);
  const dispatch = useDispatch();
  const { id } = useParams<TParams>();
  const { request } = useFetch();
  const [isOpen, setIsOpen] = useState(false);
  const [images, setImages] = useState<IImage[]>([]);
  const [initValues, setInitialData] = useState<RepushPayload>({
    orderId: id,
    imageArray: selectedImages,
    method: 'full',
    imageCategory: 'exterior',
  });

  const modalToggle = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setImages([]);
    }
  };
  const loadImages = () => {
    Promise.all(selectedImages.map((image: any) => request(getImageById, image).then(data => data))).then(data => {
      setImages(data);
    });
  };

  useEffect(() => {
    loadImages();
  }, [isOpen]);

  useEffect(() => {
    setInitialData({
      ...initValues,
      imageArray: selectedImages,
    });
  }, [selectedImages]);

  return (
    <>
      {button && (
        <Button
          id="basic-button"
          style={{ margin: '1em 1em 1em 1em', border: ' 1px' }}
          color="error"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {selectedImages.length > 0 ? `Delete Images (${selectedImages.length})` : 'Delete'}
        </Button>
      )}

      <ModalCarswip open={isOpen} onClose={modalToggle} title="DELETE IMAGES">
        <MainGridContainer>
          <div className="">
            <div>
              <h1>
                <FormattedMessage id="ORDER.DELETE.SELECTED" />
              </h1>
              <div className="d-flex">
                {images.map((image: IImage) => (
                  <div
                    style={{
                      width: '220px',
                      height: '220px',
                      background: 'black',
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: '1em',
                      border: 'grey 3px',
                      borderStyle: 'solid',
                    }}
                  >
                    <img style={{ width: '100%', height: '80%' }} src={image.thumbnailUrl} />
                  </div>
                ))}
              </div>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
                <Button
                  id="basic-button"
                  style={{ margin: '1em 1em 1em 1em', border: ' 1px' }}
                  color="error"
                  variant="contained"
                  onClick={() => {
                    request(deleteSelectedImages, selectedImages).then(() => {
                      setIsOpen(false);
                      dispatch(reloadOrderInformation(true));
                    });
                  }}
                >
                  <FormattedMessage id="ORDER.DELETE.CONFIRM" />
                </Button>
              </div>
            </div>
          </div>
        </MainGridContainer>
      </ModalCarswip>
    </>
  );
};
