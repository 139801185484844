import config from 'react-global-configuration';

export interface MessageBoxPayload {
  title: string | undefined;
  body: string | undefined;
  orderId: string;
  eventPayload?: string;
}
export const sendPushNotification = (payload: MessageBoxPayload): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/mobile/send-push-notification/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
    mode: 'cors',
    credentials: 'include',
  });
