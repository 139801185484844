import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';

import { PageTitle } from '../../../../_carswip/layout/core';
import { UserRole } from '../../../../constants';
import { getSingleOrder } from '../../../../setup/api';
import { useFetch } from '../../../../setup/hooks/fetch.hook';
import { setCurrentUserToState } from '../../../../setup/redux/actions';
import { ImageDetailModal } from '../../../../ui/components/Modals/ImageDetail/ImageDetailModal';
import { User } from '../../../../ui/helpers';
import { EventDisplay } from '../../../components/order/EventDisplay';
import { OrderMenu } from '../../../components/order/OrderMenu';
import { PushNotificationForm } from '../../../components/order/PushNotificationForm';
import { MultipleImageView } from '../../../components/order/views/MultipleImageView';
import { QaView } from '../../../components/order/views/QA';
import { UserView } from '../../../components/order/views/UserView';
import { VideoView } from '../../../components/order/views/VideoView';

import { CustomerNote } from './CustomerNote';
import { OrderInformationBox } from './OrderInformationBox';

import './SingleOrderPage.css';

/**
 * Load all the routes with all the order views
 */
const loadOrderRoutesAndView = (orderInformation: IOrder) => {
  const history = useHistory();
  const closeImageDetailModal = () => {
    history.push(`/order/${orderInformation.id}/select/`);
  };

  return (
    <Switch>
      <Route path="/order/:id/select/">
        <PageTitle>
          <FormattedMessage id="ORDER.VIEW.IMAGE_SELECT" />
        </PageTitle>
        <MultipleImageView orderInfo={orderInformation} />
        <Route path="/order/:id/select/:imageId">
          <ImageDetailModal closeModal={closeImageDetailModal} />
        </Route>
      </Route>
      <Route path="/order/:id/videos">
        <PageTitle>
          <FormattedMessage id="ORDER.VIEW.VIDEOS" />
        </PageTitle>
        <VideoView />
      </Route>
      <Route path="/order/:id/">
        <PageTitle>
          <FormattedMessage id="ORDER.VIEW.QA" />
        </PageTitle>
        <QaView orderInfo={orderInformation} />
      </Route>
    </Switch>
  );
};

/**
 * Load a navigation per route on a menu
 */
const loadOrderNavigation = ({ id }: IOrder) => {
  const intl = useIntl();

  const generateNavigationClassName = (routeName?: string): string => {
    const currentUrl = location.pathname;
    const selectedUrl = `/order/${id}${routeName || ''}`;

    return `nav-link text-active-primary me-6 ${currentUrl === selectedUrl ? 'active' : ''}`;
  };

  const orderRoutes = [
    {
      name: intl.formatMessage({ id: 'ORDER.VIEW.QA' }),
      route: '',
    },
    {
      name: intl.formatMessage({ id: 'ORDER.VIEW.SELECT_IMAGES' }),
      route: '/select/',
    },
    {
      name: intl.formatMessage({ id: 'ORDER.VIEW.VIDEOS' }),
      route: '/videos',
    },
  ];

  return (
    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder d-flex ms-1">
      {orderRoutes.map(({ route, name }) => (
        <li className="nav-item">
          <Link className={generateNavigationClassName(route)} to={`/order/${id}${route}`}>
            {name}
          </Link>
        </li>
      ))}
    </ul>
  );
};

/**
 *
 * FIXME:
 * 1. if under is undefined it should redirect to another page,  component should rename to (AdminOrderView or something like that)
 * 2. probably the order should be loaded on the wrapper and not on each of the views and pass the order on Context
 * 3. pathnames classes must be loaded with a function
 *
 * Admin And QA view
 * Load page with order details, and allows the user to change the order options by different role
 * QA and admin are able to change QA  status, Status, Order name, repush images, remove tags and  read customer events
 * Dealership and management can only read information and change the order name
 */
export const OrderAdminView: FC = () => {
  const [orderInfo, setOrderInfo] = useState<IOrder>();

  const location = useLocation();
  const params = useParams<TParams>();
  const { request } = useFetch();
  const { userRole } = User();
  const dispatch = useDispatch();

  useEffect(() => {
    request(getSingleOrder, params.id).then(order => {
      dispatch(setCurrentUserToState(order.user));
      setOrderInfo(order);
    });
  }, []);

  return (
    <div>
      <CustomerNote orderInfo={orderInfo} userRole={userRole} />
      <div className="row justify-content-center ">
        <div className={location.pathname === `/order/${orderInfo?.id}/select/` ? 'col-10' : 'col-12'}>
          {/* ORDER STATUS CARD */}
          <div className="card">
            <div className="card-body pt-9 pb-0">
              <div className="d-flex flex-wrap flex-sm-nowrap">
                <div className="flex-grow-1">
                  <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                    <div className="d-flex flex-column">{orderInfo && <OrderMenu orderInformation={orderInfo} />}</div>
                  </div>
                  {/* BEGINS ORDER VIEWS NAVIGATION */}
                  <div className="d-flex overflow-auto h-90px align-items-center">
                    {orderInfo && orderInfo?.id && loadOrderNavigation(orderInfo)}
                  </div>
                  {/* ENDS ORDER VIEWS NAVIGATION */}
                </div>
              </div>
            </div>
            {/*  ORDER DETAIL VIEWS */}
            {orderInfo && loadOrderRoutesAndView(orderInfo)}
          </div>
          {/*  ORDER EVENTS */}

          <div className="mt-5">
            <EventDisplay order />
          </div>
        </div>

        {/* there must be a button to select images that changes the view and another one for download all the images  */}

        {/* BEGINS ORDER DETAILS VIEW show user information and order info */}
        {location.pathname === `/order/${orderInfo?.id}/select/` && orderInfo && (
          <div className="col-2">
            <OrderInformationBox
              orderInfo={orderInfo}
              images={orderInfo?.images}
              repushButton
              tagDisplay
              reloadOrderInfo={() =>
                request(getSingleOrder, params.id).then(order => {
                  dispatch(setCurrentUserToState(order.user));
                  setOrderInfo(order);
                })
              }
            />
            {/* BEGINS PUSH NOTIFICATION */}
            {userRole === UserRole.ADMIN && (
              <div className="mt-5">
                <PushNotificationForm />
              </div>
            )}
            {/* ENDS PUSH NOTIFICATION */}
          </div>
        )}
        {/* ENDS ORDER DETAILS VIEW */}
      </div>
    </div>
  );
};

export const OrderDetailPageWrapper: FC = () => {
  const user = User();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        <FormattedMessage id="ORDER.ID" />
      </PageTitle>
      {(user.userRole === UserRole.ADMIN || user.userRole === UserRole.QA) && <OrderAdminView />}
      {(user.userRole === UserRole.DEALERSHIP || user.userRole === UserRole.MANAGEMENT) && <UserView />}
    </>
  );
};
