import { FormattedMessage } from 'react-intl';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Button } from '@mui/material';

export const removeEntityUserButton = (id: string, request: (id: string) => Promise<void>, disabled: boolean) => (
  <Button
    variant="contained"
    color="error"
    disabled={!disabled}
    onClick={() => {
      request(id);
    }}
  >
    <RemoveCircleOutlineIcon />
    <FormattedMessage id="ACTION.REMOVE" />
  </Button>
);
export const addEntityUserButton = (id: string, request: (id: string) => Promise<void>, disabled: boolean) => (
  <Button
    variant="contained"
    color="primary"
    disabled={disabled}
    onClick={() => {
      request(id);
    }}
  >
    <AddIcon />
    <FormattedMessage id={disabled ? 'CHILD.ACTION.DISABLED' : 'CHILD.ACTION.ADD'} />
  </Button>
);
