import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';

import './EventDisplay.css';

export type TEventType = {
  user: {
    id: number;
    displayName: string;
  };
  order: {
    id: number;
    orderName: string;
  };
  eventName: string;
  isHidden: boolean;
  payload: string;

  createdAt: string;
};

interface IProps {
  event: TEventType;
  type: 'user' | 'order';
  sequence?: number;
}
/**
 *
 * @param { TEventType } props.event  if true will show order events
 * @param { 'user' | 'order' } props.type if true will show user events
 *
 * @returns clickable event component, that shows the event name and its payload onClick
 *
 */

const EventItem: FC<IProps> = ({ event, type, sequence: index }: IProps) => {
  const intl = useIntl();
  const [displayPayload, setDisplayPayload] = useState<boolean>(false);

  return (
    <>
      <div
        className="timeline-item align-items-center event-item-clickable d-flex"
        onClick={() => setDisplayPayload(!displayPayload)}
      >
        <div className="timeline-line w-40px align-items-center col-2" />
        {index ? <span style={{ paddingRight: '5px' }}>{index}</span> : null}
        <div className="timeline-icon symbol symbol-circle symbol-40px">
          <div className="symbol-label bg-light">
            <DirectionsCarFilledIcon />
          </div>
        </div>

        <div className="timeline-content me-5 col-4" style={{ marginTop: '1em !important', marginBottom: '0em' }}>
          {event && (
            <div className="fs-5 fw-bold" style={{ cursor: 'pointer' }}>
              {event.eventName}
            </div>
          )}
        </div>

        <div className="col-2">
          {type === 'order' && event.user && (
            <Link to={`/profile/${event?.user.id}/events`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <div className="overflow-auto event-item-clickable">
                <div className="d-flex align-items-center fs-6">
                  <a className="text-primary fw-bolder me-3">
                    {`${intl.formatMessage({ id: 'ORDER.EVENT.AUTHOR' }, { name: event.user.displayName })}`}
                  </a>
                </div>
              </div>
            </Link>
          )}
          {type === 'user' && (
            <Link to={`/order/${event.order.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <div className="overflow-auto">
                <div className="d-flex align-items-center fs-6">
                  <a className="text-primary fw-bolder me-3">
                    {`${intl.formatMessage({ id: 'ORDER.EVENT.ORDER' }, { name: event.order.orderName })}`}
                  </a>
                </div>
              </div>
            </Link>
          )}
        </div>

        <div className="text-muted me-2 fs-7">
          <Moment format="YYYY.MM.DD HH:mm:ss" date={event.createdAt} />
        </div>
      </div>

      {displayPayload && (
        <div className="container" style={{ marginLeft: '2em', marginTop: '1em' }}>
          <p style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{event.payload}</p>
        </div>
      )}
    </>
  );
};

export { EventItem };
