import { FC, useEffect, useState } from 'react';
import { Autocomplete, Box, FormControl, TextField, TextFieldProps } from '@mui/material';

import { getChildAccountStatistics } from '../../../setup/api/statistics';
import { useFetch } from '../../../setup/hooks/fetch.hook';

interface IProps {
  getAccount: (event: React.SyntheticEvent<Element, Event>, newValue: { name: string; id: string } | null) => void;
  userId: string;
}

export const ChildAccountFilter: FC<IProps> = ({ getAccount, userId }) => {
  const [childAccountStatistics, setChildAccountStatistics] = useState<{ displayName: string; id: string }[]>([]);
  const { request, isLoading } = useFetch();

  useEffect(() => {
    request(getChildAccountStatistics, userId, 'users').then(accounts => {
      setChildAccountStatistics([{ displayName: 'All' }, ...accounts]);
    });
  }, []);

  return (
    <Box sx={{ minWidth: 120 }} className="card-xl-stretch mb-xl-8" style={{ backgroundColor: 'white' }}>
      <FormControl fullWidth>
        <Autocomplete
          defaultValue={{ name: 'All', id: userId }}
          disablePortal
          limitTags={5}
          options={childAccountStatistics.map((account: { [key: string]: string; id: string }) => ({
            name: account.displayName,
            id: account.id,
          }))}
          getOptionLabel={(option: any) => option.name}
          onChange={getAccount}
          loading={isLoading}
          renderInput={(params: TextFieldProps) => <TextField {...params} label="Accounts" />}
        />
      </FormControl>
    </Box>
  );
};
