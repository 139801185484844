import { FC, useEffect, useState } from 'react';
import { ImageListType } from 'react-images-uploading';
import { FormattedMessage, useIntl } from 'react-intl';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';

import { ModalCarswip } from '../../../../ui/components/Modal';
import { ModalSubtitle } from '../../../../ui/components/Title/ModalSubtitle';
import { ModalTitleHeading } from '../../../../ui/components/Title/ModalTitleHeading';

interface UploadingImagesModalProps {
  images: ImageListType;
  uploadedImages?: number;
}

const ImageLoaded = styled('img')(() => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  minWidth: '100%',
  wordWrap: 'break-word',
  backgroundColor: '#fff',
  backgroundClip: 'border-box',
  border: '1px solid rgba(0, 0, 0, 0.125)',
  borderRadius: '10px',
}));

const ImageWrapper = styled(Grid)(() => ({
  position: 'relative',
  paddingTop: '0px',
  marginTop: '2em',
  marginLeft: '1em',
  marginRight: '1em',
  marginBottom: '2em',
}));

const UploadedImageIcon = styled(TaskAltIcon)({
  position: 'absolute',
  top: 0,
  right: '-3%',
  zIndex: 10000,
  color: 'green',
  fontSize: '3.5em',
});

const UploadedImageCounterRow: FC<UploadingImagesModalProps> = ({ images, uploadedImages }) => (
  <Grid container display="flex" alignItems="center" justifyContent="center" marginBottom={5}>
    {images.map((image, index) => {
      const imagePosition = index + 1;

      return (
        <ImageWrapper item xs={12} md={1.5} key={index}>
          {uploadedImages && uploadedImages >= imagePosition && <UploadedImageIcon />}
          <ImageLoaded src={image?.data_url} />
        </ImageWrapper>
      );
    })}
  </Grid>
);

const LinearProgressWithLabel: FC<{ value: number }> = ({ value }) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Box sx={{ width: '100%', mr: 1 }}>
      <LinearProgress variant="determinate" value={value} />
    </Box>
    <Box sx={{ minWidth: 35 }}>
      <Typography variant="body2" color="text.secondary">
        {`${Math.round(value)}%`}
      </Typography>
    </Box>
  </Box>
);

export const UploadingImagesModal: FC<UploadingImagesModalProps> = ({ images, uploadedImages }) => {
  const intl = useIntl();
  const [progress, setProgress] = useState(0);
  const { isSubmitting } = useFormikContext();

  useEffect(() => {
    if (uploadedImages) {
      setProgress((uploadedImages / images.length) * 100);
    }
  }, [uploadedImages]);

  return (
    <ModalCarswip
      open={Boolean(isSubmitting)}
      onClose={undefined}
      title={intl.formatMessage({ id: `ORDER.CREATOR.MODAL.UPLOAD.TITLE` })}
    >
      <Box sx={{ width: `100%`, alignItems: `center` }}>
        <ModalTitleHeading children={<FormattedMessage id="ORDER.CREATOR.MODAL.UPLOAD.HEADER" />} />
        <ModalSubtitle children={<FormattedMessage id="ORDER.CREATOR.MODAL.UPLOAD.SUBTITLE" />} />
        <UploadedImageCounterRow images={images} uploadedImages={uploadedImages} />
        <LinearProgressWithLabel value={progress} />
      </Box>
    </ModalCarswip>
  );
};
