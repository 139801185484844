import { PayloadAction } from '@reduxjs/toolkit';

interface Selector {
  imageSelector: string | null;
}

const initialState: Selector = {
  imageSelector: null,
};

export const imageSelector = 'IMAGE_SELECTOR';

export const imageSelectorReducer = (state: any = initialState, action: PayloadAction<Selector, string>) => {
  switch (action.type) {
    case imageSelector: {
      return {
        ...state,
        imageSelector: action.payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
