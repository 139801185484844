import { FC } from 'react';
import { Container, Typography } from '@mui/material';

type ErrorProps = {
  title: string;
  message: string;
};

export const ErrorComponent: FC<ErrorProps> = ({ title, message }) => (
  <Container sx={{ mt: 4 }}>
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      style={{
        fontWeight: '600',
        fontSize: '4rem',
        color: '#5e6278',
        marginBottom: '2.5rem',
      }}
    >
      {title}
    </Typography>
    <Typography
      variant="body1"
      align="center"
      gutterBottom
      style={{
        fontWeight: '500',
        color: '#b5b5c3',
        marginBottom: '2.5rem',
        fontSize: '1.5rem',
      }}
    >
      {message}
    </Typography>
  </Container>
);
