interface ITableReload {
  [entity: string]: {
    reload: boolean;
  };
}
export interface IActionReturn<Payload> {
  entity: string;
  payload: Payload;
  type: string;
}

const initialState: ITableReload = {
  salesmen: {
    reload: false,
  },
};

export const RELOAD_TABLE_FROM_MODAL = 'RELOAD_TABLE_FROM_MODAL';

export const reloadTableFromModal = (state: ITableReload = initialState, action: any): ITableReload => {
  switch (action.type) {
    // This action should be used after an entity is changed, after the request is handled should be true and reload the list
    case RELOAD_TABLE_FROM_MODAL: {
      return {
        ...state,
        [action.entity]: {
          ...action.payload,
        },
      };
    }

    default:
      return {
        ...state,
      };
  }
};
