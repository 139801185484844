import { shallowEqual, useSelector } from 'react-redux';

import { UserModel } from '../../app/pages/auth/interfaces';
import { RootState } from '../../setup';

export const User = (): UserModel => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel;

  return user;
};
