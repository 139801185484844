import styled from '@emotion/styled';

export const RequiredSpan = styled('span')({
  fontSize: '1.20rem',
  fontWeight: '500',
  '&:after': {
    content: "'*'",
    position: 'relative',
    fontSize: 'inherit',
    color: 'red',
    paddingLeft: '0.25rem',
    fontWeight: 'bold',
  },
});
