import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

export const CreateStyleGuideButton: React.FC = () => (
  <Link to="/create-style-guide-v2">
    <Button className="mb-5" color="primary" variant="contained">
      <FormattedMessage id="STYLE_GUIDE.CREATE" />
    </Button>
  </Link>
);
