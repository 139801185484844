import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ImageListType, ImageType } from 'react-images-uploading';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Radio, Switch } from '@mui/material';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { AngleCategory } from '../../../constants';
import { getAngle, postAngle, putAngle } from '../../../setup/api/angle';
import { addUtility } from '../../../setup/api/utilities';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { reloadTable } from '../../../setup/redux/actions';
import { ButtonLoader } from '../../../ui/components/Button/ButtonLoader';
import { ImageDropZone } from '../../../ui/components/ImageDropzone/ImageDropZone';
import { MainGridContainer } from '../../../ui/components/MainGridContainer/MainGridContainer';
import { ModalCarswip } from '../../../ui/components/Modal';

type IProps = {
  button?: boolean;
};

interface IAngleForm {
  name: string | null;
  needsAngleCorrection: boolean;
  category: string;
  needsFlashlight: boolean;
  defaultAngleOverlay?: string;
  defaultFrameOverlay?: string;
}

const createAngleSchema = (intl: IntlShape) =>
  Yup.object({
    name: Yup.string()
      .required(
        intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED' }, { name: intl.formatMessage({ id: 'ANGLE.NAME' }) }),
      )
      .label('name'),
    needsAngleCorrection: Yup.string().label('needsAngleCorrection'),
    needsFlashlight: Yup.string().label('needsFlashlight'),
  });

const inits: IAngleForm = {
  name: '',
  needsAngleCorrection: false,
  needsFlashlight: false,
  category: AngleCategory.EXTERIOR,
};

enum Overlays {
  ANGLE_OVERLAY = 'angle_overlay',
  FRAME_OVERLAY = 'frame_overlay',
}
const CreateAngleModal: React.FC<IProps> = ({ button }) => {
  const intl = useIntl();
  const { id } = useParams<TParams>();
  const { request } = useFetch();
  const history = useHistory();
  const [angleOverlay, setAngleOverlay] = useState<Array<ImageBlob>>([]);
  const [frameOverlay, setFrameOverlay] = useState<Array<ImageBlob>>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [, setCategory] = useState<AngleCategory>(AngleCategory.EXTERIOR);
  const [initValues, setInitialData] = useState<IAngleForm>(inits);
  const dispatch = useDispatch();

  const modalToggle = () => {
    setIsOpen(!isOpen);
    history.push(`/${'admin/angles'}`);
  };

  const updateOverlays = (id: string, angle: ImageBlob, frame: ImageBlob) => {
    if (angle?.data_url) {
      addUtility(angle, Overlays.ANGLE_OVERLAY).then(angleResponse => {
        putAngle(
          {
            defaultAngleOverlay: angleResponse.id,
          },
          id,
        ).then(() => {
          setIsOpen(false);
          dispatch(reloadTable({ reload: true }, 'angle'));
        });
      });
    }
    if (frame && frame.data_url) {
      addUtility(frame, Overlays.FRAME_OVERLAY).then(frameResponse => {
        putAngle(
          {
            defaultFrameOverlay: frameResponse.id,
          },
          id,
        ).then(() => {
          setIsOpen(false);
          dispatch(reloadTable({ reload: true }, 'angle'));
        });
      });
    }
  };
  const updateAngle = (id: string, values: IAngleForm) => {
    delete values.defaultAngleOverlay;
    delete values.defaultFrameOverlay;
    request(putAngle, values, id).then(() => {
      updateOverlays(id, angleOverlay[0], frameOverlay[0]);
      modalToggle();
    });
  };
  const handleAngleRequest = async (values: IAngleForm) =>
    request(postAngle, values)
      .then((res: { id: string }) => {
        if (res?.id) {
          const { id } = res;

          updateOverlays(id, angleOverlay[0], frameOverlay[0]);
        }
      })
      .then(() => {
        setIsOpen(false);
        dispatch(reloadTable({ reload: true }, 'angle'));
      });

  useEffect(() => {
    if (id) {
      request(getAngle, id).then(res => {
        setInitialData(res);
        setIsOpen(true);
        setCategory(res.category);
      });
    }
  }, [id]);

  return (
    <>
      {button && (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            setInitialData(inits);
            history.push(`/${'admin/angles'}`);
            setIsOpen(true);
          }}
        >
          <FormattedMessage id="STYLE_GUIDE.ADD_ANGLE" />
        </button>
      )}

      <ModalCarswip open={isOpen} onClose={modalToggle} title="Angle">
        <MainGridContainer>
          <Formik
            validationSchema={createAngleSchema(intl)}
            initialValues={initValues}
            enableReinitialize
            onSubmit={values => {
              if (id) {
                updateAngle(id, values);
              } else if ((frameOverlay[0], angleOverlay[0] !== null)) {
                handleAngleRequest(values);
              }
            }}
          >
            {(props: FormikProps<IAngleForm>) => (
              <Form onSubmit={props.handleSubmit} className="form">
                <div className="w-100">
                  <div className="fv-row mb-10 ">
                    <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                      <span className="required">
                        <FormattedMessage id="STYLE_GUIDE.ANGLE_NAME" />
                      </span>
                    </label>

                    <Field
                      type="text"
                      className={`form-control form-control-lg  userInputFields ${
                        props.touched.name && props.errors.name ? 'is-invalid' : 'form-control-solid'
                      }`}
                      name="name"
                    />
                    <div style={{ color: 'red', marginTop: '-10px' }}>
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                  <div className="fv-row d-flex align-content-center align-items-center  mb-10 ">
                    <div className="col-6">
                      <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                        <span className="required">
                          <FormattedMessage id="STYLE_GUIDE.ANGLE_CATEGORY" />
                        </span>
                      </label>

                      <label className="me-5">
                        <Field type="radio" name="category" control={<Radio />} value={AngleCategory.EXTERIOR} />
                        <span>
                          <FormattedMessage id="STYLE_GUIDE.ANGLE_CATEGORY.EXTERIOR" />
                        </span>
                      </label>
                      <label>
                        <Field type="radio" name="category" control={<Radio />} value={AngleCategory.INTERIOR} />
                        <span>
                          <FormattedMessage id="STYLE_GUIDE.ANGLE_CATEGORY.INTERIOR" />
                        </span>
                      </label>
                    </div>

                    <div className=" offset-1 col-4">
                      <label className="d-flex align-items-center  fs-5 fw-bold mb-2">
                        <span>
                          <FormattedMessage id="STYLE_GUIDE.ANGLE_CORRECTION" />
                        </span>
                      </label>
                      <div className="d-flex align-items-center" style={{ marginRight: '6em' }}>
                        <Switch
                          name="category"
                          defaultChecked={props.values.needsAngleCorrection}
                          onChange={e => {
                            setInitialData({
                              ...initValues,
                              needsAngleCorrection: e.target.checked,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="offset-7 col-4  mb-5"
                      style={{
                        paddingLeft: '0px',
                      }}
                    >
                      <label className="d-flex align-items-center  fs-5 fw-bold mb-2">
                        <span>
                          <FormattedMessage id="STYLE_GUIDE.FLASHLIGHT" />
                        </span>
                      </label>
                      <div className="d-flex align-items-center" style={{ marginRight: '6em' }}>
                        <Switch
                          name="needsFlashlight"
                          defaultChecked={props.values.needsFlashlight}
                          onChange={e => {
                            setInitialData({
                              ...initValues,
                              needsFlashlight: e.target.checked,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-between fv-row mb-10 mt-10">
                    <span className="align-items-center  fs-5 fw-bold required">
                      <FormattedMessage id="STYLE_GUIDE.ANGLE_OVERLAY" />
                    </span>

                    <ImageDropZone
                      images={[props.initialValues?.defaultAngleOverlay as unknown as ImageType]}
                      maxNumber={1}
                      setImages={setAngleOverlay as unknown as Dispatch<SetStateAction<ImageListType | undefined>>}
                      selfDrop={false}
                    />
                  </div>
                  <div className="d-flex justify-content-between align-items-center fv-row">
                    <span className="fs-5 fw-bold required">
                      <FormattedMessage id="STYLE_GUIDE.FRAME_OVERLAY" />
                    </span>

                    <ImageDropZone
                      images={[props.initialValues.defaultFrameOverlay as unknown as ImageType]}
                      maxNumber={1}
                      setImages={setFrameOverlay as unknown as Dispatch<SetStateAction<ImageListType | undefined>>}
                      selfDrop={false}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-end flex-stack pt-10">
                  <ButtonLoader
                    text={intl.formatMessage({ id: id ? 'ACTION.UPDATE' : 'ACTION.CREATE' })}
                    loading={props.isSubmitting}
                    request={props.handleSubmit}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </MainGridContainer>
      </ModalCarswip>
    </>
  );
};

export { CreateAngleModal };
