import config from 'react-global-configuration';

import { generateRequestOptions } from './helpers';

export const getOrders = (filter: string, abort: AbortSignal): Promise<Response> => {
  const url = `${config.get('ApiUrl').Rest}/orders/get-orders${filter}`;

  return fetch(url, generateRequestOptions('GET', abort));
};
export const createOrder = (orderData: IOrder): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/orders`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(orderData),
    mode: 'cors',
    credentials: 'include',
  });

export const modifyOrder = (orderId: string, orderData: IOrder): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/orders/${orderId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(orderData),
    mode: 'cors',
    credentials: 'include',
  });

export const approveQAOrderStatus = (orderId: string, status: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/orders/approval/${status}/${orderId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    credentials: 'include',
  });

export const changeOrderStatus = (orderId: string, payload: IOrder): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/orders/${orderId}`, {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    credentials: 'include',
  });

export const getSingleOrder = (orderId: string, abort: AbortSignal): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/orders/${orderId}`, {
    method: 'GET',
    mode: 'cors',
    signal: abort,
    credentials: 'include',
  });

export const getOrderByUser = (userId: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/orders?userId=${userId}`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
  });

export const getOrderFromChildrenAccounts = (userId: string, queries: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/orders/getOrdersFromChildrenAccounts/${userId}${queries || ''} `, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
  });

export const getOrdersByTag = (tagName: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/orders/tag/${tagName}`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
  });

export const getEditorOrderRequest = (tagName: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/orders/editor/${tagName}`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
  });

export const deleteOrderById = (orderId: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/orders/${orderId}`, {
    method: 'DELETE',
    mode: 'cors',
    credentials: 'include',
  });
