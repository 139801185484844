import config from 'react-global-configuration';

export const getVideos = (): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/videos`, {
    mode: 'cors',
    credentials: 'include',
  });
export const getVideosByOrderId = (orderId: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/videos/order/${orderId}`, {
    mode: 'cors',
    credentials: 'include',
  });

export const createVideo = (videoData: Blob | any, orderId: string): Promise<Response> => {
  const formData = new FormData();

  formData.append('file', videoData.file);
  formData.append('order', orderId);

  formData.values().next();

  return fetch(`${config.get('ApiUrl').Rest}/videos`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(videoData),
    mode: 'cors',
    credentials: 'include',
  });
};

export const modifyVideo = (videoId: string, videoData: any): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/videos/${videoId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(videoData),
    mode: 'cors',
    credentials: 'include',
  });

export const downloadVideo = (videoId: string): Promise<void | Promise<void>> =>
  fetch(`${config.get('ApiUrl').Rest}/videos/download/${videoId}`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
  })
    .then(response => response.json())
    .then(response => {
      const { url } = response;
      const a = document.createElement('a');

      a.href = url;
      a.download = 'video.mp4';
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    });
