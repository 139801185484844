import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { PageTitle } from '../../../_carswip/layout/core';
import { UserRole } from '../../../constants';
import { RootState } from '../../../setup';
import { AdminDashboardPage } from '../../components/dashboard/AdminDashboard';
import { ManagementDashboard } from '../../components/dashboard/ManagementDashboard';
import { UserDashboard } from '../../components/dashboard/UserDashboard';

export enum TimePeriod {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export type TChartData = {
  labels: string[];
  series: number[];
};

/**
 *  Check the user role and loads different dashboards depending on it
 */
export const DashboardWrapper: FC = () => {
  const userRole = useSelector((state: RootState) => state?.auth?.user?.userRole);
  const userId = useSelector((state: RootState) => state?.auth?.user?.id);

  // Revisar esta parte
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        <FormattedMessage id="MENU.DASHBOARD" />
      </PageTitle>
      {userRole === UserRole.DEALERSHIP && userId && <UserDashboard userId={userId} />}
      {userRole === UserRole.ADMIN && <AdminDashboardPage />}
      {userRole === UserRole.MANAGEMENT && userId && <ManagementDashboard userId={userId} />}
    </>
  );
};
