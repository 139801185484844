import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';

import { postStyleguideConfiguration } from '../../../setup/api';
import { useFetch } from '../../../setup/hooks/fetch.hook';

import { Configuration } from './configuration/Configuration';

interface IStyleGuideImageLoaderProps {
  configurations: IStyleGuideConfigurations[];
  styleGuideId?: string;
}

/**
 * @param props.configurations Array with the configurations that will be displayed
 * @returns
 */
const StyleGuideConfigurationLoader: FC<IStyleGuideImageLoaderProps> = ({ configurations, styleGuideId }) => {
  const params = useParams<{ styleGuideId: string }>().styleGuideId;
  const isStyleGuideId = params && params?.length > 0;
  const { request } = useFetch();
  const [styleguideConfigurations, setStyleGuideConfigurations] = useState<IStyleGuideConfigurations[]>(configurations);
  const deleteConfiguration = (data: IStyleGuideConfigurations) => {
    const lastStyleGuideConfiguration = styleguideConfigurations.splice(styleguideConfigurations.indexOf(data), 1);
    const removeConfiguration = styleguideConfigurations.filter(
      (item: IStyleGuideConfigurations) => lastStyleGuideConfiguration.indexOf(item) === -1,
    );
    const refactorAllConfigurations = removeConfiguration.map(
      (configuration: IStyleGuideConfigurations, index: number) => ({
        ...configuration,
        position: index + 1,
      }),
    );

    setStyleGuideConfigurations(refactorAllConfigurations);
  };

  const addNewConfiguration = () => {
    const lastConfiguration = styleguideConfigurations[styleguideConfigurations.length - 1];

    if (isStyleGuideId) {
      const newStyleGuideConfig: IStyleGuideConfigurations = {
        id: '',
        position: styleguideConfigurations.length + 1,
        margins: '{"top":10,"bottom":4,"left":5,"right":5}',
        routeTo: 'AI',
        type: 'IMAGE',
        withLogo: true,
        styleGuide: params,
        createdAt: new Date(),
        updatedAt: new Date(),
        deletedAt: null,
      };
      request(postStyleguideConfiguration, newStyleGuideConfig).then(
        (configurationResponse: IStyleGuideConfigurations) => {
          setStyleGuideConfigurations(styleguideConfigurations.concat(configurationResponse));
        },
      );
    } else {
      const newConfiguration = {
        ...lastConfiguration,
        position: styleguideConfigurations.length + 1,
      };

      setStyleGuideConfigurations(styleguideConfigurations.concat(newConfiguration));
    }
  };

  return (
    <div className="row align-items-center">
      {styleguideConfigurations.length > 0 &&
        styleguideConfigurations.map((configuration: IStyleGuideConfigurations) => (
          <Configuration
            configurationInfo={configuration}
            styleGuideId={styleGuideId}
            deleteCallback={() => deleteConfiguration(configuration)}
          />
        ))}
      <div className="col-2 mb-10">
        <Button
          type="button"
          variant="contained"
          color="primary"
          style={{ display: 'flex', height: '120px', width: '100px' }}
          onClick={() => addNewConfiguration()}
        >
          <AddCircleIcon style={{ fontSize: '5em', position: 'absolute', margin: 'auto' }} />
        </Button>
      </div>
    </div>
  );
};

export { StyleGuideConfigurationLoader };
