import { FormattedMessage } from 'react-intl';

import './Louder.css';

export function Loader() {
  return (
    <>
      <div className="dot-container-loading-text fw-bold fs-2">
        <FormattedMessage id="ORDER.NOTIFICATION.PENDING" />
      </div>
      <div className="snippet">
        <div className="dot-container">
          <div className="dot-pulse-yellow" />
          <div className="dot-pulse" />
          <div className="dot-pulse-red" />
        </div>
      </div>
    </>
  );
}
