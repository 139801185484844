import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { Box, Button, Container } from '@mui/material';

import { FooterLinks } from '../../../ui/components/FooterLinks/FooterLinks';
import { toAbsoluteUrl } from '../../../ui/helpers';
import { ErrorComponent } from '../../components/errors/ErrorComponent';

const ErrorRouters: FC = () => {
  const intl = useIntl();

  return (
    <Switch>
      <Route path="/error/404" exact>
        <ErrorComponent
          title={intl.formatMessage({ id: 'ERROR.404' })}
          message={intl.formatMessage({ id: 'ERROR.404.MSG' })}
        />
      </Route>
      <Route path="/error/401" exact>
        <ErrorComponent
          title={intl.formatMessage({ id: 'ERROR.401' })}
          message={intl.formatMessage({ id: 'ERROR.401.MSG' })}
        />
      </Route>
      <Route path="/error/500" exact>
        <ErrorComponent
          title={intl.formatMessage({ id: 'ERROR.500' })}
          message={intl.formatMessage({ id: 'ERROR.500.MSG' })}
        />
      </Route>
      <Redirect to="/error/500" />
    </Switch>
  );
};

/**
 * Page with all errors from fetches 401 unauthorized, 404 not found and 500 internal server error
 */
export const ErrorsPage: FC = () => (
  <Container
    sx={{
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
      textAlign: 'center',
      pt: { xs: 10, lg: 20 },
      pb: 10,
    }}
  >
    <img alt="Logo" src={toAbsoluteUrl('/media/logos/logo-1.svg')} style={{ height: '50px', marginBottom: 15 }} />
    <Box sx={{ flex: '1', mb: 10 }}>
      <ErrorRouters />
      <Link to="/auth/me" style={{ textAlign: 'center' }}>
        <Button variant="contained" style={{ marginRight: '1em' }} children={<FormattedMessage id="GO_HOME" />} />
      </Link>
    </Box>
    <FooterLinks />
  </Container>
);
