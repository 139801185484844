import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Alert, Button, TextField } from '@mui/material';

import { MessageBoxPayload, sendPushNotification } from '../../../setup/api/mobile';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { User } from '../../../ui/helpers';

const checkStatusType = (status: number) => Math.floor(status / 100);

export const PushNotificationForm: React.FC = () => {
  const intl = useIntl();
  const { id } = useParams<TParams>();
  const { request, isLoading, status } = useFetch();
  const { email } = User();
  const messageInits = {
    title: undefined,
    body: undefined,
    orderId: id,
    eventPayload: intl.formatMessage({ id: 'ORDER.NOTIFICATION.PAYLOAD' }, { email }),
  };
  const [payload, setPayload] = useState<MessageBoxPayload>(messageInits);
  /**
   * Check if the request is valid
   */
  const isDisabled = () => {
    const { title, body } = payload;

    return !(title && title?.length > 3 && body && body.length > 3);
  };
  const submitForm = () => {
    request(sendPushNotification, payload)
      .then(() => {
        // Empty the fields
        setPayload({ ...messageInits, body: '', title: '' });
      })
      .catch(() => {
        setPayload({ ...messageInits, body: '', title: '' });
      });
  };

  return (
    <div className="card">
      <div className="card-header bg-white">
        <h5 className="card-title">
          <FormattedMessage id="ORDER.NOTIFICATION.HEADER" />
        </h5>
      </div>
      <div className="card-body bg-white container-fluid">
        <TextField
          className="mb-5"
          label={intl.formatMessage({ id: 'ORDER.NOTIFICATION.TITLE' })}
          fullWidth
          value={payload.title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPayload({ ...payload, title: e.target.value });
          }}
        />
        <TextField
          className="mb-5"
          fullWidth
          label={intl.formatMessage({ id: 'ORDER.NOTIFICATION.MESSAGE' })}
          rows={5}
          multiline
          value={payload.body}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPayload({ ...payload, body: e.target.value });
          }}
        />
        <div className="d-flex justify-content-end mb-5">
          <Button variant="contained" color="primary" disabled={isLoading || isDisabled()} onClick={submitForm}>
            <FormattedMessage id={isLoading ? 'ORDER.NOTIFICATION.PENDING' : 'ORDER.NOTIFICATION.SEND'} />
          </Button>
        </div>
        {status && (
          <>
            {checkStatusType(status) >= 4 && (
              <Alert severity="error">
                <FormattedMessage id="ORDER.NOTIFICATION.ERROR" />
              </Alert>
            )}
            {checkStatusType(status) < 4 && (
              <Alert severity="success">
                <FormattedMessage id="ORDER.NOTIFICATION.SUCCESS" />
              </Alert>
            )}
          </>
        )}
      </div>
    </div>
  );
};
