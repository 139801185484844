import config from 'react-global-configuration';

import { TimePeriod } from '../../app/pages/dashboard/DashboardWrapper';
import { TagCategory } from '../../constants';

import { generateRequestOptions } from './helpers';

export enum QueryTimePeriod {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}
export enum QueryTimeFrame {
  CURRENT = 'current',
  LAST = 'last',
}
export interface IQueriesTimeStatistics {
  timePeriod: QueryTimePeriod;
  timeFrame: QueryTimeFrame;
  tagCategoryFromQA?: TagCategory;
}
export const getChildAccountStatistics = (id: string, dataType: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/users/childAccounts/${id}/${dataType}`, {
    mode: 'cors',
    credentials: 'include',
  });

export const getAmountOfImagesByEditionStatus = (id: string, timePeriod: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/dashboard/getAmountOfImagesByEditionStatus/${id}?timePeriod=${timePeriod}`, {
    mode: 'cors',
    credentials: 'include',
  });

export const getDealershipOrderStatistics = (id: string, timePeriod: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/dashboard/getDealershipOrderStatistics/${id}?timePeriod=${timePeriod}`, {
    mode: 'cors',
    credentials: 'include',
  });

export const getOrdersPerSalesMan = (id: string, timePeriod: QueryTimePeriod): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/dashboard/getOrdersPerSalesMan/${id}?timePeriod=${timePeriod}`, {
    mode: 'cors',
    credentials: 'include',
  });

export const getOrdersPerDealership = (id: string, timePeriod: QueryTimePeriod): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/dashboard/get-orders-by-dealership/${id}?timePeriod=${timePeriod}`, {
    mode: 'cors',
    credentials: 'include',
  });

export const defaultQueryTimeStatistics: IQueriesTimeStatistics = {
  timePeriod: QueryTimePeriod.MONTH,
  timeFrame: QueryTimeFrame.CURRENT,
};

export const getDeviceStatistics = (abort: AbortSignal): Promise<Response> => {
  const url = `${config.get('ApiUrl').Rest}/dashboard/get-device-statistics`;

  return fetch(url, generateRequestOptions('GET', abort));
};

export const getAppVersionStatistics = (abort: AbortSignal): Promise<Response> => {
  const url = `${config.get('ApiUrl').Rest}/dashboard/get-appVersion-statistics`;

  return fetch(url, generateRequestOptions('GET', abort));
};

export const getQAActivitiesByTimePeriod = (
  queries: IQueriesTimeStatistics = defaultQueryTimeStatistics,
  abort: AbortSignal,
): Promise<Response> => {
  const url = `${config.get('ApiUrl').Rest}/dashboard/getQAActivitiesByTimePeriod?timePeriod=${
    queries.timePeriod
  }&timeFrame=${queries.timeFrame}&tagCategoryFromQA=${queries.tagCategoryFromQA}`;

  return fetch(url, generateRequestOptions('GET', abort));
};

export const getOrderStatisticsPerMonthTotal = (timePeriod: TimePeriod, abort: AbortSignal): Promise<Response> => {
  const url = `${config.get('ApiUrl').Rest}/dashboard/getOrderStatisticsPerMonth?timePeriod=${timePeriod}`;

  return fetch(url, generateRequestOptions('GET', abort));
};
export const getImageStatisticsPerMonthTotal = (timePeriod: TimePeriod, abort: AbortSignal): Promise<Response> => {
  const url = `${config.get('ApiUrl').Rest}/dashboard/getImageStatisticsPerMonthTotal?timePeriod=${timePeriod}`;

  return fetch(url, generateRequestOptions('GET', abort));
};

export const getOrderTimeLineStats = (timeLineStep: number): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/dashboard/get-order-timeline-data?timeLineStep=${timeLineStep}`, {
    mode: 'cors',
    credentials: 'include',
  });

export const getAveragePicturePerOrder = (abort: AbortSignal): Promise<Response> => {
  const url = `${config.get('ApiUrl').Rest}/dashboard/get-average-images-per-car`;

  return fetch(url, generateRequestOptions('GET', abort));
};

export const getTotalOrderByStatus = (abort: AbortSignal): Promise<Response> => {
  const url = `${config.get('ApiUrl').Rest}/dashboard/get-total-orders-by-status`;

  return fetch(url, generateRequestOptions('GET', abort));
};

export const getInactiveUsers = (timeFrame: number, page: number, abort: AbortSignal): Promise<Response> => {
  const url = `${config.get('ApiUrl').Rest}/dashboard/get-inactive-users?timeFrame=${timeFrame}&page=${page}`;

  return fetch(url, generateRequestOptions('GET', abort));
};
type CarsPerStyleguideOnManagementAccountRequest = {
  query: {
    timeFrame: QueryTimeFrame;
    timePeriod: QueryTimePeriod;
  };
  params: {
    userId: string;
  };
};

export const getCarsPerStyleguideOnManagementAccounts = (
  requestInformation: CarsPerStyleguideOnManagementAccountRequest,
  abort: AbortSignal,
): Promise<Response> => {
  const { query, params } = requestInformation;
  const url = `${config.get('ApiUrl').Rest}/dashboard/get-amount-of-orders-per-styleguide-from-child-accounts/${
    params.userId
  }?timePeriod=${query.timePeriod}&timeFrame=${query.timeFrame}`;

  return fetch(url, generateRequestOptions('GET', abort));
};

type ImagesStatisticsParams = {
  timeStamp: string;
  abort: AbortSignal;
};

export const getRepushedImagesStatistics = ({ timeStamp, abort }: ImagesStatisticsParams): Promise<Response> => {
  const url = `${config.get('ApiUrl').Rest}/dashboard/get-repushed-images-statistics?timeStamp=${timeStamp}`;

  return fetch(url, generateRequestOptions('GET', abort));
};

export const getEditedImagesStatistics = ({ timeStamp, abort }: ImagesStatisticsParams): Promise<Response> => {
  const url = `${config.get('ApiUrl').Rest}/dashboard/get-edited-images-statistics?timeStamp=${timeStamp}`;

  return fetch(url, generateRequestOptions('GET', abort));
};

export const getTotalAmountEventsLast30Days = (eventName: string, abort: AbortSignal): Promise<Response> => {
  const url = `${config.get('ApiUrl').Rest}/dashboard/get-statistics-total-events-last-30-days?eventName=${eventName}`;

  return fetch(url, generateRequestOptions('GET', abort));
};

export const getFlawlessOrderStatistics = (abort: AbortSignal): Promise<Response> => {
  const url = `${config.get('ApiUrl').Rest}/dashboard/get-amount-of-flawless-orders`;

  return fetch(url, generateRequestOptions('GET', abort));
};
