import { Children, cloneElement, FC, useEffect, useReducer } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import { useQueryParams } from '../../../setup/hooks/useQueryParams';

const PaginationReducers = {
  PREVIOUS_PAGE: 'PREVIOUS_PAGE',
  FIRST_PAGE: 'FIRST_PAGE',
  NEXT_PAGE: 'NEXT_PAGE',
  LAST_PAGE: 'LAST_PAGE',
} as const;

// Inferring types from Const
type PaginationReducersType = ObjectValues<typeof PaginationReducers>;

type PaginationReducerState = {
  page: number;
  maxPage: number;
};
type PaginationAction = {
  type: PaginationReducersType;
  payload: PaginationReducerState;
};

const pageReducer = (state: PaginationReducerState, { type, payload }: PaginationAction) => {
  const { maxPage, page } = payload;

  switch (type) {
    case 'PREVIOUS_PAGE':
      return { ...state, page: maxPage >= page ? page - 1 : page };

    case 'FIRST_PAGE':
      return { ...state, page: 1 };

    case 'NEXT_PAGE':
      return { ...state, page: maxPage > page ? page + 1 : page };

    case 'LAST_PAGE':
      return { ...state, page: maxPage };

    default:
      return state;
  }
};
const isThereAPageOnTheQueries = (page: string) => {
  if (Number(page) <= 1) {
    return true;
  }
  if (!page) {
    return true;
  }
  return false;
};

type PaginationFilterProps = {
  maxPage: number;
  disableLeftArrows?: boolean;
};

type TRenderPaginationButtonGroup = {
  isDisabled?: boolean;
  children: JSX.Element[];
};
/**
 * Renders a group of pagination buttons.
 *
 * @param props.isDisabled - (optional) Disables the pagination button group if set to true.
 * @param props.children - An array of JSX elements to render as the pagination buttons.
 * @returns A JSX element representing the pagination button group.
 */
const RenderPaginationButtonGroup: FC<TRenderPaginationButtonGroup> = ({ isDisabled, children }) => {
  const selectClassName = isDisabled ? 'page-item disabled' : 'page-item';

  return (
    <li className={selectClassName}>
      {Children.map(children, (child: JSX.Element) => cloneElement(<a className="page-link">{child}</a>))}
    </li>
  );
};
/**
 *  Renders pagination filters and total amount of items
 *
 * @param { number } props.maxPage  The maximum number of pages to display in the pagination.
 * @param {boolean} props.disableLeftArrow A flag indicating whether to disable the left arrows for navigating to the first  or previous page.
 */
export const PaginationFilter: FC<PaginationFilterProps> = ({ maxPage, disableLeftArrows = false }) => {
  const [queries, setQueries] = useQueryParams();
  const initialState = {
    page: queries.page ? Number(queries.page) : 1,
    maxPage: maxPage && Math.ceil(maxPage),
  };
  const [state, dispatch] = useReducer(pageReducer, initialState);
  const dispatchOnAction = (type: PaginationReducersType) => {
    dispatch({ type, payload: initialState });
  };

  /*
   * Props.maxPage comes from an async function that re-renders the filter when is updated,
   * this ensures that the max page values is there, avoiding having to set the queries again
   */
  useEffect(() => {
    if (maxPage) {
      setQueries('page', state.page.toString());
    }
  }, [state.page]);

  return (
    <ul className="pagination">
      {!disableLeftArrows && (
        <RenderPaginationButtonGroup isDisabled={isThereAPageOnTheQueries(queries.page)}>
          <KeyboardDoubleArrowLeftIcon
            onClick={() => {
              dispatchOnAction('FIRST_PAGE');
            }}
          />
          <KeyboardArrowLeftIcon
            onClick={() => {
              dispatchOnAction('PREVIOUS_PAGE');
            }}
          />
        </RenderPaginationButtonGroup>
      )}
      <li className="page-item">
        <a className="page-link">{queries.page ? queries.page : 1}</a>
      </li>
      <RenderPaginationButtonGroup>
        <KeyboardArrowRightIcon
          onClick={() => {
            dispatchOnAction('NEXT_PAGE');
          }}
        />
        <KeyboardDoubleArrowRightIcon
          onClick={() => {
            dispatchOnAction('LAST_PAGE');
          }}
        />
      </RenderPaginationButtonGroup>
    </ul>
  );
};
