import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../../setup';
import { getCarTypes } from '../../../../../setup/api/carType';
import { useFetch } from '../../../../../setup/hooks/fetch.hook';
import { Datagrid } from '../../../../../ui/components/Datagrid/Datagrid';
import { CreateCarTypeModal } from '../../../../components/carType/CreateCarTypeModal';

import { renderTestsRow } from './helpers';

const CarTypesList: FC = () => {
  const intl = useIntl();
  const { request, isLoading, abortController } = useFetch();
  const [carTypes, setCarTypes] = useState([]);

  const carTypeHeaders: [string, string][] = [
    ['icon', intl.formatMessage({ id: 'ICON' })],
    ['carTypeName', intl.formatMessage({ id: 'CAR_TYPE.NAME' })],
  ];

  const fetchCarTypes = () => {
    request(getCarTypes, abortController.signal)
      .then(data => {
        setCarTypes(data);
      })
      .catch();
  };
  const reloadValue = useSelector((state: RootState) => state.reloadTableModal);

  useEffect(() => {
    fetchCarTypes();
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (reloadValue?.cartype?.reload) {
      fetchCarTypes();
    }
  }, [reloadValue]);

  return (
    <div className="content d-flex flex-column flex-column-fluid">
      <div className="card">
        <div className="card-header border-0 pt-6">
          <div className="card-title">
            <div className="d-flex align-items-center position-relative my-1" />
          </div>
          <div className="card-toolbar">
            <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
              <CreateCarTypeModal button />
            </div>
          </div>
        </div>
        <div className="card-body pt-10">
          <Datagrid
            data={carTypes}
            loading={isLoading}
            headings={carTypeHeaders}
            renderRow={renderTestsRow}
            modalURL="admin/car-type"
          />
        </div>
      </div>
    </div>
  );
};

export { CarTypesList };
