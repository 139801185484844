const errorMessages = [
  {
    pattern: /^Custom auth.*/i,
    message: 'ERROR.CREDENTIALS',
  },
  {
    pattern: /^CUSTOM_AUTH.*/i,
    message: 'ERROR.AUTH',
  },
  {
    pattern: /^2 validation.*/i,
    message: 'ERROR.EMAIL',
  },
  {
    pattern: /^Not.*/i,
    message: 'ERROR.INVALID_CREDENTIALS',
  },
  {
    pattern: /^Password.*/i,
    message: 'ERROR.PASSWORD.LEVEL',
  },
  {
    pattern: /^Code.*/i,
    message: 'ERROR.VERIFICATION.CODE',
  },
  {
    pattern: /^Expired.*/i,
    message: 'ERROR.VERIFICATION.EXPIRED',
  },
  {
    pattern: /^Too.*/i,
    message: 'ERROR.ATTEMPTS',
  },
  {
    pattern: /^Invalid verification.*/i,
    message: 'ERROR.VERIFICATION',
  },
  {
    pattern: /^Invalid code provided.*/i,
    message: 'ERROR.VERIFICATION',
  },
  {
    pattern: /^Invalid username.*/i,
    message: 'ERROR.CREDENTIALS',
  },
  {
    pattern: /^Incorrect username.*/i,
    message: 'ERROR.CREDENTIALS',
  },
  {
    pattern: /^Limit.*/i,
    message: 'ERROR.ATTEMPTS.MAX',
  },
  {
    pattern: /.*/,
    message: 'ERROR.ANY',
  },
];

export const getErrorMessage = (message: string) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { message: errorMessage } = errorMessages.find(({ pattern }) => pattern.test(message))!;

  return errorMessage;
};
