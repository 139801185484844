import { ChangeEvent, FC, useMemo } from 'react';
import { SketchPicker } from 'react-color';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, TextField } from '@mui/material';

import HorizontalLineExample from '../../../_carswip/assets/img/horizon_line_guide_v2.jpg';

import { StyleGuideTable } from './RepushStyleGuideTable';

const boxStyle = {
  justifyContent: 'center',
  display: 'flex',
  marginTop: '1em',
  alignItems: 'center',
};

const buttonStyle = {
  width: '200px',
  height: '40px',
  margin: '10px',
};

const defaultMargins = '{"top":10,"bottom":4,"left":5,"right":5}';
const noMargins = '{"top":0,"bottom":0,"left":0,"right":0}';

type Props = {
  setRepushPayload: (repushPayload: RepushPayload) => void;
  handleRepushImages: (repushPayload: RepushPayload) => void;
  setStyleGuide: (styleGuide: IStyleGuide) => void;
  repushPayload: RepushPayload;
  styleGuide?: IStyleGuide;
  loader: boolean;
  images: IImage[];
};

export const RepushSettings: FC<Props> = (props: Props) => {
  const { handleRepushImages, images, loader, repushPayload, setRepushPayload, setStyleGuide, styleGuide } = props;
  const intl = useIntl();

  const handleImageCategoryChange = () => {
    if (repushPayload.imageCategory === 'exterior') {
      setRepushPayload({
        ...repushPayload,
        imageCategory: 'interior',
        backgroundColor: styleGuide?.interiorColor ?? '#ffffff',
        margins: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
        method: 'ai_no_enh',
      });
    } else if (repushPayload.imageCategory === 'interior') {
      setRepushPayload({ ...repushPayload, imageCategory: 'exterior' });
    }
  };

  function findMargin(): { margins: string; fromStyleGuide: boolean } {
    if (!images || images.length === 0) {
      return { margins: defaultMargins, fromStyleGuide: false };
    }

    if (!styleGuide?.configurations || styleGuide?.configurations?.length === 0) {
      return { margins: defaultMargins, fromStyleGuide: false };
    }

    const margins = styleGuide?.configurations.find(x => x.position === images[0].position)?.margins;

    if (!margins) {
      return { margins: defaultMargins, fromStyleGuide: false };
    }

    return { margins: margins.toString(), fromStyleGuide: true };
  }

  const marginsResult = useMemo(() => findMargin(), [images, styleGuide]);

  return (
    <>
      <div className="row">
        <Box sx={boxStyle}>
          <FormControlLabel
            label={intl.formatMessage({ id: 'REPUSH.SETTINGS.EXTERIOR' })}
            control={
              <Checkbox checked={repushPayload.imageCategory === 'exterior'} onChange={handleImageCategoryChange} />
            }
          />
          <FormControlLabel
            label={intl.formatMessage({ id: 'REPUSH.SETTINGS.INTERIOR' })}
            control={
              <Checkbox checked={repushPayload.imageCategory === 'interior'} onChange={handleImageCategoryChange} />
            }
          />
        </Box>
      </div>
      <div className="row">
        <div style={{ display: 'flex' }}>
          <Box sx={boxStyle}>
            {repushPayload.imageCategory === 'interior' && (
              <>
                <SketchPicker
                  styles={{ default: { picker: { width: '300px' } } }}
                  color={repushPayload.backgroundColor}
                  onChangeComplete={color =>
                    setRepushPayload({
                      ...repushPayload,
                      backgroundColor: color.hex,
                    })
                  }
                />
                <FormControlLabel
                  label={intl.formatMessage({ id: 'REPUSH.SETTINGS.COLOR' })}
                  labelPlacement="top"
                  control={
                    <TextField
                      style={{ width: '250px' }}
                      id="backgroundColor"
                      label={false}
                      variant="outlined"
                      value={repushPayload.backgroundColor}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setRepushPayload({
                          ...repushPayload,
                          backgroundColor: e.target.value,
                        });
                      }}
                    />
                  }
                />
                <Button
                  style={{ ...buttonStyle, marginTop: '20px' }}
                  id="basic-button"
                  color="error"
                  variant="contained"
                  onClick={() => {
                    repushPayload.method = repushPayload.imageCategory === 'exterior' ? 'full' : 'ai_no_enh';
                    if (styleGuide) {
                      repushPayload.styleGuideId = styleGuide.id;
                    }
                    repushPayload.isImageRepushedWithSettings = true;
                    handleRepushImages(repushPayload);
                  }}
                >
                  <FormattedMessage id="REPUSH.SETTINGS.REPUSH" />
                </Button>
              </>
            )}
          </Box>
        </div>
      </div>

      <div className="row">
        {repushPayload.imageCategory === 'exterior' ? (
          <div style={{ display: 'flex' }}>
            <div style={{ textAlign: 'center' }}>
              <img src={HorizontalLineExample} style={{ width: '300px', background: 'grey' }} alt="" />
            </div>
            <div style={{ textAlign: 'center', margin: '20px' }}>
              <FormControlLabel
                label={intl.formatMessage({ id: 'REPUSH.SETTINGS.LINE' })}
                labelPlacement="bottom"
                control={
                  <TextField
                    id="horizonLine"
                    style={{ width: '250px' }}
                    label={false}
                    variant="outlined"
                    value={repushPayload.horizonLineHeight}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setRepushPayload({
                        ...repushPayload,
                        horizonLineHeight: Number(e.target.value),
                      });
                    }}
                  />
                }
              />
              <FormControlLabel
                label={intl.formatMessage({ id: 'REPUSH.SETTINGS.MARGINS' })}
                labelPlacement="bottom"
                control={
                  <TextField
                    id="margins"
                    label={false}
                    style={{ width: '250px' }}
                    variant="outlined"
                    value={repushPayload.margins}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setRepushPayload({
                        ...repushPayload,
                        margins: e.target.value,
                      });
                    }}
                  />
                }
              />
            </div>
            <div>
              {repushPayload.imageCategory && loader ? (
                <CircularProgress size={24} />
              ) : (
                <Button
                  style={{ ...buttonStyle, marginTop: '20px' }}
                  id="basic-button"
                  color="error"
                  variant="contained"
                  onClick={() => {
                    repushPayload.method = repushPayload.imageCategory === 'exterior' ? 'full' : 'ai_no_enh';
                    if (styleGuide) {
                      repushPayload.styleGuideId = styleGuide.id;
                    }
                    repushPayload.isImageRepushedWithSettings = true;

                    handleRepushImages(repushPayload);
                  }}
                >
                  <FormattedMessage id="REPUSH.SETTINGS.REPUSH" />
                </Button>
              )}
              <Button
                style={{ ...buttonStyle }}
                variant="contained"
                color="primary"
                onClick={() => {
                  setRepushPayload({
                    ...repushPayload,
                    margins: marginsResult.margins,
                    horizonLineHeight: 50,
                  });
                }}
              >
                <FormattedMessage
                  id={
                    marginsResult.fromStyleGuide
                      ? 'REPUSH.SETTINGS.MARGINS.STYLEGUIDE'
                      : 'REPUSH.SETTINGS.MARGINS.DEFAULT'
                  }
                />
              </Button>
              {!marginsResult.fromStyleGuide && (
                <div style={{ color: 'red', margin: '10px' }}>
                  <FormattedMessage id="REPUSH.SETTINGS.MARGINS.DEFAULT.APPLIED" values={{ margins: defaultMargins }} />
                </div>
              )}
              {marginsResult.fromStyleGuide && (
                <div style={{ color: 'red', margin: '10px', width: 'min-content' }}>
                  {images?.length !== undefined && images?.length === 1 ? (
                    <FormattedMessage
                      id="REPUSH.SETTINGS.MARGINS.STYLEGUIDE.APPLIED"
                      values={{ margins: findMargin().margins }}
                    />
                  ) : (
                    <FormattedMessage
                      id="REPUSH.SETTINGS.MARGINS.STYLEGUIDE.MULTI"
                      values={{
                        br: <br />,
                        number: images[0].position,
                        margins: findMargin().margins,
                      }}
                    />
                  )}
                </div>
              )}

              <Button
                style={{ ...buttonStyle }}
                variant="contained"
                color="primary"
                onClick={() => {
                  setRepushPayload({
                    ...repushPayload,
                    margins: noMargins,
                    horizonLineHeight: 50,
                  });
                }}
              >
                <FormattedMessage id="REPUSH.SETTINGS.MARGINS.EMPTY" />
              </Button>
            </div>
          </div>
        ) : null}
      </div>
      <StyleGuideTable getSelectedStyleGuideInfo={setStyleGuide} />
    </>
  );
};
