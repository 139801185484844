import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { styled } from '@mui/styles';

import { User } from '../../ui/helpers';

type Props = {
  onClick?: () => void;
};

const MenuItemForUserMenu = styled(Link)(() => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  flex: '0 0 100%',
  padding: '0.65rem 1rem',
  transition: 'none',
  outline: 'none',
  borderRadius: '0.475rem',
  color: '#7e8299',
}));

export const HeaderUserMenu: FC<Props> = ({ onClick }: Props) => {
  const user = User();

  return (
    <div className="mx-3 my-2">
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3" style={{ overflow: 'hidden' }}>
          <div className="symbol symbol-50px me-5">
            <AccountCircleIcon />
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">{user?.displayName || 'Carswip User'}</div>
            <a className="fw-bold text-muted text-hover-primary fs-7">
              {user.loggedInSalesPersonInfo ? user?.loggedInSalesPersonInfo?.email : user.email}
            </a>
          </div>
        </div>
      </div>

      <div className="separator my-2" />

      <div className="menu-item px-5 my-1" onClick={onClick}>
        <MenuItemForUserMenu to="/account/settings" className="menu-link px-5">
          <FormattedMessage id="MENU.ACCOUNT_SETTINGS" />
        </MenuItemForUserMenu>
      </div>

      <div className="menu-item px-5">
        <MenuItemForUserMenu to="/logout" className="menu-link px-5">
          <FormattedMessage id="MENU.SIGN_OUT" />
        </MenuItemForUserMenu>
      </div>
    </div>
  );
};
