import { Dispatch, FC, SetStateAction, useState } from 'react';
import ImageUploading, { ImageListType, ImageType } from 'react-images-uploading';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/material';
import Button from '@mui/material/Button';

/**
 * @param props.setImages - The function to set the images
 * @param props.images - The images to be uploaded
 * @returns Renders a drag and drop zone for uploading images
 */

type Props = {
  images: ImageListType;
  setImages: Dispatch<SetStateAction<ImageType[]>>;
};

const DragAndDropZone = styled('div')({
  paddingTop: '1em !important',
  height: 'fit-content',
  width: '100%',
  padding: '1em',
  border: '3px dashed #dadfe3',
  borderRadius: '15px',
  overflow: 'hidden',
  textAlign: 'center',
  background: 'white',
});

const StyledDropContainer = styled('div')({
  width: '500px',
  height: '170px',
  color: '#8e99a5',
  margin: 'auto',
});

const StyledTitle = styled('div')({
  fontSize: '400%',
  textTransform: 'uppercase',
});

const StyledCircularProgress: FC<{ value: number }> = ({ value }) => (
  <div
    style={{
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      width: '50px',
      height: '50px',
      marginLeft: '2em',
      marginTop: '1em',
    }}
  >
    <div
      style={{
        width: '100%',
        height: '100%',
        border: '6px solid #f3f3f3',
        borderRadius: '50%',
        borderTop: '6px solid #3498db',
        animation: 'spin 2s linear infinite',
      }}
    />

    <div style={{ position: 'absolute' }}>
      {Math.round(value)}%{value === 100 && <FormattedMessage id="ORDER.CREATOR.IMG.COMPLETED" />}
    </div>

    <style>
      {`@keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
            }`}
    </style>
  </div>
);

export const ImageUpload: FC<Props> = ({ images, setImages }) => {
  const [progress, setProgress] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  const uploadAsync = async (payload: ImageListType) => {
    const newImages = payload.filter(image => !images.some(x => x.file === image.file));

    for (const image of newImages) {
      setImages(prevImages => [...prevImages, image]);
      // Adjust delay as needed
      await new Promise(resolve => setTimeout(resolve, 100));
      setProgress(prevProgress => prevProgress + 100 / newImages.length);
    }
    setProgress(0);
    setIsLoading(false);
  };

  return (
    <ImageUploading
      multiple
      value={images}
      onChange={val => {
        setIsLoading(true);
        setTimeout(() => uploadAsync(val), 100);
      }}
      maxNumber={100}
      dataURLKey="data_url"
    >
      {({ onImageUpload, dragProps }) => (
        <>
          <DragAndDropZone {...dragProps} onClick={onImageUpload}>
            <StyledDropContainer>
              <StyledTitle children={<FormattedMessage id="ORDER.CREATOR.DROP" />} />
              <Button
                type="button"
                className="btn btn-primary mr-5"
                children={<FormattedMessage id="ORDER.CREATOR.BROWSE" />}
                disabled={isLoading}
              />
              {/* <Button
                                type='button'
                                onClick={e => {
                                    e.stopPropagation();
                                    setImages([]);
                                }}
                                disabled={isLoading}
                                className='btn btn-danger'
                                style={{ marginLeft: '2em' }}
                                children='Remove all images'
                            /> */}
            </StyledDropContainer>
            {isLoading ? <StyledCircularProgress value={progress} /> : null}
          </DragAndDropZone>

          {isLoading ? (
            <div style={{ display: 'grid', justifyContent: 'center', marginTop: '1em' }}>
              <FormattedMessage id="ORDER.CREATOR.IMG.PENDING" />
            </div>
          ) : null}
        </>
      )}
    </ImageUploading>
  );
};
