import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ManIcon from '@mui/icons-material/Man';

import { getUserStatistics } from '../../../setup/api';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { CarsPerSalesmanOrDealerhip } from '../statistics/CarsPerSalesmanOrDealerhip';
import { OrderGauge } from '../statistics/OrdersGauge';
import { StatisticsForTotalCount } from '../statistics/StatisticsForTotalCount';
import { UserOrderDashboard } from '../statistics/UserOrderDashboard';

interface IProps {
  userId: string;
}
const UserDashboard: FC<IProps> = ({ userId }) => {
  const intl = useIntl();
  const { request } = useFetch();
  const [userStatistics, setUserStatistics] = useState<{
    salesManCount: string;
    imagesCount: string;
    ordersCount: string;
  }>();

  useEffect(() => {
    if (userId) {
      request(getUserStatistics, userId).then(data => {
        setUserStatistics(data);
      });
    }
  }, [userId]);

  return (
    <div style={{ overflow: 'hidden' }}>
      {/* begin::Row */}
      <div className="row g-5 g-xl-8">
        <div className="col-8">
          <UserOrderDashboard className="card-xl-stretch mb-xl-8" iconMUI={<DirectionsCarIcon />} userId={userId} />
        </div>
        <div className="col-4">
          <OrderGauge userId={userId} />
        </div>
      </div>
      {/* end::Row */}

      <div className="row g-5 g-xl-8">
        <div className="col-8">
          <CarsPerSalesmanOrDealerhip
            className="card-xxl-stretch-30 mb-5 mb-xl-8"
            title={intl.formatMessage({ id: 'DASHBOARD.CARS_RATIO_SALESMEN' })}
            loadSalesMen
            userId={userId}
          />
        </div>

        {userStatistics && (
          <div className="col-4">
            <div>
              <StatisticsForTotalCount
                className="card-xl-stretch mb-xl-8"
                iconMUI={<ManIcon color="action" />}
                color="warning"
                iconColor="white"
                title={userStatistics.salesManCount}
                description={intl.formatMessage({ id: 'DASHBOARD.SALESMEN' })}
              />
            </div>
            <div>
              <StatisticsForTotalCount
                className="card-xl-stretch mb-xl-8"
                iconMUI={<DirectionsCarIcon color="action" />}
                color="white"
                iconColor="primary"
                title={userStatistics.ordersCount}
                description={intl.formatMessage({ id: 'DASHBOARD.MANAGER.CARS_RATIO_DEALERSHIP' })}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { UserDashboard };
