import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';

import { deleteStyleGuide } from '../../../../../setup/api';
import { useFetch } from '../../../../../setup/hooks/fetch.hook';
import { setStyleGuideInformation } from '../../../../../setup/redux/actions';
import { ImageDisplay } from '../../../../../ui/components/Image/ImageDisplay';
import { ConfirmDeletionModal } from '../../../../../ui/components/Modals/ConfirmDeletionModal';
import { error, info, uploading } from '../../../../../ui/helpers';
import { copyStyleGuideConfiguration } from '../../../styleGuidesV2/helpers';

type BodyProps = {
  styleGuide: IStyleGuide;
  headerKeys: string[];
  removeStyleGuide: (styleGuide: IStyleGuide) => void;
};

export const Row: FC<BodyProps> = ({ headerKeys, removeStyleGuide, styleGuide }) => {
  const intl = useIntl();
  const link = 'style-guide-v2';

  const history = useHistory();
  const dispatch = useDispatch();
  const { request } = useFetch();

  if (!styleGuide) {
    return null;
  }

  return (
    <tbody>
      <tr
        className="table-row h-40px listHover"
        onClick={() => {
          history.push(`/${link}/${styleGuide?.id}`);
        }}
      >
        <td key={headerKeys.find(x => x === 'name')}>{styleGuide?.name}</td>
        <td key={headerKeys.find(x => x === 'logo_thumbnail')}>
          <ImageDisplay src={styleGuide?.logo?.thumbnailUrl} />
        </td>
        <td key={headerKeys.find(x => x === 'background_thumbnail')}>
          <ImageDisplay src={styleGuide?.background?.thumbnailUrl} />
        </td>
        <td key={headerKeys.find(x => x === 'floor_thumbnail')}>
          <ImageDisplay src={styleGuide?.floor?.thumbnailUrl} />
        </td>
        <td
          key={headerKeys.find(x => x === 'delete')}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <ConfirmDeletionModal
            handleDelete={(itemId: string) =>
              request(deleteStyleGuide, itemId).then(result => {
                if (result.success) {
                  info(intl.formatMessage({ id: 'STYLE_GUIDE.DELETED' }), {});
                  removeStyleGuide(styleGuide);
                } else {
                  error(intl.formatMessage({ id: 'STYLE_GUIDE.DELETED.ERROR' }, { message: result.message }), {});
                }
              })
            }
            itemId={styleGuide?.id as string}
            entity={link ?? 'entity'}
            entityName={styleGuide.name}
          />
        </td>
        <td key={headerKeys.find(x => x === 'duplicate')}>
          <Button
            variant="contained"
            color="primary"
            onClick={e => {
              e.stopPropagation();
              dispatch(setStyleGuideInformation(styleGuide));
              copyStyleGuideConfiguration(styleGuide.id as string).then((styleGuideId: string) => {
                history.push(`/${link}/${styleGuideId}`);
              });
              uploading(intl.formatMessage({ id: 'STYLE_GUIDE.DUPLICATED' }), {});
            }}
          >
            <FormattedMessage id="ACTION.DUPLICATE" />
          </Button>
        </td>
      </tr>
    </tbody>
  );
};
