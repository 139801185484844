import React, { useState } from 'react';
import ImageUploading from 'react-images-uploading';
import { FormattedMessage } from 'react-intl';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';

import { downloadMediaByOrderAndPosition, downloadOrderImages } from '../../../setup/api';
import { updateImageBySignedUrl } from '../../../setup/helpers/uploadImages';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { ImageDetailModal } from '../../../ui/components/Modals/ImageDetail/ImageDetailModal';

import '../../pages/editing/EditingPage.scss';

type Props = {
  originalImage: any;
  order: IOrder;
  refresher: () => void;
};

export const SingleImageEditor: React.FC<Props> = ({ originalImage, order, refresher }) => {
  const { id, orderName } = order;
  const [images, setImages] = useState<Array<any> | null>(null);
  const history = useHistory();

  const closeImageDetailModal = (): void => {
    history.push('/editing-page');
  };
  const openImageDetailModal = () => {
    history.push(`/editing-page/${id}/${originalImage.id}`);
  };

  return (
    <li
      className="subItem type1 "
      style={{ justifyContent: '', width: '47%', maxHeight: '150px' }}
      onDoubleClick={openImageDetailModal}
    >
      <ImageUploading
        value={images || []}
        onChange={imageList => {
          setImages([imageList[0]]);
        }}
        dataURLKey="data_url"
      >
        {({ onImageUpload, isDragging, dragProps }) => (
          <>
            <div
              style={{
                height: '140px',
                maxWidth: '140px',
                minWidth: '140px',
                background: 'black',
                display: 'flex',
              }}
              {...dragProps}
              onClick={onImageUpload}
            >
              <img src={originalImage.thumbnailUrl} className="QA-image" />
            </div>

            {!isDragging && images && (
              <div
                style={{
                  height: '140px',
                  width: '140px',
                  background: 'black',
                  marginLeft: '2em',
                  border: '2px solid #2e7d32',
                }}
                {...dragProps}
                onLoad={() => {
                  if (images[0]) {
                    const im = new Image();

                    im.src = images[0].data_url;
                    images[0].width = im.width;
                    images[0].height = im.height;
                    updateImageBySignedUrl(originalImage.id, images[0])
                      .then(() => {
                        setImages(null);
                        refresher();
                      })
                      .catch(err => {
                        console.log(err);
                      });
                  }
                }}
                onClick={() => {
                  updateImageBySignedUrl(originalImage.id, images[0])
                    .then(() => {
                      setImages(null);
                      refresher();
                    })
                    .catch(err => {
                      console.log(err);
                    });
                }}
              >
                <img src={images ? images[0].data_url : originalImage.thumbnailUrl} className="QA-image" />
              </div>
            )}
            {isDragging && (
              <>
                <div
                  style={{
                    height: '140px',
                    width: '140px',
                    background: 'black',
                    marginLeft: '2em',
                    border: '2px solid #2e7d32',
                  }}
                  {...dragProps}
                />
              </>
            )}
            <div className="status">
              {originalImage.tags &&
                originalImage.tags.map((tag: ITag) => (
                  <span className="badge badge-pill badge-danger">{tag.name}</span>
                ))}
            </div>
            {images && images.length > 0 ? (
              <></>
            ) : (
              <div className="row">
                <div className="col-6">
                  <Button
                    color="primary"
                    style={{ border: 'solid 1px', width: '100%', marginBottom: '1em' }}
                    onClick={() => {
                      downloadMediaByOrderAndPosition(id, originalImage.position, 'original');
                    }}
                  >
                    <FormattedMessage id="ORDER.EDITOR.ORIGINAL" />
                  </Button>

                  <Button
                    color="primary"
                    style={{ border: 'solid 1px', width: '100%', marginBottom: '1em' }}
                    onClick={() => {
                      downloadOrderImages({ orderId: id, orderName }, [originalImage.id]);
                    }}
                  >
                    <FormattedMessage id="ORDER.EDITOR.OPTIMIZED" />
                  </Button>
                </div>
                <div className="col-6">
                  <Button
                    color="primary"
                    style={{ border: 'solid 1px', width: '100%', marginBottom: '1em' }}
                    onClick={() => {
                      downloadMediaByOrderAndPosition(id, originalImage.position, 'cutout');
                    }}
                  >
                    <FormattedMessage id="ORDER.EDITOR.CUTOUT" />
                  </Button>

                  <Button
                    color="primary"
                    style={{ border: 'solid 1px', width: '100%', marginBottom: '1em' }}
                    onClick={() => {
                      downloadOrderImages({ orderId: id, orderName }, [originalImage.id], true);
                    }}
                  >
                    <FormattedMessage id="ORDER.EDITOR.STYLEGUIDE" />
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </ImageUploading>
      <Switch>
        <Route path="/editing-page/:id/:imageId">
          <ImageDetailModal closeModal={closeImageDetailModal} />
        </Route>
      </Switch>
    </li>
  );
};
