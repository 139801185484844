import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';

import { PageLink, PageTitle } from '../../../_carswip/layout/core';
import { UserRole } from '../../../constants';
import { getUser } from '../../../setup/api';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { User } from '../../../ui/helpers';
import { ManagementDashboard } from '../../components/dashboard/ManagementDashboard';
import { UserDashboard } from '../../components/dashboard/UserDashboard';
import { EventDisplay } from '../../components/order/EventDisplay';
import { ChildAccountSettings } from '../../components/profile/ChildAccountSettings';
import { UserStyleGuideSettings } from '../../components/styleGuides/views/UserStyleGuideSettings';
import { CreateAccountWrapper } from '../account/CreateAccountWrapper';
import { OrdersPage } from '../orders/OrdersPage';
import { PermissionsWrapper } from '../permission/PermissionsPage';
import { RemoteIntegrationWrapper } from '../remoteIntegration/RemoteIntegrationsPage';

import { ProfileHeader } from './ProfileHeader';

/**
 *  Loads Profile routes on /profile/ those routes are only accessible for {@link UserRole.Admin}
 */
export const ProfilePage: React.FC = () => {
  const intl = useIntl();
  const { id } = useParams<{ id: string }>();
  const { request } = useFetch();
  const [userRole, setUserRole] = useState<UserRole>();
  const userPermission = User().userRole;

  const profileBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: 'PROFILE' }),
      path: '/profile/overview',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];

  useEffect(() => {
    if (id) {
      request(getUser, id).then(res => {
        setUserRole(res.userRole);
      });
    }
  }, [id]);

  return (
    <>
      <ProfileHeader />
      <Switch>
        <Route path="/profile/:id/orders">
          <PageTitle breadcrumbs={profileBreadCrumbs}>
            <FormattedMessage id="PERMISSIONS.HEADER.ORDERS" />
          </PageTitle>
          <OrdersPage renderSalesMenDropDown />
        </Route>
        <Redirect from="/profile/:id" exact to="/profile/:id/orders" />
        {userPermission === UserRole.ADMIN && (
          <>
            <Route path="/profile/:id/events">
              <PageTitle breadcrumbs={profileBreadCrumbs}>
                <FormattedMessage id="PERMISSIONS.HEADER.EVENTS" />
              </PageTitle>
              <EventDisplay user />
            </Route>
            <Route path="/profile/:id/statistics">
              <PageTitle breadcrumbs={profileBreadCrumbs}>
                <FormattedMessage id="PERMISSIONS.HEADER.STATISTICS" />
              </PageTitle>
              {userRole === UserRole.DEALERSHIP && <UserDashboard userId={id} />}
              {userRole === UserRole.MANAGEMENT && <ManagementDashboard userId={id} />}
            </Route>
            <Route path="/profile/:id/settings">
              <PageTitle breadcrumbs={profileBreadCrumbs}>
                <FormattedMessage id="PERMISSIONS.HEADER.SETTINGS" />
              </PageTitle>
              <CreateAccountWrapper />
            </Route>
            <Route path="/profile/:id/integrations">
              <PageTitle breadcrumbs={profileBreadCrumbs}>
                <FormattedMessage id="PERMISSIONS.HEADER.INTEGRATIONS" />
              </PageTitle>
              <RemoteIntegrationWrapper />
            </Route>
            <Route path="/profile/:id/permissions">
              <PageTitle breadcrumbs={profileBreadCrumbs}>
                <FormattedMessage id="PERMISSIONS.HEADER.PERMISSIONS" />
              </PageTitle>
              <PermissionsWrapper />
            </Route>
            <Route path="/profile/:id/styleguides">
              <PageTitle breadcrumbs={profileBreadCrumbs}>
                <FormattedMessage id="PERMISSIONS.HEADER.STYLEGUIDES" />
              </PageTitle>
              <UserStyleGuideSettings />
            </Route>
            <Route path="/profile/:id/child-accounts">
              <PageTitle breadcrumbs={profileBreadCrumbs}>
                <FormattedMessage id="PERMISSIONS.HEADER.CHILD-ACCOUNTS" />
              </PageTitle>
              <ChildAccountSettings />
            </Route>
          </>
        )}
      </Switch>
    </>
  );
};
