import { FC, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { StyleGuideImageTypes } from '../../../constants';
import { addUtility, modifyStyleGuides } from '../../../setup/api';
import { enumToArray } from '../../../setup/helpers/convertEnumToArray';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { addUploadedMedia } from '../../../setup/redux/actions';
import { ImageDropZone } from '../../../ui/components/ImageDropzone/ImageDropZone';

interface IStyleGuideImageLoaderProps {
  styleGuideId?: string;
  sendImages: boolean;
  loadUtilitiesFromStyleGuide?: { [key: string]: { url: string } };
}

interface IImageMediaLoaderAndUploadProps {
  mediaType: StyleGuideImageTypes;
  sendImages: boolean;
  styleGuideMedia?: { [key: string]: { url: string } };
  styleGuideId?: string;
}

/**
 *
 * @param props.mediaType type of utility that will be attach to the styleguide
 * @param props.url previous image on the styleguide
 * @param props.sendImages is of the styleguide where the utility that will be attach
 * @returns single set of image uploader, that uploads utilities and attach them to a styleguide
 */
export const ImageMediaLoaderAndUpload: FC<IImageMediaLoaderAndUploadProps> = ({
  mediaType,
  styleGuideId,
  sendImages,
  styleGuideMedia,
}) => {
  const prevMediaRef = useRef<ImageBlob[] | undefined>(undefined);
  const [media, setMedia] = useState<ImageBlob[] | undefined>();
  const { request } = useFetch();
  const dispatch = useDispatch();
  const modifyStyleGuideId = useParams<{ styleGuideId: string }>().styleGuideId;

  const attachMediaToStyleGuide = async () => {
    if (media && media[0] && media[0].data_url) {
      addUtility(media[0], mediaType).then(async (utilityMedia: IUtility) =>
        request(modifyStyleGuides, styleGuideId, { [mediaType]: utilityMedia.id }).then(() => {
          dispatch(addUploadedMedia());
        }),
      );
    } else {
      dispatch(addUploadedMedia());
    }
  };

  const removeMediaFromStyleGuide = () => request(modifyStyleGuides, modifyStyleGuideId, { [mediaType]: null });

  useEffect(() => {
    if (prevMediaRef.current && !media) {
      removeMediaFromStyleGuide();
    }

    // Update the ref with the current value of media for the next render
    prevMediaRef.current = media;

    if (modifyStyleGuideId && media && media[0] && media[0].data_url) {
      addUtility(media[0], mediaType).then(async (utilityMedia: IUtility) =>
        request(modifyStyleGuides, modifyStyleGuideId, { [mediaType]: utilityMedia.id }),
      );
    }
  }, [media]);

  useEffect(() => {
    if (sendImages) {
      attachMediaToStyleGuide();
    }
  }, [sendImages]);

  return (
    <div className="d-flex align-items-center justify-content-between fs-5 fw-bold mb-4" style={{ height: '100px' }}>
      <div className="required">
        <FormattedMessage id={`STYLE_GUIDE.MEDIA_TYPE.${mediaType.toUpperCase()}`} />:
      </div>
      <div className="w-80 d-flex align-items-center mr-5">
        {media && !media[0]?.data_url && !styleGuideMedia && (
          <span className="badge rounded-pill bg-danger me-5">
            {mediaType !== StyleGuideImageTypes.LOGO && <FormattedMessage id="REQUIRED" />}
          </span>
        )}
        {styleGuideMedia ? (
          <ImageDropZone
            maxNumber={1}
            images={[styleGuideMedia[mediaType as unknown as string]]}
            selfDrop={false}
            setImages={setMedia}
            download
          />
        ) : (
          <ImageDropZone maxNumber={1} images={[media]} selfDrop={false} setImages={setMedia} download />
        )}
      </div>
    </div>
  );
};

/**
 * @param props.sendImages if the values is available, it will trigger the upload of the floor, logo or background
 * @returns returns three sets of image upload that are needed on the styleguide and it attach them to the styleGuide once this one is created
 */
export const StyleGuideImageLoader: FC<IStyleGuideImageLoaderProps> = ({
  styleGuideId,
  sendImages,
  loadUtilitiesFromStyleGuide,
}) => {
  const styleGuideMediaTypeArray = enumToArray(StyleGuideImageTypes);

  return (
    <div className="w-100 mt-10">
      {styleGuideMediaTypeArray.map(mediaType => (
        <div key={mediaType.key} className="fv-row">
          {loadUtilitiesFromStyleGuide ? (
            <ImageMediaLoaderAndUpload
              sendImages={sendImages}
              styleGuideId={styleGuideId}
              styleGuideMedia={loadUtilitiesFromStyleGuide}
              mediaType={mediaType.value as StyleGuideImageTypes}
            />
          ) : (
            <ImageMediaLoaderAndUpload
              sendImages={sendImages}
              styleGuideId={styleGuideId}
              mediaType={mediaType.value as StyleGuideImageTypes}
            />
          )}
        </div>
      ))}
    </div>
  );
};
