import config from 'react-global-configuration';

import { generateRequestOptions } from './helpers';

export const getUsers = (queries: string, abort: AbortSignal): Promise<Response> => {
  const url = `${config.get('ApiUrl').Rest}/users${queries}`;

  return fetch(url, generateRequestOptions('GET', abort));
};

export const createUser = (userData: IUser): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/users`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userData),
    mode: 'cors',
    credentials: 'include',
  });

export const createUserWithoutCognito = (userData: IUser): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/users/createAccountWithoutCognito`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userData),
    mode: 'cors',
    credentials: 'include',
  });

export const updateUser = (userData: IUser, id: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/users/${id}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userData),
    mode: 'cors',
    credentials: 'include',
  });

export const getUser = (id: number): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/users/${id}`, {
    mode: 'cors',
    credentials: 'include',
  });

export const deleteUser = (id: number, hash: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/users/delete-user-account`, {
    mode: 'cors',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      userId: id,
      hash,
    }),
    credentials: 'include',
  });
export const getUserChildAccounts = (id: number): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/users/childAccounts/${id}/users`, {
    mode: 'cors',
    credentials: 'include',
  });

export const postChildAccounts = (userId: string, childAccountsIds: string[]): Promise<Response> => {
  const body = {
    childAccounts: childAccountsIds,
  };

  return fetch(`${config.get('ApiUrl').Rest}/users/childAccounts/${userId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
    mode: 'cors',
    credentials: 'include',
  });
};

export const deleteChildAccount = (userId: string, childAccountId: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/users/childAccounts/${userId}/${childAccountId}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    credentials: 'include',
  });

export const addUsersToCognito = (hashKey: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/users/createSubToAccountsWithout/${hashKey}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    credentials: 'include',
  });

export const addUserSub = (userId: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/users/addUserSub/${userId}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    credentials: 'include',
  });

export const resendEmailRegistration = (email: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/users/resend-temporary-password/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email }),
    mode: 'cors',
    credentials: 'include',
  });

export const getChildAccountDataFromUser = (userId: string, abort: AbortSignal): Promise<Response> => {
  const url = `${config.get('ApiUrl').Rest}/users/get-child-accounts-data-by-user-id/${userId}`;

  return fetch(url, generateRequestOptions('GET', abort));
};
