import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const DashboardCard = styled(Box)(() => ({
  border: '1px solid #eff2f5',
  borderRadius: '0.475rem',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  wordWrap: 'break-word',
  backgroundColor: '#ffffff',
  backgroundClip: 'border-box',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)', // Adjusted the shadow values
  justifyContent: 'space-between',
  transition: 'box-shadow 0.3s ease', // Adding a transition for smooth effect
  '&:hover': {
    boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.15)', // Shadow when hovering
  },
}));

export const DashboardCardHeader = styled(Box)(() => ({
  padding: '2rem 2.25rem',
  marginBottom: '0',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'transparent',
  justifyContent: 'space-between',
}));
