import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { FormattedMessage } from 'react-intl';
import CollectionsIcon from '@mui/icons-material/Collections';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Button from '@mui/material/Button';

import { getUtilityMedia } from '../../../setup/api/styleGuide';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { ButtonLoader } from '../Button/ButtonLoader';

// FIXME: should be refactored into two components
type IProps = {
  setImages?: Dispatch<SetStateAction<ImageListType | undefined> | any>;
  maxNumber?: number;
  images?: ImageListType | any;
  selfDrop?: boolean;
  galleryData?: IMediaType;
  download?: boolean;
};

const dropContainerDefault: React.CSSProperties = {
  maxWidth: '90px',
  maxHeight: '45px',
  borderRadius: '10%',
  marginRight: '0.3em',
};

const smallDropContainer: React.CSSProperties = {
  borderStyle: 'dashed',
  borderRadius: '3px',
  borderColor: 'grey',
  marginRight: '4px',
  textAlign: 'center',
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  width: '150px',
  height: '50px',
};

/**
 * This component handles images upload to the webapp and load image information on memory
 * is used on multiple forms
 * @param props.images number of images that are loaded in the component
 * @param props.maxNumber maximum amount of number that can be load by the component to the parent element
 * @param props.galleryData if true it will load multiple images on a gallery style
 * @param props.download buttons that allows to download by given
 * @returns images to the parent component
 */
export const ImageDropZone: React.FC<IProps> = ({
  images,
  maxNumber,
  selfDrop,
  setImages,
  galleryData,
  download = true,
}) => {
  const [loadedImages, setLoadedImages] = useState<ImageListType | undefined>(images);
  const [galleryImagesLoaded, setGalleryImagesLoaded] = useState<IImage[]>();
  const { request } = useFetch();
  const getImagesFromUtility = (type: IMediaType) => {
    request(getUtilityMedia, type).then(res => {
      setGalleryImagesLoaded(res);
    });
  };

  const onImageDownload = async () => {
    if (loadedImages) {
      const url = loadedImages[0]?.url;
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', 'no_content.txt');

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      if (link && link.parentNode) {
        link?.parentNode.removeChild(link);
      }
      return true;
    }
  };

  useEffect(() => {
    if (setImages) {
      setImages(loadedImages);
    }
  }, [loadedImages]);

  return (
    <ImageUploading
      value={[]}
      onChange={imagesLoaded => setLoadedImages(imagesLoaded)}
      maxNumber={maxNumber}
      dataURLKey="data_url"
    >
      {({ onImageUpload, onImageRemoveAll, dragProps }) => (
        <div style={{ display: 'contents' }}>
          {selfDrop === false && loadedImages && loadedImages.length > 0 && loadedImages[0]?.data_url && (
            <img
              className="img-fluid"
              style={{ ...dropContainerDefault, width: '150px', height: '115%' }}
              src={loadedImages[0].data_url}
            />
          )}
          {selfDrop === false && loadedImages && loadedImages.length > 0 && loadedImages[0]?.thumbnailUrl && (
            <img
              className="img-fluid"
              style={{ ...dropContainerDefault }}
              src={loadedImages[0].thumbnailUrl}
              alt="Ima Error"
            />
          )}
          <div className="d-flex">
            <div className="" {...dragProps} style={{ ...smallDropContainer }}>
              <div>
                <FormattedMessage id="IMAGE.DROP" />
              </div>
            </div>
            <Button
              variant="contained"
              color="info"
              type="button"
              onClick={onImageUpload}
              {...dragProps}
              style={{ marginRight: '4px' }}
            >
              <FileUploadIcon />
            </Button>
            <Button
              type="button"
              disabled={!loadedImages || !loadedImages[0] || !loadedImages[0].data_url}
              onClick={() => {
                onImageRemoveAll();
                setLoadedImages(undefined);
              }}
              variant="contained"
              color="error"
              style={{ marginRight: '4px' }}
            >
              <HighlightOffIcon />
            </Button>
            {download && (
              <ButtonLoader
                variant="contained"
                color="primary"
                disabled={!(loadedImages && loadedImages[0])}
                loading={false}
                selfLoader
                request={onImageDownload}
                text=""
                style={{ marginRight: '2px' }}
              >
                <FileDownloadIcon />
              </ButtonLoader>
            )}
          </div>
          {galleryData && (
            <Button
              type="button"
              onClick={() => {
                getImagesFromUtility('floor');
              }}
              id="dropdownMenuButton1"
              className="btn btn-info"
              data-kt-menu-trigger="click"
              data-kt-menu-attach="parent"
              data-kt-menu-placement="bottom-end"
              data-kt-menu-flip="bottom"
              aria-expanded="false"
              style={{ marginLeft: '0.3em' }}
            >
              <CollectionsIcon />
            </Button>
          )}
          <div className="dropdown-menu container" data-kt-menu="true" aria-labelledby="dropdownMenuButton1">
            <div className=" row">
              {galleryImagesLoaded &&
                galleryImagesLoaded.map((image: any) => (
                  <img
                    className="col-4 image-gallery-select"
                    style={{
                      maxWidth: '150px',
                      maxHeight: '112px',
                    }}
                    src={image.thumbnailUrl}
                    alt="imm has not loaded yet"
                    onClick={() => {
                      setLoadedImages([image]);
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
      )}
    </ImageUploading>
  );
};
