import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type TUseQueryParams = [TParams, TSetQueries];

type TParams = { [key: string]: string };
type TSetQueries = (query: string, value: string) => void;

const decodeSearchString = (search: string): string => search.replace(/%20/g, ' ');
/**
 * The encodeSearchString function takes in a string called search and uses
 * the replace method to replace all occurrences of + in the string with %20.
 * since the values set with the function URLSearchParams are encoded with '+' instead of ' '
 * @param {string} search value coming from the {@link URLSearchParams}
 * @returns {string} Example: from "hello+world" outputs "hello%20world"
 */
const encodeSearchString = (search: string): string => search.replace(/\+/g, '%20');

/**
 * @param queryStrings Array with queries from the URL route
 * @returns {Object} customized objects with all the queries and its parameters as keys and objects
 */
const convertQueriesToObject = (queryStrings: string[]): TParams => {
  const params: TParams = {};

  // Iterate over the searchParams array and add each key-value pair to the params object
  queryStrings.forEach(param => {
    const [key, value] = param.split('=');

    if (key && value) {
      params[key] = decodeSearchString(value);
    }
  });

  return params;
};

/**
 * FIXME: it should have a parameter that can allow you to return the params an object or as a query string (Boolean)
 * The useQueryParams hook to get the current query params and a function to set new query params
 * @returns {TUseQueryParams} An object with the query parameters as keys and their values as values.
 */
export function useQueryParams(): TUseQueryParams {
  const location = useLocation();
  const history = useHistory();
  // Split the search string into an array of individual parameters
  const searchParams = location.search.slice(1).split('&');
  // Creates a memoized object that stores the queries
  const params = useMemo(() => convertQueriesToObject(searchParams), [location.search]);
  /**
   * Sets a new URL parameter in the current location.
   *
   * @param {string} value - The value to set for the query parameter.
   */
  const setQueries = (query: string, value: string) => {
    const queries = location.search.replaceAll('All', '');
    const URLParams = new URLSearchParams(queries);

    URLParams.set(query, value);

    const newSearch = URLParams.toString();

    history.push({
      pathname: location.pathname,
      search: encodeSearchString(newSearch),
    });
  };

  return [params, setQueries];
}
