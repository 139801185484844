import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Moment from 'react-moment';
import { Link, useHistory } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';

import { OrderStatus, UserRole, wideLogo } from '../../../constants';
import { deleteOrderById, downloadOptimizedImages, downloadOrderImages } from '../../../setup/api';
import { pickStyleStatusColor } from '../../../setup/helpers/pickStyleStatusColor';
import { User } from '../../helpers';
import { getDifferenceBetweenDatesInHours } from '../../helpers/helpers';
import { ButtonLoader } from '../Button/ButtonLoader';
import { Loader } from '../Louder/Louder';
import { ConfirmDeletionModal } from '../Modals/ConfirmDeletionModal';
import { UnderlinedText } from '../UnderlineText/UnderlineText';

import { DatagridView } from './Datagrid.style';

import './DataGrid.css';

const generateEllipsisIfTextIsTooLong = (content: number | string) => {
  const stringifiedContent = String(content);

  if (stringifiedContent.length > 60) {
    const contentOnEllipsis = `${stringifiedContent.substring(0, 60)}...`;

    return contentOnEllipsis;
  }
  return stringifiedContent;
};

const Text = ({ item, propKey }: { item: IOrder; propKey: keyof IOrder }) => (
  <div style={{ minHeight: '20px' }}>{generateEllipsisIfTextIsTooLong(item[propKey])}</div>
);

const renderTestRows = (userHeadings: Array<string[]>, item: IOrder, isClickable?: boolean): JSX.Element[] =>
  userHeadings.map(([key]) => {
    if (key === 'user') {
      return (
        <td
          key={key}
          className="listHover"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <Link to={`/profile/${item[key]?.id}/events`} style={{ textDecoration: 'none', color: 'inherit' }}>
            <UnderlinedText>{item[key]?.displayName}</UnderlinedText>
          </Link>
        </td>
      );
    }

    return (
      <td key={key} className="listHover">
        {isClickable ? (
          <Link
            to={`/order/${item.id}`}
            style={{
              textDecoration: 'none',
              color: 'inherit',
              minHeight: '20px',
            }}
          >
            <Text item={item} propKey={key} />
          </Link>
        ) : (
          <Text item={item} propKey={key} />
        )}
      </td>
    );
  });
// FIXME: the component should have some refresh for the list after the item is deleted, maybe the best is to pass the function to the data grist as deletable
const handleOrderDeletion = (orderId: string) => deleteOrderById(orderId);

type Props = {
  orders: Array<IOrder>;
  headings: [string, string][];
  loading?: boolean;
  download?: (
    orderInfo: { orderId: string; orderName: string },
    imageIds?: Array<string>,
    styleguide?: boolean,
  ) => Promise<void | Promise<void>>;
  QA?: boolean;
  deletable?: boolean;
  deliveryTime?: boolean;
};
/**
 * Display order list
 * @param props.order Array payload with order list
 * @param props.headings tuple of string arrays with the key object name and the column name EXAMPLE: ['customerName', 'Customer Name']
 * @param props.loading if true will load skeleton while the data is loading, if false it will show the list with the data
 * @param props.download should contain a download function for the order
 * @param props.QA if true it wil show the QAStatus of the order
 * @param props. deletable if true it will load a deletion button with a modal, only Admins should have access to this
 * @returns list with options and personalized columns
 */

const OrderGrid: FC<Props> = ({ orders = [], headings, deletable, loading, download, QA, deliveryTime }) => {
  const intl = useIntl();
  const history = useHistory();
  const { userRole } = User();

  const renderHeader = (headings: string[][]) =>
    headings.map(([key, header]) => (
      <th key={key} className="grid-header">
        {header}
      </th>
    ));

  return (
    <DatagridView>
      <div className="grid-wrapper" data-testid="datagrid">
        {loading && <FormattedMessage id="ACTION.PENDING" />}
        <table className="grid-table" style={{ minWidth: '700px' }}>
          {loading && Loader()}
          <thead>
            <tr>
              <th className="grid-header">
                <FormattedMessage id="DATA.IMAGE" />
              </th>
              {renderHeader(headings)}
              <th className="grid-header">
                <FormattedMessage id="DATA.CREATED" />
              </th>
              {deliveryTime && (
                <>
                  <th className="grid-header">
                    <FormattedMessage id="DATA.READY_TO_DOWNLOAD" />
                  </th>
                  <th className="grid-header">
                    <FormattedMessage id="DATA.COMPLETED" />
                  </th>
                  <th className="grid-header">
                    <FormattedMessage id="DATA.APP_VERSION" />
                  </th>
                </>
              )}

              <th className="grid-header">
                <FormattedMessage id="DATA.STATUS" />
              </th>
              {QA && (
                <th className="grid-header">
                  <FormattedMessage id="QA" />
                </th>
              )}
              {download && (
                <th className="grid-header">
                  <FormattedMessage id="ACTION.DOWNLOAD_IMAGE" />
                </th>
              )}
              {deletable && (
                <th className="grid-header">
                  <FormattedMessage id="ACTION.DELETE" />
                </th>
              )}
            </tr>
          </thead>
          {loading ? (
            // FIXME: the skeleton should be mapped and not hard coded
            <tbody>
              <tr>
                <td>
                  <Skeleton variant="rectangular" width="100%" height={520} />
                </td>

                {headings.map((item: (React.Key | null | undefined)[]) => (
                  <td key={item[0]}>
                    <Skeleton variant="rectangular" width="100%" height={520} />
                  </td>
                ))}
                <td>
                  <Skeleton variant="rectangular" width="100%" height={520} />
                </td>
                <td>
                  <Skeleton variant="rectangular" width="100%" height={520} />
                </td>
                {QA && (
                  <td>
                    <Skeleton variant="rectangular" width="100%" height={520} />
                  </td>
                )}
                {deletable && (
                  <td>
                    <Skeleton variant="rectangular" width="100%" height={520} />
                  </td>
                )}
                {download && (
                  <td>
                    <Skeleton variant="rectangular" width="100%" height={520} />
                  </td>
                )}
                {deletable && (
                  <td>
                    <Skeleton variant="rectangular" width="100%" height={520} />
                  </td>
                )}
              </tr>
            </tbody>
          ) : (
            <tbody>
              {orders.map((order: IOrder) => {
                const isClickable =
                  [OrderStatus.COMPLETED, OrderStatus.READY_TO_DOWNLOAD].includes(order.status) ||
                  (userRole && [UserRole.ADMIN, UserRole.QA].includes(userRole));

                if (!order?.id) {
                  return;
                }
                return (
                  <tr
                    onClick={() => {
                      isClickable && history.push(`/order/${order.id}`);
                    }}
                    key={order.id}
                    className={isClickable ? 'table-row listHover' : 'table-row-no-click'}
                    style={{ maxHeight: '140px' }}
                  >
                    <td style={{ height: 'inherit' }}>
                      <img
                        style={{ width: '180px' }}
                        src={(order.images && order?.images[0]?.thumbnailUrl) ?? order?.images[0]?.url ?? wideLogo}
                        onError={({ currentTarget }) => {
                          currentTarget.src = wideLogo;
                        }}
                      />
                    </td>
                    {renderTestRows(headings, order, isClickable)}
                    <td>
                      <div className="container-fluid">
                        <Moment className="row" format="YYYY-MM-DD HH:mm:ss" date={`${order.createdAt}`} />
                      </div>
                    </td>
                    {deliveryTime && (
                      <>
                        <td>
                          <div className="container-fluid">
                            {order.timeToReadyToDownload
                              ? getDifferenceBetweenDatesInHours(order.timeToReadyToDownload, order.createdAt, intl)
                              : 'N/A'}
                          </div>
                        </td>
                        <td>
                          <div className="container-fluid">
                            {order.timeToCompleted
                              ? getDifferenceBetweenDatesInHours(order.timeToCompleted, order.createdAt, intl)
                              : 'N/A'}
                          </div>
                        </td>
                        <td>
                          <div className="container-fluid">{order.appVersion || '-'}</div>
                        </td>
                      </>
                    )}

                    <td>
                      <span
                        className={`badge pl-0 ${pickStyleStatusColor(order.status)}`}
                        style={{ alignSelf: 'center', padding: '5px' }}
                      >
                        {order.status}
                      </span>
                    </td>
                    {QA && (
                      <td>
                        <span className={`badge pl-0 ${pickStyleStatusColor(order.QAstatus)}`}>{order.QAstatus}</span>
                      </td>
                    )}
                    {download && isClickable && (
                      <td
                        onClick={e => {
                          e.stopPropagation();
                        }}
                      >
                        <ButtonLoader
                          style={{ cursor: 's-resize' }}
                          loading={false}
                          request={() => {
                            if (userRole === UserRole.ADMIN || userRole === UserRole.QA) {
                              return downloadOrderImages({
                                orderId: order.id,
                                orderName: order.orderName,
                              });
                            }
                            return downloadOptimizedImages(order.id, order.orderName);
                          }}
                          text={intl.formatMessage({ id: 'ACTION.DOWNLOAD' })}
                          selfLoader
                        />
                      </td>
                    )}
                    {deletable && (
                      <td
                        onClick={e => {
                          e.stopPropagation();
                        }}
                      >
                        <ConfirmDeletionModal
                          handleDelete={handleOrderDeletion}
                          itemId={order.id}
                          entity="order"
                          entityName={order.orderName}
                        />
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </DatagridView>
  );
};

export { OrderGrid };
