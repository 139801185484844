import { useCallback, useState } from 'react';
import { ToastOptions } from 'react-toastify';

import { error, uploading } from '../../ui/helpers';

export interface ICustomizedFetchHooksPayload {
  message: string | null;
  options: ToastOptions;
}
const defaultToastOptions = {
  position: 'bottom-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: 1,
} as ToastOptions;

/**
 * FIXME: the code could be cleaner, there should be a way to pass a T type on before calling the component instead of using 'any'
 * and all actions should be documented
 *
 * This component can only be called once per component since other request may interfere with the error, since will reload the component
 * @param payload Alert Information from {@link ToastOptions}
 * @returns
 */
export const useFetchWithAlert: (payload: ICustomizedFetchHooksPayload) => {
  isLoading: boolean;
  request: (apiCall: any, ...params: any[]) => Promise<any>;
} = payload => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const { message, options } = payload;
  const toastOptions = options || defaultToastOptions;
  const request = useCallback(async (apiCall, ...params) => {
    try {
      setLoading(true);
      const response = await apiCall(...params);
      const statusFirstDigit = Number(String(response.status)[0]);

      if (statusFirstDigit === 2) {
        uploading(`${message}- Successfully`, toastOptions);
      } else if (String(response.status)) {
        error(`${message}- Missing permissions to run action`, toastOptions);
      } else if (statusFirstDigit === 4) {
        error(`${message}- Error`, toastOptions);
      }

      const jsonData = await response.json();

      if (!response.ok) {
        throw new Error((jsonData || {}).message);
      }
      setLoading(false);
      return jsonData;
    } catch (err: unknown) {
      setLoading(false);
    }
  }, []);

  return { isLoading, request };
};

export const alertPayload = (message: string): ICustomizedFetchHooksPayload => ({
  message,
  options: {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  },
});
