/**
 * @param {string | undefined} status  receive the QAStatus or status of an order or image status
 * @returns CSS style class for each type of status (changing the color)
 */

export const pickStyleStatusColor = (status: string | undefined | boolean) => {
  switch (status) {
    case 'Completed':
      return 'badge  pl-0 badge-success';

    case 'Uploading':
      return 'badge  pl-0 badge badge-warning';

    case 'In Progress':
      return 'badge  pl-0 badge badge-warning';

    case 'Carswip QA':
      return 'badge  pl-0 badge badge-warning';

    case 'Waiting For QA':
      return 'badge  pl-0 badge badge-warning';

    case 'Waiting For Edition':
      return 'badge  pl-0 badge badge-danger';

    case 'Approved':
      return 'badge  pl-0 badge badge-success';

    case 'Ready to download':
      return 'badge  pl-0 badge badge-warning';

    case 'Declined':
      return 'badge  pl-0 badge badge-danger';

    case false:
      return 'badge  pl-0 badge badge-danger';

    case true:
      return 'badge  pl-0 badge badge-success';

    case null:
      return 'badge  pl-0 badge badge-danger';

    default:
      return 'badge  pl-0 badge badge-light-success';
  }
};
