import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import { PageTitle } from '../../../_carswip/layout/core';
import { OrderQAStatus, OrderStatus, TagCategory, UserRole } from '../../../constants';
import { RootState } from '../../../setup';
import { downloadOrderImages, getOrders, getSalesMenByUserId } from '../../../setup/api';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { OrderGrid } from '../../../ui/components/Datagrid/OrderGrid';
import { FilterComponent } from '../../../ui/components/Filters/FilterComponent';
import { DropdownOption, TFilterProps } from '../../../ui/components/Filters/FilterDropDown';
import { OrderSuccessModal } from '../../components/order/OrderSuccesModal';

const getDataGridOptionsFromUserRole = (userRole?: string) => {
  if (userRole !== UserRole.ADMIN && userRole !== UserRole.QA) {
    return {
      tags: false,
      QA: false,
      deletable: false,
      deliveryTime: false,
    };
  }
  return {
    tags: TagCategory.EDITOR,
    QA: true,
    deletable: true,
    deliveryTime: userRole === UserRole.ADMIN,
  };
};

type Props = {
  renderSalesMenDropDown?: boolean;
};

const Row: FC = ({ children }) => (
  <Grid xs={12} marginTop={3}>
    {children}
  </Grid>
);
/**
 *  Renders Orders Page with custom list and filters depending on the user role
 */
export const OrdersPage: FC<Props> = ({ renderSalesMenDropDown }) => {
  const intl = useIntl();
  const { request, isLoading, abortController } = useFetch();
  const { id } = useParams<TParams>();
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const userInformation = useSelector((state: RootState) => state.auth.user);
  const queriesFromRouter = useLocation().search;

  const orderListHeaders: [string, string][] = [
    ['orderName', intl.formatMessage({ id: 'ORDER.VIEW.ORDER_NAME' })],
    ['imageCount', intl.formatMessage({ id: 'ORDER.VIEW.IMAGES' })],
    ['user', intl.formatMessage({ id: 'ORDER.CREATOR.DEALERSHIP' })],
  ];

  const statusOptions: DropdownOption[] = [
    {
      label: intl.formatMessage({ id: 'ORDER.FILTER.ALL' }),
      value: 'All',
    },
    {
      label: intl.formatMessage({ id: 'STATS.ORDER.STATUS.UPLOADING' }),
      value: OrderStatus.UPLOADING,
    },
    {
      label: intl.formatMessage({ id: 'STATS.ORDER.STATUS.IN_PROGRESS' }),
      value: OrderStatus.IN_PROGRESS,
    },
    {
      label: intl.formatMessage({ id: 'STATS.ORDER.STATUS.QA' }),
      value: OrderStatus.CARSWIP_QA,
    },
    {
      label: intl.formatMessage({ id: 'STATS.ORDER.STATUS.DELIVERING' }),
      value: OrderStatus.DELIVERING,
    },
    {
      label: intl.formatMessage({ id: 'ORDER.CREATOR.IMG.READY' }),
      value: OrderStatus.READY_TO_DOWNLOAD,
    },
    {
      label: intl.formatMessage({ id: 'ORDER.CREATOR.IMG.COMPLETED' }),
      value: OrderStatus.COMPLETED,
    },
  ];
  const adminAndQAOrderFilters: TFilterProps[] = [
    {
      name: intl.formatMessage({ id: 'DATA.STATUS' }),
      query: 'status',
      options: statusOptions,
    },
    {
      name: intl.formatMessage({ id: 'DATA.QA_STATUS' }),
      query: 'QAstatus',
      options: [
        {
          label: intl.formatMessage({ id: 'ORDER.FILTER.ALL' }),
          value: 'All',
        },
        {
          label: intl.formatMessage({ id: 'ORDER.CREATOR.IMG.WAITING.QA' }),
          value: OrderQAStatus.QA,
        },
        {
          label: intl.formatMessage({ id: 'ORDER.CREATOR.IMG.WAITING.EDIT' }),
          value: OrderQAStatus.EDITOR,
        },
        {
          label: intl.formatMessage({ id: 'ORDER.CREATOR.IMG.APPROVED' }),
          value: OrderQAStatus.APPROVED,
        },
      ],
    },
    {
      name: intl.formatMessage({ id: 'DATA.VIDEO' }),
      query: 'hasVideo',
      options: [
        {
          label: intl.formatMessage({ id: 'ORDER.FILTER.ALL' }),
          value: 'All',
        },
        {
          label: intl.formatMessage({ id: 'ORDER.VIEW.VIDEO' }),
          value: 'Videos',
        },
      ],
    },
  ];

  const customerOrderFilters: TFilterProps[] = [
    {
      name: intl.formatMessage({ id: 'DATA.STATUS' }),
      query: 'status',
      options: statusOptions,
    },
    {
      name: intl.formatMessage({ id: 'DATA.VIDEO' }),
      query: 'hasVideo',
      options: [
        {
          label: intl.formatMessage({ id: 'ORDER.FILTER.ALL' }),
          value: 'All',
        },
        {
          label: intl.formatMessage({ id: 'ORDER.VIEW.VIDEO' }),
          value: 'Videos',
        },
      ],
    },
  ];

  const getFilterOptionsByUserRole = (userRole?: string) => {
    if (userRole === UserRole.ADMIN || userRole === UserRole.QA) {
      return adminAndQAOrderFilters;
    }
    return customerOrderFilters;
  };

  const [filters, setFilters] = useState<TFilterProps[] | undefined>(
    getFilterOptionsByUserRole(userInformation?.userRole),
  );

  const fetchOrders = (queries?: string) => {
    const userId: string = id;

    if (userId) {
      queries ? (queries += `&userId=${userId}`) : (queries += `?userId=${userId}`);
    }
    request(getOrders, queries, abortController.signal)
      .then((ordersAndCount: [IOrder[], number]) => {
        setOrders(ordersAndCount[0]);
        setTotalOrders(ordersAndCount[1]);
      })
      .catch(() => console.log('error'));
  };

  useEffect(() => {
    fetchOrders(queriesFromRouter);
    return () => {
      abortController.abort();
    };
  }, [queriesFromRouter]);

  useEffect(() => {
    if (renderSalesMenDropDown && id) {
      request(getSalesMenByUserId, id).then((res: ISalesMan[]) => {
        const salesManNames = res.map(({ name }) => ({ value: name, label: name }));
        const salesManFilter: TFilterProps = {
          name: 'SalesMan',
          options: salesManNames,
          query: 'salesMan',
        };
        const addSalesManFilter: TFilterProps[] = filters ? filters?.concat(salesManFilter) : [];

        setFilters(addSalesManFilter);
      });
    }
  }, [renderSalesMenDropDown]);

  return (
    <Grid container padding={4} className="card">
      <Row>
        <FilterComponent
          showSearchFilter
          customFilterQueries={filters}
          count={totalOrders}
          entity="orders"
          showDateFilter
          showPagination
        />
      </Row>
      <Row>
        <OrderGrid
          orders={orders}
          download={downloadOrderImages}
          loading={isLoading}
          QA={getDataGridOptionsFromUserRole(userInformation?.userRole).QA}
          headings={orderListHeaders}
          deletable={getDataGridOptionsFromUserRole(userInformation?.userRole).deletable}
          deliveryTime={getDataGridOptionsFromUserRole(userInformation?.userRole).deliveryTime}
        />
      </Row>
    </Grid>
  );
};

export const OrdersWrapper: React.FC = () => {
  const { formatMessage } = useIntl();
  const showOrderSuccessModal = useLocation().pathname.includes('/success');

  return (
    <>
      <PageTitle breadcrumbs={[]}>{formatMessage({ id: 'MENU.ORDERS' })}</PageTitle>
      <OrdersPage />
      <OrderSuccessModal showOrderSuccessModal={showOrderSuccessModal} />
    </>
  );
};
