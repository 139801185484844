import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../../setup';
import { getBanners } from '../../../../../setup/api/banner';
import { useFetch } from '../../../../../setup/hooks/fetch.hook';
import { Datagrid } from '../../../../../ui/components/Datagrid/Datagrid';
import { CreateBannerModal } from '../../../../components/banner/CreateBannerModal';

const BannerList: FC = () => {
  const intl = useIntl();
  const { request, isLoading, abortController } = useFetch();
  const [banners, setBanners] = useState([]);

  const bannerListHeaders: [string, string][] = [
    ['message', intl.formatMessage({ id: 'BANNER.MESSAGE.PLACEHOLDER' })],
    ['isActive', intl.formatMessage({ id: 'BANNER.ACTIVE' })],
    ['createdAt', intl.formatMessage({ id: 'BANNER.CREATED' })],
  ];

  const renderTestsRow = (headings: [string, string][], item: Record<string, boolean | string>) =>
    headings.map(([key]) => {
      let text;

      if (item[key] === true || item[key] === 'true') {
        text = intl.formatMessage({ id: 'BANNER.VISIBLE' });
      } else if (item[key] === false || item[key] === 'false') {
        text = intl.formatMessage({ id: 'BANNER.HIDDEN' });
      } else {
        text = item[key].toString();
      }

      return <td key={key}>{text}</td>;
    });

  const fetchBanners = () => {
    request(getBanners, abortController.signal).then(bannerResponse => {
      setBanners(bannerResponse);
    });
  };
  const reloadValue = useSelector((state: RootState) => state.reloadTableModal);

  useEffect(() => {
    fetchBanners();
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (reloadValue?.banner?.reload) {
      fetchBanners();
    }
  }, [reloadValue]);

  return (
    <div className="content d-flex flex-column flex-column-fluid">
      <div className="card">
        <div className="card-header border-0 pt-6">
          <div className="card-title">
            <div className="d-flex align-items-center position-relative my-1" />
          </div>
          <div className="card-toolbar">
            <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
              <CreateBannerModal button />
            </div>
          </div>
        </div>
        <div className="card-body pt-10">
          <Datagrid
            loading={isLoading}
            data={banners}
            headings={bannerListHeaders}
            renderRow={renderTestsRow}
            modalURL="admin/banner"
          />
        </div>
      </div>
    </div>
  );
};

export { BannerList };
