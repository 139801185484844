import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

/**
 * Renders a button that replaced all the queries from the url to an empty string
 */
export const ResetFiltersButton = () => {
  const history = useHistory();
  const removeQueriesFromFiltersAndRouter = () => {
    history.push({ search: '' });
  };

  // It is necessary to wrap the button on a div so the position is absolute to the parent but relative to the grid
  return (
    <Grid xs={1} marginBottom={1} marginRight={1} display="flex" alignItems="center">
      <Button variant="contained" onClick={removeQueriesFromFiltersAndRouter}>
        <FormattedMessage id="DATA.FILTER.RESET" />
      </Button>
    </Grid>
  );
};
