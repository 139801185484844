import config from 'react-global-configuration';

import { generateRequestOptions } from './helpers';

export const getCarTypes = (abort: AbortSignal): Promise<Response> => {
  const url = `${config.get('ApiUrl').Rest}/car-types`;

  return fetch(url, generateRequestOptions('GET', abort));
};

export const getCarType = (id: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/car-types/${id}`, {
    mode: 'cors',
    credentials: 'include',
  });

export const createCarType = (carTypeData: ICarType): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/car-types`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(carTypeData),
    mode: 'cors',
    credentials: 'include',
  });

export const modifyCarType = (carTypeId: string, carTypeData: ICarType): Promise<Response> =>
  fetch(`${config.get(`ApiUrl`).Rest}/car-types/${carTypeId}`, {
    method: `PUT`,
    headers: { 'Content-Type': `application/json` },
    body: JSON.stringify(carTypeData),
    mode: `cors`,
    credentials: `include`,
  });
