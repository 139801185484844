import config from 'react-global-configuration';

import { IntegrationProvider } from '../../carswip_shared_models/src/web_view_models/IntegrationProviders';

export const getRemoteIntegrationByUserId = (userId: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/remote-integration/user/${userId}`, {
    mode: 'cors',
    credentials: 'include',
  });

export const createRemoteIntegration = (remoteIntegration: RemoteIntegration): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/remote-integration`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(remoteIntegration),
  });

export const deleteIntegration = (id: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/remote-integration/${id}`, {
    method: 'DELETE',
    mode: 'cors',
    credentials: 'include',
  });

export const reDeliverOrderToIntegration = (
  orderId: string,
  reDeliveryPayload: { selectedIntegrations: IntegrationProvider[] },
): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/orders/enqueue-order-delivery-by-id/${orderId}`, {
    method: 'POST',
    body: JSON.stringify(reDeliveryPayload),
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    credentials: 'include',
  });

export const updateIntegration = (remoteIntegration: RemoteIntegration): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/remote-integration/${remoteIntegration.id}`, {
    method: 'put',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(remoteIntegration),
  });
