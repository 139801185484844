import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ApexCharts, { ApexOptions } from 'apexcharts';

import { getCSSVariableValue } from '../../../_carswip/assets/ts/_utils';
import { getOrdersPerDealership, getOrdersPerSalesMan } from '../../../setup/api/statistics';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { TChartData, TimePeriod } from '../../pages/dashboard/DashboardWrapper';

import './increase-footer-size.css';

type Props = {
  className: string;
  title: string;
  userId: string;
  loadSalesMen?: boolean;
};
export const barChartByNameOptions = (series: number[], labels: string[], barChartName: string): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500');

  return {
    series: [{ name: barChartName, data: series }],
    chart: { height: 400, type: 'bar', toolbar: { show: false } },
    plotOptions: { bar: { columnWidth: '45%', distributed: true } },
    dataLabels: { enabled: false },
    legend: { show: false },
    xaxis: {
      categories: labels,
      labels: { style: { colors: labelColor, fontSize: '12px' } },
    },
  };
};

const CarsPerSalesmanOrDealerhip: React.FC<Props> = ({ title, className, userId, loadSalesMen }) => {
  const intl = useIntl();
  const chartRef = useRef<HTMLDivElement | null>(null);
  const [apexOptions, setApexOptions] = useState<ApexOptions>();
  const [series, setSeries] = useState<number[]>();
  const [timePeriod] = useState<string>(TimePeriod.MONTH);
  const { request } = useFetch();
  const barChartName = intl.formatMessage({ id: 'STATS.UPLOADED' });

  const getOrdersPerDealershipByUser = async (): Promise<TChartData | void> =>
    request(getOrdersPerDealership, userId, timePeriod).then(res => {
      if (res && res.length > 0) {
        const labels = res.map((item: Record<string, unknown>) => item.user_displayName);
        const series = res.map((item: Record<string, unknown>) => Number(item.totalorders));

        return { labels, series };
      }
    });
  const getOrdersPerSalesManByUser = async (): Promise<TChartData | void> =>
    request(getOrdersPerSalesMan, userId, timePeriod).then(res => {
      if (res && res.length > 0) {
        const labels = res.map((item: Record<string, unknown>) => item.salesMan_name);
        const series = res.map((item: Record<string, unknown>) => Number(item.totalorders));

        return { labels, series };
      }
    });

  useEffect(() => {
    if (loadSalesMen) {
      getOrdersPerSalesManByUser().then((chartData: TChartData | void) => {
        if (chartData) {
          setSeries(chartData.series);
          setApexOptions({ ...apexOptions, labels: chartData.labels });
        }
      });
    } else {
      getOrdersPerDealershipByUser().then((chartData: TChartData | void) => {
        if (chartData) {
          setSeries(chartData.series);
          setApexOptions({ ...apexOptions, labels: chartData.labels });
        }
      });
    }
  }, [userId]);

  useEffect(() => {
    if (apexOptions?.labels && apexOptions.labels?.length > 0 && series) {
      const chart = new ApexCharts(chartRef.current, barChartByNameOptions(series, apexOptions.labels, barChartName));

      if (chart) {
        chart.render();
      }

      return () => {
        if (chart) {
          chart.destroy();
        }
      };
    }
  }, [series, apexOptions]);

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className="card-body d-flex flex-column p-0">
        {/* begin::Stats */}
        <div className="card-p pb-0">
          <div className="d-flex flex-stack flex-wrap">
            <div className="me-2">
              <a className="text-dark text-hover-primary fw-bolder fs-3">{title}</a>
              <div className="text-muted fs-7 fw-bold">
                <FormattedMessage id="STATS.LAST_30" />
              </div>
            </div>
          </div>
        </div>
        {/* end::Stats */}
        {/* begin::Chart */}
        <div ref={chartRef} className="mixed-widget-10-chart increase-footer-size" />
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { CarsPerSalesmanOrDealerhip };
