import { GET_CURRENT_USER_FROM_PAGE } from '../reducers/currentUserReducer';
import { GET_STYLE_GUIDE_INFO } from '../reducers/getStyleGuideInfo';
import { RELOAD_TABLE_FROM_MODAL } from '../reducers/reloadTableFromModal';
import { RELOAD_TAG_DISPLAY } from '../reducers/reloadTagDisplay';
import { ADD_FILTERS, IFilterConfigurations, REMOVE_FILTER } from '../reducers/selectedFilters';
import {
  ADD_ARRAY_OF_IMAGES,
  ADD_IMAGE,
  ADD_IMAGES,
  REMOVE_ALL_IMAGES,
  REMOVE_IMAGES,
} from '../reducers/selectedImages';
import {
  ADD_FORM,
  CONFIGURATION_UPLOADED,
  REMOVE_FORMS,
  STYLE_GUIDE_MEDIA_UPLOADED,
} from '../reducers/StyleGuideConfigurationsForms';

export interface IActionReturn<Payload> {
  payload: Payload;
  type: string;
}
export const setStyleguideConfigurationForm = (payload: IConfiguration): IActionReturn<IConfiguration> => ({
  payload,
  type: ADD_FORM,
});

export const removeStyleguideConfigurationForms = (): { type: string } => ({ type: REMOVE_FORMS });

export const setStyleGuideInformation = (payload: IStyleGuide): IActionReturn<IStyleGuide> => ({
  payload,
  type: GET_STYLE_GUIDE_INFO,
});

export const addUploadedConfiguration = (): { type: string } => ({ type: CONFIGURATION_UPLOADED });

export const addUploadedMedia = (): { type: string } => ({ type: STYLE_GUIDE_MEDIA_UPLOADED });

export const addSelectedImages = (payload: string): IActionReturn<string> => ({ payload, type: ADD_IMAGES });

export const addImageToSelection = (payload: string): IActionReturn<string> => ({
  payload,
  type: ADD_IMAGE,
});

export const removeSelectedImages = (payload: string): IActionReturn<string> => ({ payload, type: REMOVE_IMAGES });

export const removeAllSelectedImages = (): { type: string } => ({ type: REMOVE_ALL_IMAGES });

export const addAllSelectedImages = (payload: string[]): IActionReturn<Array<string | undefined>> => ({
  payload,
  type: ADD_ARRAY_OF_IMAGES,
});

interface IFilterReturn extends IActionReturn<IFilterConfigurations> {
  entity: string;
}
export const addNewFilters = (payload: IFilterConfigurations, entity = 'orders'): IFilterReturn => ({
  payload,
  type: ADD_FILTERS,
  entity,
});

export const removeFilters = (entity = 'orders'): { type: string; entity: string } => ({
  type: REMOVE_FILTER,
  entity,
});

export interface IReloadReturn extends IActionReturn<{ reload: boolean }> {
  entity: string;
}
export const reloadTable = (payload: { reload: boolean }, entity: string): IReloadReturn => ({
  payload,
  type: ADD_FILTERS,
  entity,
});

export const reloadTagDisplay = (payload: boolean): IActionReturn<boolean> => ({ payload, type: RELOAD_TAG_DISPLAY });

export const setCurrentUserToState = (payload: IUser): IActionReturn<IUser> => ({
  payload,
  type: GET_CURRENT_USER_FROM_PAGE,
});

export const reloadTableFromModal = (payload: { reload: boolean }, entity: string): IReloadReturn => ({
  payload,
  type: RELOAD_TABLE_FROM_MODAL,
  entity,
});
