import React, { useEffect, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Button, Divider, TextField } from '@mui/material';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import {
  activateSalesMan,
  createSalesMan,
  createUserWithoutCognito,
  deleteSalesMan,
  getSalesMenByUserId,
  getUser,
  modifySalesMan,
  postChildAccounts,
  resendEmailRegistration,
  updateUser,
} from '../../../setup/api';
import { isValidEmail } from '../../../setup/helpers/emailValidator';
import { isValidPhoneNumber } from '../../../setup/helpers/phoneNumberValidator';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { ButtonLoader } from '../../../ui/components/Button/ButtonLoader';
import { Datagrid } from '../../../ui/components/Datagrid/Datagrid';
import { SelectFieldFromArray } from '../../../ui/components/FieldSelect/FieldSelect';
import { MainGridContainer } from '../../../ui/components/MainGridContainer/MainGridContainer';
import { User } from '../../../ui/helpers';
import { stringifyValue } from '../../../ui/helpers/DataGridUtils';
import { PasteBox } from '../../components/user/PasteBox/PasteBox';

import { SettingsSalesManForm } from './SettingsSalesManFrom';

import './CreateAccount.css';
import '../../../_carswip/assets/sass/style.scss';

export interface IUserPayload {
  displayName: string;
  userName: string;
  email: string;
  userRole?: string;
  desaturateYellowLight?: boolean;
  mobileAppAccess?: boolean;
  childAccounts?: string[];
  subscriptionPrice?: number;
  currency?: string;
  minimumZoomValue?: string;
  enabled_qa?: boolean;
  contactPerson: {
    phoneNumber: string;
    email: string;
    name: string;
    title: string;
  };
  address: {
    country: string;
    city: string;
    postalCode: string;
    address: string;
    additionalInfo?: string;
    companyId: string;
  };
  salesMan?: ISalesMan[];
  language?: string;
}

export const renderStyleGuidesOnCreateUser = (headings: Array<string[]>, item: any) => {
  if (item?.id) {
    return headings.map(([key]) => {
      try {
        return (
          <td key={key}>
            {item[key.split('_')[0]][key.split('_')[1]] ? (
              <div className="symbol symbol-55px me-2">
                <span className="symbol-label" style={{ overflow: 'hidden' }}>
                  <img src={item[key.split('_')[0]][key.split('_')[1]]} className="h-100 align-self-center" alt="" />
                </span>
              </div>
            ) : (
              item[key]
            )}
          </td>
        );
      } catch (e) {
        return <td key={key}>{item[key]}</td>;
      }
    });
  }
};

const createUserSchema = (intl: IntlShape) =>
  Yup.object({
    email: Yup.string()
      .required(
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.REQUIRED' },
          { name: intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' }) },
        ),
      )
      .label('email')
      .email(
        intl.formatMessage({ id: 'AUTH.VALIDATION.INVALID' }, { name: intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' }) }),
      ),

    displayName: Yup.string()
      .required(
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.REQUIRED' },
          { name: intl.formatMessage({ id: 'AUTH.INPUT.DISPLAY_NAME' }) },
        ),
      )
      .label('displayName'),
    address: Yup.object({
      country: Yup.string()
        .required(
          intl.formatMessage(
            { id: 'AUTH.VALIDATION.REQUIRED' },
            { name: intl.formatMessage({ id: 'ACCOUNT.COUNTRY' }) },
          ),
        )
        .label('country'),
      city: Yup.string()
        .required(
          intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED' }, { name: intl.formatMessage({ id: 'ACCOUNT.CITY' }) }),
        )
        .label('city'),
      postalCode: Yup.string()
        .required(
          intl.formatMessage(
            { id: 'AUTH.VALIDATION.REQUIRED' },
            { name: intl.formatMessage({ id: 'ACCOUNT.POSTAL_CODE' }) },
          ),
        )
        .label('postalCode'),
      address: Yup.string()
        .required(
          intl.formatMessage(
            { id: 'AUTH.VALIDATION.REQUIRED' },
            { name: intl.formatMessage({ id: 'ACCOUNT.ADDRESS' }) },
          ),
        )
        .label('address'),
      companyId: Yup.string()
        .label('companyId')
        .required(
          intl.formatMessage(
            { id: 'AUTH.VALIDATION.REQUIRED' },
            { name: intl.formatMessage({ id: 'ACCOUNT.COMPANY' }) },
          ),
        ),
    }),
    contactPerson: Yup.object({
      phoneNumber: Yup.string()
        .required(
          intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED' }, { name: intl.formatMessage({ id: 'ACCOUNT.PHONE' }) }),
        )
        .label('phoneNumber'),
      name: Yup.string()
        .required(
          intl.formatMessage(
            { id: 'AUTH.VALIDATION.REQUIRED' },
            { name: intl.formatMessage({ id: 'ACCOUNT.CONTACT_PERSON' }) },
          ),
        )
        .label('contactPerson.name'),
      title: Yup.string()
        .required(
          intl.formatMessage(
            { id: 'AUTH.VALIDATION.REQUIRED' },
            { name: intl.formatMessage({ id: 'ACCOUNT.USER_TITLE' }) },
          ),
        )
        .label('contactPerson.title'),
      email: Yup.string()
        .required(
          intl.formatMessage(
            { id: 'AUTH.VALIDATION.REQUIRED' },
            { name: intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' }) },
          ),
        )
        .label('contactPerson.email')
        .email('must enter a valid email'),
    }),
    userRole: Yup.string().label('userRole'),
    currency: Yup.string()
      .required(
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.REQUIRED' },
          { name: intl.formatMessage({ id: 'ACCOUNT.CURRENCY' }) },
        ),
      )
      .label('currency'),
    subscriptionPrice: Yup.number()
      .required(
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.REQUIRED' },
          { name: intl.formatMessage({ id: 'ACCOUNT.SUBSCRIPTION.PRICE' }) },
        ),
      )
      .label('subscriptionPrice'),
    mobileAppAccess: Yup.boolean().label('mobileAppAccess'),
    minimumZoomValue: Yup.number().label('minimumZoomValue'),
  });
const minimumZoomValueOptions = [0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2];
const userRoles: IUserRole[] = ['Admin', 'QA', 'Management', 'Dealership'];

interface IProps {
  adminPermissions: boolean;
}
/**
 *
 * This components includes a form top create and modify user information
 *
 * the component take the url params and if is empty will load an empty form otherwise it will load information from the params' id and use fill out the form
 * with the fetched info of the user
 *
 * Includes styleguide list and user list that can be attached on selection
 *
 * @param props.adminPermissions - option to add field that should be changed only by admin accounts (user settings, style guides, child accounts, etc)
 */
export const CreateDealership: React.FC<IProps> = ({ adminPermissions }) => {
  const intl = useIntl();
  const { request } = useFetch();
  const user = User();
  const location = useLocation();
  const settings = location.pathname.split('/')[2];
  const params = useParams<TParams>();
  const history = useHistory();
  const [id, setId] = useState<string>(params.id);
  const [salesMen, setSalesMen] = useState<ISalesMan[]>([]);
  const [childAccounts, setChildAccounts] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<Record<string, unknown>>({});
  const [openSalesManForm, setOpenSalesManForm] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<IUserPayload | any>();
  const [isResending, setIsResending] = useState(false);

  const salesPeopleHeadings: [string, string][] = [
    ['name', intl.formatMessage({ id: 'SALESMAN.NAME' })],
    ['email', intl.formatMessage({ id: 'SALESMAN.EMAIL' })],
    ['phone', intl.formatMessage({ id: 'SALESMAN.PHONE' })],
    ['cognitoSub', intl.formatMessage({ id: 'ACCOUNT.LOGIN' })],
    ['activation', intl.formatMessage({ id: 'ACCOUNT.ACTIVATION' })],
    ['resend', intl.formatMessage({ id: 'SALESMAN.RESEND' })],
    ['edit', intl.formatMessage({ id: 'ACTION.EDIT' })],
  ];

  /**
   * @param id user id to fetch user information
   * @returns user information on the form updating the user state
   */
  const getUserData = async (id: string) => {
    if (id !== null) {
      try {
        const userInfo = await request(getUser, id);

        if (userInfo) {
          if (userInfo.styleGuides) {
            const currentStyleGuides: Record<string, boolean> = {};

            userInfo.styleGuides.forEach(({ id }: IStyleGuide) => {
              if (id) {
                currentStyleGuides[id] = true;
              }
            });
          }
          if (userInfo.childAccounts) {
            const currentChildAccounts: Record<string, boolean> = {};

            userInfo.childAccounts.map((childAccount: string) => {
              currentChildAccounts[childAccount] = true;
              return currentChildAccounts;
            });

            setSelectedUsers(currentChildAccounts);
          }
          if (userInfo.salesMan) {
            setSalesMen(userInfo.salesMan);
          }
        }

        setUserInfo(userInfo);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const addSalesMen = (values: ISalesMan[], userId: string) => {
    const payload: ISalesManPayload = { salesMan: values, userId };

    request(createSalesMan, payload).then(() => setSalesMen(values));
  };

  /**
   * This function handles a post with the current user payload, then get salesMen, child accounts and style guides and attach
   * them to the user entity once request are processed the user is sent back to the customer list
   * @param values user payload on {@link createUserSchema}
   */
  const postUserWithoutCognito = (values: IUser) => {
    request(createUserWithoutCognito, values)
      .then(data => {
        Promise.all([request(postChildAccounts, data.id, childAccounts), addSalesMen(salesMen, data.id)]).then(() => {
          history.push('/customers');
        });

        return history.push('/customers');
      })
      .catch(err => {
        console.log(err);
      });
  };

  const updateUserData = (values: IUser, id: string) => {
    Promise.all([
      salesMen !== userInfo.salesMan ? addSalesMen(salesMen, id) : Promise.resolve(),
      values.userRole === 'Management' ? request(postChildAccounts, id, childAccounts) : Promise.resolve(),
    ])
      .then(() => request(updateUser, values, id))
      .then(() => getUserData(id))
      .catch(err => {
        console.log(err);
      });
  };

  const handleSalesManDeletion = (salesmanId: string) => {
    deleteSalesMan(salesmanId).then(() => {
      request(getSalesMenByUserId, id).then(salesMen => {
        setSalesMen(salesMen);
      });
    });
  };

  const salesManIsAlreadyAttached = (id: string) =>
    userInfo?.salesMan?.map((x: ISalesMan) => x.id).includes(id) ?? false;

  const renderRowCell = (key: string, item: { [key: string]: string }, exists: boolean) => {
    const itemKeysAreValid = () => isValidEmail(item.email) && item.name.length >= 3 && isValidPhoneNumber(item.phone);

    const cognito = 'cognitoSub';
    const switchEditMode = () => setSalesMen(ps => ps.map(sm => (sm.id === item.id ? { ...sm, edit: !sm.edit } : sm)));

    switch (key) {
      case cognito:
        return (
          <td style={{ padding: '0 0 0 25px' }}>
            {item[key] ? (
              <CheckCircleTwoToneIcon style={{ color: 'green' }} />
            ) : (
              <CancelTwoToneIcon style={{ color: 'red' }} />
            )}
          </td>
        );

      case 'activation':
        return (
          <td>
            {item[cognito] ? (
              <FormattedMessage id="DATA.EMPTY" />
            ) : (
              <Button
                className="action-button"
                variant="contained"
                color="success"
                disabled={!exists}
                onClick={() => request(activateSalesMan, item.id).then(() => getUserData(userInfo.id))}
                style={{ cursor: 'default' }}
              >
                <FormattedMessage id="ACTION.ACTIVATE" />
              </Button>
            )}
          </td>
        );

      case 'resend':
        return (
          <td>
            {item.userStatus === 'CONFIRMED' ? (
              <FormattedMessage id="DATA.EMPTY" />
            ) : (
              <Button
                className="action-button"
                variant="contained"
                color="info"
                disabled={isResending}
                onClick={() => {
                  setIsResending(true);
                  request(resendEmailRegistration, item.email)
                    .then(() => getUserData(userInfo.id))
                    .finally(() => setIsResending(false));
                }}
                style={{ cursor: 'default' }}
              >
                <FormattedMessage id="SALESMAN.RESEND" />
              </Button>
            )}
          </td>
        );

      case 'edit':
        return !item.edit ? (
          <td
            style={{ color: 'blue' }}
            onClick={() => {
              if (salesManIsAlreadyAttached(item.id)) {
                switchEditMode();
              } else {
                alert(intl.formatMessage({ id: 'ACCOUNT.ALERT.SAVE_BEFORE_EDIT' }));
              }
            }}
          >
            <EditIcon />
          </td>
        ) : (
          <td
            style={{ color: 'blue' }}
            onClick={() => {
              if (!itemKeysAreValid()) {
                return alert(intl.formatMessage({ id: 'ACCOUNT.ALERT.FILL' }));
              }
              switchEditMode();
              request(modifySalesMan, item.id, item).then(() => getSalesMenByUserId(id));
            }}
          >
            <SaveIcon />
          </td>
        );

      default:
        return (
          <td key={key} style={{ minWidth: '275px', maxWidth: '100%' }}>
            {!item.edit ? (
              stringifyValue(item[key])
            ) : (
              <TextField
                variant="standard"
                style={{ minWidth: '275px', maxWidth: '275px' }}
                error={!itemKeysAreValid()}
                defaultValue={stringifyValue(item[key])}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSalesMen(ps => ps.map(sm => (sm.id === item.id ? { ...sm, [key]: event.target.value } : sm)));
                }}
              />
            )}
          </td>
        );
    }
  };

  useEffect(() => {
    if (params.id) {
      getUserData(params.id);
    } else if (id) {
      getUserData(id);
    }
  }, [params.id, id]);

  useEffect(() => {
    if (selectedUsers) {
      const parsedChildAccounts: string[] = [];

      Object.keys(selectedUsers).forEach(key => {
        if (selectedUsers[key]) {
          parsedChildAccounts.push(key);
        }
      });

      setChildAccounts(parsedChildAccounts);
    }
  }, [selectedUsers]);

  useEffect(() => {
    if (settings === 'settings') {
      setId(user.id);
    }
  }, []);

  return (
    <MainGridContainer>
      <div className="flex-row-fluid py-lg-5">
        <Formik
          validationSchema={createUserSchema(intl)}
          initialValues={{
            ...userInfo,
            minimumZoomValue: userInfo?.minimumZoomValue ?? 1,
            userRole: userInfo?.userRole ?? 'Dealership',
          }}
          validateOnMount
          validateOnChange
          enableReinitialize
          onSubmit={values => {
            id === null || id === undefined ? postUserWithoutCognito(values) : updateUserData(values, id);
          }}
        >
          {(props: FormikProps<any>) => (
            <Form className="form" noValidate>
              <div className="w-100">
                <div className="fv-row mb-10">
                  <div className=" d-flex row">
                    {/* DEALERSHIP INFORMATION */}
                    <div className="col-5">
                      <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                        <span>
                          <FormattedMessage id="ACCOUNT.DEALERSHIP" />
                        </span>
                        <i
                          className="fas fa-exclamation-circle ms-2 fs-7"
                          data-bs-toggle="tooltip"
                          title={intl.formatMessage({ id: 'ACCOUNT.ORDER.NAME' })}
                        />
                      </label>
                      <div className="col-10 col-pull-6 ">
                        <label className="fs-7 fw-bold mb-2 userInputLabel required">
                          <FormattedMessage id="ACCOUNT.DEALERSHIP.NAME" />:
                        </label>
                        <Field
                          type="text"
                          className={`form-control form-control-lg userInputFields ${
                            props.touched.displayName && props.errors.displayName ? 'is-invalid' : 'form-control-solid'
                          }`}
                          name="displayName"
                          placeholder={intl.formatMessage({ id: 'ACCOUNT.DEALERSHIP.NAME.PLACEHOLDER' })}
                        />
                        <div style={{ color: 'red', marginTop: '-10px' }}>
                          <ErrorMessage name="displayName" />
                        </div>
                      </div>
                      <div className="col-10 col-pull-6">
                        <label className="fs-7 fw-bold mb-2 userInputLabel required">
                          <FormattedMessage id="ACCOUNT.DEALERSHIP.LOGIN_EMAIL" />:
                        </label>
                        <Field
                          error={props.errors.email}
                          type="text"
                          className={`form-control form-control-lg userInputFields ${
                            props.errors.email && props.touched.email ? 'is-invalid' : 'form-control-solid'
                          }`}
                          name="email"
                          placeholder="example@gmail.com"
                          required
                          errorClassName="fieldOnError"
                        />
                        <div style={{ color: 'red', marginTop: '-10px' }}>
                          <ErrorMessage name="email" />
                        </div>
                      </div>
                      <div className="col-10">
                        <label className="fs-7 fw-bold mb-2 userInputLabel required">
                          <FormattedMessage id="ACCOUNT.COUNTRY" />:
                        </label>
                        <Field
                          error={(props.errors.address as any)?.country}
                          type="text"
                          className={`form-control form-control-lg userInputFields ${
                            (props.errors.address as any)?.country && (props.touched?.address as any)?.country
                              ? 'is-invalid'
                              : 'form-control-solid'
                          }`}
                          name="address.country"
                          placeholder={intl.formatMessage({ id: 'ACCOUNT.COUNTRY.PLACEHOLDER' })}
                        />
                        <div style={{ color: 'red', marginTop: '-10px' }}>
                          <ErrorMessage name="address.country" />
                        </div>
                      </div>
                      <div className="col-10">
                        <label className="fs-7 fw-bold mb-2 userInputLabel">
                          <FormattedMessage id="ACCOUNT.ADDRESS" />:
                        </label>
                        <Field
                          // error={props.errors.address}
                          type="text"
                          className={`form-control form-control-lg userInputFields ${
                            (props.errors.address as any)?.address && (props.touched.address as any)?.address
                              ? 'is-invalid'
                              : 'form-control-solid'
                          }`}
                          name="address.address"
                          placeholder={intl.formatMessage({ id: 'ACCOUNT.ADDRESS' })}
                        />
                        <div style={{ color: 'red', marginTop: '-10px' }}>
                          <ErrorMessage name="address.address" />
                        </div>
                      </div>
                      <div className="col-10">
                        <label className="fs-7 fw-bold mb-2 userInputLabel">
                          <FormattedMessage id="ACCOUNT.POSTAL_CODE" />:
                        </label>
                        <Field
                          type="text"
                          className={`form-control form-control-lg userInputFields ${
                            (props.errors.address as any)?.postalCode && (props.touched.address as any)?.postalCode
                              ? 'is-invalid'
                              : 'form-control-solid'
                          }`}
                          name="address.postalCode"
                          placeholder="20050"
                        />
                        <div style={{ color: 'red', marginTop: '-10px' }}>
                          <ErrorMessage name="address.postalCode" />
                        </div>
                      </div>
                      <div className="col-10">
                        <label className="fs-7 fw-bold mb-2 userInputLabel">
                          <FormattedMessage id="ACCOUNT.CITY" />:
                        </label>
                        <Field
                          type="text"
                          className={`form-control form-control-lg userInputFields ${
                            (props.errors.address as any)?.city && (props.touched.address as any)?.city
                              ? 'is-invalid'
                              : 'form-control-solid'
                          }`}
                          name="address.city"
                          placeholder={intl.formatMessage({ id: 'ACCOUNT.CITY.PLACEHOLDER' })}
                        />
                        <div style={{ color: 'red', marginTop: '-10px' }}>
                          <ErrorMessage name="address.city" />
                        </div>
                      </div>
                      <div className="col-10">
                        <label className="fs-7 fw-bold mb-2 userInputLabel">
                          <FormattedMessage id="ACCOUNT.COMPANY_NUMBER" />
                        </label>
                        <Field
                          type="text"
                          className={`form-control form-control-lg userInputFields ${
                            (props.errors.address as any)?.companyId && (props.touched.address as any)?.companyId
                              ? 'is-invalid'
                              : 'form-control-solid'
                          }`}
                          name="address.companyId"
                          placeholder="21312321"
                        />
                        <div style={{ color: 'red', marginTop: '-10px' }}>
                          <ErrorMessage name="address.companyId" />
                        </div>
                      </div>
                    </div>
                    <div className="col-5">
                      {/* CONTACT PERSON INFORMATION */}
                      <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                        <span>
                          <FormattedMessage id="ACCOUNT.CONTACT_PERSON" />
                        </span>
                        <i
                          className="fas fa-exclamation-circle ms-2 fs-7"
                          data-bs-toggle="tooltip"
                          title={intl.formatMessage({ id: 'PASTE_BOX.SUBTITLE' })}
                        />
                      </label>
                      {/* INSERT CONTACT PERSON */}
                      <div className="col-10 col-pull-6 ">
                        <label className="fs-7 fw-bold mb-2 userInputLabel required">
                          <FormattedMessage id="ORDER.MENU.NAME" />
                        </label>
                        <Field
                          type="text"
                          className={`form-control form-control-lg userInputFields ${
                            (props.errors.contactPerson as any)?.name && (props.touched.contactPerson as any)?.name
                              ? 'is-invalid'
                              : 'form-control-solid'
                          }`}
                          name="contactPerson.name"
                          placeholder="John Doe"
                        />
                        <div style={{ color: 'red', marginTop: '-10px' }}>
                          <ErrorMessage name="contactPerson.name" />
                        </div>
                      </div>
                      <div className="col-10 col-pull-6">
                        <label className="fs-7 fw-bold mb-2 userInputLabel required">
                          <FormattedMessage id="ACCOUNT.EMAIL" />:
                        </label>
                        <Field
                          type="text"
                          className={`form-control form-control-lg userInputFields ${
                            (props.errors.contactPerson as any)?.email && (props.touched.contactPerson as any)?.email
                              ? 'is-invalid'
                              : 'form-control-solid'
                          }`}
                          name="contactPerson.email"
                          placeholder="johndoe@gmail.com"
                          required
                          errorClassName="fieldOnError"
                        />
                        <div style={{ color: 'red', marginTop: '-10px' }}>
                          <ErrorMessage name="contactPerson.email" />
                        </div>
                      </div>
                      <div className="col-10">
                        <label className="fs-7 fw-bold mb-2 userInputLabel">
                          <FormattedMessage id="ACCOUNT.PHONE" />:
                        </label>
                        <Field
                          type="text"
                          className={`form-control form-control-lg userInputFields ${
                            (props.errors.contactPerson as any)?.phoneNumber &&
                            (props.touched.contactPerson as any)?.phoneNumber
                              ? 'is-invalid'
                              : 'form-control-solid'
                          }`}
                          name="contactPerson.phoneNumber"
                          placeholder=" +45 12 34 06 72"
                        />
                        <div style={{ color: 'red', marginTop: '-10px' }}>
                          <ErrorMessage name="contactPerson.phoneNumber" />
                        </div>
                      </div>
                      <div className="col-10">
                        <label className="fs-7 fw-bold mb-2 userInputLabel">
                          <FormattedMessage id="ACCOUNT.USER_TITLE" />:
                        </label>
                        <Field
                          type="text"
                          className={`form-control form-control-lg userInputFields ${
                            (props.errors.contactPerson as any)?.title && (props.touched.contactPerson as any)?.title
                              ? 'is-invalid'
                              : 'form-control-solid'
                          }`}
                          name="contactPerson.title"
                          placeholder={intl.formatMessage({ id: 'ACCOUNT.USER_TITLE.PLACEHOLDER' })}
                        />
                        <div style={{ color: 'red', marginTop: '-10px' }}>
                          <ErrorMessage name="contactPerson.title" />
                        </div>
                      </div>
                    </div>
                    {/* PASTEBOX INFORMATION */}
                    <div className="col-2">
                      <PasteBox getPastedData={setUserInfo} />
                    </div>
                  </div>
                </div>
              </div>
              {adminPermissions && (
                <div className="row mb-5 align-items-center">
                  <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                    <span>
                      <FormattedMessage id="ACCOUNT.DETAILS" />
                    </span>
                    <i
                      className="fas fa-exclamation-circle ms-2 fs-7"
                      data-bs-toggle="tooltip"
                      title={intl.formatMessage({ id: 'PASTE_BOX.SUBTITLE' })}
                    />
                  </label>
                  <div className="col-2 d-flex mt-5 mb-5 cursor-pointer align-items-baseline">
                    <label className="fs-7 fw-bold mb-2 userInputLabel">
                      <FormattedMessage id="ACCOUNT.USER_ROLE" />
                    </label>
                    <SelectFieldFromArray name="userRole" options={userRoles} />
                  </div>
                  <div className="col-2 d-flex mt-5 mb-5 cursor-pointer align-items-baseline">
                    <label className="fs-7 fw-bold mb-2 userInputLabel">
                      <FormattedMessage id="ACCOUNT.ZOOM" />
                    </label>
                    <SelectFieldFromArray name="minimumZoomValue" options={minimumZoomValueOptions} />
                  </div>
                  <div className="col-2 d-block">
                    <label style={{ marginTop: '1em' }} className="ml-5 fs-7 fw-bold mb-2 userInputLabel">
                      <FormattedMessage id="ACCOUNT.MOBILE" />
                    </label>
                    <Field defaultChecked type="checkbox" name="mobileAppAccess" />
                  </div>
                  <div className="col-2 d-block">
                    <label style={{ marginTop: '1em' }} className="ml-5 fs-7 fw-bold mb-2 userInputLabel ">
                      <FormattedMessage id="ACCOUNT.QA" />
                    </label>
                    <Field defaultChecked={userInfo?.enabled_qa} type="checkbox" name="enabled_qa" />
                  </div>
                  <div className="col-2 d-block">
                    <label style={{ marginTop: '1em' }} className="ml-5 fs-7 fw-bold mb-2 userInputLabel ">
                      <FormattedMessage id="ACCOUNT.DESATURATE_YELLOW_LIGHT" />
                    </label>
                    <Field
                      defaultChecked={userInfo?.desaturateYellowLight}
                      type="checkbox"
                      name="desaturateYellowLight"
                    />
                  </div>
                  <div className="row mb-5">
                    <div className="col-3 ">
                      <label className="fs-7 fw-bold mb-2 userInputLabel accountDetailLabel">
                        <FormattedMessage id="ACCOUNT.CURRENCY" />:
                      </label>
                      <Field
                        type="text"
                        className={`form-control form-control-lg userInputFields ${
                          props.errors.currency && props.touched.currency ? 'is-invalid' : 'form-control-solid'
                        }`}
                        name="currency"
                        placeholder="EUR"
                      />
                      <div style={{ color: 'red', marginTop: '-10px' }}>
                        <ErrorMessage name="currency" />
                      </div>
                    </div>
                    <div className="col-3">
                      <label className="fs-7 fw-bold mb-2 userInputLabel">
                        <FormattedMessage id="ACCOUNT.SUBSCRIPTION.PRICE" />:
                      </label>
                      <Field
                        type="number"
                        className={`form-control form-control-lg userInputFields ${
                          props.errors.subscriptionPrice && props.touched.subscriptionPrice
                            ? 'is-invalid'
                            : 'form-control-solid'
                        }`}
                        name="subscriptionPrice"
                        placeholder="200"
                      />
                      <div style={{ color: 'red', marginTop: '-10px' }}>
                        <ErrorMessage name="subscriptionPrice" />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <Divider />
              <div className="row mb-5 mt-5">
                <div className="d-flex align-items-center fs-5 fw-bold mb-2">
                  <span>
                    <FormattedMessage id="ACCOUNT.SALES_PEOPLE.ADD" />
                  </span>
                  <Button color="primary" onClick={() => setOpenSalesManForm(!openSalesManForm)}>
                    {openSalesManForm ? <CloseIcon /> : <AddIcon />}
                  </Button>
                </div>
                <SettingsSalesManForm
                  open={openSalesManForm}
                  setSalesMen={setSalesMen}
                  setOpenSalesManForm={setOpenSalesManForm}
                  formikProps={props}
                />
                <div className="col-6" style={{ minHeight: '300px', width: 'fit-content' }}>
                  <Datagrid
                    deletable
                    handleDelete={handleSalesManDeletion}
                    data={salesMen}
                    headings={salesPeopleHeadings}
                    renderRow={(headings: Array<string[]>, item: { [key: string]: string }) =>
                      headings.map(([key]) => renderRowCell(key, item, salesManIsAlreadyAttached(item.id)))
                    }
                  />
                </div>
              </div>
              <div className="d-flex flex-end flex-stack pt-10">
                <div className="me-2 offset-10">
                  <ButtonLoader
                    disabled={!props.dirty}
                    text={intl.formatMessage({
                      id: id === null || id === undefined ? 'ACTION.CREATE_USER' : 'ACTION.UPDATE',
                    })}
                    loading={props.isSubmitting}
                    request={props.handleSubmit}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </MainGridContainer>
  );
};
