import { FC } from 'react';

import { MenuInner } from './MenuInner';

export const Header: FC = () => (
  <div className="header-menu align-items-stretch">
    <div className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch">
      <MenuInner />
    </div>
  </div>
);
