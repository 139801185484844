function emailContainsSpecialChars(email: string): boolean {
  const specialCharacters = ['!', '#', '$', '%', '&', "'", '*', '+', '/', '=', '?', '^', '`', '{', '|', '}', '~', ' '];

  for (const char of email) {
    if (specialCharacters.includes(char)) {
      return true;
    }
    continue;
  }

  return false;
}

export function isValidEmail(email: string | null): boolean {
  if (email) {
    const atSymbolIndex = email.indexOf('@');
    const lastPeriodIndex = email.lastIndexOf('.');

    const hasMultipleAtSymbols = email.indexOf('@', atSymbolIndex + 1) !== -1;
    const hasConsecutiveDots = email.includes('..');
    const containsSpecialCharacters = emailContainsSpecialChars(email);

    return (
      atSymbolIndex > 0 &&
      lastPeriodIndex > atSymbolIndex &&
      lastPeriodIndex < email.length - 1 &&
      !hasMultipleAtSymbols &&
      !hasConsecutiveDots &&
      !containsSpecialCharacters
    );
  }

  return false;
}
