import { ImageDisplay } from '../../../../../ui/components/Image/ImageDisplay';

const isThumbnail = (key: string) => ['icon'].includes(key);

type ThumbnailItem = Record<string, { thumbnailUrl: string; url: string }>;

export const renderTestsRow = (headings: [string, string][], item: ThumbnailItem) =>
  headings.map(([key]) => (
    <td key={key}>
      {isThumbnail(key) ? (
        <div className="symbol me-2">
          <ImageDisplay
            src={item[key]?.url}
            style={{
              objectFit: 'contain',
              alignSelf: 'center',
              minWidth: '70px',
              minHeight: '55px',
            }}
          />
        </div>
      ) : (
        item[key]
      )}
    </td>
  ));
