import config from 'react-global-configuration';

import { generateRequestOptions } from './helpers';

export const getStyleGuides = (queries: string, abort: AbortSignal): Promise<Response> => {
  const url = `${config.get('ApiUrl').Rest}/style-guides${queries}`;

  return fetch(url, generateRequestOptions('GET', abort));
};
export const getStyleGuide = async (id: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/style-guides/${id}`, {
    mode: 'cors',
    credentials: 'include',
  });

export const getStyleGuidesByUserId = (userId: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/style-guides/user/${userId}`, {
    mode: 'cors',
    credentials: 'include',
  });

export const createStyleGuide = (styleGuideData: IStyleGuide): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/style-guides`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(styleGuideData),
    mode: 'cors',
    credentials: 'include',
  });

export const modifyStyleGuides = (styleGuideId: string, styleGuideData: IStyleGuide): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/style-guides/${styleGuideId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(styleGuideData),
    mode: 'cors',
    credentials: 'include',
  });

/**
 * @deprecated should be removed after Dec, 2022. it is changed by {@link addStyleGuideByUserId}
 */
export const addStyleGuideToUser = (styleGuideIds: string[], userId: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/style-guides/connect/${userId}/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    body: JSON.stringify({ styleGuides: styleGuideIds }),
    credentials: 'include',
  });

export const addStyleGuideByUserId = (userId: string, styleGuideId: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/style-guides/add-style-guide-to-user/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    body: JSON.stringify({
      userId,
      styleGuideId,
    }),
    credentials: 'include',
  });

export const removeStyleGuideFromUser = (userId: string, styleGuideId: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/style-guides/remove-style-guide-to-user/`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    body: JSON.stringify({
      userId,
      styleGuideId,
    }),
    credentials: 'include',
  });

export const deleteStyleGuide = (styleGuideId: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/style-guides/delete-style-guide/`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    body: JSON.stringify({
      id: styleGuideId,
    }),
    credentials: 'include',
  });

export const getStyleGuideConfigurations = (): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/style-guide-configurations`, {
    mode: 'cors',
    credentials: 'include',
  });

export const getStyleguideConfigurationDetails = (id: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/style-guide-configurations/${id}`, {
    mode: 'cors',
    credentials: 'include',
  });

export const postStyleguideConfiguration = (data: IStyleGuideConfigurations): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/style-guide-configurations`, {
    body: JSON.stringify(data),
    mode: 'cors',
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const putStyleguideConfiguration = (id: string, payload: IStyleGuideConfigurations): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/style-guide-configurations/${id}`, {
    body: JSON.stringify(payload),
    mode: 'cors',
    credentials: 'include',
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const removeStyleguideConfiguration = (id: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/style-guide-configurations/${id}`, {
    mode: 'cors',
    credentials: 'include',
    method: 'DELETE',
  });

export const getUtilityMedia = (): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/media`, {
    mode: 'cors',
    credentials: 'include',
  });
