import { FC, FormEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormControl, Grid, MenuItem, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

import { MediaOrigin } from '../../../constants';
import { insertImageOnOrder, InsertImagePayload } from '../../../setup/api';
import { alertPayload, useFetchWithAlert } from '../../../setup/hooks/useFetchWithAlert';
import { reloadOrderInformation } from '../../../setup/redux/reducers/currentOrderInformationReducer';
import { ContainerGrid } from '../MainGridContainer/GridSystemMUI';

import { OrderParams } from './ImageDetail/ImageDetailModal';

const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30%',
  height: '40%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  display: 'block',
  p: 4,
};

const MediaOriginArray = Object.values(MediaOrigin);

const AddNewImageToOrderButton: FC<{ useToggle: () => void }> = ({ useToggle }) => (
  <Button style={{ margin: '1em 1em 1em 1em' }} color="primary" variant="contained" onClick={useToggle}>
    <FormattedMessage id="DATA.IMAGE.INSERT" />
  </Button>
);

const renderTitle = (title: string) => (
  <div className="card-header pt-6" style={{ marginBottom: '2em' }}>
    <div className="h4" style={{ width: '40%' }}>
      <FormattedMessage id={title} />
    </div>
  </div>
);

export const InsertImageModal: FC = () => {
  const intl = useIntl();
  const { id } = useParams<OrderParams>();
  const [imagePayload, setImagePayload] = useState<InsertImagePayload>({ order: id });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { request, isLoading } = useFetchWithAlert(alertPayload(intl.formatMessage({ id: 'IMAGE.INSERT' })));

  const dispatch = useDispatch();

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const isImagePayloadInvalid = (payload: InsertImagePayload) => {
    if (!payload.position) {
      return true;
    }
    if (Number(payload.position) < 0) {
      return true;
    }
    if (!payload.mediaOrigin) {
      return true;
    }
    return false;
  };

  const insertImageRequest = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    request(insertImageOnOrder, imagePayload).then(() => {
      setIsOpen(false);
      dispatch(reloadOrderInformation(true));
    });
  };

  return (
    <>
      <AddNewImageToOrderButton useToggle={toggleModal} />
      <Modal sx={{ overflowY: 'scroll' }} disableScrollLock={false} open={Boolean(isOpen)} onClose={toggleModal}>
        <Box sx={modalStyle} noValidate component="form" onSubmit={insertImageRequest}>
          {renderTitle('IMAGE.CREATE')}
          <ContainerGrid>
            <Grid xs={12} justifyContent="space-around" className="mx-5 d-flex">
              <FormControl required fullWidth>
                <TextField
                  style={{ width: '80%' }}
                  helperText={intl.formatMessage({ id: 'IMAGE.POSITION' })}
                  onChange={({ target }) => {
                    setImagePayload({
                      ...imagePayload,
                      position: target.value,
                    });
                  }}
                  error={imagePayload.position === undefined || Number(imagePayload.position) < 0}
                  type="number"
                />
              </FormControl>

              <FormControl required fullWidth>
                <TextField
                  style={{ width: '80%' }}
                  select
                  label={intl.formatMessage({ id: 'ACTION.SELECT' })}
                  onChange={({ target }) => {
                    setImagePayload({
                      ...imagePayload,
                      mediaOrigin: target.value as MediaOrigin,
                    });
                  }}
                  error={imagePayload.mediaOrigin === undefined}
                  helperText={intl.formatMessage({ id: 'IMAGE.TYPE.SELECT' })}
                >
                  {MediaOriginArray.map(option => (
                    <MenuItem key={option} value={option}>
                      <FormattedMessage id={`IMAGE.${option.toUpperCase()}`} />
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid xs={12} justifyContent="space-around" className="mt-5 d-flex">
              <Button
                className="col-5 ms-1"
                variant="contained"
                color="success"
                type="submit"
                disabled={isImagePayloadInvalid(imagePayload)}
                children={<FormattedMessage id={isLoading ? 'ACTION.PENDING' : 'DATA.IMAGE.INSERT'} />}
              />
            </Grid>
          </ContainerGrid>
        </Box>
      </Modal>
    </>
  );
};
