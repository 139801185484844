import { FC, MouseEvent, useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { IconButton, Menu } from '@mui/material';

import { HeaderUserMenu } from '../../../partials/HeaderUserMenu';
import { CreateShortcut } from '../CreateShortcut';

export const UserMenu: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CreateShortcut />
      <IconButton
        size="large"
        onClick={(event: MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        <AccountCircleIcon sx={{ fontSize: 40 }} color="primary" />
      </IconButton>
      <Menu anchorEl={anchorEl} open={anchorEl !== null} onClose={handleMenuClose}>
        <HeaderUserMenu onClick={handleMenuClose} />
      </Menu>
    </>
  );
};
