/* eslint-disable quotes */
import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';

import * as auth from './auth/AuthRedux';
import currentOrderInformationReducer from './reducers/currentOrderInformationReducer';
import { currentUserReducer } from './reducers/currentUserReducer';
import { getStyleGuideReducer } from './reducers/getStyleGuideInfo';
import { imageSelectorReducer } from './reducers/imageSelector';
import { reloadTableFromModal } from './reducers/reloadTableFromModal';
import { reloadTagsReducer } from './reducers/reloadTagDisplay';
import { selectedFiltersReducer } from './reducers/selectedFilters';
import { selectedImagesReducer } from './reducers/selectedImages';
import { styleGuideConfigurationsReducer } from './reducers/StyleGuideConfigurationsForms';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  images: selectedImagesReducer,
  filters: selectedFiltersReducer,
  styleGuideConfigurations: styleGuideConfigurationsReducer,
  reloadTableModal: reloadTableFromModal,
  reloadTags: reloadTagsReducer,
  imageSelectors: imageSelectorReducer,
  styleGuide: getStyleGuideReducer,
  currentUser: currentUserReducer,
  currentOrderInformation: currentOrderInformationReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

// Thanks to that you will have ability to use useSelector hook with state value
declare module 'react-redux' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultRootState extends RootState {}
}

export function* rootSaga() {
  yield all([auth.saga()]);
}
