import config from 'react-global-configuration';

import { IAnglePayload } from './models/angle';
import { generateRequestOptions } from './helpers';

export const getAngles = (queries: string, abort: AbortSignal): Promise<Response> => {
  const url = `${config.get('ApiUrl').Rest}/angle${queries}`;

  return fetch(url, generateRequestOptions('GET', abort));
};

export const getAngle = (id: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/angle/${id}`, {
    mode: 'cors',
    credentials: 'include',
  });
export const putAngle = (payload: IAnglePayload, id: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/angle/${id}`, {
    body: JSON.stringify(payload),
    mode: 'cors',
    credentials: 'include',
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const postAngle = (payload: IAnglePayload): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/angle`, {
    body: JSON.stringify(payload),
    mode: 'cors',
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const getAllAngleNames = (): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/angle/angleNames`, {
    mode: 'cors',
    credentials: 'include',
  });
