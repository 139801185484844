import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Grid, styled, Typography } from '@mui/material';

import { RootState } from '../../../setup/redux/RootReducer';
import { MainGridContainer } from '../../../ui/components/MainGridContainer/MainGridContainer';

const DisabledTextField = styled('div')({
  color: 'darkslategray',
  backgroundColor: '#f5f8fa',
  height: 45,
  padding: '0.825rem 1.5rem;',
  marginRight: 100,
  fontSize: '1.15rem',
  borderRadius: 4,
  fontWeight: 500,
  marginBottom: 10,
});

const CustomLabel = styled('label')({
  color: '#8f8f8f',
  fontWeight: 500,
  fontSize: '0.95rem',
  marginBottom: 5,
});

export const AccountInfo: FC = () => {
  const intl = useIntl();
  const userInformation = useSelector((state: RootState) => state.auth.user);

  const accountInformationValuesAndLabels = [
    [userInformation?.displayName, intl.formatMessage({ id: 'ACCOUNT.DEALERSHIP' })],
    [userInformation?.email, intl.formatMessage({ id: 'ACCOUNT.EMAIL' })],
    [userInformation?.address?.country, intl.formatMessage({ id: 'ACCOUNT.COUNTRY' })],
    [userInformation?.address?.city, intl.formatMessage({ id: 'ACCOUNT.CITY' })],
    [userInformation?.address?.postalCode, intl.formatMessage({ id: 'ACCOUNT.POSTAL_CODE' })],
    [userInformation?.address?.companyId, intl.formatMessage({ id: 'ACCOUNT.COMPANY' })],
    [userInformation?.contactPerson?.phoneNumber, intl.formatMessage({ id: 'ACCOUNT.PHONE' })],
    [userInformation?.contactPerson?.email, intl.formatMessage({ id: 'ACCOUNT.CONTACT_MAIL' })],
    [userInformation?.contactPerson?.title, intl.formatMessage({ id: 'ACCOUNT.USER_TITLE' })],
    [userInformation?.contactPerson?.name, intl.formatMessage({ id: 'ACCOUNT.NAME' })],
  ];

  return (
    <MainGridContainer>
      <div style={{ display: 'flex', flexDirection: 'column', padding: '2em 3em' }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
          <FormattedMessage id="ACCOUNT.TITLE" />
        </Typography>

        <Grid container spacing={2}>
          {accountInformationValuesAndLabels.map(([values, labels]) => (
            <Grid item xs={6}>
              <CustomLabel>{labels}</CustomLabel>
              <DisabledTextField>{values}</DisabledTextField>
            </Grid>
          ))}
        </Grid>
      </div>
    </MainGridContainer>
  );
};
