import { FC } from 'react';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import { MediaOrigin, TagEntity } from '../../../../constants';
import { deleteTagFromEntity } from '../../../../setup/api';
import { useFetch } from '../../../../setup/hooks/fetch.hook';

type Props = {
  imageData: IImage;
  callback: () => void;
};

export const ImageBadges: FC<Props> = (props: Props) => {
  const { request } = useFetch();
  const { imageData, callback } = props;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <p
        className={`badge badge-${imageData.mediaOrigin === MediaOrigin.OPTIMIZED ? 'primary' : 'secondary'}`}
        style={{ fontSize: '1.05rem' }}
      >
        {`${imageData.position} - ${imageData.mediaOrigin.toUpperCase()}`}
      </p>

      {imageData.tags.map((tag: ITag) => (
        <p className="badge" style={{ backgroundColor: tag.color, color: 'white', marginLeft: '5px' }}>
          {tag.name}
          <div style={{ fontSize: 'small', display: 'inline-block', cursor: 'pointer', paddingLeft: '5px' }}>
            <DeleteForeverOutlinedIcon
              onClick={() => {
                request(deleteTagFromEntity, {
                  entity: `${TagEntity.IMAGE}s`,
                  entityId: imageData.id,
                  tagId: tag.id,
                }).then(() => callback());
              }}
            />
          </div>
        </p>
      ))}
    </div>
  );
};
