import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { IntegrationProvider } from '../../../carswip_shared_models/src/web_view_models/IntegrationProviders';

type Props = {
  addNewIntegration: (integrationProviderName: string) => void;
  currentIntegrations: string[];
};

export const DialogSelect: React.FC<Props> = ({ addNewIntegration, currentIntegrations }) => {
  const [open, setOpen] = React.useState(false);
  const [integration, setIntegration] = React.useState<string>('');

  const handleChange = (event: SelectChangeEvent<typeof integration>) => {
    setIntegration(event.target.value || '');
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  return (
    <>
      <AddCircleIcon
        color="primary"
        onClick={handleClickOpen}
        sx={{
          fontSize: 22,
          cursor: 'pointer',
          marginLeft: '64px',
        }}
      />
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>
          <FormattedMessage id="API.SELECT" />
        </DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select value={integration} onChange={handleChange}>
                {Object.values(IntegrationProvider).map(provider => {
                  if (currentIntegrations.includes(provider)) {
                    return null;
                  }
                  return (
                    <MenuItem key={provider} value={provider}>
                      {provider}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            <FormattedMessage id="ACTION.CANCEL" />
          </Button>
          <Button
            onClick={e => {
              handleClose(e);
              addNewIntegration(integration);
            }}
          >
            <FormattedMessage id="ACTION.OK" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
