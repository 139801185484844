import React from 'react';

type Props = {
  className: string;
  color: string;
  iconMUI?: JSX.Element;
  iconColor: string;
  title: string;
  description: string;
};

const StatisticsForTotalCount: React.FC<Props> = ({ className, color, iconMUI, title, description }) => (
  <a className={`card bg-${color} hoverable ${className}`}>
    {/* begin::Body */}
    <div className="card-body">
      {iconMUI && iconMUI}
      <div className={`text-inverse-${color} fw-bolder fs-2 mb-2 mt-5`}>{title}</div>

      <div className={`fw-bold text-inverse-${color} fs-7`}>{description}</div>
    </div>
    {/* end::Body */}
  </a>
);

export { StatisticsForTotalCount };
