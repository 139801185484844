import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import ImageIcon from '@mui/icons-material/Image';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import StyleIcon from '@mui/icons-material/Style';
import { Button } from '@mui/material';

import { MediaOrigin, UserRole } from '../../../../constants';
import { RootState } from '../../../../setup';
import { downloadOptimizedImages, downloadOrderImages } from '../../../../setup/api';
import { ButtonLoader } from '../../../../ui/components/Button/ButtonLoader';
import { InsertImageModal } from '../../../../ui/components/Modals/InsertImageModal';
import { TagDisplay } from '../../../../ui/components/TagManager/TagDisplay';
import { UnderlinedText } from '../../../../ui/components/UnderlineText/UnderlineText';
import { User } from '../../../../ui/helpers';
import { DeleteModal } from '../../../components/order/DeleteModal';
import { RepushModal } from '../../../components/repush/RepushModal';
import { ModalType, SalesManModal } from '../../../components/salesMan/SalesManModal';

import ImageCheckbox from './ImageCheckbox';
import { OrderInformationDetailRow } from './OrderInformationDetailRow';
import { OrderNameField } from './OrderNameField';

type Props = {
  orderInfo: IOrder;
  images: any;
  repushButton?: boolean;
  tagDisplay?: boolean;
  reloadOrderInfo: () => void;
};

const createDateRow = (createdAt: Date) => (
  <li className="list-group-item d-flex justify-content-between align-items-start">
    <div className="ms-2 me-auto">
      <div className="fw-bold">
        <CalendarTodayIcon fontSize="inherit" />
        <FormattedMessage id="ORDER.DATE" />
      </div>
      <Moment format="HH:mm:ss" date={`${createdAt}`} /> - <Moment format="DD/MM/YYYY " date={`${createdAt}`} />
    </div>
  </li>
);

/**
 * Renders a component with the order details,  option to delete, repush and download images
 * @param props.orderInfo order information (styleguide, salesman, dealerships, creation date)
 * @param props.images images ofe the order, used to count the amount of images
 * @param props.repushButton if true it will show a button that will load the repush modal with the selected images on Redux
 * @param props.tagDisplay  if true it will display image tags of the order
 */
export const OrderInformationBox: FC<Props> = ({ orderInfo, images, repushButton, tagDisplay, reloadOrderInfo }) => {
  const { userRole } = User();
  const { id } = useParams<TParams>();
  const [isSalesManModalOpen, setIsSalesManModalOpen] = useState(false);
  const selectedImages = useSelector((state: RootState) => state.images.images);
  const intl = useIntl();

  return (
    <div className="card" style={{ minWidth: '250px' }}>
      <div className="card-header bg-white">
        <h5 className="card-title">
          <FormattedMessage id="ORDER.DETAILS" />
        </h5>
      </div>
      <div className="card-body bg-white">
        <ul className="list-group mt-0">
          {orderInfo?.createdAt && createDateRow(orderInfo?.createdAt)}
          {orderInfo?.user && (
            <Link to={`/profile/${orderInfo.user.id}`}>
              <li className="list-group-item d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                  <div className="fw-bold">
                    <PersonOutlineIcon fontSize="inherit" />
                    <FormattedMessage id="ACCOUNT.DEALERSHIP" />
                  </div>
                  <UnderlinedText>
                    {orderInfo?.user.displayName} <br />
                  </UnderlinedText>
                </div>
              </li>
            </Link>
          )}
          {orderInfo?.styleGuide && orderInfo?.styleGuide.name && (
            <Link to={`/style-guide-v2/${orderInfo.styleGuide.id}`}>
              <li className="list-group-item d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                  <div className="fw-bold">
                    <StyleIcon fontSize="inherit" />
                    <FormattedMessage id="STYLE_GUIDE" />
                  </div>
                  <UnderlinedText>{orderInfo?.styleGuide.name}</UnderlinedText>
                </div>
              </li>
            </Link>
          )}
          {orderInfo?.salesMan && orderInfo?.salesMan.name && (
            <li
              className="list-group-item d-flex justify-content-between align-items-start"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setIsSalesManModalOpen(!isSalesManModalOpen);
              }}
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">
                  <PersonIcon fontSize="inherit" />
                  <FormattedMessage id="SALESMAN.TITLE" />
                </div>
                <UnderlinedText
                  style={{
                    textOverflow: 'ellipsis',
                    width: '180px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {orderInfo?.salesMan.name}
                </UnderlinedText>
              </div>
            </li>
          )}
          <li className="list-group-item d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto">{OrderNameField(orderInfo.orderName, reloadOrderInfo)}</div>
          </li>
          {(userRole === UserRole.ADMIN || userRole === UserRole.QA) && (
            <>
              <OrderInformationDetailRow
                header={intl.formatMessage({ id: 'ORDER.CAR_TYPE' })}
                value={orderInfo?.carType?.carTypeName ?? intl.formatMessage({ id: 'ORDER.CAR_TYPE.EMPTY' })}
              />
              {orderInfo?.appVersion !== '-' && orderInfo.appVersion ? (
                <OrderInformationDetailRow
                  header={intl.formatMessage({ id: 'VERSION.APP' })}
                  value={orderInfo?.appVersion ?? intl.formatMessage({ id: 'DATA.EMPTY' })}
                />
              ) : null}
            </>
          )}
          {orderInfo?.userAgent && (
            <li className="list-group-item d-flex justify-content-between align-items-start">
              <div className="ms-2 me-auto">
                <div className="fw-bold">
                  <DevicesOtherIcon fontSize="inherit" />
                  <FormattedMessage id="ORDER.DEVICE" />
                </div>
                {orderInfo?.userAgent}
              </div>
            </li>
          )}
          {orderInfo?.images && (
            <li className="list-group-item d-flex justify-content-between align-items-start">
              <div className="ms-2 me-auto">
                <div className="fw-bold">
                  <ImageIcon fontSize="inherit" />
                  <FormattedMessage id="ORDER.VIEW.IMAGES" />
                </div>
                {orderInfo?.images.length}
              </div>
            </li>
          )}
        </ul>
        {tagDisplay && <TagDisplay entityId={orderInfo.id} entityType="order" />}
      </div>
      {(images && images.length > 0) || userRole === UserRole.DEALERSHIP ? (
        <>
          {userRole !== UserRole.DEALERSHIP && <ImageCheckbox />}
          {userRole !== UserRole.DEALERSHIP && (
            <ButtonLoader
              style={{
                margin: '1em 1em 1em 1em',
                border: 'solid 1px',
              }}
              color="primary"
              variant="outlined"
              selfLoader
              loading={false}
              request={() => {
                if (selectedImages.length > 0) {
                  return downloadOrderImages(
                    {
                      orderId: id,
                      orderName: orderInfo.orderName,
                    },
                    selectedImages,
                  );
                }
                return downloadOrderImages(
                  {
                    orderId: id,
                    orderName: orderInfo.orderName,
                  },
                  selectedImages,
                );
              }}
              text={
                selectedImages.length > 0
                  ? intl.formatMessage({ id: 'ACTION.DOWNLOAD_IMAGES_COUNT' }, { count: selectedImages.length })
                  : intl.formatMessage({ id: 'ACTION.DOWNLOAD_IMAGES_ALL' })
              }
            />
          )}
          <ButtonLoader
            style={{ margin: '1em 1em 1em 1em' }}
            color="primary"
            variant="contained"
            selfLoader
            loading={false}
            request={() =>
              downloadOrderImages(
                {
                  orderId: orderInfo.id,
                  orderName: images.id,
                },
                images
                  .filter((image: any) => !image.approved && image.mediaOrigin === MediaOrigin.OPTIMIZED)
                  .map(({ id }: any) => id),
                true,
              )
            }
            text={intl.formatMessage({ id: 'ACTION.DOWNLOAD_REQUIRED_EDITION' })}
          />
          <ButtonLoader
            style={{ margin: '1em 1em 1em 1em' }}
            color="primary"
            variant="contained"
            selfLoader
            loading={false}
            request={() => downloadOptimizedImages(id, orderInfo.orderName)}
            text={intl.formatMessage({ id: 'ACTION.DOWNLOAD_IMAGES_OPTIMIZED' })}
          />
          {userRole !== UserRole.DEALERSHIP && <DeleteModal button />}
        </>
      ) : (
        <>
          <Button
            style={{ margin: '1em 1em 1em 1em', border: 'solid 1px' }}
            color="primary"
            onClick={() => {
              downloadOrderImages(
                {
                  orderId: orderInfo.id,
                  orderName: images.id,
                },
                [images.id],
              );
            }}
          >
            <FormattedMessage id="ACTION.DOWNLOAD_IMAGE" />
          </Button>
          <Button
            style={{ margin: '1em 1em 1em 1em', border: 'solid 1px' }}
            color="success"
            disabled={!orderInfo.styleGuide}
            onClick={() => {
              downloadOrderImages(
                {
                  orderId: orderInfo.id,
                  orderName: images.id,
                },
                [images.id],
                true,
              );
            }}
          >
            <FormattedMessage id="ACTION.DOWNLOAD_IMAGE_AND_STYLEGUIDE" />
          </Button>
        </>
      )}
      <div className="d-flex justify-content-end">
        <SalesManModal
          type={ModalType.READ}
          salesManId={(orderInfo.salesMan && orderInfo.salesMan.id) || ' '}
          open={isSalesManModalOpen}
        />
      </div>
      {repushButton && <RepushModal usedStyleGuide={orderInfo.styleGuide} button />}
      {(userRole === UserRole.ADMIN || userRole === UserRole.QA) && <InsertImageModal />}
    </div>
  );
};
