import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { PageTitle } from '../../../_carswip/layout/core';
import { SalesMenList } from '../admin/views/salesMen/SalesMenLists';

const SalesMenWrapper: FC = () => (
  <>
    <PageTitle breadcrumbs={[]}>
      <FormattedMessage id="MENU.SALESMEN" />
    </PageTitle>
    <SalesMenList />
  </>
);

export { SalesMenWrapper };
