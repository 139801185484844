import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { Reducer } from 'redux';

export const GET_CURRENT_USER_FROM_PAGE = 'GET_CURRENT_USER_FROM_PAGE';

const initialState = null;

export const currentUserReducer: Reducer<IUser | null, PayloadAction<IUser, string>> = (
  state = initialState,
  action,
): IUser | null => {
  switch (action.type) {
    case GET_CURRENT_USER_FROM_PAGE: {
      return action.payload;
    }

    default:
      return state;
  }
};
