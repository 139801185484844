import { Dispatch, SetStateAction } from 'react';
import { ImageType } from 'react-images-uploading';
import { Observable } from 'rxjs';

import { resizeImage } from '../../ui/helpers/resizeImage';
import { getImageUrl, postApprovalForImageId, sendImageToPipeline } from '../api';

export type TImageDataBase64 = {
  file: {
    size: number;
  };
  width: number;
  height: number;
  data_url: string;
};

type IRequestDataToS3 = {
  fields: string;
  url: string;
  imageId: string;
};

async function urlToFile(url: string): Promise<Blob> {
  const response = await fetch(url);
  const blob = await response.blob();

  return blob;
}

/**
 *
 * @param requestData request data from {@link uploadImageToS3SignedUrl}
 * @param binary contains the image in Binary or Base64
 * @param isImageSendToPipeline if image should be send to the pipeline after is uploaded
 * @returns function to send image to pipeline {@link sendImageToPipeline}
 */
async function sendImageToS3(requestData: IRequestDataToS3, binary: Blob, isImageSendToPipeline = false): Promise<any> {
  const formData = new FormData();
  const { url, fields, imageId } = requestData;
  const parsedFields = JSON.parse(fields);

  Object.entries<string>(parsedFields).forEach(([key, value]) => {
    formData.append(key, value);
  });

  formData.append('file', binary); // Adjust the filename as needed

  try {
    const fileUploadResponse = await fetch(url, {
      method: 'POST',
      mode: 'no-cors',
      body: formData,
    });

    if (isImageSendToPipeline) {
      return await sendImageToPipeline(imageId);
    }

    return fileUploadResponse;
  } catch (err) {
    console.error('Error uploading image:', err);
    throw err;
  }
}

export const updateImageBySignedUrl = async (id: string, imageData: ImageType) => {
  const imageInfo = {
    fileSize: imageData?.file?.size,
    height: imageData.height,
    width: imageData.width,
  };
  const response = await postApprovalForImageId(id, imageInfo);
  const requestInformation = await response.json();
  const image = await urlToFile(imageData.data_url);
  return sendImageToS3(requestInformation, image, false);
};

export const uploadImageToS3SignedUrl = async (imageData: ImageType) => {
  const imageDataResponse = await getImageUrl(imageData);
  const requestData = await imageDataResponse.json();

  return sendImageToS3(requestData, imageData.binary, true);
};

/**
 * Upload multiple images to an order, delaying the image 500 millisecond between each upload
 * @param {TImageDataBase64[]} imageFiles array with blob and image information
 * @param orderId order id to attach the images
 */
const uploadImageToOrder = async (image: ImageType, orderId: string, index: number) => {
  const resizedImage = await resizeImage(image);
  const position = index + 1;
  const imageData = {
    order: orderId,
    position: position.toString(),
    fileSize: resizedImage?.file?.size,
    height: resizedImage.height,
    width: resizedImage.width,
    binary: await urlToFile(resizedImage.data_url),
  };

  return uploadImageToS3SignedUrl(imageData);
};
/**
 * @param image image to upload, file with dimensions and base64
 * @param orderId  order id to attach the images
 * @param index image index in the array, used to know the position of the image
 * @returns observable with the index and success status
 */
export const uploadImageToOrderObservable = (
  image: ImageType,
  orderId: string,
  index: number,
): Observable<{ index: number; success: boolean }> =>
  new Observable(observer => {
    uploadImageToOrder(image, orderId, index)
      .then(() => {
        observer.next({ index, success: true });
        observer.complete();
      })
      .catch(error => {
        observer.error(error);
      });
  });
