import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { Button, Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

import { getEventsByOrderId, getEventsByUserId } from '../../../setup/api';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { addNewFilters } from '../../../setup/redux/actions';

import { EventItem, TEventType } from './EvenItem';

import './EventDisplay.css';

interface IProps {
  order?: boolean;
  user?: boolean;
}
const entityName = 'events';
// TODO: finish proper pagination and showhidden feature on the events
/**
 *
 *  Event component that shows all the events related to a specific entity  {@link IOrder} | {@link IUser}
 *  component load data by taking extracting  ID from the URL param
 *
 * @param { boolean } props.order  if true will show order events
 * @param { boolean } props.user if true will show user events
 *
 * @returns scrollable component that load events of a given entity by different chunks of data
 *
 */
const EventDisplay: FC<IProps> = ({ order, user }) => {
  const [totalEvents, setTotalEvents] = useState(0);
  const [open, setOpen] = useState(false);
  const [events, setEvents] = useState<Array<TEventType>>([]);
  const [lastPage, setLastPage] = useState(2);
  const { request } = useFetch();
  const { id } = useParams<TParams>();
  const [showHiddenEvents, setShowHiddenEvents] = useState(false);
  const pageFromState = useSelector((state: any) => state?.filters?.events?.page) || 1;
  const dispatch = useDispatch();

  const loadNextPage = () => {
    if (pageFromState <= lastPage) {
      dispatch(addNewFilters({ page: pageFromState + 1 }, entityName));
    }
  };

  const fetchOrderEvents = (cleanEventPayloadBeforeLoad?: boolean) => {
    if (cleanEventPayloadBeforeLoad) {
      setEvents([]);
    }
    request(getEventsByOrderId, id, pageFromState, showHiddenEvents)
      .then((newEventChunk: [TEventType[], number]) => {
        if (newEventChunk[1] > 0) {
          if (cleanEventPayloadBeforeLoad) {
            setEvents([...newEventChunk[0]]);
          } else {
            setEvents([...events, ...newEventChunk[0]]);
          }
          setLastPage(newEventChunk[1] / 10);
          setTotalEvents(newEventChunk[1]);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const fetchUserEvents = (cleanEventPayloadBeforeLoad?: boolean) => {
    request(getEventsByUserId, id, pageFromState, showHiddenEvents).then((newEventChunk: [TEventType[], number]) => {
      if (newEventChunk[0]) {
        if (cleanEventPayloadBeforeLoad) {
          setEvents([...newEventChunk[0]]);
        } else {
          setEvents([...events, ...newEventChunk[0]]);
        }
        const totalPages = newEventChunk[1] / 10;

        setLastPage(totalPages + 1);
      }
    });
  };

  useEffect(() => {
    const firstPage = 1;

    dispatch(addNewFilters({ page: firstPage as unknown as { [key: string]: string | number | null } }, entityName));
    if (order) {
      fetchOrderEvents(true);
    } else if (user) {
      fetchUserEvents(true);
    }
  }, [showHiddenEvents]);

  useEffect(() => {
    if (order) {
      fetchOrderEvents();
    } else if (user) {
      fetchUserEvents();
    }
  }, [pageFromState]);

  return (
    <div className="bg-white">
      <div className="card shadow-none rounded-0">
        <div className="card-header align-items-center">
          <div className="col-5 card-title fw-bolder ">
            <h3 className="">
              <FormattedMessage id="ORDER.EVENT.DISPLAY.TITLE" />
            </h3>
            <Button
              style={{ margin: '1em 1em 1em 1em' }}
              color="primary"
              variant="contained"
              onClick={() => {
                setOpen(!open);
              }}
            >
              <FormattedMessage id="ORDER.EVENT.DISPLAY.OPEN" />
            </Button>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showHiddenEvents}
                  onChange={() => {
                    setShowHiddenEvents(!showHiddenEvents);
                  }}
                  name="showHidden"
                />
              }
              label={<FormattedMessage id="ORDER.EVENT.DISPLAY.SHOW_HIDDEN" />}
            />
          </div>
        </div>

        {open && (
          <div className="card-body position-relative" id="kt_activities_body">
            <div className="position-relative scroll-y me-n5 pe-5">
              <span style={{ paddingRight: '5px' }}>
                <FormattedMessage id="ORDER.EVENT.DISPLAY.TOTAL" values={{ count: totalEvents }} />
              </span>
              <div className="timeline">
                {events &&
                  events.map((event: TEventType, index: number) => (
                    <EventItem key={index} event={event} type={order ? 'order' : 'user'} sequence={index + 1} />
                  ))}
              </div>
              <Button
                variant="contained"
                disabled={pageFromState === Math.ceil(lastPage)}
                style={{ marginTop: '10px' }}
                onClick={loadNextPage}
              >
                <FormattedMessage id="ORDER.EVENT.DISPLAY.NEXT" />
                <KeyboardDoubleArrowDownIcon />
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { EventDisplay };
