import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Chip, List } from '@mui/material';

import { RootState } from '../../../setup';
import { deleteTagFromEntity, getTagsFromEntity } from '../../../setup/api';
import { useFetch } from '../../../setup/hooks/fetch.hook';

interface Props {
  entityId: string;
  entityType: 'order' | 'user' | 'image';
}

const pickTextColorBasedOnBgColorAdvanced = (bgColor: string) => {
  const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
  // HexToR
  const r = parseInt(color.substring(0, 2), 16);
  // HexToG
  const g = parseInt(color.substring(2, 4), 16);
  // HexToB
  const b = parseInt(color.substring(4, 6), 16);
  const uiColors = [r / 255, g / 255, b / 255];
  const c = uiColors.map(col => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return ((col + 0.055) / 1.055) ** 2.4;
  });
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];

  return L > 0.179 ? '#212121' : '#FFF';
};

/**
 *  Show tags from  a specific entity and allow to remove them from the entity
 *
 * @param { string  } props.entityId ID of the entity
 * @param { 'order' | 'user' | 'image' } props.entityType type of the entity order | user | image
 */
export const TagDisplay: React.FC<Props> = ({ entityId, entityType }) => {
  const { request } = useFetch();
  const reloadTags = useSelector((state: RootState) => state.reloadTags);
  const [tags, setTags] = useState<Array<ITag>>([]);
  const fetchTags = (entity: string, id: string) => {
    request(getTagsFromEntity, entity, id).then(data => {
      setTags(data);
    });
  };
  const deleteTag = (tagId: string, entity: string, entityId: string) => {
    const payload = {
      tagId,
      // The entity needs to be send with an S at the end since the database can only get the entity in plural, so we need to add an S
      entity: `${entity}s`,
      entityId,
    };

    request(deleteTagFromEntity, payload).then(() => {
      fetchTags(entityType, entityId);
    });
  };

  useEffect(() => {
    fetchTags(entityType, entityId);
  }, [reloadTags]);

  return (
    <List style={{ padding: 0 }} sx={{ padding: 0 }}>
      {tags &&
        tags.map((tag: ITag) => (
          <Chip
            style={{
              width: '100%',
              marginRight: '1em',
              padding: '0px 5px 0px 5px',
              borderRadius: '5px 5px 5px 5px',
              margin: '2px 5px 0px 5px',
              fontSize: '0.9em',
              height: '20px',
              fontWeight: 'bold',
              color: 'white',
              backgroundColor: tag.color,
              display: 'flex',
              justifyContent: 'space-between',
            }}
            onDelete={() => {
              deleteTag(tag.id, entityType, entityId);
            }}
            deleteIcon={
              <div style={{ color: pickTextColorBasedOnBgColorAdvanced(tag.color) }}>
                <HighlightOffIcon color="inherit" />
              </div>
            }
            label={tag.name}
            variant="filled"
          />
        ))}
    </List>
  );
};
