import { SyntheticEvent } from 'react';

import loadingSpinnerCircle from '../../_carswip/assets/svg/spinner-primary.svg';

/**
 * When an image fails to load, it can be detected on the OnError event,
 * this function takes the event target from an {@link IImage} or {@link IUtility}
 * and tries to load first the thumbnail url, if not available the original URL and lastly a loading spinner
 * by default the preferred image is the thumbnail but there are situation when this oen is not available,
 * FX: thumbnail generation errors, manual deletion of an image, optimized image is not generated by the pipeline, etc
 * @param {EventTarget & HTMLImageElement} currentTarget OnError current target from HTML <img/> tag, which will contain the source and className
 * @param {IImage | IUtility} imageData entity that is loading, usually this function only apply for {@link IImage} or {@link IUtility}
 */
export const loadOriginalUrlIfThumbnailFails = (
  currentTarget: EventTarget & HTMLImageElement,
  imageData: IImage | IUtility,
) => {
  const { url, thumbnailUrl } = imageData;
  const loadingSpinnerClassName = ' loadingImage spinner-border';

  if (currentTarget.src === url) {
    currentTarget.src = loadingSpinnerCircle;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    currentTarget.className = loadingSpinnerClassName;
  } else if (currentTarget.src === thumbnailUrl) {
    currentTarget.src = url;
  }
};

/**
 *  This function attach media to a image element, and returns a spinner loader if the images is not there yet
 * @param values.currentTarget image target information
 * @param index index from the carousel component
 * @param order order information used to load the correct image on the QA Carousel
 */
export const handleImageOnLoadError = (
  { currentTarget }: SyntheticEvent<HTMLImageElement>,
  index: number,
  order: IOrder,
) => {
  currentTarget.onerror = null;
  const currentImage = order?.images[index];

  loadOriginalUrlIfThumbnailFails(currentTarget, currentImage);
};
