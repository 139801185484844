import React, { useEffect, useState } from 'react';
import { GithubPicker } from 'react-color';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FormControl from '@mui/material/FormControl/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { createTags, getTagById, updateTag } from '../../../setup/api';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { reloadTable } from '../../../setup/redux/actions';
import { SelectFieldFromObjects } from '../../../ui/components/FieldSelect/FieldSelect';
import { MainGridContainer } from '../../../ui/components/MainGridContainer/MainGridContainer';
import { ModalCarswip } from '../../../ui/components/Modal';

/*
 * Load images, then rester url with the close and do it with salesmen
 * add put functions
 */
type IProps = {
  button?: boolean;
};

const createTagSchema = (intl: IntlShape) =>
  Yup.object({
    name: Yup.string()
      .required(
        intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED' }, { name: intl.formatMessage({ id: 'TAG.NAME' }) }),
      )
      .label('name'),
    category: Yup.string()
      .required(
        intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED' }, { name: intl.formatMessage({ id: 'TAG.CATEGORY' }) }),
      )
      .label('category'),
    entity: Yup.string()
      .required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED' }, { name: intl.formatMessage({ id: 'ENTITY' }) }))
      .label('entity'),
    color: Yup.string()
      .required(
        intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED' }, { name: intl.formatMessage({ id: 'TAG.COLOR' }) }),
      )
      .label('color'),
  });

type TTagPayload = {
  name: string | null;
  category: string | null;
  entity: string | null;
  color?: string;
};
const inits: TTagPayload = {
  name: '',
  category: null,
  entity: null,
};
const CreateTagModal: React.FC<IProps> = ({ button }) => {
  const intl = useIntl();
  const { id } = useParams<TParams>();
  const { request } = useFetch();
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const [initValues, setInitialData] = useState(inits);
  const [categories, setCategories] = useState<Array<ITag> | null>(null);
  const [colorPicker, setColorPicker] = useState<boolean>(false);
  const dispatch = useDispatch();

  const modalToggle = () => {
    setIsOpen(!isOpen);
    history.push('/admin/tags');
  };

  const changeColor = (color: { hex: string }) => {
    setInitialData({ ...initValues, color: color.hex });
  };
  const handleCreateTag = (values: TTagPayload) => {
    request(createTags, values).then(() => {
      dispatch(reloadTable({ reload: true }, 'tags'));
      modalToggle();
    });
  };
  const handleUpdateTag = (values: TTagPayload) => {
    request(updateTag, values, id).then(() => {
      dispatch(reloadTable({ reload: true }, 'tags'));
      modalToggle();
    });
  };
  const [categoryFieldText, setCategoryFieldText] = useState<boolean>(false);
  const entities = ['user', 'order', 'image'];

  useEffect(() => {
    if (id) {
      request(getTagById, id).then((res: ITag) => {
        setInitialData(res);
        setIsOpen(true);
      });
    }
  }, [id]);

  return (
    <>
      {button && (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            setInitialData(inits);
            history.push('/admin/tags');
            setIsOpen(true);
          }}
        >
          <FormattedMessage id="TAG.ADD" />
        </button>
      )}

      <ModalCarswip open={isOpen} onClose={modalToggle} title={intl.formatMessage({ id: 'TAG' })}>
        <MainGridContainer>
          <Formik
            validationSchema={createTagSchema(intl)}
            initialValues={initValues}
            enableReinitialize
            onSubmit={values => {
              if (id) {
                handleUpdateTag(values);
              } else if (values) {
                handleCreateTag(values);
              }
            }}
          >
            {(props: FormikProps<TTagPayload>) => (
              <Form onSubmit={props.handleSubmit} className="form" noValidate>
                <div className="w-100">
                  <div className="row mb-10 ">
                    <div>
                      <label className="d-flex align-items-center fw-bold mb-2">
                        <span className="">
                          <FormattedMessage id="TAG.NAME" />:
                        </span>
                      </label>
                      <div>
                        <Field
                          type="text"
                          className={`form-control form-control-lg userInputFields ${
                            props.errors.name ? 'is-invalid' : 'form-control-solid'
                          }`}
                          name="name"
                          placeholder={intl.formatMessage({ id: 'TAG.NAME' })}
                          onChange={(event: React.FormEvent<HTMLInputElement>) => {
                            setInitialData({
                              ...initValues,
                              name: event.currentTarget.value.trim(),
                            });
                          }}
                        />
                        <div style={{ color: 'red', marginTop: '-10px' }}>
                          <ErrorMessage name="name" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-10">
                    <div className="col-6">
                      <div className="d-flex">
                        <label className="d-flex align-items-center fs-5 fw-bold mb-2" style={{ marginRight: '1em' }}>
                          <span>
                            <FormattedMessage id="ENTITY" />
                          </span>
                        </label>
                        <Field name="entity">
                          {({ field }: FieldProps) => (
                            <FormControl fullWidth>
                              <Select
                                id="entity"
                                name="entity"
                                required
                                value={initValues.entity}
                                onBlur={field.onBlur}
                              >
                                {entities.map((entity, index) => (
                                  <MenuItem
                                    key={index}
                                    value={entity}
                                    children={entity}
                                    onClick={() => {
                                      setInitialData({
                                        ...initValues,
                                        entity,
                                      });
                                    }}
                                  />
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        </Field>
                        <div style={{ color: 'red', marginTop: '-10px' }}>
                          <ErrorMessage name="entity" />
                        </div>
                      </div>
                    </div>
                    {categories && !categoryFieldText ? (
                      <div className="col-6">
                        <div className="d-flex">
                          <label className="d-flex align-items-center fs-5 fw-bold mb-2" style={{ marginRight: '1em' }}>
                            <span className="">
                              <FormattedMessage id="TAG.CATEGORY" />:
                            </span>
                          </label>
                          <SelectFieldFromObjects<ITag> name="category" options={categories} keyName="name" />
                        </div>
                      </div>
                    ) : (
                      <div className="col-6">
                        <div className="d-flex">
                          <label className="d-flex align-items-center fs-5 fw-bold mb-2" style={{ marginRight: '1em' }}>
                            <span className="">
                              <FormattedMessage id="TAG.CATEGORY" />:
                            </span>
                          </label>

                          <div>
                            <Field
                              type="text"
                              className="form-control form-control-lg userInputFields form-control-solid"
                              name="category"
                              placeholder={intl.formatMessage({ id: 'TAG.CATEGORY' })}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setInitialData({
                                  ...initValues,
                                  category: e.target.value,
                                });
                              }}
                              onDoubleClick={() => {
                                setCategoryFieldText(!categoryFieldText);
                              }}
                            />
                            <div style={{ color: 'red', marginTop: '-10px' }}>
                              <ErrorMessage name="category" />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className=" row mb-10">
                    <div className="d-flex col-6">
                      <div className="d-block">
                        <div className="d-flex">
                          <label className="d-flex align-items-center fs-5 fw-bold mb-2" style={{ marginRight: '1em' }}>
                            <span className="">
                              <FormattedMessage id="TAG.COLOR" />:
                            </span>
                          </label>

                          <div>
                            <Field
                              type="text"
                              className={`form-control form-control-lg userInputFields ${
                                props.touched.color && props.errors.color ? 'is-invalid' : 'form-control-solid'
                              }`}
                              name="color"
                              placeholder="#FFF"
                              onClick={() => {
                                setColorPicker(true);
                              }}
                            />
                            <div style={{ color: 'red', marginTop: '-10px' }}>
                              <ErrorMessage name="color" />
                            </div>
                          </div>
                        </div>
                        <div style={{ marginLeft: '5em' }}>
                          {colorPicker && <GithubPicker color={initValues.color} onChangeComplete={changeColor} />}
                        </div>
                      </div>
                    </div>
                    <div className="col-6" />
                  </div>
                </div>

                <div className="d-flex justify-content-end flex-stack pt-10">
                  <button type="submit" className="btn btn-lg btn-primary d-flex">
                    <span className="indicator-label">
                      <FormattedMessage id={id ? 'ACTION.UPDATE' : 'ACTION.CREATE'} />
                    </span>
                    <AddCircleIcon />
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </MainGridContainer>
      </ModalCarswip>
    </>
  );
};

export { CreateTagModal };
