import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Grid from '@mui/material/Grid';

import { OrderEvents } from '../../../constants';
import { getImageStatisticsPerMonthTotal, getOrderStatisticsPerMonthTotal } from '../../../setup/api/statistics';
import { LocalStorageKey } from '../../../setup/hooks/useLocalStorage';
import { AppVersionStatistics } from '../statistics/AppVersionStatistics';
import { AveragePicturePerCar } from '../statistics/AveragePicturePerCar';
import { DeviceStatistics } from '../statistics/DeviceStatistics';
import { FlawlessOrdersPieChart } from '../statistics/FlawlessOrdersPieChart';
import { InactiveUserChart } from '../statistics/InactiveUsersChart';
import { OrdersByStatus } from '../statistics/OrdersBySatus';
import { OrderTimelineChart } from '../statistics/OrderTimelineChart';
import { ImageStatisticsByEvents } from '../statistics/RepushedImagesStatistics';
import { StatisticsOrdersEditedByQAMember } from '../statistics/StatisticsOrdersEditedByQAMember';
import { TotalAmountOfEntities } from '../statistics/TotalAmountOfEntities';
import { TotalAmountOfEventsLast30Days } from '../statistics/TotalAmountOfEventsLast30Days';

export const AdminDashboardPage: FC = () => {
  const intl = useIntl();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h1>
          <FormattedMessage id="DASHBOARD.TITLE" />
        </h1>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12} md={6}>
          <TotalAmountOfEntities
            requestFunction={getOrderStatisticsPerMonthTotal}
            entity="order"
            chartColor="#1976D2"
            strokeColor="#2196F3"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TotalAmountOfEntities
            requestFunction={getImageStatisticsPerMonthTotal}
            entity="image"
            chartColor="#ff5f52"
            strokeColor="#9E9E9E"
          />
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12} md={6}>
          <OrdersByStatus />
        </Grid>
        <Grid item xs={12} md={6}>
          <InactiveUserChart />
        </Grid>
      </Grid>
      <Grid item xs={12} mt={5}>
        <h1>
          <FormattedMessage id="DASHBOARD.QA" />
        </h1>
      </Grid>
      <Grid container item spacing={2}>
        <StatisticsOrdersEditedByQAMember />
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <OrderTimelineChart
            className="card-xl-stretch mb-xl-8"
            title={intl.formatMessage({ id: 'DASHBOARD.QA.AVG_TIME_ORDER' })}
          />
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <ImageStatisticsByEvents
            eventName={OrderEvents.IMAGE_DECLINED.eventName}
            title={intl.formatMessage({ id: 'DASHBOARD.QA.EDIT_IMG_MONTH' })}
            localStorageKey={LocalStorageKey.EDITED_IMAGE_RATIO_PER_ORDER_LAST_MONTH}
          />
        </Grid>
        <Grid item xs={12}>
          <ImageStatisticsByEvents
            eventName={OrderEvents.IMAGE_REPUSHED.eventName}
            title={intl.formatMessage({ id: 'DASHBOARD.QA.REPUSH_IMG_MONTH' })}
            localStorageKey={LocalStorageKey.REPUSHED_IMAGE_RATIO_PER_ORDER_LAST_MONTH}
          />
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12} md={6} sx={{ pl: 0 }}>
          <TotalAmountOfEventsLast30Days
            eventName={OrderEvents.QA_UPLOADED.eventName}
            title={intl.formatMessage({ id: 'DASHBOARD.QA.UPLOADED' })}
            localStorageKey={LocalStorageKey.EDITED_IMAGES_30_DAYS_STATISTICS}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ pr: 0 }}>
          <TotalAmountOfEventsLast30Days
            eventName={OrderEvents.IMAGE_REPUSHED.eventName}
            title={intl.formatMessage({ id: 'DASHBOARD.QA.REPUSHED' })}
            localStorageKey={LocalStorageKey.REPUSHED_IMAGES_30_DAYS_STATISTICS}
          />
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12} md={6}>
          <DeviceStatistics />
        </Grid>
        <Grid item xs={12} md={6}>
          <FlawlessOrdersPieChart />
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12} md={6}>
          <AveragePicturePerCar />
        </Grid>
        <Grid item xs={12} md={6}>
          <AppVersionStatistics />
        </Grid>
      </Grid>
    </Grid>
  );
};
