import { FC, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { FormattedMessage, useIntl } from 'react-intl';
import { colors, LinearProgress } from '@mui/material';
import Button from '@mui/material/Button';
import { ApexOptions } from 'apexcharts';
import moment from 'moment';

import { getTotalAmountEventsLast30Days } from '../../../setup/api/statistics';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import {
  ExpiryOptions,
  isLocalDataExpired,
  LocalStorageKey,
  useLocalStorage,
} from '../../../setup/hooks/useLocalStorage';
import { DashboardDataChip } from '../../../ui/components/Chips/DashboardDataChip';
import { DashboardCard, DashboardCardHeader } from '../../../ui/components/MainGridContainer/DashboardGrid';
import { TitleTag } from '../../../ui/components/Title/TitleTag';
import { getPercentageDifference } from '../../../ui/helpers/helpers';

type ChartSeries = {
  name: string;
  data: string[];
};

type ChartOptionsSettings = {
  series: ChartSeries[];
  options: ApexOptions;
};

type StatisticsFromLast30Days = {
  chartSettings: ChartOptionsSettings;
  averageEvents: number;
  expires?: string;
};

type TotalAmountEventsLast30Days = {
  result: TotalEventLast30DaysResult[];
  average: string;
  previousResults: TotalEventLast30DaysResult[];
  previousAverage: string;
  expires?: string;
};

type Props = {
  eventName: string;
  title: string;
  localStorageKey: LocalStorageKey;
};

type TotalEventLast30DaysResult = {
  day: string;
  total_events: string;
};

interface ChartParameters {
  totalEvents: string[];
  days: string[];
}

const convertResponseToValidChartParameters = (statistics: TotalEventLast30DaysResult[]): ChartParameters => {
  const { totalEvents, days } = statistics.reduce<ChartParameters>(
    (acc, element) => {
      acc.totalEvents.push(element.total_events);
      acc.days.push(moment(element.day).format('MM/DD'));
      return acc;
    },
    { totalEvents: [], days: [] },
  );

  return { totalEvents, days };
};

export const TotalAmountOfEventsLast30Days: FC<Props> = ({ eventName, title, localStorageKey }) => {
  const intl = useIntl();
  const { request, abortController, isLoading } = useFetch();
  const [statisticsFromLast30Days, setStatisticsFromLast30Days] = useState<StatisticsFromLast30Days>();
  const [statisticsFromPrevious30Days, setStatisticsFromPrevious30Days] = useState<StatisticsFromLast30Days>();
  const [responseFromRequest, setResponseFromRequest] = useLocalStorage<TotalAmountEventsLast30Days>(
    localStorageKey,
    ExpiryOptions.day,
  );
  const [showPreviousStatistics, setShowPreviousStatistics] = useState(false);
  const [shouldLoadChart, setShouldLoadChart] = useState(false);

  const getChartSettings = (series: string[], categories: string[]): ChartOptionsSettings => ({
    series: [{ name: intl.formatMessage({ id: 'ORDER.VIEW.IMAGES' }), data: series }],
    options: {
      chart: { height: 350 },
      colors: ['#318fb5'],
      plotOptions: { bar: { distributed: true } },
      dataLabels: { enabled: false },
      legend: { show: false },
      stroke: { width: [0, 4] },
      xaxis: {
        categories,
        labels: { style: { fontSize: '12px' } },
      },
    },
  });

  const requestChartData = () => {
    request(getTotalAmountEventsLast30Days, eventName, abortController.signal).then(res => {
      if (res) {
        setResponseFromRequest(res);
        setShouldLoadChart(false);
      }
      return () => {
        abortController.abort();
      };
    });
  };

  const convertRequestIntoApexOptions = (results: TotalEventLast30DaysResult[]) => {
    const item = convertResponseToValidChartParameters(results);

    return getChartSettings(item.totalEvents, item.days);
  };

  useEffect(() => {
    if (responseFromRequest) {
      const last30DaysOptions = convertRequestIntoApexOptions(responseFromRequest.result);
      const previousDaysOptions = convertRequestIntoApexOptions(responseFromRequest.previousResults);

      setStatisticsFromPrevious30Days({
        chartSettings: last30DaysOptions,
        averageEvents: Number(responseFromRequest.previousAverage),
        expires: responseFromRequest.expires,
      });

      setStatisticsFromLast30Days({
        chartSettings: previousDaysOptions,
        averageEvents: Number(responseFromRequest.average),
        expires: responseFromRequest.expires,
      });
    }
  }, [responseFromRequest]);

  const toggleChartProps = (showPreviousStatistics: boolean) => {
    if (!showPreviousStatistics) {
      return {
        options: statisticsFromPrevious30Days?.chartSettings.options,
        series: statisticsFromPrevious30Days?.chartSettings.series,
      };
    }

    return {
      options: statisticsFromLast30Days?.chartSettings.options,
      series: statisticsFromLast30Days?.chartSettings.series,
    };
  };

  useEffect(() => {
    if (shouldLoadChart && isLocalDataExpired(responseFromRequest?.expires)) {
      requestChartData();
    }
  }, [shouldLoadChart]);

  return (
    <DashboardCard>
      <DashboardCardHeader>
        <TitleTag>{title}</TitleTag>
        <Button
          variant="contained"
          onClick={() => setShouldLoadChart(!shouldLoadChart)}
          children={<FormattedMessage id="STATS.LOAD" />}
        />
      </DashboardCardHeader>
      <DashboardCardHeader style={{ paddingTop: '0px' }}>
        {statisticsFromLast30Days && (
          <DashboardDataChip
            label={
              <FormattedMessage
                id="STATS.AVG.IMAGES_DAY"
                values={{ average: statisticsFromLast30Days.averageEvents }}
              />
            }
            sx={{ backgroundColor: colors.blue[500] }}
            onClick={() => setShowPreviousStatistics(false)}
          />
        )}
        {statisticsFromPrevious30Days && statisticsFromLast30Days && (
          <DashboardDataChip
            label={
              <FormattedMessage
                id="STATS.MONTHLY.DIFF"
                values={{
                  diff: `${getPercentageDifference(
                    statisticsFromLast30Days?.averageEvents,
                    statisticsFromPrevious30Days.averageEvents,
                  )}%`,
                }}
              />
            }
            sx={{ backgroundColor: colors.green[500] }}
            onClick={() => setShowPreviousStatistics(true)}
          />
        )}
      </DashboardCardHeader>
      {isLoading && <LinearProgress style={{ margin: '2em' }} />}
      {statisticsFromLast30Days && statisticsFromPrevious30Days && (
        <Chart {...toggleChartProps(showPreviousStatistics)} type="bar" height={350} />
      )}
    </DashboardCard>
  );
};
