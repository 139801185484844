import React from 'react';

import { Content } from './components/Content';
import { Footer } from './components/Footer';
import { HeaderWrapper } from './components/header/HeaderWrapper';
import { PageDataProvider } from './core';
import { MasterInit } from './MasterInit';

export const MasterLayout: React.FC = ({ children }) => (
  <PageDataProvider>
    <div className="page d-flex flex-row flex-column-fluid">
      <div className="wrapper d-flex flex-column flex-row-fluid">
        <HeaderWrapper />
        <div className=" d-flex flex-column flex-column-fluid mb-5">
          <div className="mt-5 d-flex flex-column-fluid">
            <Content>{children}</Content>
          </div>
        </div>
        <Footer />
      </div>
    </div>
    <MasterInit />
  </PageDataProvider>
);
