import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, TextField } from '@mui/material';
import { ErrorMessage, Form, FormikProps } from 'formik';

import { ForgotPasswordProps } from '../../pages/auth/interfaces';

interface VerifyPasswordProps extends FormikProps<ForgotPasswordProps> {
  verificationCode?: boolean;
}

export const VerifyNewPasswordForm: FC<VerifyPasswordProps> = ({
  getFieldProps,
  touched,
  errors,
  verificationCode,
}) => {
  const intl = useIntl();

  return (
    <Form className="form" noValidate>
      <span className="authentication-title mb-5" style={{ marginBottom: '1em' }}>
        <FormattedMessage id="AUTH.REGISTER.VERIFY_PWD" />
      </span>
      {verificationCode && (
        <>
          <TextField
            type="string"
            placeholder={intl.formatMessage({ id: 'AUTH.REGISTER.CODE' })}
            {...getFieldProps('verificationCode')}
            error={touched.verificationCode && Boolean(errors.verificationCode)}
            helperText={touched.verificationCode && errors.verificationCode}
            className="mb-5"
          />
          <div style={{ color: 'red', marginTop: '-10px' }}>
            <ErrorMessage name="verificationCode" />
          </div>
        </>
      )}
      <TextField
        type="password"
        placeholder={intl.formatMessage({ id: 'AUTH.REGISTER.NEW_PWD' })}
        {...getFieldProps('newPassword')}
        error={touched.newPassword && Boolean(errors.newPassword)}
        helperText={touched.newPassword && errors.newPassword}
        className="mb-10"
      />
      <div style={{ color: 'red', marginTop: '-10px' }}>
        <ErrorMessage name="newPassword" />
      </div>
      {verificationCode && (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          children={<FormattedMessage id="AUTH.REGISTER.VERIFY" />}
        />
      )}
    </Form>
  );
};
