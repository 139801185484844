import { createContext, Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { FormattedMessage, useIntl } from 'react-intl';
import { ApexOptions } from 'apexcharts';

import {
  getCarsPerStyleguideOnManagementAccounts,
  QueryTimeFrame,
  QueryTimePeriod,
} from '../../../setup/api/statistics';
import { parseObjectKeyToReadableString } from '../../../setup/helpers/parseObjectKeySyntaxToReadableString';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { StatisticsDropDownOnContext } from '../../../ui/components/Dropdown/StatisticsDropDownOnContext';

type Props = {
  userId: string;
};

type CarsPerStyleGuideStatistics = {
  style_guide_name: string;
  total_orders: string;
};

type ChartOptionsSettings = {
  series: [{ name: string; data: number[] }];
  options: ApexOptions;
};
type TimeQueries = {
  timeFrame: QueryTimeFrame;
  timePeriod: QueryTimePeriod;
};

type ChartContext = {
  timeQueries: TimeQueries;
  setTimeQueries: Dispatch<SetStateAction<TimeQueries>>;
};

const defaultTimeQueries: TimeQueries = {
  timeFrame: QueryTimeFrame.CURRENT,
  timePeriod: QueryTimePeriod.YEAR,
};

export const ChartTimeQueriesContext = createContext<ChartContext>({
  timeQueries: defaultTimeQueries,
  setTimeQueries: () => null,
});

export const CarsPerStyleGuideChart: FC<Props> = ({ userId }) => {
  const intl = useIntl();
  const [apexOptions, setApexOptions] = useState<ChartOptionsSettings>();
  const [timeQueries, setTimeQueries] = useState<TimeQueries>(defaultTimeQueries);
  const { request, abortController } = useFetch();

  const getChartSettings = (series: number[], categories: Array<[string, string]>): ChartOptionsSettings => ({
    series: [{ name: intl.formatMessage({ id: 'STATS.TOTAL_CARS' }), data: series }],
    options: {
      chart: { height: 400, toolbar: { show: false } },
      plotOptions: { bar: { columnWidth: '45%', distributed: true } },
      dataLabels: { enabled: false },
      legend: { show: false },
      xaxis: { categories, labels: { style: { fontSize: '12px' } } },
    },
  });

  const convertResponseToValidChartParameters = (
    statistics: CarsPerStyleGuideStatistics[],
  ): {
    totalOrders: number[];
    styleGuideNames: Array<[string, string]>;
  } => {
    let styleGuideNames: Array<[string, string]> = [];
    const totalOrders: number[] = [];

    statistics.forEach(item => {
      totalOrders.push(parseInt(item.total_orders, 10));
    });

    styleGuideNames = statistics.map(item => [
      item.style_guide_name,
      intl.formatMessage({ id: 'STATS.TOTAL' }, { number: item.total_orders }),
    ]);
    return { totalOrders, styleGuideNames };
  };

  const getOrdersPerDealershipByUser = async () => {
    const requestInformation = {
      query: { timeFrame: timeQueries.timeFrame, timePeriod: timeQueries.timePeriod },
      params: { userId },
    };

    request(getCarsPerStyleguideOnManagementAccounts, requestInformation, abortController.signal).then(res => {
      if (res && res.length > 0) {
        const { totalOrders, styleGuideNames } = convertResponseToValidChartParameters(res);
        const apexChartSettings = getChartSettings(totalOrders, styleGuideNames);

        return setApexOptions(apexChartSettings);
      }
      setApexOptions(undefined);
    });
  };

  useEffect(() => {
    getOrdersPerDealershipByUser();

    return () => abortController.abort();
  }, [timeQueries, userId]);

  return (
    <ChartTimeQueriesContext.Provider value={{ timeQueries, setTimeQueries }}>
      <div className="card mb-5 mb-xl-8">
        <div className="card-p pb-0 d-flex" style={{ justifyContent: 'space-between' }}>
          <div>
            <a className="text-dark text-hover-primary fw-bolder fs-3 me-2">
              <FormattedMessage id="STATS.CARS_STYLEGUIDE" />
            </a>
            <div className="text-muted fs-7 fw-bold">
              {parseObjectKeyToReadableString(timeQueries.timeFrame)}
              &nbsp;
              {parseObjectKeyToReadableString(timeQueries.timePeriod)}
            </div>
          </div>

          <StatisticsDropDownOnContext />
        </div>
        {apexOptions && (
          <ReactApexChart options={apexOptions.options} series={apexOptions.series} type="bar" height={350} />
        )}
      </div>
    </ChartTimeQueriesContext.Provider>
  );
};
