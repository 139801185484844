import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { FormattedMessage } from 'react-intl';

import { getAppVersionStatistics } from '../../../setup/api/statistics';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { DashboardCard, DashboardCardHeader } from '../../../ui/components/MainGridContainer/DashboardGrid';
import { TitleTag } from '../../../ui/components/Title/TitleTag';

type AppVersionStatisticResult = {
  appVersion: string;
  count: number;
};

export const AppVersionStatistics: React.FC = () => {
  const { request, abortController } = useFetch();
  const [statistics, setStatistics] = useState<AppVersionStatisticResult[]>();

  useEffect(() => {
    request(getAppVersionStatistics).then(res => {
      if (res) {
        setStatistics(res);
      }
      return () => {
        abortController.abort();
      };
    });
  }, []);

  if (statistics) {
    const chartOptions = statistics
      .map(x => x.appVersion)
      .sort((a, b) => Number(b.split(' ')[0]) - Number(a.split(' ')[0])); // Reverse sorting
    const options = { labels: chartOptions };
    const series = chartOptions.map(x => Number(statistics.find(y => y.appVersion === x)?.count));

    return (
      <div>
        <DashboardCard>
          <DashboardCardHeader>
            <TitleTag children={<FormattedMessage id="STATS.VERSION" />} />
          </DashboardCardHeader>
          <Chart
            style={{ display: `flex`, justifyContent: `center` }}
            options={options}
            series={series}
            type="pie"
            width="380"
          />
        </DashboardCard>
      </div>
    );
  }

  return null;
};
