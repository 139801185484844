import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ApexCharts, { ApexOptions } from 'apexcharts';

import { getTotalOrderByStatus } from '../../../setup/api/statistics';
import { useFetch } from '../../../setup/hooks/fetch.hook';

const OrdersByStatus: React.FC = () => {
  const intl = useIntl();
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { request, abortController } = useFetch();
  const [series, setSeries] = useState<number[]>();
  const [maxValue, setMaxValue] = useState(10);

  const chartOptions = (orderSeries: number[], maxValue: number, entityName: string): ApexOptions => ({
    series: [
      {
        name: intl.formatMessage({ id: 'STATS.TOTAL.ENTITY' }, { name: entityName }),
        data: orderSeries,
      },
    ],
    chart: { height: 350, type: 'bar', toolbar: { show: false } },
    plotOptions: { bar: { borderRadius: 10, dataLabels: { position: 'top' } } },
    dataLabels: {
      enabled: true,
      formatter: val => `${val} ${entityName}`,
      offsetY: -20,
      style: { fontSize: '12px', colors: ['#1c1b1b'] },
    },
    xaxis: {
      categories: [
        intl.formatMessage({ id: 'STATS.ORDER.STATUS.UPLOADING' }),
        intl.formatMessage({ id: 'STATS.ORDER.STATUS.IN_PROGRESS' }),
        intl.formatMessage({ id: 'STATS.ORDER.STATUS.QA' }),
        intl.formatMessage({ id: 'STATS.ORDER.STATUS.DELIVERING' }),
      ],
      position: 'top',
      axisBorder: { show: false },
      axisTicks: { show: false },
      crosshairs: { fill: { type: 'gradient' } },
      tooltip: { enabled: false },
    },
    yaxis: {
      max: maxValue + maxValue * 0.1,
      axisBorder: { show: false },
      axisTicks: { show: false },
      labels: { show: false, formatter: val => `${val} ${entityName}` },
    },
    fill: {
      colors: undefined,
      opacity: 1,
      type: 'solid',
      gradient: {
        type: 'horizontal',
        shadeIntensity: 0,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 100],
      },
      pattern: {
        style: 'verticalLines',
        width: 6,
        height: 6,
        strokeWidth: 2,
      },
    },
    colors: ['#F44336', '#E91E63', '#9C27B0'],
    markers: { colors: ['#000000'], strokeColors: ['#2196F3'], strokeWidth: 3 },
    title: {
      text: intl.formatMessage({ id: 'STATS.TIME_FRAME' }),
      floating: true,
      offsetX: 0,
      offsetY: 0,
      align: 'center',
      style: { color: 'white' },
    },
  });

  const getOrderStatistics = () => {
    request(getTotalOrderByStatus, abortController.signal)
      .then(res => {
        if (res) {
          let maximum = 0;
          const parsedSeries = res.map((serie: number) => {
            if (serie > maximum) {
              maximum = serie;
            }

            return serie;
          });

          setMaxValue(maximum);
          setSeries(parsedSeries);
        }
      })
      .catch();
  };

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    if (series) {
      const chart = new ApexCharts(
        chartRef.current,
        chartOptions(series, maxValue, intl.formatMessage({ id: 'STATS.ORDER.AXIS_VALUE' })),
      );

      if (chart) {
        chart.render();
      }

      return () => {
        if (chart) {
          chart.destroy();
        }
      };
    }
  }, [series]);

  useEffect(() => {
    getOrderStatistics();
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <div className="card card-xl-stretch mb-xl-8">
      <div className="card-header border-0 py-5 bg-#1976D2" style={{ backgroundColor: '#fff' }}>
        <h3 className="card-title fw-bolder">
          <FormattedMessage id="STATS.ORDERS_STATUS_WEEK" />
        </h3>
      </div>
      {/* begin::Body */}
      <div className="card-body p-0">
        {/* begin::Chart */}
        <div
          ref={chartRef}
          className="mixed-widget-2-chart card-rounded-bottom bg-#1976D2"
          style={{ backgroundColor: '#fff' }}
        />
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { OrdersByStatus };
