import { Select, styled } from '@mui/material';

import { customButtonColors } from '../../helpers';

/**
 * Dropdown styled as MUI button Component
 * Fixed width with pixels to ensure text is fitting, and maxWidth with vw
 */
export const CustomDropdown = styled(Select)({
  width: 200,
  color: 'white',
  backgroundColor: customButtonColors.orange,
  fontWeight: '600',
  fontSize: '1.22rem',
  border: '0',
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  alignItems: 'center',
  textAlign: 'center',
  height: '37px',
  maxWidth: '12vw',
  padding: '0',
  borderRadius: '4px',
  boxShadow: ' 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
});
