import { Dispatch, FC, SetStateAction } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import { styled } from '@mui/styles';

interface Props {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>> | undefined;
  title: string;
  fullWidth?: boolean;
}

const ModalContent = styled(DialogContent)(() => ({
  position: 'relative',
  flex: '1 1 auto',
  padding: '1.75rem',
}));

const ModalHeader = styled(DialogTitle)(() => ({
  display: 'flex',
  flexShrink: 0,
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1.75rem',
  borderBottom: '1px solid #eff2f5',
  borderTopLeftRadius: '0.475rem',
  borderTopRightRadius: '0.475rem',
}));

const ModalTitle = styled('h3')(() => ({
  fontWeight: '600',
  lineHeight: '1.2',
  color: '#181c32',
}));

/**
 * Isolated modal component that can be fulfilled with props.children
 * is generally use to load forms
 * @param props.open current state of the modal
 * @param props.onClose callback that should update the open variable
 * @param props.title title of the modal
 */
export const ModalCarswip: FC<Props> = ({ children, open, onClose, title, fullWidth = true }) => (
  <Dialog
    fullWidth={fullWidth}
    sx={{ height: '100%', overflow: 'hidden' }}
    maxWidth="lg"
    open={open}
    onClose={() => {
      if (onClose) {
        onClose(false);
      }
    }}
  >
    <ModalHeader>
      <ModalTitle>{title}</ModalTitle>
      {onClose && (
        <IconButton aria-label="close" onClick={() => onClose(false)}>
          <CloseIcon />
        </IconButton>
      )}
    </ModalHeader>
    <ModalContent>
      <Grid marginX={5} marginBottom={3} marginTop={3}>
        {children}
      </Grid>
    </ModalContent>
  </Dialog>
);
