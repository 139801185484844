import { FC, Suspense, useEffect } from 'react';
import config from 'react-global-configuration';
import { BrowserRouter } from 'react-router-dom';
import { Amplify } from 'aws-amplify';

import { I18nProvider } from '../_carswip/i18n/i18nProvider';
import { LayoutSplashScreen } from '../_carswip/layout/core';
import { Toaster } from '../ui/components/Toaster';

import AuthInit from './pages/auth/AuthInit';
import { Routes } from './routing/Routes';
import { ScrollTopBlocker } from './routing/ScrollTopBlocker';
import configuration from './config';

import 'react-toastify/dist/ReactToastify.css';

config.set(configuration);
Amplify.configure(configuration.Auth);

type Props = {
  basename: string;
};

const disableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen');

  if (splashScreen) {
    splashScreen.style.setProperty('display', 'none');
  }
};

export const App: FC<Props> = ({ basename }) => {
  useEffect(() => {
    disableSplashScreen();
  }, []);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <ScrollTopBlocker />
        <AuthInit>
          <Routes />
          <Toaster />
        </AuthInit>
      </BrowserRouter>
    </Suspense>
  );
};
