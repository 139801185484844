import React, { FC, useEffect, useRef, useState } from 'react';
import { ChromePicker } from 'react-color';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Button, CircularProgress, MenuItem } from '@mui/material';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Select } from 'formik-mui';
import * as Yup from 'yup';

import { StepperComponent } from '../../../_carswip/assets/ts/components/_StepperComponent';
import { PageTitle } from '../../../_carswip/layout/core/PageData';
import { createStyleGuide, getStyleGuide, modifyStyleGuides } from '../../../setup/api/styleGuide';
import { enumToArray } from '../../../setup/helpers/convertEnumToArray';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { removeStyleguideConfigurationForms } from '../../../setup/redux/actions';
import { MainGridContainer } from '../../../ui/components/MainGridContainer/MainGridContainer';
import { StyleGuideConfigurationLoader } from '../../components/styleGuides/StyleGuideConfigurationLoader';
import { StyleGuideImageLoader } from '../../components/styleGuides/StyleGuideImageLoader';

import { DisplayRoutes } from './utils';

const DEFAULT_ROUTING = 'AI (NO ENHANCER)';

const createStyleGuideSchema = (intl: IntlShape) =>
  Yup.object({
    name: Yup.string()
      .required(
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.REQUIRED' },
          { name: intl.formatMessage({ id: 'STYLE_GUIDE.NAME' }) },
        ),
      )
      .label('name'),
  });

interface IProps {
  styleGuide?: IStyleGuide;
}

type StyleGuideFormValues = {
  name: string;
  outputWidth?: number;
  outputHeight?: number;
  maxImageSize?: number;
  customerNote?: string;
  interiorColor?: string;
  frameFreeRouting?: string;
};

function kbToBytes(kb: number): number {
  return kb * 1000;
}

function bytesToKB(bytes: number): number {
  return bytes / 1000;
}

const hasAnyInitValueChanged = (currentValues: ICreateStyleGuide, inits: StyleGuideFormValues) =>
  currentValues.name !== inits.name ||
  currentValues.outputHeight !== inits.outputHeight ||
  currentValues.outputWidth !== inits.outputWidth ||
  currentValues.maxImageSize !== inits.maxImageSize ||
  currentValues.customerNote !== inits.customerNote ||
  currentValues.interiorColor !== inits.interiorColor ||
  currentValues.frameFreeRouting !== inits.frameFreeRouting;

const validateFormikValues = (values: StyleGuideFormValues) => {
  if ((values.outputHeight as unknown) === '') {
    values.outputHeight = undefined;
  }
  if ((values.outputWidth as unknown) === '') {
    values.outputWidth = undefined;
  }
  if ((values.maxImageSize as unknown) === '') {
    values.maxImageSize = undefined;
  }

  return {
    name: values.name,
    outputHeight: Number(values.outputHeight),
    outputWidth: Number(values.outputWidth),
    maxImageSize: kbToBytes(Number(values.maxImageSize)),
    customerNote: values.customerNote,
    interiorColor: values.interiorColor,
    frameFreeRouting: values.frameFreeRouting,
  };
};

const StyleGuideStepper: FC = () => (
  <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-200px">
    <div className="stepper-nav ps-lg-10">
      <div className="stepper-item current" data-kt-stepper-element="nav">
        <div className="stepper-line w-40px" />
        <div className="stepper-icon w-40px h-40px">
          <span className="stepper-number">1</span>
        </div>
        <div className="stepper-label">
          <h3 className="stepper-title">
            <FormattedMessage id="STYLE_GUIDE" />
          </h3>
          <div className="stepper-desc">
            <FormattedMessage id="STYLE_GUIDE.CREATE" />
          </div>
        </div>
      </div>
      <div className="stepper-item" data-kt-stepper-element="nav">
        <div className="stepper-line w-40px" />
        <div className="stepper-icon w-40px h-40px">
          <span className="stepper-number">2</span>
        </div>
        <div className="stepper-label">
          <h3 className="stepper-title">
            <FormattedMessage id="STYLE_GUIDE.CONFIGURATION" />
          </h3>
          <div className="stepper-desc">
            <FormattedMessage id="STYLE_GUIDE.CONFIGURATION.OPTIONAL" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

/**
 * @param props.styleGuide styleguide payload, if not available it will load an empty form
 * @returns form to create or modify style guides
 */
export const CreateStyleGuidePage: FC<IProps> = ({ styleGuide }) => {
  const intl = useIntl();
  const { request } = useFetch();
  const dispatch = useDispatch();
  const [isStyleGuideId, setIsStyleGuideId] = useState<string | undefined>(undefined);
  const isSavableForm = !useParams<{ styleGuideId: string }>().styleGuideId;
  const [styleGuideInits, setStyleGuideInits] = useState<StyleGuideFormValues>({
    name: '',
    customerNote: undefined,
    outputWidth: undefined,
    outputHeight: undefined,
    maxImageSize: undefined,
    interiorColor: undefined,
    frameFreeRouting: DEFAULT_ROUTING,
  });
  const history = useHistory();
  const [loaderButton, setLoaderButton] = useState(false);
  const [requestConfirmation, setRequestConfirmation] = useState(false);
  const [currentStep, setCurrentStep] = useState<number>();
  const [frameFreeRouting, setFrameFreeRouting] = useState<TypeOptimizationMethod>(DEFAULT_ROUTING);
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const optimizationMethodArray = enumToArray(DisplayRoutes);

  const addStyleGuide = async (values: { name: string }) => {
    request(createStyleGuide, validateFormikValues(values)).then(styleGuideResponse => {
      if (styleGuideResponse?.id) {
        setIsStyleGuideId(styleGuideResponse?.id);
        setRequestConfirmation(true);
        setLoaderButton(true);
      }
    });
  };

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement);
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }

    stepper.current.goPrev();
    setCurrentStep(undefined);
  };

  const submitStep = (values: ICreateStyleGuide) => {
    if (!stepper.current) {
      return console.log('Can not find stepper');
    }
    const currentStep = stepper.current.currentStepIndex;
    const totalSteps = stepper.current.totatStepsNumber;

    setCurrentStep(currentStep);

    if (stepper.current.currentStepIndex === 2) {
      // This will empty the forms and add new ones
      dispatch(removeStyleguideConfigurationForms());
    }
    if (currentStep !== totalSteps) {
      stepper.current.goNext();
    } else if (!styleGuide) {
      return addStyleGuide(values);
    }
  };

  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }
    loadStepper();
  }, [stepperRef, stepper]);

  useEffect(() => {
    if (styleGuide) {
      const inits = {
        ...styleGuide,
        maxImageSize: bytesToKB(styleGuide.maxImageSize as number),
      };

      setFrameFreeRouting((styleGuide.frameFreeRouting ?? DEFAULT_ROUTING) as TypeOptimizationMethod);
      setStyleGuideInits(inits);
    }
  }, [styleGuide]);

  return (
    <div
      ref={stepperRef}
      className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
    >
      <StyleGuideStepper />

      <div className="flex-row-fluid py-lg-5 px-lg-15">
        <Formik
          enableReinitialize
          validationSchema={createStyleGuideSchema(intl)}
          initialValues={styleGuideInits}
          onSubmit={submitStep}
        >
          {({ errors, values, setFieldValue, touched }) => (
            <Form className="form">
              <div className="current" data-kt-stepper-element="content">
                <div className="w-100">
                  <div className="fv-row mb-10">
                    <div className="row align-content-center">
                      <div className="col-10">
                        <FormattedMessage id="STYLE_GUIDE.CREATE.NAME" />
                        <Field
                          type="text"
                          className={`form-control form-control-lg  userInputFields ${
                            touched.name && errors.name ? 'is-invalid' : 'form-control-solid'
                          }`}
                          name="name"
                        />
                        <div style={{ color: 'red', marginTop: '-10px' }}>
                          <ErrorMessage name="name" />
                        </div>
                        <div className="col-10">
                          <FormattedMessage id="STYLE_GUIDE.CREATE.NOTE" />
                          <Field
                            type="text"
                            className="form-control form-control-lg  userInputFields form-control-solid"
                            name="customerNote"
                            placeholder=""
                            maxlength="999"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex mt-5 align-items-center" style={{ justifyContent: 'space-between' }}>
                      <div className="fs-5 fw-bold me-5" style={{ width: '300px', display: 'flex' }}>
                        <FormattedMessage id="STYLE_GUIDE.CREATE.WIDTH" />
                        <Field
                          type="number"
                          className="form-control form-control-solid"
                          name="outputWidth"
                          placeholder=""
                        />
                      </div>
                      <div className="fs-5 fw-bold me-5" style={{ width: '300px', display: 'flex' }}>
                        <FormattedMessage id="STYLE_GUIDE.CREATE.HEIGHT" />
                        <Field
                          type="number"
                          className="form-control form-control-solid"
                          name="outputHeight"
                          placeholder=""
                        />
                      </div>
                      <div className="fs-5 fw-bold me-5" style={{ width: '300px', display: 'flex' }}>
                        <FormattedMessage id="STYLE_GUIDE.CREATE.MAX_SIZE" />
                        <Field
                          type="number"
                          className="form-control form-control-solid"
                          name="maxImageSize"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="d-flex mt-5 align-items-center" style={{ justifyContent: 'space-between' }}>
                      <div className="fs-5 fw-bold me-5" style={{ width: '350px', display: 'flex' }}>
                        <FormattedMessage id="STYLE_GUIDE.CREATE.FRAME_FREE" />
                        <Field
                          sx={{
                            '& label.Mui-focused': {
                              display: 'none',
                              height: '40px',
                            },
                            '& legend': {
                              display: 'none',
                            },
                          }}
                          style={{ width: '20em' }}
                          name="frameFreeRouting"
                          value={frameFreeRouting}
                          component={Select}
                        >
                          {optimizationMethodArray.map((optimizationMethod: { value: string }) => {
                            const method = optimizationMethod.value;

                            return (
                              <MenuItem
                                key={method}
                                value={method}
                                onClick={() => {
                                  setFrameFreeRouting(method as TypeOptimizationMethod);
                                }}
                              >
                                {method}
                              </MenuItem>
                            );
                          })}
                        </Field>
                      </div>
                    </div>
                    <div className="d-flex mt-5 align-items-center">
                      <div
                        className="fs-5 fw-bold me-5"
                        style={{ width: '300px', justifyContent: 'space-between', display: 'flex' }}
                      >
                        <FormattedMessage id="STYLE_GUIDE.CREATE.INTERIOR" />
                        <ChromePicker
                          styles={{ default: { picker: { width: '250px' } } }}
                          color={values.interiorColor ?? '#fff'}
                          onChange={color => setFieldValue('interiorColor', color.hex)}
                        />
                      </div>
                    </div>

                    <StyleGuideImageLoader
                      styleGuideId={isStyleGuideId}
                      sendImages={requestConfirmation}
                      // Forcing the type since the key of the object can vary (background | logo | floor), but it must contain a url
                      loadUtilitiesFromStyleGuide={styleGuide as unknown as { [key: string]: { url: string } }}
                    />
                  </div>
                </div>
              </div>

              <div data-kt-stepper-element="content">
                <div className="w-100">
                  <label className="fs-5 fw-bold mb-2">
                    <FormattedMessage id="STYLE_GUIDE.CREATE.CONFIG" />
                  </label>
                  <div className="fv-row mb-10 d-block">
                    <div className="d-flex align-items-center align-content-center styleGuideMarginForm">
                      <StyleGuideConfigurationLoader
                        configurations={styleGuide?.configurations || []}
                        styleGuideId={isStyleGuideId}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex pt-10" style={{ justifyContent: 'end' }}>
                <div className="me-2">
                  <button
                    onClick={prevStep}
                    type="button"
                    className="btn btn-lg btn-light-primary me-3 align-items-center"
                    data-kt-stepper-action="previous"
                  >
                    <ArrowLeftIcon />
                    <span className="indicator-label">
                      <FormattedMessage id="AUTH.GENERAL.BACK_BUTTON" />
                    </span>
                  </button>
                </div>
                {currentStep === undefined && (
                  <Button
                    color="primary"
                    disabled={isSavableForm || !hasAnyInitValueChanged(values, styleGuideInits)}
                    type="button"
                    style={{ fontSize: '1.125rem', marginRight: '1rem' }}
                    onClick={() => {
                      if (styleGuide?.id) {
                        request(modifyStyleGuides, styleGuide.id, validateFormikValues(values)).then(x =>
                          setStyleGuideInits(x),
                        );
                      }
                    }}
                    variant="contained"
                  >
                    <FormattedMessage id="ACTION.UPDATE" />
                  </Button>
                )}
                <div>
                  {isSavableForm && !loaderButton && (
                    <button type="submit" className="btn btn-lg btn-primary me-3 d-flex align-items-center">
                      <span className="indicator-label">
                        {!currentStep && <FormattedMessage id="ACTION.CONTINUE" />}
                        {currentStep === 1 && <FormattedMessage id="ACTION.SAVE" />}
                      </span>
                      <ArrowRightIcon />
                    </button>
                  )}
                  {loaderButton && (
                    <button type="submit" className="btn btn-lg btn-primary me-3 d-flex align-items-center">
                      <CircularProgress size={24} />
                    </button>
                  )}
                  {!isSavableForm && !currentStep && (
                    <button type="submit" className="btn btn-lg btn-primary me-3 d-flex align-items-center">
                      <span className="indicator-label">
                        <FormattedMessage id="STYLE_GUIDE.CREATE.CONFIG.ADD" />
                      </span>
                      <ArrowRightIcon />
                    </button>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export const CreateStyleGuidePageWrapper2: React.FC = () => {
  const { styleGuideId } = useParams<{ styleGuideId: string }>();
  const { request } = useFetch();
  const [styleGuideData, setStyleGuideData] = useState<IStyleGuide>();

  useEffect(() => {
    if (styleGuideId) {
      request(getStyleGuide, styleGuideId).then(styleGuideResponse => {
        setStyleGuideData(styleGuideResponse);
      });
    }
  }, []);

  return (
    <>
      <PageTitle breadcrumbs={[]}>{}</PageTitle>
      <MainGridContainer>
        {!styleGuideId && <CreateStyleGuidePage />}
        {styleGuideId && styleGuideData && <CreateStyleGuidePage styleGuide={styleGuideData} />}
      </MainGridContainer>
    </>
  );
};
