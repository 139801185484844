import { ActionWithPayload } from '../auth/AuthRedux';

type Image = {
  order: string;
  id: string;
};

interface Configurations {
  images: Image[];
  id: string;
}

const initialState: Configurations = {
  images: [],
  id: '',
};

export const ADD_IMAGES = 'ADD_IMAGES';
export const ADD_IMAGE = 'ADD_IMAGE';
export const REMOVE_IMAGES = 'REMOVE_IMAGES';
export const REMOVE_ALL_IMAGES = 'REMOVE_ALL_IMAGES';
export const ADD_ARRAY_OF_IMAGES = 'ADD_ARRAY_OF_IMAGES';

export const selectedImagesReducer = (state: any = initialState, action: ActionWithPayload<Configurations>) => {
  switch (action.type) {
    case ADD_IMAGE: {
      const image = action.payload;

      return {
        ...state,
        images: [image],
      };
    }

    case ADD_IMAGES: {
      const image = action.payload;

      return {
        ...state,
        images: [...state.images, image],
      };
    }

    // Add remove images also to redux actions so they get deleted qhen diselected
    case REMOVE_IMAGES: {
      return {
        ...state,
        images: state.images.filter((image: any) => image !== action.payload),
      };
    }
    // Adds a new array of images

    case ADD_ARRAY_OF_IMAGES: {
      return {
        ...state,
        images: action.payload,
      };
    }

    case REMOVE_ALL_IMAGES: {
      return {
        ...state,
        images: [],
      };
    }

    default:
      return state;
  }
};
