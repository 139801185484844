import config from 'react-global-configuration';

export const getEventsByOrderId = (orderId: string, page: number, showHidden = false): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/events/order/${orderId}?page=${page}&isHidden=${showHidden}`, {
    mode: 'cors',
    credentials: 'include',
  });

export const getEventsByUserId = (userId: string, page: number, showHidden = false): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/events/user/${userId}?page=${page}&isHidden=${showHidden}`, {
    mode: 'cors',
    credentials: 'include',
  });
