import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import { Button } from '@mui/material';

import { RootState } from '../../../../../setup';
import { activateSalesMan, deleteSalesMan, getAllSalesMenByUserId } from '../../../../../setup/api';
import { useFetch } from '../../../../../setup/hooks/fetch.hook';
import { Datagrid } from '../../../../../ui/components/Datagrid/Datagrid';
import { User } from '../../../../../ui/helpers';
import { ModalType, SalesManModal } from '../../../../components/salesMan/SalesManModal';

const SalesMenList: FC = () => {
  const intl = useIntl();
  const { request, isLoading, abortController } = useFetch();
  const [salesManList, setSalesManList] = useState([]);
  const user = User();
  const reloadValue = useSelector((state: RootState) => state.reloadTableModal);

  const salesMenHeadings: [string, string][] = [
    ['name', intl.formatMessage({ id: 'SALESMAN.NAME' })],
    ['email', intl.formatMessage({ id: 'SALESMAN.EMAIL' })],
    ['phone', intl.formatMessage({ id: 'SALESMAN.PHONE' })],
    ['cognitoSub', intl.formatMessage({ id: 'ACCOUNT.LOGIN' })],
    ['activation', intl.formatMessage({ id: 'ACCOUNT.ACTIVATION' })],
  ];

  const fetchSalesMen = (userId: string) => {
    request(getAllSalesMenByUserId, userId, abortController.signal)
      .then(salesManResponse => {
        setSalesManList(salesManResponse);
      })
      .catch();
  };

  const handleSalesManDeletion = (salesmanId: string) => {
    deleteSalesMan(salesmanId).then(() => {
      request(getAllSalesMenByUserId, user.id).then(salesMen => {
        setSalesManList(salesMen);
      });
    });
  };

  useEffect(() => {
    fetchSalesMen(user.id);
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (reloadValue?.salesmen?.reload) {
      fetchSalesMen(user.id);
    }
  }, [reloadValue]);

  const renderRowCell = (key: string, item: { [key: string]: string }) => {
    const cognito = 'cognitoSub';

    switch (key) {
      case cognito:
        return item[key] ? (
          <td style={{ padding: '0 0 0 25px' }}>
            <CheckCircleTwoToneIcon style={{ color: 'green' }} />
          </td>
        ) : (
          <td style={{ padding: '0 0 0 25px' }}>
            <CancelTwoToneIcon style={{ color: 'red' }} />
          </td>
        );

      case 'activation':
        return item[cognito] ? (
          <td>
            <FormattedMessage id="DATA.EMPTY" />
          </td>
        ) : (
          <td>
            <Button
              className="action-button"
              variant="contained"
              color="success"
              onClick={e => {
                e.stopPropagation();
                request(activateSalesMan, item.id).then(() => fetchSalesMen(user.id));
              }}
              style={{ cursor: 'default' }}
            >
              <FormattedMessage id="ACTION.ACTIVATE" />
            </Button>
          </td>
        );

      default:
        return <td key={key}>{item[key]}</td>;
    }
  };

  return (
    <div className="content d-flex flex-column flex-column-fluid">
      <div className="card">
        <div className="card-header border-0 pt-6">
          <div className="card-title">
            <div className="d-flex align-items-center position-relative my-1" />
          </div>
          <div className="card-toolbar">
            <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
              <SalesManModal button type={ModalType.CREATE} />
            </div>
          </div>
        </div>
        <div className="card-body pt-10">
          <Datagrid
            loading={isLoading}
            data={salesManList}
            handleDelete={handleSalesManDeletion}
            link="Salesman"
            headings={salesMenHeadings}
            renderRow={(headings: Array<string[]>, item: { [key: string]: string }) =>
              headings.map(([key]) => renderRowCell(key, item))
            }
            deletable
            createdAt
            modalURL="salesmen"
          />
        </div>
      </div>
    </div>
  );
};

export { SalesMenList };
