import { FC, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, colors, LinearProgress } from '@mui/material';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers';
import { ApexOptions } from 'apexcharts';

import { OrderEvents } from '../../../constants';
import { getEditedImagesStatistics, getRepushedImagesStatistics } from '../../../setup/api/statistics';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import {
  ExpiryOptions,
  isLocalDataExpired,
  LocalStorageKey,
  useLocalStorage,
} from '../../../setup/hooks/useLocalStorage';
import { usePrevious } from '../../../setup/hooks/usePrevious';
import { DashboardDataChip } from '../../../ui/components/Chips/DashboardDataChip';
import { DashboardCard, DashboardCardHeader } from '../../../ui/components/MainGridContainer/DashboardGrid';
import { TitleTag } from '../../../ui/components/Title/TitleTag';
import { formatDateForSQL, getPercentageDifference, getPreviousMonth } from '../../../ui/helpers/helpers';

type DataSets = { name: string; data: string[] };

type ChartOptionsSettings = {
  series: DataSets[];
  options: ApexOptions;
};
type Statistics = {
  chartSettings: ChartOptionsSettings;
  percentAverage: number;
  expires?: string;
  total_orders: number;
};
// January 1, 2022
const minDate = new Date(2023, 0, 1);
// Today's date
const now = new Date();
// Default Date, last month
const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);

type Props = {
  title: string;
  localStorageKey: LocalStorageKey;
  eventName: string;
};

type ChartDashType = {
  localStorageKey: string;
  timeStamp: string;
  previousMonthTimeStamp: string;
  shouldLoadChart: boolean;
  eventName: string;
  setShouldLoadChart: (value: boolean) => void;
};

function removeDateFromLocalStorageKey(str: string): string {
  if (str.length <= 10) {
    return '';
  }
  return str.substring(0, str.length - 10);
}

const ChartDash: FC<ChartDashType> = ({
  localStorageKey,
  timeStamp,
  previousMonthTimeStamp,
  shouldLoadChart,
  setShouldLoadChart,
  eventName,
}) => {
  const intl = useIntl();
  const { request, abortController, isLoading } = useFetch();
  const [statistics, setStatistics] = useLocalStorage<Statistics | undefined>(localStorageKey, ExpiryOptions.month);
  const [previousStatistics, setPreviousStatistics] = useLocalStorage<Statistics>(
    `${removeDateFromLocalStorageKey(localStorageKey)}${previousMonthTimeStamp}`,
    ExpiryOptions.month,
  );
  const prevKey = usePrevious(localStorageKey);

  const getChartSettings = (series: string[], categories: string[]): ChartOptionsSettings => ({
    series: [{ name: intl.formatMessage({ id: 'STATS.ORDERS' }), data: series }],
    options: {
      chart: { height: 350 },
      colors: ['#318fb5'],
      plotOptions: { bar: { distributed: true } },
      dataLabels: { enabled: false },
      legend: { show: false },
      stroke: { width: [0, 4] },
      xaxis: { categories, labels: { style: { fontSize: '12px' } } },
    },
  });

  const requestChartData = (date: string, setStats: (value: Statistics) => void) => {
    const requestParams = {
      timeStamp: date,
      abort: abortController.signal,
    };

    request(
      eventName === OrderEvents.IMAGE_DECLINED.eventName ? getEditedImagesStatistics : getRepushedImagesStatistics,
      requestParams,
    ).then(res => {
      if (res) {
        const options = getChartSettings(
          res.map((x: any) => Math.round(x.total_orders)),
          res.map((x: any) => `${x.percentage}%`),
        );

        const totalOrders = res.reduce(
          (acc: number, row: { total_orders: string }) => acc + Number(row.total_orders),
          0,
        );

        const weightedRepushPercentage = res
          .map((row: any) => Number(row.percentage) * Number(row.total_orders))
          .reduce((acc: any, val: any) => acc + val, 0);

        setStats({
          chartSettings: options,
          percentAverage: weightedRepushPercentage / totalOrders,
          total_orders: totalOrders,
        });
        setShouldLoadChart(false);
      }
      return () => {
        abortController.abort();
      };
    });
  };

  useEffect(() => {
    if (shouldLoadChart) {
      if (!previousStatistics || isLocalDataExpired(previousStatistics?.expires)) {
        return requestChartData(previousMonthTimeStamp, setPreviousStatistics);
      }
    }
  }, [timeStamp]);

  useEffect(() => {
    if (shouldLoadChart) {
      if (prevKey === localStorageKey && isLocalDataExpired(statistics?.expires)) {
        return requestChartData(timeStamp, setStatistics);
      }
    }
  }, [timeStamp, shouldLoadChart]);

  return (
    <>
      <DashboardCardHeader style={{ paddingTop: '0px' }}>
        <DashboardDataChip
          label={
            <FormattedMessage
              id={eventName === OrderEvents.IMAGE_REPUSHED.eventName ? 'STATS.ORDERS.REPUSHED' : 'STATS.ORDERS.EDITED'}
              values={{ orders: statistics?.total_orders ?? '😭' }}
            />
          }
          color="success"
          sx={{ backgroundColor: colors.blue[500] }}
        />
        <DashboardDataChip
          label={
            <FormattedMessage
              id="STATS.AVG.SUCCESS"
              values={{
                avg: statistics?.percentAverage ? `${statistics?.percentAverage}%` : '😭',
              }}
            />
          }
          color="success"
          sx={{ backgroundColor: colors.blue[500] }}
        />
        <DashboardDataChip
          label={
            <FormattedMessage
              id="STATS.MONTHLY.DIFF"
              values={{
                diff: previousStatistics?.percentAverage
                  ? `${getPercentageDifference(statistics?.percentAverage ?? 0, previousStatistics.percentAverage)}%`
                  : '😭',
              }}
            />
          }
          color="success"
          sx={{ backgroundColor: colors.green[500] }}
        />
      </DashboardCardHeader>
      {isLoading && <LinearProgress style={{ margin: '2em' }} />}
      {statistics && (
        <Chart
          options={statistics.chartSettings.options}
          series={statistics.chartSettings.series}
          type="bar"
          height={350}
        />
      )}
    </>
  );
};

export const ImageStatisticsByEvents: FC<Props> = ({ title, localStorageKey, eventName }) => {
  const [dateFilter, setDateFilter] = useState(lastMonth);
  const [shouldLoadChart, setShouldLoadChart] = useState(false);
  const [key, setKey] = useState(`${localStorageKey}-${formatDateForSQL(dateFilter)}`);

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setDateFilter(date);
    }
  };

  useEffect(() => {
    setKey(`${localStorageKey}-${formatDateForSQL(dateFilter)}`);
  }, [dateFilter]);

  return (
    <DashboardCard>
      <DashboardCardHeader>
        <Box alignItems="center" display="flex" justifyContent="space-between" width="40%">
          <TitleTag children={title} />
          <DatePicker
            label={<FormattedMessage id="STATS.SELECT.DATE" />}
            views={['year', 'month']}
            minDate={minDate}
            maxDate={lastMonth}
            value={dateFilter}
            onChange={handleDateChange}
          />
        </Box>
        <Button
          variant="contained"
          onClick={() => setShouldLoadChart(true)}
          children={<FormattedMessage id="STATS.LOAD" />}
        />
      </DashboardCardHeader>
      {key && (
        <ChartDash
          localStorageKey={key}
          timeStamp={formatDateForSQL(dateFilter)}
          previousMonthTimeStamp={getPreviousMonth(dateFilter)}
          shouldLoadChart={shouldLoadChart}
          setShouldLoadChart={setShouldLoadChart}
          eventName={eventName}
        />
      )}
    </DashboardCard>
  );
};
