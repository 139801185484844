import config from 'react-global-configuration';

import { generateRequestOptions } from './helpers';

export const getOrderDeliveryMessages = (queries: string, abort: AbortSignal): Promise<Response> => {
  const url = `${config.get('ApiUrl').Rest}/order-delivery-messages${queries}`;

  return fetch(url, generateRequestOptions('GET', abort));
};

export const rebootAWSRobot = (abort: AbortSignal): Promise<Response> => {
  const url = `${config.get('ApiUrl').Rest}/order-delivery-messages/reboot-remote-delivery-robot`;

  return fetch(url, generateRequestOptions('GET', abort));
};

export const getOrderDeliveryMessageAvailableCount = (abort: AbortSignal): Promise<Response> => {
  const url = `${config.get('ApiUrl').Rest}/order-delivery-messages/get-current-available-count`;

  return fetch(url, generateRequestOptions('GET', abort));
};

export const removeOrderDeliveryMessage = (orderDeliveryMessageId: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/order-delivery-messages/${orderDeliveryMessageId}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    credentials: 'include',
  });

export const setOrderMessageVisibility = (orderDeliveryMessageId: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/order-delivery-messages/set-visible/${orderDeliveryMessageId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    credentials: 'include',
  });
