import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Chip, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { getInactiveUsers } from '../../../setup/api/statistics';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { Datagrid } from '../../../ui/components/Datagrid/Datagrid';
import { PaginationFilter } from '../../../ui/components/Filter/FilterPagination';

type InactiveUsersStatistics = {
  id: string;
  user_email: string;
  user_displayName: string;
  last_order_date: string;
  days_since_last_order: string;
  contactPerson_phoneNumber: string;
};

export type InactiveUserResultsAncCount = {
  results: InactiveUsersStatistics[];
  count: number;
};

const reduxIdentifier = 'inactiveUsers';

const Counter: FC<{ count: number }> = ({ count }) => (
  <Chip
    className="me-5"
    style={{ fontWeight: '500', fontSize: '1.075rem' }}
    label={<FormattedMessage id="STATS.INACTIVE_USERS.COUNT" values={{ count }} />}
  />
);

const options = ['STATS.14_D', 'STATS.20_D', 'STATS.30_D'];

const DropDown: FC<{ handleChange: (event: SelectChangeEvent) => void }> = ({ handleChange }) => {
  const intl = useIntl();

  return (
    <FormControl>
      <Select defaultValue={options[0]} onChange={handleChange}>
        {options &&
          options.map((option: string) => <MenuItem value={option}>{intl.formatMessage({ id: option })}</MenuItem>)}
      </Select>
    </FormControl>
  );
};

const renderInactiveUsersRow = (headings: [string, string][], item: InactiveUsersStatistics, link: string) =>
  headings.map(([key]) => {
    const property = item[key as keyof InactiveUsersStatistics];
    const linkProps = {
      to: `/${link}/${item.id}/settings/`,
      style: { textDecoration: 'none', color: 'inherit' },
    };

    if (key === 'last_order_date') {
      return (
        <td key={key}>
          <Link {...linkProps}>
            {property ? (
              <Moment format="YYYY-MM-DD" date={property} />
            ) : (
              <p>
                <FormattedMessage id="STATS.NO_ORDERS" />
              </p>
            )}
          </Link>
        </td>
      );
    }

    return (
      <td key={key} className="listHover">
        <Link {...linkProps}>{property}</Link>
      </td>
    );
  });

export const InactiveUserChart: FC = () => {
  const intl = useIntl();
  const { request, abortController } = useFetch();
  const [users, setUsers] = useState<InactiveUsersStatistics[]>([]);
  const [count, setCount] = useState<number>();
  const [timeFrame, setTimeFrame] = useState<number>(14);
  const pagination = useSelector(({ filters }) => filters[reduxIdentifier]?.page);

  const headings: Array<[string, string]> = [
    ['user_displayName', intl.formatMessage({ id: 'SALESMAN.NAME' })],
    ['contactPerson_phoneNumber', intl.formatMessage({ id: 'SALESMAN.PHONE' })],
    ['user_email', intl.formatMessage({ id: 'SALESMAN.EMAIL' })],
    ['last_order_date', intl.formatMessage({ id: 'STATS.LAST_ORDER' })],
    ['days_since_last_order', intl.formatMessage({ id: 'STATS.LAST_ORDER_DAYS' })],
  ];

  const handleChange = (event: SelectChangeEvent) => {
    const time = Number(event.target.value.split('.')[1].split('_')[0]);

    setTimeFrame(time);
  };

  useEffect(() => {
    request(getInactiveUsers, timeFrame, pagination || 1, abortController.signal).then(
      (res: InactiveUserResultsAncCount) => {
        if (res && res.results) {
          setUsers(res.results);
          setCount(res.count);
        }
      },
    );

    return () => {
      abortController.abort();
    };
  }, [timeFrame, pagination]);

  return (
    <Grid container padding={4} className="card justify-content-between align-items-center">
      <h3 className="mb-0">
        <FormattedMessage id="STATS.INACTIVE_USERS" />
      </h3>
      {count && <PaginationFilter maxPage={count / 10} type={reduxIdentifier} defaultPage={1} />}
      {count && <Counter count={count} />}

      <DropDown handleChange={handleChange} />
      <Grid xs={12} marginTop={3}>
        <Datagrid data={users} renderRow={renderInactiveUsersRow} headings={headings} link="profile" />
      </Grid>
    </Grid>
  );
};
