import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ImageListType } from 'react-images-uploading';
import { FormattedMessage } from 'react-intl';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useHistory, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

import { CancelEdition } from '../../../../app/components/order/views/CancelEdition';
import { SendToEdition } from '../../../../app/components/order/views/SendToEdition';
import { OrderInformationBox } from '../../../../app/pages/orders/orderDetails/OrderInformationBox';
import { TagEntity } from '../../../../constants';
import { getImageById, getSingleOrder, getTagsFromEntity } from '../../../../setup/api';
import { loadOriginalUrlIfThumbnailFails } from '../../../../setup/helpers/handleImageOnloadIfError';
import { updateImageBySignedUrl } from '../../../../setup/helpers/uploadImages';
import { useFetch } from '../../../../setup/hooks/fetch.hook';
import { formatDetectedAngle } from '../../../helpers/helpers';
import { ImageDropZone } from '../../ImageDropzone/ImageDropZone';
import { ColumnGrid, ContainerGrid, RowGrid } from '../../MainGridContainer/GridSystemMUI';

import { ImageBadges } from './ImageBadges';

import '../../../../app/pages/editing/EditingPage.scss';

type ImageFile = {
  // Base 64 with the image load
  data_url: string;
  file: File;
  height: number;
  width: number;
  fileSize: number;
};

export type OrderParams = {
  // Order Id
  id: string;
  imageId: string;
};

type TImageDetailModalProps = {
  closeModal: () => void;
};

const modalStyle = {
  position: 'absolute' as const,
  top: '2%',
  left: '10%',
  width: '80%',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  display: 'block',
  p: 4,
};

const loaderStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh', // Adjust as needed
};

const uploadingTextStyle = {
  color: '#ccc',
  fontSize: '4em',
};
/**
 *
 *  Loads hidden modal that can be displayed to show image information by a given image.id
 *
 * @param {() => void} closeModal function that returns the router to the original, closing the modal
 * For example: history.push('/url')
 * @returns Modal with image display, options:
 *
 * - Download image and styleguide media
 * - Image and order information
 * - Added tags
 * - Reupload
 */
export const ImageDetailModal: React.FC<TImageDetailModalProps> = ({ closeModal }) => {
  const [imageData, setImageData] = useState<IImage>();
  const [imageFile, setImageFile] = useState<ImageFile[]>([]);
  const [orderDetails, setOrderDetails] = useState<IOrder>();
  const [displayLoader, setDisplayLoader] = useState<boolean>(false);
  const { imageId, id } = useParams<OrderParams>();
  const history = useHistory();
  const { request } = useFetch();

  const getImageInfo = () => {
    request(getImageById, imageId).then((imageResult: IImage) => {
      request(getTagsFromEntity, TagEntity.IMAGE, imageId).then(tagsResult => {
        setImageData({ ...imageResult, tags: tagsResult });
      });
    });
  };
  // Gets order information to load the order details
  const getOrderDetails = () => {
    request(getSingleOrder, id).then(order => {
      setOrderDetails(order);
    });
  };

  // Upload new image if there is on loaded, and refresh the image data
  const uploadImage = () => {
    if (imageFile && imageFile.length > 0) {
      setDisplayLoader(true);
      request(updateImageBySignedUrl, imageId, imageFile[0]).then(() => {
        history.goBack();
        setDisplayLoader(false);
      });
    }
  };

  useEffect(() => {
    if (imageId) {
      getOrderDetails();
      getImageInfo();
    }
  }, [imageId]);

  return (
    <Modal sx={{ overflowY: 'scroll' }} disableScrollLock={false} open={Boolean(imageId)} onClose={closeModal}>
      {displayLoader ? (
        <div style={{ ...loaderStyle }}>
          <p style={uploadingTextStyle}>
            <FormattedMessage id="STATS.ORDER.STATUS.UPLOADING" />
            ...
          </p>
        </div>
      ) : (
        <Box sx={modalStyle}>
          <div className="card-header pt-6" style={{ marginBottom: '2em' }}>
            {imageData && (
              <>
                <ImageBadges imageData={imageData} callback={getImageInfo} />
                <div className="d-flex justify-content-between">
                  <div style={{ fontWeight: 500, fontSize: '1.125rem' }}>
                    <div>
                      <FormattedMessage id="DATA.IMAGE.ID" values={{ imageId: imageData.id }} />
                    </div>
                    <div>
                      <FormattedMessage id="DATA.IMAGE.SENSOR" values={{ data: imageData?.sensorData ?? 'null' }} />
                    </div>
                    <div>
                      <FormattedMessage
                        id="DATA.IMAGE.EXPECTED"
                        values={{
                          sensor:
                            orderDetails?.styleGuide?.configurations && imageData?.position
                              ? orderDetails?.styleGuide.configurations.find(x => x.position === imageData.position)
                                  ?.angle?.name
                              : 'null',
                        }}
                      />
                    </div>
                    <div>
                      <FormattedMessage
                        id="DATA.IMAGE.DETECTED"
                        values={{ sensor: formatDetectedAngle(imageData?.detectedAngle) ?? 'null' }}
                      />
                    </div>
                  </div>
                  <div>
                    {imageData && imageData.approved ? (
                      <SendToEdition
                        optimizedImage={imageData}
                        callback={() => {
                          getOrderDetails();
                          getImageInfo();
                        }}
                      />
                    ) : (
                      <CancelEdition optimizedImageId={imageData.id} callback={() => getImageInfo()} />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <ContainerGrid>
            {/* Image Column */}
            <ColumnGrid size={9}>
              <RowGrid>
                {/* If there is a file loaded on memory it wil display the file, on the contrary it will print the current image */}
                <LazyLoadImage
                  src={imageFile[0] ? imageFile[0].data_url : imageData?.thumbnailUrl}
                  className="img-fluid "
                  style={{ maxWidth: '80%' }}
                  onLoad={() => {
                    if (imageFile[0]) {
                      const im = new Image();

                      im.src = imageFile[0].data_url;
                      imageFile[0].width = im.width;
                      imageFile[0].height = im.height;
                    }
                  }}
                  onError={e => {
                    if (imageData) {
                      loadOriginalUrlIfThumbnailFails(e.currentTarget, imageData);
                    }
                  }}
                />
              </RowGrid>

              {/*  Image Upload Buttons Row */}
              <RowGrid>
                <ImageDropZone
                  setImages={setImageFile as unknown as Dispatch<SetStateAction<ImageListType | undefined>>}
                  images={[]}
                  selfDrop
                  maxNumber={1}
                  download={false}
                />
                <Button
                  className="col-5 ms-1"
                  variant="contained"
                  color="success"
                  onClick={uploadImage}
                  children={<FormattedMessage id="ACTION.SUBMIT" />}
                />
              </RowGrid>
            </ColumnGrid>
            {/* Order Details Column */}
            <ColumnGrid size={3}>
              <>
                {orderDetails && (
                  <OrderInformationBox orderInfo={orderDetails} images={imageData} reloadOrderInfo={getOrderDetails} />
                )}
              </>
            </ColumnGrid>
          </ContainerGrid>
        </Box>
      )}
    </Modal>
  );
};
