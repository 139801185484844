import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import { CircularProgress } from '@mui/material';

import { downloadVideo, getVideosByOrderId } from '../../../../setup/api';
import { useFetch } from '../../../../setup/hooks/fetch.hook';

import './MultipleImageView.css';

const VideoError: React.FC = () => (
  <>
    <h1 className="fw-bolder fs-42x text-gray-700 mb-10">
      <FormattedMessage id="ORDER.VIEW.VIDEO.ERROR" />
    </h1>
    <div className="fw-bold fs-3 text-gray-400 mb-15" />
  </>
);

interface TProps {
  videoData: IVideo;
}
const VideoDownload: React.FC<TProps> = ({ videoData }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { request } = useFetch();

  return (
    <>
      {isLoading === false ? (
        <DownloadIcon
          fontSize="inherit"
          color="inherit"
          style={{
            position: 'absolute',
            display: 'inline-block',
            top: '25%',
            left: '35%',
            color: 'white',
            border: 'none',
            fontSize: '8em',
          }}
          onClick={async () => {
            setIsLoading(true);
            await request(downloadVideo, videoData.id);
            setIsLoading(false);
          }}
        />
      ) : (
        <CircularProgress
          style={{
            position: 'absolute',
            display: 'inline-block',
            top: '35%',
            left: '43%',
            color: 'white',
            border: 'none',
          }}
          color="primary"
          size={50}
        />
      )}

      <img src={videoData.thumbnailUrl} style={{ width: '75%', height: '100%' }} className="img-fluid loadedImage" />
    </>
  );
};

export const VideoView: FC = () => {
  const [videos, setVideos] = useState<IVideo[]>([]);
  const { request } = useFetch();
  const { id } = useParams<TParams>();
  const getOrderVideos = () => {
    request(getVideosByOrderId, id).then((data: IVideo[]) => {
      setVideos(data);
    });
  };

  useEffect(() => {
    getOrderVideos();
  }, []);

  return (
    <div
      className="row d-flex multipleImageUser align-items-center"
      style={{
        marginLeft: '3em',
        marginRight: '1.4em',
        paddingBottom: '1.4em',
      }}
    >
      {videos &&
        videos.length > 0 &&
        videos.map((video: IVideo) => (
          <div className="d-flex justify-content-between" style={{ width: '225x', height: '225px' }}>
            <VideoDownload videoData={video} />
          </div>
        ))}
      {videos.length < 1 && <VideoError />}
    </div>
  );
};
