import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#b5b5c3',
  padding: theme.spacing(2),
  fontWeight: '500',
  fontSize: '1.1rem',
}));

const CustomLink = styled(Link)(({ theme }) => ({
  color: '#b5b5c3',
  '&:hover': {
    color: theme.palette.text.primary,
  },
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

export const FooterLinks = (): JSX.Element => (
  <Container>
    <CustomLink href="carswip.com" underline="none">
      <FormattedMessage id="FOOTER.ABOUT" />
    </CustomLink>
    <CustomLink href="mailto:support@carswip.com" underline="none">
      <FormattedMessage id="FOOTER.CONTACT" />
    </CustomLink>
  </Container>
);
