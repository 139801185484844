import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import AddIcon from '@mui/icons-material/Add';
import { Chip, ListItemButton } from '@mui/material';

import { TagCategory, TagEntity } from '../../../../constants';
import { addTagToEntity, getTags, updateImageApproval } from '../../../../setup/api';
import { useFetch } from '../../../../setup/hooks/fetch.hook';

type Props = {
  optimizedImage: IImage;
  callback?: () => void;
};

export const SendToEdition: FC<Props> = ({ optimizedImage, callback }) => {
  const [openImageToEditing, setOpenImageToEditing] = useState<boolean>(false);
  const [tags, setTags] = useState<Array<ITag>>([]);
  const { request } = useFetch();

  const changeImageApprovalStatus = (imageId: string, payload: { approved: boolean }, tagId?: string) => {
    request(updateImageApproval, imageId, payload)
      .then(() => (tagId ? request(addTagToEntity, imageId, TagEntity.IMAGE, tagId) : Promise.resolve()))
      .then(() => (callback ? callback() : Promise.resolve()));
  };

  useEffect(() => {
    request(getTags, `?entity=${TagEntity.IMAGE}&category?=${TagCategory.IMAGE_ERROR}`).then(
      (data: [ITag[], number]) => {
        if (data) {
          setTags(data[0]);
        }
      },
    );
  }, []);

  return (
    <div className="btn-group" role="group">
      <button
        type="button"
        className="btn btn-danger"
        onClick={() => {
          changeImageApprovalStatus(optimizedImage.id, { approved: false });
        }}
      >
        <FormattedMessage id="ORDER.QA.EDITING" />
      </button>
      <button
        style={{
          borderRadius: '5px',
          borderTopLeftRadius: '0px',
          borderBottomLeftRadius: '0px',
        }}
        className="btn btn-danger"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
        onClick={() => {
          setOpenImageToEditing(!openImageToEditing);
        }}
      >
        <AddIcon />
      </button>
      <ul className={openImageToEditing ? 'show dropdown-menu' : 'dropdown-menu'} data-kt-menu="true">
        {tags &&
          tags.map((tag: ITag) => (
            <ListItemButton
              className="ms-3 menu-item mb-3 order-status-list-items"
              sx={{ pl: 4 }}
              disabled={optimizedImage.tags?.some((x: ITag) => x.name === tag.name)}
              onClick={() => {
                changeImageApprovalStatus(optimizedImage.id, { approved: false }, tag.id);
                setOpenImageToEditing(!openImageToEditing);
              }}
            >
              <Chip
                style={{
                  cursor: 'pointer',
                  width: '100%',
                  borderRadius: '0px 5px 5px 0px',
                  border: '1px solid #fff',
                  height: '20px',
                  backgroundColor: tag.color,
                }}
                label={tag.name}
                variant="filled"
              />
            </ListItemButton>
          ))}
      </ul>
    </div>
  );
};
