import config from 'react-global-configuration';

import { generateRequestOptions } from './helpers';

export const getSalesManById = (id: string, abort: AbortSignal): Promise<Response> => {
  const url = `${config.get('ApiUrl').Rest}/salesman/${id}`;

  return fetch(url, generateRequestOptions('GET', abort));
};

export const createSalesMan = (salesManData: ISalesManPayload): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/salesman`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(salesManData),
    mode: 'cors',
    credentials: 'include',
  });

export const modifySalesMan = (salesManId: string, salesManData: ISalesMan): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/salesman/${salesManId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(salesManData),
    mode: 'cors',
    credentials: 'include',
  });

export const getSalesMenByUserId = (userId: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/salesman/user/${userId}`, {
    mode: 'cors',
    credentials: 'include',
  });

export const getAllSalesMenByUserId = (userId: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/salesman/user/${userId}/all`, {
    mode: 'cors',
    credentials: 'include',
  });

export const deleteSalesMan = (salesManId: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/salesman/${salesManId}`, {
    method: 'DELETE',
    mode: 'cors',
    credentials: 'include',
  });

export const activateSalesMan = (salesManId: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/salesman/${salesManId}/activate`, {
    method: 'PUT',
    mode: 'cors',
    credentials: 'include',
  });
