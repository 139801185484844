import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, Route, Switch, useParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { ThemeProvider } from '@mui/material';

import { PageTitle } from '../../../../_carswip/layout/core';
import { UserRole } from '../../../../constants';
import { getSingleOrder } from '../../../../setup/api';
import { handleImageOnLoadError } from '../../../../setup/helpers/handleImageOnloadIfError';
import { pickStyleStatusColor } from '../../../../setup/helpers/pickStyleStatusColor';
import { useFetch } from '../../../../setup/hooks/fetch.hook';
import { addSelectedImages, removeSelectedImages, setCurrentUserToState } from '../../../../setup/redux/actions';
import { RemoteIntegrationDropDown } from '../../../../ui/components/Dropdown/RemoteIntegrationDropDown';
import { CustomizedButtonTheme } from '../../../../ui/components/Themes/CustomizedCarswipButton';
import { OrderInformationBox } from '../../../pages/orders/orderDetails/OrderInformationBox';

import { VideoView } from './VideoView';

import './MultipleImageView.css';

type IProps = {
  orderInfo?: IOrder;
};

const getNavigation = (orderId: string) => (
  <ul
    className="nav nav-stretch nav-line-tabs nav-line-tabs-2x  border-transparent fs-5 fw-bolder flex-nowrap"
    style={{ display: 'flex', marginLeft: '1.4em' }}
  >
    <li className="nav-item">
      <Link
        className={`nav-link text-active-primary me-6 ${location.pathname === `/order/${orderId}` ? 'active' : ''}`}
        to={`/order/${orderId}`}
      >
        <FormattedMessage id="ORDER.VIEW.IMAGES" />
      </Link>
    </li>
    <li className="nav-item">
      <Link
        className={`nav-link text-active-primary me-6 ${
          location.pathname === `/order/${orderId}/videos` ? 'active' : ''
        }`}
        to={`/order/${orderId}/videos`}
      >
        <FormattedMessage id="ORDER.VIEW.VIDEOS" />
      </Link>
    </li>
  </ul>
);

interface ImageViewProps {
  orderInfo: IOrder;
  selectedImages: IImage[];
  setSelectedImages: Dispatch<SetStateAction<IImage[]>>;
}

const ImageView: React.FC<ImageViewProps> = ({ orderInfo, selectedImages, setSelectedImages }) => {
  const [modal, showModal] = useState(false);
  const dispatch = useDispatch();
  const handleModal = () => {
    showModal(!modal);
  };

  return (
    <div
      className="row d-flex multipleImageUser align-items-center"
      style={{
        marginLeft: '3em',
        marginRight: '1.4em',
        paddingBottom: '1.4em',
      }}
    >
      {orderInfo !== undefined &&
        orderInfo?.images &&
        orderInfo?.images.map((image: IImage, index: number) => (
          <div className="col-4 justify-content-between">
            {selectedImages && selectedImages.includes(image) ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  if (image.id) {
                    const arr = selectedImages.filter((item: IImage) => item.id !== image.id);

                    dispatch(removeSelectedImages(image.id));
                    setSelectedImages(arr);
                  }
                }}
              >
                <CheckCircleIcon />
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  if (image.id) {
                    dispatch(addSelectedImages(image.id));
                    setSelectedImages([...selectedImages, image]);
                  }
                }}
              >
                <CircleIcon />
              </button>
            )}
            <img
              src={orderInfo?.images[index].thumbnailUrl}
              className="img-fluid loadedImage"
              onClick={() => {
                if (image.id) {
                  handleModal();
                }
              }}
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) =>
                handleImageOnLoadError(e, index, orderInfo)
              }
            />
          </div>
        ))}
      ;
    </div>
  );
};

/**
 * This component is the default view for the {@link UserRole.Dealership } && {@link UserRole.QA} and allows the user to select multiple images from the gallery
 *  this selected images are send to redux
 * @param props.orderInfo receive from parent the order information
 */
export const UserView: FC<IProps> = () => {
  const dispatch = useDispatch();
  const params = useParams<TParams>();
  const { request } = useFetch();
  const [selectedImages, setSelectedImages] = React.useState<Array<IImage>>([]);
  const [orderInfo, setOrderInfo] = useState<IOrder>();
  const getOrderInfo = async () => {
    request(getSingleOrder, params.id).then((res: IOrder) => {
      setOrderInfo(res);
      dispatch(setCurrentUserToState(res?.user));
    });
  };

  useEffect(() => {
    getOrderInfo();
  }, []);

  return (
    <div className="row">
      <div className="bg-white p-8 rounded col-9" style={{ flex: '1 0 auto' }}>
        <div className="d-flex">
          <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2" style={{ marginLeft: '1.4em' }}>
            <a className="d-flex align-items-center text-black me-5 mb-2">
              <FormattedMessage id="ORDER.VIEW.USER" />
              <div className="text-gray-700">&nbsp;{orderInfo?.user?.displayName}</div>
            </a>
            <a className="d-flex align-items-center text-black me-5  mb-2">
              <FormattedMessage id="ORDER.VIEW.STATUS" />
              <div>
                <span className={`${pickStyleStatusColor(orderInfo?.status)} badge-status`}>{orderInfo?.status}</span>
              </div>
            </a>

            <a className="d-flex align-items-center text-black me-5 mb-2">
              <FormattedMessage id="ORDER.VIEW.ORDER_NAME" />
              :&nbsp;
              <div style={{ color: 'grey' }}>{orderInfo?.orderName}</div>
            </a>
            <div style={{ display: 'inline-flex', position: 'relative', justifyContent: 'right' }}>
              <ThemeProvider theme={CustomizedButtonTheme}>
                <RemoteIntegrationDropDown />
              </ThemeProvider>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="d-flex overflow-auto h-55px">{orderInfo && getNavigation(orderInfo.id)}</div>
        </div>
        <div>
          {orderInfo && (
            <Switch>
              <Route path="/order/:id/videos">
                <PageTitle>
                  <FormattedMessage id="ORDER.VIEW.VIDEOS" />
                </PageTitle>
                <VideoView />
              </Route>
              <Route path="/order/:id/">
                <PageTitle>
                  <FormattedMessage id="ORDER.VIEW.IMAGES" />
                </PageTitle>
                <ImageView
                  orderInfo={orderInfo}
                  selectedImages={selectedImages}
                  setSelectedImages={setSelectedImages}
                />
              </Route>
            </Switch>
          )}
        </div>
      </div>

      <div className="col-2">
        {orderInfo && orderInfo.styleGuide && (
          <OrderInformationBox orderInfo={orderInfo} images={selectedImages} reloadOrderInfo={getOrderInfo} />
        )}
      </div>
    </div>
  );
};
