import { FC } from 'react';
import { Grid, GridSize } from '@mui/material';

interface IGrid {
  children: JSX.Element[] | JSX.Element;
}
interface IColumnGrid extends IGrid {
  size?: boolean | GridSize;
}

const ContainerGrid: FC<IGrid> = ({ children }) => <Grid container>{children}</Grid>;
const RowGrid: FC<IGrid> = ({ children }) => (
  <Grid item xs={12}>
    <Grid container justifyContent="center" spacing={2} marginBottom="2em">
      {children}
    </Grid>
  </Grid>
);
const ColumnGrid: FC<IColumnGrid> = ({ children, size }) => (
  <Grid item xs={size}>
    {children}
  </Grid>
);

export { ColumnGrid, ContainerGrid, RowGrid };
