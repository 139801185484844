import { Dispatch, FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';

type CheckBoxProps = {
  repushPayload: RepushPayload;
  setRepushPayload: Dispatch<React.SetStateAction<RepushPayload>>;
};

export const RepushPipelineOptions: FC<CheckBoxProps> = ({ repushPayload, setRepushPayload }) => {
  const intl = useIntl();

  const pipelineCheckBoxOptions: Array<[keyof PipelineOptions, string]> = [
    ['userControlCropEnabled', intl.formatMessage({ id: 'REPUSH.PIPELINE.CROP' })],
    ['userControlForceAddLogo', intl.formatMessage({ id: 'REPUSH.PIPELINE.FORCE' })],
    ['userControlRotationFixEnabled', intl.formatMessage({ id: 'REPUSH.PIPELINE.ROTATION' })],
    ['userControlYellowFixEnabled', intl.formatMessage({ id: 'REPUSH.PIPELINE.LIGHT' })],
    ['userControlForceBGRemove', intl.formatMessage({ id: 'REPUSH.PIPELINE.BACKGROUND' })],
  ];

  const handleChange = (name: keyof PipelineOptions, alignment: string) => {
    setRepushPayload({
      ...repushPayload,
      [name]: alignment === 'undefined' ? undefined : JSON.parse(alignment),
    });
  };

  return (
    <Box style={{ display: 'grid', justifyContent: 'center', marginLeft: '5em' }}>
      <h3>
        <FormattedMessage id="REPUSH.PIPELINE.TITLE" />
      </h3>
      {pipelineCheckBoxOptions.map(([name, label]) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ paddingRight: '10px', alignSelf: 'center' }}>{label}</span>
          <ToggleButtonGroup
            color="info"
            value={repushPayload[name] === undefined ? 'undefined' : repushPayload[name] === true ? 'true' : 'false'}
            exclusive
            onChange={(_e, alignment) => handleChange(name, alignment as string)}
            aria-label="Platform"
          >
            <ToggleButton value="false">
              <FormattedMessage id="REPUSH.PIPELINE.DISABLED" />
            </ToggleButton>
            <ToggleButton value="undefined">
              <FormattedMessage id="REPUSH.PIPELINE.AUTO" />
            </ToggleButton>
            <ToggleButton value="true">
              <FormattedMessage id="REPUSH.PIPELINE.ENABLED" />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      ))}
    </Box>
  );
};
