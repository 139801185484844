import React, { Link } from 'react-router-dom';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';

export function renderTestRows(userHeadings, item, link) {
  return userHeadings.map(([key]) => {
    if (key === 'user') {
      return (
        <td key={key} href={process.env.REACT_APP_URL} className="listHover">
          <Link to={`/profile/${item[key]?.id}/orders`} style={{ textDecoration: 'none', color: 'inherit' }}>
            {item[key]?.displayName}
          </Link>
        </td>
      );
    }
    return (
      <td key={key} href={process.env.REACT_APP_URL} className="listHover">
        <Link to={`/${link}/${item.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
          {item[key]}
        </Link>
      </td>
    );
  });
}

/**
 * Renders the rows for {@link DataGrid}
 * @param {[string, string][]} userHeadings must include an array with a tuple of strings,
 * @param  item the item should be the customer payload which will be use to load a row with information
 * @param {string} link should include part of the url that the customer can access on click fot example: "user" will return on click "carswip.com/user/123"
 * @returns row with with user information
 */
export function renderTestRowsUsers(userHeadings, item, link) {
  return userHeadings.map(([key]) => {
    if (key === 'cognitoSub') {
      return item[key] ? (
        <CheckCircleTwoToneIcon style={{ color: 'green', margin: '10px 0 0 25px' }} />
      ) : (
        <CancelTwoToneIcon style={{ color: 'red', margin: '10px 0 0 25px' }} />
      );
    }

    const split = key.split('_');

    if (split.length > 1) {
      if (item[split[0]] !== null) {
        return (
          <td key={key} className="listHover">
            <Link to={`/${link}/${item.id}/orders`} style={{ textDecoration: 'none', color: 'inherit' }}>
              {item[split[0]][split[1]]}
            </Link>
          </td>
        );
      }
      return <td key={key} className="listHover" />;
    }
    return (
      <td key={key} className="listHover">
        <Link to={`/${link}/${item.id}/orders`} style={{ textDecoration: 'none', color: 'inherit' }}>
          {item[key]}
        </Link>
      </td>
    );
  });
}
