import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { UserRole } from '../../../../constants';

type Props = {
  orderInfo?: IOrder;
  userRole?: UserRole;
};

export const CustomerNote: FC<Props> = ({ orderInfo, userRole }) => {
  const [noteIsHovered, setNoteIsHovered] = useState(false);

  if (orderInfo === undefined || userRole === undefined) {
    return null;
  }

  return (userRole === UserRole.ADMIN || userRole === UserRole.QA) && orderInfo?.styleGuide?.customerNote ? (
    <div
      onMouseEnter={() => setNoteIsHovered(true)}
      onMouseLeave={() => setNoteIsHovered(false)}
      style={{
        width: '955px',
        alignSelf: 'baseline',
        fontStyle: 'italic',
        boxSizing: 'content-box',
        border: '1px solid rgb(255 230 236)',
        padding: '3px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        backgroundColor: 'rgb(255 230 236)',
        borderLeft: 'solid 4px red',
        ...(noteIsHovered && { overflow: 'auto', whiteSpace: 'normal', overflowWrap: 'break-word' }),
      }}
    >
      <WarningAmberIcon style={{ color: 'red', fontSize: '2rem', marginRight: '5px' }} />
      <span style={{ fontWeight: '500' }}>
        <FormattedMessage id="ORDER.NOTE" />
      </span>{' '}
      {orderInfo?.styleGuide?.customerNote}
    </div>
  ) : null;
};
