import React, { Dispatch, SetStateAction, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import TagIcon from '@mui/icons-material/Tag';
import { Chip, Collapse, Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { addTagToEntity, getTags } from '../../../setup/api';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { useFetchWithAlert } from '../../../setup/hooks/useFetchWithAlert';
import { reloadTagDisplay } from '../../../setup/redux/actions';

interface Props {
  category: string;
  entityId: string;
  entityType: string;
  handleClose: Dispatch<SetStateAction<boolean>>;
}

const NestedListItem: React.FC<Props> = ({ category, entityType, entityId, handleClose }) => {
  const intl = useIntl();
  const add = useFetchWithAlert({
    message: intl.formatMessage({ id: 'TAG.ADDED' }),
    options: {
      position: 'bottom-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: 1,
    },
  });
  const { request } = useFetch();
  const [names, setNames] = useState<ITag[]>();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const fetchNames = (category: string) => {
    request(getTags, `?entity=${entityType}&category=${category}`).then((tagNames: any) => {
      setNames(tagNames[0]);
    });
  };
  const addTag = (tag: string) => {
    add.request(addTagToEntity, entityId, entityType, tag).then(() => {
      dispatch(reloadTagDisplay(true));
    });

    handleClose(false);
  };
  const theme = createTheme({
    components: {
      MuiListItemButton: {
        styleOverrides: {
          root: {
            backgroundColor: 'white',
            fontFamily: 'sans-serif',
            color: 'white',
            fontWeight: 'bold',
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            fontSize: '1.2rem',
            fontWeight: 'bold',
            color: 'white',
          },
        },
      },
    },
    palette: {
      mode: 'dark',
      primary: { main: 'rgb(102, 157, 246)', contrastText: '#fff' },
      background: { paper: 'rgb(5, 30, 52)' },
    },
  });

  return (
    <>
      <ListItemButton
        color="primary"
        onClick={() => {
          fetchNames(category);
          setOpen(!open);
        }}
      >
        <ListItemIcon>
          <TagIcon />
        </ListItemIcon>
        <ListItemText primary={category} />
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {names &&
            names.map((tag: ITag) => (
              <ThemeProvider theme={theme}>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => {
                    addTag(tag.id);
                  }}
                >
                  <Chip
                    style={{
                      cursor: 'pointer',
                      width: '100%',
                      borderRadius: '0px 5px 5px 0px',
                      border: '1px solid #fff',
                      height: '20px',
                      backgroundColor: tag.color,
                    }}
                    label={tag.name}
                    variant="filled"
                  />
                </ListItemButton>
                <Divider />
              </ThemeProvider>
            ))}
        </List>
      </Collapse>
    </>
  );
};

export { NestedListItem };
