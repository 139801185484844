import { Header } from './Header';
import { UserMenu } from './UserMenu';

export function HeaderWrapper() {
  return (
    <div
      className="header align-items-stretch"
      style={{ backgroundColor: '#fff', margin: '0% 7%', borderRadius: '0.475rem' }}
    >
      <div
        className="d-flex align-items-stretch justify-content-between container-fluid"
        style={{ boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)' }}
      >
        <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
          <div className="d-flex align-items-stretch">
            <Header />
          </div>
          <div className="d-flex align-items-stretch flex-shrink-0">
            <UserMenu />
          </div>
        </div>
      </div>
    </div>
  );
}
