import { FC, useEffect, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import * as H from 'history';
import { Dispatch } from 'redux';
import * as Yup from 'yup';

import { createAppVersion, getAppVersion, modifyAppVersion } from '../../../setup/api';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { IReloadReturn, reloadTableFromModal } from '../../../setup/redux/actions';
import { MainGridContainer } from '../../../ui/components/MainGridContainer/MainGridContainer';
import { ModalCarswip } from '../../../ui/components/Modal';

const createAppVersionSchema = (intl: IntlShape) =>
  Yup.object({
    versionString: Yup.string()
      .required(
        intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED' }, { name: intl.formatMessage({ id: 'VERSION.APP' }) }),
      )
      .label('versionString'),
  });

interface IAppVersion {
  versionString: string;
  platform: string;
  isTheRequiredMinimumVersionCurrently: boolean;
  needsDatabaseDeleteOnMobile: boolean;
}
const inits: IAppVersion = {
  versionString: '',
  platform: 'Android',
  needsDatabaseDeleteOnMobile: false,
  isTheRequiredMinimumVersionCurrently: true,
};

interface IProps {
  button?: boolean;
}

function onSubmit(
  id: string,
  values: IAppVersion,
  request: (apiCall: unknown, ...params: unknown[]) => Promise<IAppVersion>,
  setIsOpen: (isOpen: boolean) => void,
  dispatch: Dispatch<IReloadReturn>,
) {
  if (id) {
    request(modifyAppVersion, values, id).then(() => {
      setIsOpen(false);
      dispatch(reloadTableFromModal({ reload: true }, 'appversion'));
    });
  } else {
    request(createAppVersion, { ...values }).then(() => {
      setIsOpen(false);
      dispatch(reloadTableFromModal({ reload: true }, 'appversion'));
    });
  }
}

function createAppVersionField(props: FormikProps<IAppVersion>) {
  const intl = useIntl();

  return (
    <div className="fv-row mb-10 ">
      <label className="d-flex align-items-center fs-5 fw-bold mb-2">
        <span className="required">
          <FormattedMessage id="VERSION.APP" />
        </span>
      </label>

      <Field
        type="text"
        className={`form-control form-control-lg  userInputFields ${
          props.touched.versionString && props.errors.versionString ? 'is-invalid' : 'form-control-solid'
        }`}
        name="versionString"
        placeholder={intl.formatMessage({ id: 'VERSION.APP.NUMBER' })}
      />
      <div style={{ color: 'red', marginTop: '-10px' }}>
        <ErrorMessage name="versionString" />
      </div>
    </div>
  );
}

function createPlatformField() {
  const marginRight = { marginRight: '1em' };

  return (
    <div className="fv-row mb-10">
      <label className="d-flex align-items-center fs-5 fw-bold mb-2">
        <span className="required">
          <FormattedMessage id="VERSION.PLATFORM" />
        </span>
      </label>

      <div role="group" aria-labelledby="my-radio-group">
        <label style={marginRight}>
          <Field type="radio" name="platform" value="Android" style={marginRight} />
          <FormattedMessage id="VERSION.PLATFORM.ANDROID" />
        </label>
        <label style={marginRight}>
          <Field type="radio" name="platform" value="iOS" style={marginRight} />
          <FormattedMessage id="VERSION.PLATFORM.IOS" />
        </label>
      </div>
    </div>
  );
}

function createCurrentlyRequiredField() {
  return (
    <div className="fv-row mb-10">
      <label className="d-flex align-items-center fs-5 fw-bold mb-2">
        <span className="required">
          <FormattedMessage id="VERSION.MIN_REQUIRED" />
        </span>
      </label>
      <Field name="isTheRequiredMinimumVersionCurrently" className="mr-2 leading-tight" type="checkbox" />
    </div>
  );
}

function createNeedsDBRebuildField() {
  return (
    <div className="fv-row mb-10 ">
      <label className="d-flex align-items-center fs-5 fw-bold mb-2">
        <span className="required">
          <FormattedMessage id="VERSION.DB_DEL_REQUIRED" />
        </span>
      </label>
      <Field name="needsDatabaseDeleteOnMobile" className="mr-2 leading-tight" type="checkbox" />
    </div>
  );
}

const CreateAppVersionModal: FC<IProps> = ({ button }) => {
  const intl = useIntl();
  const { request } = useFetch();
  const [initValues, setInitialData] = useState<IAppVersion>(inits);
  const { id } = useParams<TParams>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const history: H.History<H.LocationState> = useHistory();
  const dispatch = useDispatch();
  const modalToggle = () => {
    setIsOpen(!isOpen);
    history.push(`/${'admin/app-versions'}`);
  };

  useEffect(() => {
    if (id) {
      request(getAppVersion, id).then((appVersionResponse: IAppVersion) => {
        setInitialData(appVersionResponse);
        setIsOpen(true);
      });
    }
  }, [id]);

  return (
    <>
      {button && (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            setInitialData(inits);
            history.push(`/${'admin/app-versions'}`);
            setIsOpen(true);
          }}
        >
          <FormattedMessage id="VERSION.APP.ADD" />
        </button>
      )}

      <ModalCarswip open={isOpen} onClose={modalToggle} title="App Version">
        <MainGridContainer>
          <Formik
            validationSchema={createAppVersionSchema(intl)}
            initialValues={initValues}
            onSubmit={values => {
              onSubmit(id, values, request, setIsOpen, dispatch);
            }}
          >
            {(props: FormikProps<IAppVersion>) => (
              <Form onSubmit={props.handleSubmit} className="form" noValidate>
                <div className="w-100">
                  {createAppVersionField(props)}
                  {createPlatformField()}
                  {createCurrentlyRequiredField()}
                  {createNeedsDBRebuildField()}
                </div>

                <div className="d-flex justify-content-end flex-stack pt-10">
                  <button type="submit" className="btn btn-lg btn-primary d-flex">
                    <span className="indicator-label ">
                      <FormattedMessage id={id ? 'ACTION.UPDATE' : 'ACTION.CREATE'} />
                    </span>
                    <AddCircleIcon />
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </MainGridContainer>
      </ModalCarswip>
    </>
  );
};

export { CreateAppVersionModal };
