import { FormattedMessage } from 'react-intl';

import { toAbsoluteUrl } from '../../helpers';

export const FallbackView = (): JSX.Element => (
  <div className="splash-screen">
    <img src={toAbsoluteUrl('/media/logos/logo-compact.svg')} alt="Start logo" />
    <span>
      <FormattedMessage id="ACTION.PENDING" />
    </span>
  </div>
);
