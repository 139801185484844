import config from 'react-global-configuration';
import { ImageType } from 'react-images-uploading';

import { MediaOrigin } from '../../constants';

export const getImages = (): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/images`, {
    mode: 'cors',
    credentials: 'include',
  });

export type IImageUpload = {
  order: string;
  position?: string;
  fileSize: number;
  height: number;
  width: number;
  binary: Blob;
  file?: string;
};

export const getImageUrl = (data: ImageType): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/images/get-signed-url-for-upload`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    credentials: 'include',
  });
export const getImageById = (id: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/images/data/${id}`, {
    mode: 'cors',
    credentials: 'include',
  });

export const getOrderbyImageStatus = (status = 'false'): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/orders?approved=${status}`, {
    mode: 'cors',
    credentials: 'include',
  });

export const postApprovalForImageId = (id: string, imageNewDimensions?: ImageType): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/images/approval/${id}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(imageNewDimensions),
    mode: 'cors',
    credentials: 'include',
  });

export const getCoordinatorOrdersRequest = (coordinator?: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/orders/coordinator/${coordinator || 'undefined'}`, {
    mode: 'cors',
    credentials: 'include',
  });

export const downloadSingleImage = (url: string): Promise<void | Promise<void>> =>
  fetch(`${url}`, {
    mode: 'no-cors',
    credentials: 'include',
  })
    .then(res => res.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');

      a.href = url;
      a.download = 'image.png';
      document.body.appendChild(a);
      a.click();
      a.remove();
    });

export const downloadOrderImages = (
  orderInfo: { orderId: string; orderName: string },
  imageIds?: Array<string>,
  styleguide?: boolean,
): Promise<void | Promise<void>> => {
  const { orderId, orderName } = orderInfo;
  const payload = {
    order: orderId,
    images: imageIds,
  };

  return fetch(
    `${config.get('ApiUrl').Rest}/images/download/${orderId}?downloadStyleGuide=${styleguide === true && 'true'}`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(payload),
    },
  )
    .then(response => response.json())
    .then(response => {
      const { url } = response;
      const a = document.createElement('a');

      a.href = url;
      a.download = `${orderName}.zip`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    })
    .catch(err => {
      console.log(err);
    });
};
export const downloadOptimizedImages = (orderId: string, orderName: string): Promise<void | Promise<void>> =>
  fetch(`${config.get('ApiUrl').Rest}/images/download/${orderId}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    credentials: 'include',
  })
    .then(response => response.json())
    .then(response => {
      const { url } = response;
      const a = document.createElement('a');

      a.href = url;
      a.download = `${orderName}.zip`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    })
    .catch(err => {
      console.log(err);
    });

export const repushImage = (imageId: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/images/repush/${imageId}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    credentials: 'include',
  });

export const updateImageApproval = (imageId: string, imageData: IImage): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/images/updateApproval/${imageId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(imageData),
    mode: 'cors',
    credentials: 'include',
  });

export const repushImages = (payload: RepushPayload): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/images/repush`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
    mode: 'cors',
    credentials: 'include',
  });

type TDownloadMediaType = 'cutout' | 'original';

export const downloadMediaByOrderAndPosition = (
  orderId: string,
  position: number,
  mediaType?: TDownloadMediaType,
): Promise<void> =>
  fetch(`${config.get('ApiUrl').Rest}/images/downloadMedia/${orderId}/${position}?media=${mediaType}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    credentials: 'include',
  })
    .then(response => response.json())
    .then(response => {
      const { url } = response;
      const a = document.createElement('a');

      a.href = url;
      a.download = `${orderId}.zip`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    })
    .catch(err => {
      console.log(err);
    });

export const getOrderImages = (orderId: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/images/order/${orderId}`, {
    mode: 'cors',
    credentials: 'include',
  });

export const deleteSelectedImages = (ids: string[]): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/images/`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    credentials: 'include',
    body: JSON.stringify({ ids }),
  });

export const sendImageToPipeline = (imageId: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/images/invoke-image-optimization`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    credentials: 'include',
    body: JSON.stringify({ imageId }),
  });

export type InsertImagePayload = {
  order?: string;
  position?: string;
  mediaOrigin?: MediaOrigin;
};

export const insertImageOnOrder = (imagePayload: InsertImagePayload): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/images/insert-image-on-order`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    credentials: 'include',
    body: JSON.stringify(imagePayload),
  });
