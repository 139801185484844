export interface IFilterConfigurations {
  [entity: string]: {
    [key: string]: string | number | null;
  };
}

const initialState: IFilterConfigurations = {
  orders: {
    from: null,
    to: null,
    page: 1,
  },
  users: {
    page: 1,
  },
  styleGuides: {
    page: 1,
  },
  tags: {
    page: 1,
  },
  statistics: {
    page: 1,
  },
  angle: {
    page: 1,
  },
};

interface IAction {
  type: string;
  entity: string;
  payload: { [key: string]: string | null };
}

export const ADD_FILTERS = 'ADD_FILTERS';
export const REMOVE_FILTER = 'REMOVE_FIlTERS';

export const selectedFiltersReducer = (
  state: IFilterConfigurations = initialState,
  action: IAction,
): IFilterConfigurations => {
  switch (action.type) {
    // Add customized filters on entity
    case ADD_FILTERS: {
      return {
        ...state,
        [action.entity]: {
          ...state[action.entity],
          ...action.payload,
        },
      };
    }

    // Remove all the filters of the state
    case REMOVE_FILTER: {
      return {
        ...state,
        [action.entity]: {},
      };
    }

    default:
      return {
        ...state,
      };
  }
};
