import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Collapse, List } from '@mui/material';
import Button from '@mui/material/Button';

import { TagEntities } from '../../../constants';
import { getTags } from '../../../setup/api';
import { useFetch } from '../../../setup/hooks/fetch.hook';

import { NestedListItem } from './NestedListItem';

interface Props {
  entityId: string;
  entityType: TagEntities;
}

/**
 *
 * @param tagArray array with the tags to be compared
 * @param newTag  the tag that will be compared
 * @returns {boolean} true/false if the category is already on teh array or not
 */
const isTagCategoryThere = (tagArray: ITag[], newTag: ITag) => {
  let tagsBool = false;

  if (tagArray.some(({ category }: ITag) => category === newTag.category)) {
    tagsBool = true;
  }
  // tagArray.map((tag: ITag) => {
  //   if (tag.category === newTag.category) {
  //     tagsBool = true;
  //   }
  // });

  if (tagsBool) {
    return true;
  }
  return false;
};
/**
 *  Show categories of tags for a specific entity and allow to attach them to the entity itself
 *
 * @param { string  } props.entityId ID of the entity
 * @param { TagEntities } props.entityType type of the entity order | user | image
 *
 */

const TagManager: React.FC<Props> = ({ entityId, entityType }) => {
  const { request } = useFetch();
  const [categories, setCategories] = useState<ITag[]>();
  const [open, setOpen] = useState<boolean>(false);
  const fetchCategories = () => {
    request(getTags, `?entity=${entityType}`).then((tagCategories: any) => {
      if (tagCategories) {
        const uniqueCategories: ITag[] = [];

        tagCategories[0].forEach((tag: ITag) => {
          if (!isTagCategoryThere(uniqueCategories, tag)) {
            uniqueCategories.push(tag);
          }
        });

        setCategories(uniqueCategories);
      }
    });
  };

  useEffect(() => {
    if (open) {
      fetchCategories();
    }
  }, [open]);

  return (
    <div style={{ padding: '10px' }}>
      <Button
        id="tag-manager"
        variant="contained"
        color="primary"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <FormattedMessage id="TAG.ADD.PLURAL" />
      </Button>
      <Collapse in={open}>
        <List
          sx={{
            width: '100%',
            maxWidth: '200px',
            color: 'grey',
            bgcolor: 'white',
            position: 'absolute',
            marginTop: '14px',
            zIndex: '100',
            boxShadow: '0 1px 10px #00000040',
            borderRadius: '5px',
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {categories &&
            categories.map((tag: ITag) => (
              <NestedListItem
                category={tag.category}
                entityType={tag.entity}
                entityId={entityId}
                handleClose={setOpen}
              />
            ))}
        </List>
      </Collapse>
    </div>
  );
};

export { TagManager };
