import danskFlag from './_carswip/assets/svg/flags/da.svg';
import deutchFlag from './_carswip/assets/svg/flags/de.svg';
import dutchFlag from './_carswip/assets/svg/flags/du.svg';
import englishFlag from './_carswip/assets/svg/flags/en.svg';
import spanishFlag from './_carswip/assets/svg/flags/es.svg';
import frenchFlag from './_carswip/assets/svg/flags/fr.svg';
import hindiFlag from './_carswip/assets/svg/flags/hi.svg';
import japaneseFlag from './_carswip/assets/svg/flags/jp.svg';

export enum TagCategory {
  COORDINATOR = 'QA_COORDINATOR',
  EDITOR = 'QA_EDITOR',
  PRIORITY = 'PRIORITY',
  STATUS = 'STATUS',
  IMAGE_ERROR = 'IMAGE_ERROR',
  IMAGE_DECLINED = 'IMAGE_WARNING',
  IMAGE_APPROVED = 'IMAGE_APPROVED',
}

export enum TagEntity {
  IMAGE = 'image',
  ORDER = 'order',
  USER = 'user',
  STYLEGUIDE = 'styleguide',
}

export enum UserRole {
  ADMIN = 'Admin',
  DEALERSHIP = 'Dealership',
  MANAGEMENT = 'Management',
  QA = 'QA',
}

export enum IntegrationType {
  FTP = 'FTP',
  BLOB = 'BLOB',
  REMOTE_INTEGRATION = 'REMOTE_INTEGRATION',
  ROBOT = 'ROBOT',
  API = 'API',
}

export enum OptionType {
  BOOLEAN = 'boolean',
  STRING = 'string',
  DATE = 'date',
  NUMBER = 'number',
}

// TODO: find a country list
export enum Country {
  DK = 'Denmark',
  HU = 'Hungary',
}

export enum UtilityMediaType {
  BACKGROUND = 'background',
  FLOOR = 'floor',
  LOGO = 'logo',
  AVATAR = 'avatar',
  ICON = 'icon',
  FEATURED = 'featured',
  ANGLE_OVERLAY = 'angle_overlay',
  FRAME_OVERLAY = 'frame_overlay',
  OVERLAY = 'overlay',
}

export enum StyleGuideConfigurationType {
  IMAGE = 'IMAGE',
  FEATURED = 'FEATURED',
}

export enum OptimisationMethod {
  // AI PIPELINE
  AI = 'full',
  ENHANCE = 'enhancer',
  BYPASS = 'bypass',
  FEATURE_IMAGE = 'FEATURED',
  AI_NO_ENH = 'ai_no_enh',
  LOGO_ONLY = 'logo_only',
}

export enum OptimisationStatus {
  DONE = 'DONE',
  IN_PROGRESS = 'IN_PROGRESS',
  REJECTED = 'REJECTED',
}

export enum CarswipMediaType {
  // These are single images, typically 'spherical'
  INTERIOR360 = 'interior360',
  // Simple image, part of an exterior 360 collection. Usually 16-30 images
  EXTERIOR360 = 'exterior360',
  VIDEO = 'video',
  PHOTO = 'photo',
}

export enum MediaOrigin {
  ORIGINAL = 'original',
  OPTIMIZED = 'optimized',
}

export enum DealershipSettingOption {
  ANGLE_CORRECTOR = 'ANGLE_CORRECTOR',
  DEFAULT_ZOOM = 'DEFAULT_ZOOM',
}

export enum DashboardType {
  SINGLE_VALUE = 'SINGLE_VALUE',
  MULTI_VALUE = 'MULTI_VALUE',
}

export enum DateTemplate {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  THIS_WEEK = 'THIS_WEEK',
  LAST_WEEK = 'LAST_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  LAST_14_DAYS = 'LAST_14_DAYS',
  LAST_3_MONTHS = 'LAST_3_MONTHS',
  LAST_QUARTER = 'LAST_QUARTER',
  THIS_QUARTER = 'THIS_QUARTER',
  THIS_YEAR = 'THIS_YEAR',
  LAST_YEAR = 'LAST_YEAR',
  ALL_TIME = 'ALL_TIME',
}
export const enum AngleCategory {
  EXTERIOR = 'exterior',
  INTERIOR = 'interior',
}
export const enum TagNames {
  DELETED = 'DELETED',
  EDITED = 'EDITED',
  NEW = 'NEW',
  UPDATED = 'UPDATED',
  PRIORITY = 'PRIORITY',
  PRIORITY_HIGH = 'PRIORITY_HIGH',
  PRIORITY_LOW = 'PRIORITY_LOW',
  PRIORITY_MEDIUM = 'PRIORITY_MEDIUM',
}
export const enum TagEntities {
  ORDER = 'order',
  USER = 'user',
  IMAGE = 'image',
}

export const enum TagColumns {
  ID = 'id',
  NAME = 'name',
  CATEGORY = 'category',
  ENTITY = 'entity',
  COLOR = 'color',
}
export const enum QAEDITORS {
  WILLIAM = 'William',
}

export type TEvent = {
  eventName: string;
  isHidden: boolean;
  isPriority: boolean;
  payload?: string;
  order?: { id: string };
  user?: { id: string };
};
export interface IOrderEvents {
  CUSTOMER_REQUESTED_QA_INSPECTION: TEvent;
  CUSTOMER_UPLOADED_THE_LAST_IMAGE: TEvent;
  CUSTOMER_ADDED_EXTRA_IMAGES: TEvent;
  CUSTOMER_ADDED_EXTRA_VIDEO: TEvent;
  CUSTOMER_CAR_NUMBER_CHANGE: TEvent;
  CUSTOMER_IMAGE_DELETE: TEvent;
  ORDER_DELIVERED: TEvent;
  ORDER_DECLINED: TEvent;
  ORDER_COMPLETED: TEvent;
  PUSH_NOTIFICATION_SENT: TEvent;
  PUSH_NOTIFICATION_SEND_FAILED: TEvent;
  DMS_DELIVERY_STARTED: TEvent;
  DMS_DELIVERY_SUCCESSFUL: TEvent;
  DMS_DELIVERY_NO_CAR_FOUND: TEvent;
  DMS_DELIVERY_NO_INTEGRATION: TEvent;
  DMS_DELIVERY_LOGIN_FAILED: TEvent;
  DMS_DELIVERY_FAILED: TEvent;
  DMS_DELIVERY_FAILED_ROBOT: TEvent;
  DMS_DELIVERY_SUCCESSFUL_ROBOT: TEvent;
  DMS_PARTIAL_DELIVERY: TEvent;
  RE_DELIVERY_REQUEST: TEvent;
  AWAITING_ROBOT_DELIVERY: TEvent;
  FTP_DELIVERY_STARTED: TEvent;
  FTP_DELIVERY_FAILED: TEvent;
  FTP_DELIVERY_SINGLE_IMAGE_FAILED: TEvent;
  FTP_DELIVERY_SUCCESSFUL: TEvent;
  FTP_PARTIAL_DELIVERY: TEvent;
  IMAGE_PIPELINE_ERROR: TEvent;
  IMAGE_PIPELINE_MESSAGE: TEvent;
  IMAGE_DECLINED: TEvent;
  IMAGE_APPROVED: TEvent;
  IMAGE_REPUSHED: TEvent;
  DELETED_PICTURE: TEvent;
  DELETED_ORDER: TEvent;
  QA_EDITOR_ASSIGNED: TEvent;
  ORDER_PLACED: TEvent;
  QA_APPROVED: TEvent;
  QA_DECLINED: TEvent;
  QA_UPLOADED: TEvent;
  ADDED_TAG_TO_ORDER: TEvent;
  ORDER_STATUS_CHANGED: TEvent;
  ORDER_TAG_AUTOMATIC_REMOVE: TEvent;
}

export enum OrderStatus {
  UPLOADING = 'Uploading',
  IN_PROGRESS = 'In Progress',
  CARSWIP_QA = 'Carswip QA',
  DELIVERING = 'Delivering',
  READY_TO_DOWNLOAD = 'Ready to download',
  COMPLETED = 'Completed',
}

export enum OrderQAStatus {
  QA = 'Waiting For QA',
  EDITOR = 'Waiting For Edition',
  APPROVED = 'Approved',
}

export const enum ImageCategory {
  EXTERIOR = 'exterior',
  INTERIOR = 'interior',
}

export interface Action<T, P> {
  readonly type: T;
  readonly payload?: P;
}

export const wideLogo = 'https://carswip-public-files.s3.eu-central-1.amazonaws.com/wide_logo.png';

export enum StyleGuideImageTypes {
  LOGO = 'logo',
  BACKGROUND = 'background',
  FLOOR = 'floor',
  NUMBERPLATE = 'numberplate',
}

export const OrderEvents: IOrderEvents = {
  CUSTOMER_REQUESTED_QA_INSPECTION: {
    eventName: 'CUSTOMER_REQUESTED_QA_INSPECTION',
    isHidden: false,
    isPriority: true,
  },
  CUSTOMER_UPLOADED_THE_LAST_IMAGE: {
    eventName: 'CUSTOMER_UPLOADED_THE_LAST_IMAGE',
    isHidden: false,
    isPriority: true,
  },
  CUSTOMER_ADDED_EXTRA_IMAGES: {
    eventName: 'CUSTOMER_ADDED_EXTRA_IMAGES',
    isHidden: false,
    isPriority: true,
  },
  CUSTOMER_ADDED_EXTRA_VIDEO: {
    eventName: 'CUSTOMER_ADDED_EXTRA_VIDEO',
    isHidden: false,
    isPriority: true,
  },
  CUSTOMER_CAR_NUMBER_CHANGE: {
    eventName: 'CUSTOMER_CAR_NUMBER_CHANGE',
    isHidden: false,
    isPriority: true,
  },
  CUSTOMER_IMAGE_DELETE: {
    eventName: 'CUSTOMER_IMAGE_DELETE',
    isHidden: false,
    isPriority: true,
  },
  ORDER_DECLINED: {
    eventName: 'ORDER_DECLINED',
    isHidden: false,
    isPriority: true,
  },
  IMAGE_DECLINED: {
    eventName: 'IMAGE_DECLINED',
    isHidden: false,
    isPriority: true,
  },
  IMAGE_PIPELINE_ERROR: {
    eventName: 'IMAGE_PIPELINE_ERROR',
    isHidden: false,
    isPriority: true,
  },
  IMAGE_PIPELINE_MESSAGE: {
    eventName: 'IMAGE_PIPELINE_MESSAGE',
    isHidden: false,
    isPriority: true,
  },
  IMAGE_REPUSHED: {
    eventName: 'IMAGE_REPUSHED',
    isHidden: false,
    isPriority: true,
  },
  ORDER_COMPLETED: {
    eventName: 'ORDER_COMPLETED',
    isHidden: false,
    isPriority: true,
  },
  ORDER_DELIVERED: {
    eventName: 'ORDER_DELIVERED',
    isHidden: false,
    isPriority: true,
  },
  IMAGE_APPROVED: {
    eventName: 'IMAGE_APPROVED',
    isHidden: false,
    isPriority: true,
  },
  QA_EDITOR_ASSIGNED: {
    eventName: 'QA_EDITOR_ASSIGNED',
    isHidden: false,
    isPriority: true,
  },
  ORDER_PLACED: {
    eventName: 'ORDER_PLACED',
    isHidden: false,
    isPriority: true,
  },
  QA_APPROVED: {
    eventName: 'QA_APPROVED',
    isHidden: false,
    isPriority: true,
  },
  QA_DECLINED: {
    eventName: 'QA_DECLINED',
    isHidden: false,
    isPriority: true,
  },
  QA_UPLOADED: {
    eventName: 'QA_UPLOADED_PICTURE',
    isHidden: false,
    isPriority: true,
  },
  DMS_DELIVERY_STARTED: {
    eventName: 'DMS_DELIVERY_STARTED',
    isHidden: false,
    isPriority: true,
  },
  DMS_DELIVERY_SUCCESSFUL: {
    eventName: 'DMS_DELIVERY_SUCCESSFUL',
    isHidden: false,
    isPriority: true,
  },
  DMS_DELIVERY_NO_CAR_FOUND: {
    eventName: 'DMS_DELIVERY_NO_CAR_FOUND',
    isHidden: false,
    isPriority: true,
  },
  DMS_DELIVERY_NO_INTEGRATION: {
    eventName: 'DMS_DELIVERY_NO_INTEGRATION',
    isHidden: false,
    isPriority: true,
  },
  DMS_DELIVERY_FAILED: {
    eventName: 'DMS_DELIVERY_FAILED',
    isHidden: false,
    isPriority: true,
  },
  DMS_DELIVERY_FAILED_ROBOT: {
    eventName: 'DMS_DELIVERY_FAILED_ROBOT',
    isHidden: false,
    isPriority: true,
  },
  DMS_DELIVERY_SUCCESSFUL_ROBOT: {
    eventName: 'DMS_DELIVERY_SUCCESSFUL_ROBOT',
    isHidden: false,
    isPriority: true,
  },
  DMS_DELIVERY_LOGIN_FAILED: {
    eventName: 'DMS_DELIVERY_LOGIN_FAILED',
    isHidden: false,
    isPriority: true,
  },
  // Only 1 platform was delivered successfully
  DMS_PARTIAL_DELIVERY: {
    eventName: 'DMS_PARTIAL_DELIVERY',
    isHidden: false,
    isPriority: true,
  },
  AWAITING_ROBOT_DELIVERY: {
    eventName: 'AWAITING_ROBOT_DELIVERY',
    isHidden: false,
    isPriority: true,
  },
  RE_DELIVERY_REQUEST: {
    eventName: 'RE_DELIVERY_REQUEST',
    isHidden: false,
    isPriority: true,
  },
  FTP_DELIVERY_STARTED: {
    eventName: 'FTP_DELIVERY_STARTED',
    isHidden: false,
    isPriority: true,
  },
  FTP_DELIVERY_FAILED: {
    eventName: 'FTP_DELIVERY_FAILED',
    isHidden: false,
    isPriority: true,
  },
  FTP_DELIVERY_SINGLE_IMAGE_FAILED: {
    eventName: 'FTP_DELIVERY_SINGLE_IMAGE_FAILED',
    isHidden: false,
    isPriority: true,
  },
  FTP_DELIVERY_SUCCESSFUL: {
    eventName: 'FTP_DELIVERY_SUCCESSFUL',
    isHidden: false,
    isPriority: true,
  },
  FTP_PARTIAL_DELIVERY: {
    eventName: 'FTP_PARTIAL_DELIVERY',
    isHidden: false,
    isPriority: true,
  },
  PUSH_NOTIFICATION_SENT: {
    eventName: 'PUSH_NOTIFICATION_SENT',
    isHidden: false,
    isPriority: true,
  },
  PUSH_NOTIFICATION_SEND_FAILED: {
    eventName: 'PUSH_NOTIFICATION_SEND_FAILED',
    isHidden: false,
    isPriority: true,
  },
  DELETED_PICTURE: {
    eventName: 'DELETED_PICTURE',
    isHidden: false,
    isPriority: true,
  },
  DELETED_ORDER: {
    eventName: 'DELETED_ORDER',
    isHidden: false,
    isPriority: true,
  },
  ADDED_TAG_TO_ORDER: {
    eventName: 'ADDED_TAG_TO_ORDER',
    isHidden: false,
    isPriority: true,
  },
  ORDER_STATUS_CHANGED: {
    eventName: 'ORDER_STATUS_CHANGED',
    isHidden: false,
    isPriority: true,
  },
  ORDER_TAG_AUTOMATIC_REMOVE: {
    eventName: 'ORDER_TAG_AUTOMATIC_REMOVE',
    isHidden: false,
    isPriority: true,
  },
};

export enum LOCALES {
  ENGLISH = 'en',
  FRENCH = 'fr',
  DEUTSCH = 'de',
  DANISH = 'dk',
  HINDI = 'hi',
  JAPANESE = 'jp',
  DUTCH = 'du',
  SPANISH = 'es',
}

export interface Language {
  name: string;
  code: LOCALES;
  flag: string;
}

export const LANGUAGES: Language[] = [
  { name: 'English', code: LOCALES.ENGLISH, flag: englishFlag },
  { name: 'Français', code: LOCALES.FRENCH, flag: frenchFlag },
  { name: 'Deutsche', code: LOCALES.DEUTSCH, flag: deutchFlag },
  { name: 'Dansk', code: LOCALES.DANISH, flag: danskFlag },
  { name: '日本語', code: LOCALES.JAPANESE, flag: japaneseFlag },
  { name: 'हिंदी', code: LOCALES.HINDI, flag: hindiFlag },
  { name: 'Dutch', code: LOCALES.DUTCH, flag: dutchFlag },
  { name: 'Spanish', code: LOCALES.SPANISH, flag: spanishFlag },
];

export const EMAIL_SUPPORT = 'support@carswip.com';
export const DK_CONTACT = 'DK +45 69 15 48 49';
export const DK_CONTACT_NUMBER = '004569154849';
export const INTL_CONTACT = 'INTL +45 89 87 76 25';
export const INTL_CONTACT_NUMBER = '004589877625';
export const BRAND = 'Carswip';
