import { styled } from '@mui/material';

/**
 *  Grid component user all around the webapp, load information on props.children
 */
export const MainGridContainer = styled('div')(({ theme }) => ({
  backgroundColor: '#FFF',
  padding: '1em 4em',
  borderRadius: theme.shape.borderRadius,
  '& .row': {
    '& .col': {
      '& .col-lg-12': {
        '& .MuiFormControl-root': {
          marginBottom: theme.spacing(2),
        },
      },
    },
  },
}));
