import config from 'react-global-configuration';

import { IAppVersion } from './models/appVersion';

export const getAppVersions = (): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/app-versions`, {
    mode: 'cors',
    credentials: 'include',
  });

export const getAppVersion = (id: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/app-versions/${id}`, {
    mode: 'cors',
    credentials: 'include',
  });
export const modifyAppVersion = (payload: IAppVersion, id: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/app-versions/${id}`, {
    body: JSON.stringify(payload),
    mode: 'cors',
    credentials: 'include',
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const createAppVersion = (payload: IAppVersion): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/app-versions`, {
    body: JSON.stringify(payload),
    mode: 'cors',
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const deleteAppVersion = (id: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/app-versions/${id}`, {
    mode: 'cors',
    credentials: 'include',
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });
