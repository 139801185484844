import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import { RootState } from '../../../setup';
import { getRemoteIntegrationByUserId, reDeliverOrderToIntegration } from '../../../setup/api/remoteIntegration';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { useSimpleFetch } from '../../../setup/hooks/simpleFetch.hook';

export const RemoteIntegrationDropDown: React.FC = () => {
  const intl = useIntl();
  const [openMenu, setOpenMenu] = useState(false);
  const [buttonText, setButtonText] = useState<string>('ORDER.DELIVERY.DROPDOWN.DELIVERY');
  const [integrationOptions, setIntegrationOptions] = useState<RemoteIntegration[]>();
  const [selected, setSelected] = useState<string[]>([]);
  const userId = useSelector((state: RootState) => state?.currentUser?.id);
  const { request, isLoading, status } = useSimpleFetch();
  const fetchData = useFetch();
  const { id } = useParams<{ id: string }>();
  const sendRequest = () => {
    const selectedProviders: RemoteIntegration[] = [];

    integrationOptions &&
      integrationOptions.forEach(integration => {
        if (selected.indexOf(integration.provider) !== -1) {
          return selectedProviders.push(integration);
        }
      });
    request(reDeliverOrderToIntegration, id, { selectedIntegrations: selectedProviders }).then(() => {
      setOpenMenu(!openMenu);
      setSelected([]);
    });
  };
  const getIntegrationsFromUser = (userId: string) => {
    fetchData.request(getRemoteIntegrationByUserId, userId).then((integrationsResponse: RemoteIntegration[]) => {
      setIntegrationOptions(integrationsResponse);
    });
  };
  const handleChange = (event: SelectChangeEvent<typeof selected>) => {
    const {
      target: { value },
    } = event;

    setSelected(typeof value === 'string' ? value.split(',') : value);
  };

  useEffect(() => {
    if (status === 'secondary') {
      setButtonText('ORDER.DELIVERY.DROPDOWN.REQUEST.FAILED');
    }
    if (status === 'error') {
      setButtonText('ORDER.DELIVERY.DROPDOWN.DELIVERY.FAILED');
    }
    if (status === 'success') {
      setButtonText('ORDER.DELIVERY.DROPDOWN.DELIVERED');
    }
  }, [status]);
  useEffect(() => {
    if (userId && openMenu) {
      getIntegrationsFromUser(userId);
    }
  }, [openMenu]);

  return (
    <div style={{ padding: '10px' }}>
      {!isLoading ? (
        <Button
          color={status || 'info'}
          variant="contained"
          disabled={status === 'secondary'}
          className="ml-5"
          onClick={() => setOpenMenu(!openMenu)}
        >
          <FormattedMessage id={buttonText} />
        </Button>
      ) : (
        <Box sx={{ '& > button': { m: 1 }, display: 'block' }}>
          <LoadingButton loading color="primary" variant="outlined" loadingPosition="end" endIcon={<SendIcon />}>
            <FormattedMessage id="ORDER.DELIVERY.DELIVERING" values={{ order: selected }} />
          </LoadingButton>
        </Box>
      )}
      <div
        className={`menu menu-sub menu-sub-dropdown w-250px w-md-300px ${openMenu ? ' show' : ''}`}
        style={{ position: 'absolute', right: '0.25rem' }}
      >
        <div className="px-7 py-5">
          <div>
            <div>
              <FormControl
                sx={{
                  display: 'flex',
                  width: '100%',
                }}
              >
                <InputLabel>
                  <FormattedMessage id="PERMISSIONS.HEADER.INTEGRATIONS" />
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selected}
                  onChange={handleChange}
                  input={<OutlinedInput label={intl.formatMessage({ id: 'PERMISSIONS.HEADER.INTEGRATIONS' })} />}
                  renderValue={selected => selected.join(', ')}
                >
                  {integrationOptions &&
                    integrationOptions.map(integration => (
                      <MenuItem key={integration.id} value={integration.provider}>
                        <Checkbox checked={selected.indexOf(integration.provider) > -1} />
                        <ListItemText primary={integration.provider} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <div className="row mt-5 justify-content-center">
                <div className="d-flex justify-content-between">
                  <Button
                    color={selected.length < 1 ? 'secondary' : 'success'}
                    disabled={selected.length < 1 && true}
                    variant="contained"
                    onClick={() => {
                      sendRequest();
                    }}
                  >
                    <FormattedMessage id="ORDER.DELIVERY.DELIVER" />
                  </Button>
                  <Button color="primary" variant="text" onClick={() => setOpenMenu(!openMenu)}>
                    <FormattedMessage id="ACTION.CLOSE" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
