import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { PageTitle } from '../../../_carswip/layout/core';
import { createRemoteIntegration, getRemoteIntegrationByUserId } from '../../../setup/api/remoteIntegration';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { MainGridContainer } from '../../../ui/components/MainGridContainer/MainGridContainer';
import { IntegrationProviderRow } from '../../components/remoteIngration/IntegrationProviderRow';

import { DialogSelect } from './dialogSelect';

/**
 * Remote Integration page fetch the user integrations by params.id renders {@link IntegrationProvider}
 */
const RemoteIntegrationPage: FC = () => {
  const { id } = useParams<TParams>();
  const { request } = useFetch();

  const [integrations, setIntegrations] = useState<RemoteIntegration[]>([]);

  const getUserIntegrations = () => {
    request(getRemoteIntegrationByUserId, id).then(result => {
      setIntegrations(
        result.sort((a: RemoteIntegration, b: RemoteIntegration) => a.provider.localeCompare(b.provider)),
      );
    });
  };

  useEffect(() => {
    getUserIntegrations();
  }, []);

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <MainGridContainer>
          <div className="flex-row-fluid py-lg-5 px-lg-15">
            {integrations.map(integration => (
              <IntegrationProviderRow
                integration={integration}
                handleUpdate={(integration: RemoteIntegration) => {
                  const changed = integrations.map(x => {
                    if (x.id === integration.id) {
                      return integration;
                    }
                    return x;
                  });

                  setIntegrations(changed);
                }}
                handleRemove={(id: string) => {
                  const changed = integrations.filter(int => int.id !== id);

                  setIntegrations(changed);
                  getUserIntegrations();
                }}
              />
            ))}
          </div>
          <DialogSelect
            currentIntegrations={integrations.map(integration => integration.provider)}
            addNewIntegration={(integrationProviderName: string) => {
              request(createRemoteIntegration, {
                provider: integrationProviderName,
                isActive: false,
                userId: id,
                Username: '',
                Password: '',
              }).then(res => {
                if (res) {
                  getUserIntegrations();
                }
              });
            }}
          />
        </MainGridContainer>
      </div>
    </>
  );
};

const RemoteIntegrationWrapper: FC = () => (
  <>
    <PageTitle breadcrumbs={[]}>
      <FormattedMessage id="PERMISSIONS.HEADER.INTEGRATIONS" />
    </PageTitle>
    <RemoteIntegrationPage />
  </>
);

export { RemoteIntegrationWrapper };
