import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import './ClipBoardText.css';

type Props = {
  textToClipBoard: string;
  textToDisplay: string;
};

const enum CopyStatus {
  COPIED = 'COPIED',
  NOT_COPIED = 'COPY',
}
/**
 *  This component is meant to wrapped up a text, once that text is clicked it will be saved to the clipboard
 * @param props.textToClipBoard the text that will be saved on the clipboard
 * @param props.textToDisplay the text that will be displayed
 * @returns saved on the clipboard the text that is clicked by the user
 */
const ClipBoardText: React.FC<Props> = ({ textToClipBoard, textToDisplay }) => {
  const [copied, setCopied] = useState(false);
  const ref = React.createRef<HTMLDivElement>();
  const [coordinates, setCoordinates] = useState({
    position: 'absolute',
    top: '0px',
    left: '0px',
  });
  const handleMouseMove = (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
    if (ref && ref.current) {
      const style = ref?.current.style;
      const clickX = event.clientX;
      const clickY = event.clientY;
      const screenW = window.innerWidth;
      const screenH = window.innerHeight;
      const rootW = ref.current.offsetWidth;
      const rootH = ref.current.offsetHeight;
      const right = screenW - clickX > rootW;
      const left = !right;
      const top = screenH - clickY > rootH;
      const bottom = !top;

      if (right) {
        style.left = `${clickX + 5}px`;
      }

      if (left) {
        style.left = `${clickX - rootW - 5}px`;
      }

      if (top) {
        style.top = `${clickY + 5}px`;
      }

      if (bottom) {
        style.top = `${clickY - rootH - 5}px`;
      }

      setCoordinates(style);
    }
  };

  return (
    <p
      onClick={() => {
        navigator.clipboard.writeText(textToClipBoard);
        setCopied(true);
      }}
      onMouseEnter={() => {
        setCopied(false);
      }}
      onMouseMove={event => handleMouseMove(event)}
      className="clipboard-text"
      style={{
        textOverflow: 'ellipsis',
        width: '180px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      {textToDisplay}
      <span
        ref={ref}
        style={{
          position: 'fixed',
          top: coordinates.top,
          left: coordinates.left,
        }}
        className="hover-text"
      >
        <FormattedMessage id={copied ? CopyStatus.COPIED : CopyStatus.NOT_COPIED} />
      </span>
    </p>
  );
};

export { ClipBoardText };
