import { FC } from 'react';
import { ImageListType } from 'react-images-uploading';
import DeleteIcon from '@mui/icons-material/Delete';
import { Container, styled } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

type LoadedImagesRowProps = {
  images: ImageListType;
  onImageRemove: (index: number) => void;
};

const ImageLoaded = styled('img')(() => ({
  width: '15em',
  height: '10em',
  marginLeft: '1em',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  minWidth: 0,
  wordWrap: 'break-word',
  backgroundColor: '#fff',
  backgroundClip: 'border-box',
  border: '1px solid rgba(0, 0, 0, 0.125)',
  borderRadius: '10px',
}));

/**
 * @param props.images - The list of images to be displayed
 * @param props.onImageRemove - The function to remove an image from the list
 * @returns Renders a row of images loaded on the drag and drop zone
 */
export const LoadedImagesRow: FC<LoadedImagesRowProps> = ({ images, onImageRemove }) =>
  images?.length > 0 ? (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        {images.map(
          (image, index) =>
            image && (
              <Grid
                item
                xs={12}
                md={3}
                key={index}
                style={{ position: 'relative', paddingTop: '0px', marginTop: '2em' }}
              >
                <ImageLoaded src={image?.data_url} />
                <Button
                  variant="contained"
                  color="error"
                  style={{ paddingRight: '4px', position: 'absolute', top: 0, right: '-3%' }}
                  startIcon={<DeleteIcon />}
                  onClick={() => onImageRemove(index)}
                />
              </Grid>
            ),
        )}
      </Grid>
    </Container>
  ) : null;
