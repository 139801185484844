import { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import TextField from '@mui/material/TextField';
import _ from 'lodash';

import { useQueryParams } from '../../../setup/hooks/useQueryParams';

import { getQueryOrEmptyString } from './helpers';

export const SearchFilter: FC = () => {
  const intl = useIntl();
  const [queries, setSearchQuery] = useQueryParams();
  const { search } = queries;
  const [value, setValue] = useState<string>();
  // Debounced search query callback
  const delayedQuery = useCallback(
    _.debounce(textInput => {
      setSearchQuery('search', textInput);
    }, 500),
    [queries],
  );

  // Set the input value to the search query on mount
  useEffect(() => {
    setValue(search);
  }, []);

  // ComponentDidUpdate, only refresh when it cleans the query
  useEffect(() => {
    if (!search) {
      setValue(search);
    }
  }, [search]);

  return (
    <TextField
      label={intl.formatMessage({ id: 'DATA.SEARCH' })}
      variant="outlined"
      value={getQueryOrEmptyString(value)}
      onChange={e => {
        setValue(e.target.value);
        delayedQuery(e.target.value);
      }}
    />
  );
};
