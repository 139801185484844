import { ComponentType } from 'react';

import { CreateAccountWrapper } from '../pages/account/CreateAccountWrapper';
import { CustomersWrapper } from '../pages/account/CustomersPage';
import { AdminWrapper } from '../pages/admin/AdminPage';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import { EditingWrapper } from '../pages/editing/EditingPage';
import { CreateOrderPage } from '../pages/orders/CreateOrderPage';
import { OrderDetailPageWrapper } from '../pages/orders/orderDetails/OrderAdminView';
import { OrdersWrapper } from '../pages/orders/OrdersPage';
import { ProfilePage } from '../pages/profile/ProfilePage';
import { SalesMenWrapper } from '../pages/salesmen/SalesMenPage';
import { CreateStyleGuidePageWrapper2 } from '../pages/styleGuidesV2/CreateStyleguidePage';

export const privateRoutes: [string, ComponentType<unknown>][] = [
  ['/dashboard', DashboardWrapper],
  ['/orders', OrdersWrapper],
  ['/salesmen', SalesMenWrapper],
  ['/order/:id', OrderDetailPageWrapper],
  ['/create-order', CreateOrderPage],
  ['/account', CreateAccountWrapper],
  ['/editor-list', EditingWrapper],
];

export const adminRoutes: [string, React.ComponentType<unknown>][] = [
  ['/customer/:id', CreateAccountWrapper],
  ['/create-account', CreateAccountWrapper],
  ['/style-guide-v2/:styleGuideId', CreateStyleGuidePageWrapper2],
  ['/create-style-guide-v2', CreateStyleGuidePageWrapper2],
  ['/profile/:id', ProfilePage],
  ['/editing-page', EditingWrapper],
  ['/admin', AdminWrapper],
  ['/customers', CustomersWrapper],
];

export const QARoutes: [string, React.ComponentType<unknown>][] = [
  ['/customer/:id', CreateAccountWrapper],
  ['/profile/:id', ProfilePage],
  ['/editing-page', EditingWrapper],
  ['/admin', AdminWrapper],
  ['/customers', CustomersWrapper],
];
