export enum Permissions {
    CREATE_ORDERS = `CREATE_ORDERS`,
    READ_ORDERS = `READ_ORDERS`,
    UPDATE_ORDERS = `UPDATE_ORDERS`,
    DELETE_ORDERS = `DELETE_ORDERS`,

    CREATE_USERS = `CREATE_USERS`,
    READ_USERS = `READ_USERS`,
    UPDATE_USERS = `UPDATE_USERS`,
    DELETE_USERS = `DELETE_USERS`,

    CREATE_SALESMAN = `CREATE_SALESMAN`,
    READ_SALESMAN = `READ_SALESMAN`,
    UPDATE_SALESMAN = `UPDATE_SALESMAN`,
    DELETE_SALESMAN = `DELETE_SALESMAN`,

    CREATE_STYLE_GUIDES = `CREATE_STYLE_GUIDES`,
    READ_STYLE_GUIDES = `READ_STYLE_GUIDES`,
    UPDATE_STYLE_GUIDES = `UPDATE_STYLE_GUIDES`,
    DELETE_STYLE_GUIDES = `DELETE_STYLE_GUIDES`,

    CREATE_CAR_TYPES = `CREATE_CAR_TYPES`,
    READ_CAR_TYPES = `READ_CAR_TYPES`,
    UPDATE_CAR_TYPES = `UPDATE_CAR_TYPES`,
    DELETE_CAR_TYPES = `DELETE_CAR_TYPES`,

    CREATE_ANGLES = `CREATE_ANGLES`,
    READ_ANGLES = `READ_ANGLES`,
    UPDATE_ANGLES = `UPDATE_ANGLES`,
    DELETE_ANGLES = `DELETE_ANGLES`,

    CREATE_APP_VERSIONS = `CREATE_APP_VERSIONS`,
    READ_APP_VERSIONS = `READ_APP_VERSIONS`,
    UPDATE_APP_VERSIONS = `UPDATE_APP_VERSIONS`,
    DELETE_APP_VERSIONS = `DELETE_APP_VERSIONS`,

    CREATE_TAGS = `CREATE_TAGS`,
    READ_TAGS = `READ_TAGS`,
    UPDATE_TAGS = `UPDATE_TAGS`,
    DELETE_TAGS = `DELETE_TAGS`,

    CREATE_BANNERS = `CREATE_BANNERS`,
    READ_BANNERS = `READ_BANNERS`,
    UPDATE_BANNERS = `UPDATE_BANNERS`,
    DELETE_BANNERS = `DELETE_BANNERS`,

    // READ SPECIFIC
    READ_QA_COORDINATOR_LIST = `READ_QA_COORDINATOR_LIST`,
    READ_QA_EDITOR_LIST = `READ_QA_EDITOR_LIST`,

    READ_ADMIN_STATISTICS = `READ_ADMIN_STATISTICS`,

    READ_USER_EVENTS = `READ_USER_EVENTS`,
    READ_USER_STATISTICS = `READ_USER_STATISTICS`,
    READ_USER_SETTINGS = `READ_USER_SETTINGS`,
    READ_USER_INTEGRATIONS = `READ_USER_INTEGRATIONS`,

    // UPDATE SPECIFIC
    UPDATE_USER_INTEGRATIONS = `UPDATE_USER_INTEGRATIONS`,
    UPDATE_USER_INTEGRATION_CREDENTIALS = `UPDATE_USER_INTEGRATION_CREDENTIALS`,
    UPDATE_WEB_APPLICATION_SETTINGS = `UPDATE_WEB_APPLICATION_SETTINGS`,

    READ_QUEUE_MESSAGES = `READ_QUEUE_MESSAGES`,
    UPDATE_QUEUE_MESSAGES = `UPDATE_QUEUE_MESSAGES`,
    DELETE_QUEUE_MESSAGES = `DELETE_QUEUE_MESSAGES`,
}