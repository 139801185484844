import React from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { isValidEmail } from '../../../setup/helpers/emailValidator';
import { isValidPhoneNumber } from '../../../setup/helpers/phoneNumberValidator';

type IProps = {
  handleSubmit: (values: any, event: any) => void;
  initialData: IFormValues;
  button?: 'ACTION.CREATE' | 'ACTION.UPDATE' | null;
};
interface IFormValues {
  name: string | null;
  email: string | null;
  phone: number | null;
  carswipUser?: string | null;
}

const salesManSchema = (intl: IntlShape) =>
  Yup.object({
    name: Yup.string().required().label('name'),
    email: Yup.string()
      .required(
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.REQUIRED' },
          { name: intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' }) },
        ),
      )
      .label('email')
      .email(
        intl.formatMessage({ id: 'AUTH.VALIDATION.INVALID' }, { name: intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' }) }),
      ),
    phone: Yup.string()
      .required(
        intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED' }, { name: intl.formatMessage({ id: 'ACCOUNT.PHONE' }) }),
      )
      .label('phone'),
  });

const SalesManForm: React.FC<IProps> = ({ handleSubmit, initialData, button }) => {
  const intl = useIntl();

  return (
    <Formik
      validationSchema={salesManSchema(intl)}
      initialValues={initialData}
      validateOnMount
      validateOnChange
      onSubmit={handleSubmit}
    >
      {(props: FormikProps<IFormValues>) => (
        <Form className="form" noValidate>
          <div className="w-100">
            <div className="fv-row mb-10 ">
              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                <span className="required">
                  <FormattedMessage id="SALESMAN.NAME" />:
                </span>
              </label>

              <Field
                type="text"
                className={`form-control form-control-lg userInputFields ${
                  props.touched.name && props.errors.name ? 'is-invalid' : 'form-control-solid'
                }`}
                name="name"
                placeholder={intl.formatMessage({ id: 'SALESMAN.NAME' })}
              />
              <div style={{ color: 'red', marginTop: '-10px' }}>
                <ErrorMessage name="name" />
              </div>
            </div>
            <div className="fv-row d-flex align-content-center align-items-center  mb-10 ">
              <div className="col-4">
                <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                  <span className="required">
                    <FormattedMessage id="SALESMAN.PHONE" />:
                  </span>
                </label>
                <Field
                  type="number"
                  className={`form-control form-control-lg userInputFields ${
                    props.touched.phone && props.errors.phone && isValidPhoneNumber(props.values.phone)
                      ? 'is-invalid'
                      : 'form-control-solid'
                  }`}
                  name="phone"
                  placeholder={intl.formatMessage({ id: 'SALESMAN.PHONE' })}
                />
                <div style={{ color: 'red', marginTop: '-10px' }}>
                  <ErrorMessage name="phone" />
                </div>
              </div>
              <div className="offset-2 col-6">
                <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                  <span className="required">
                    <FormattedMessage id="SALESMAN.EMAIL" />:
                  </span>
                </label>
                <Field
                  type="email"
                  className={`form-control form-control-lg userInputFields ${
                    props.touched.email && props.errors.email && isValidEmail(props.values.email)
                      ? 'is-invalid'
                      : 'form-control-solid'
                  }`}
                  name="email"
                  normalize={(value: string) => value.trim()}
                  placeholder={intl.formatMessage({ id: 'SALESMAN.EMAIL' })}
                />
                <div style={{ color: 'red', marginTop: '-10px' }}>
                  <ErrorMessage name="email" />
                </div>
              </div>
            </div>
          </div>
          {button && (
            <div className="d-flex justify-content-end flex-stack pt-10">
              <button
                type="submit"
                className="btn btn-lg btn-primary d-flex"
                disabled={!isValidEmail(props.values.email) || !isValidPhoneNumber(props.values.phone)}
              >
                <span className="indicator-label">
                  <FormattedMessage id={button} />
                </span>
                <AddCircleIcon />
              </button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export { SalesManForm };
