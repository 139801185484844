import { createContext, FC, useContext } from 'react';
import { IntlProvider } from 'react-intl';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { da, de, enAU, es, fr, hi, ja } from 'date-fns/locale';

import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';

import deMessages from './messages/de.json';
import dkMessages from './messages/dk.json';
import duMessages from './messages/du.json';
import enMessages from './messages/en.json';
import esMessages from './messages/es.json';
import frMessages from './messages/fr.json';
import hiMessages from './messages/hi.json';
import jpMessages from './messages/jp.json';

export const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig';

type TLanguajes = 'en' | 'dk' | 'jp' | 'fr' | 'hi' | 'de' | 'du' | 'es';
type Props = {
  selectedLang: TLanguajes;
};

const initialState: Props = {
  selectedLang: 'en',
} as Props;

export function getLocalStorageLanguage() {
  return JSON.parse(localStorage.getItem(I18N_CONFIG_KEY) as string)?.selectedLang;
}

export function currentLanguageIsTheSame(lang: string) {
  return getLocalStorageLanguage() === lang;
}

const allMessages = {
  en: enMessages,
  dk: dkMessages,
  jp: jpMessages,
  de: deMessages,
  fr: frMessages,
  hi: hiMessages,
  du: duMessages,
  es: esMessages,
};

// Side effect
export function setLanguage(lang: string): void {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }));
  window.location.reload();
}

const I18nProvider: FC = ({ children }) => {
  const I18nContext = createContext<Props>(initialState);
  const locale: TLanguajes = getLocalStorageLanguage() || useContext(I18nContext).selectedLang;
  const messages = allMessages[locale];

  const localeToAdapterLocale: Record<string, Locale> = {
    en: enAU,
    de,
    jp: ja,
    dk: da,
    fr,
    hi,
    du: de,
    es,
  };

  return (
    <IntlProvider locale={locale} messages={messages}>
      <LocalizationProvider adapterLocale={localeToAdapterLocale[locale]} dateAdapter={AdapterDateFns}>
        {children}
      </LocalizationProvider>
    </IntlProvider>
  );
};

export { I18nProvider };
