import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { FormControlLabel, FormGroup, Radio, RadioGroup } from '@mui/material';

import './SingleOrderPage.css';

const imageTypes = ['Original', 'Optimized', 'AI', 'Enhanced', 'Bypassed', 'Featured'];
/**
 * Renders a component with all the image types, selects them on click and sends them to the redux state {state.images}
 */
const ImageCheckbox: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [checked, setChecked] = React.useState<string | null>(null);

  useEffect(() => {
    dispatch({ type: 'IMAGE_SELECTOR', payload: checked });
  }, [checked]);

  return (
    <FormGroup>
      <div className="container-fluid selectors">
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue=""
          value={checked}
          name="radio-buttons-group"
          className="row"
        >
          <div className="row">
            {imageTypes.map((type: string) => (
              <div className="col-6">
                <FormControlLabel
                  onClick={() => {
                    setChecked(checked === type ? null : type);
                  }}
                  value={type}
                  control={<Radio />}
                  label={intl.formatMessage({
                    id: `ORDER.IMAGE.TYPE.${type.toUpperCase()}`,
                  })}
                />
              </div>
            ))}
          </div>
        </RadioGroup>
      </div>
    </FormGroup>
  );
};

export default ImageCheckbox;
