import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { MainGridContainer } from '../../../ui/components/MainGridContainer/MainGridContainer';
import { ModalCarswip } from '../../../ui/components/Modal';
import { ModalSubtitle } from '../../../ui/components/Title/ModalSubtitle';
import { ModalTitleHeading } from '../../../ui/components/Title/ModalTitleHeading';

export const OrderSuccessModal: FC<{ showOrderSuccessModal: boolean }> = ({ showOrderSuccessModal }) => {
  const intl = useIntl();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(showOrderSuccessModal);
  const modalToggle = () => {
    setIsOpen(false);
    history.replace('/orders');
  };

  return (
    <ModalCarswip open={isOpen} onClose={modalToggle} title={intl.formatMessage({ id: 'ORDER.SUCCESS.TITLE' })}>
      <MainGridContainer>
        <ModalTitleHeading>
          <FormattedMessage id="ORDER.SUCCESS.HEADER" />
        </ModalTitleHeading>
        <ModalSubtitle>
          <FormattedMessage id="ORDER.SUCCESS.SUBTITLE" values={{ br: <br /> }} />
        </ModalSubtitle>
      </MainGridContainer>
    </ModalCarswip>
  );
};
