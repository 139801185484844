import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { updateImageApproval } from '../../../../setup/api/images';
import { useFetch } from '../../../../setup/hooks/fetch.hook';

type Props = {
  optimizedImageId: string;
  callback: () => void;
};

export const CancelEdition: FC<Props> = ({ optimizedImageId, callback }) => {
  const { request } = useFetch();
  const changeImageApprovalStatus = (imageId: string, payload: { approved: boolean }) => {
    request(updateImageApproval, imageId, payload).then(() => callback());
  };

  return (
    <div className="editor-button-group" style={{ alignItems: 'end' }}>
      <button
        type="button"
        className="btn btn-success"
        onClick={() => {
          changeImageApprovalStatus(optimizedImageId, { approved: true });
        }}
      >
        <FormattedMessage id="ORDER.EDITOR.CANCEL" />
      </button>
    </div>
  );
};
