import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button, Grid, TextField } from '@mui/material';
import { Form, FormikProps } from 'formik';

import { ForgotPasswordProps } from '../../pages/auth/interfaces';

export const RequestPasswordRecoveryForm: FC<FormikProps<ForgotPasswordProps>> = ({
  getFieldProps,
  touched,
  errors,
  isValid,
}) => {
  const intl = useIntl();

  return (
    <Form className="form" noValidate>
      <span className="authentication-title">
        <FormattedMessage id="AUTH.FORGOT.TITLE" />
      </span>
      <div className="text-gray-400 fw-bold fs-5 mb-10">
        <FormattedMessage id="AUTH.FORGOT.DESC" />
      </div>
      <Grid item xs={12} style={{ width: '100%' }}>
        <TextField
          type="email"
          placeholder={intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' })}
          error={touched.email && Boolean(errors.email)}
          helperText={touched.email && errors.email}
          {...getFieldProps('email')}
        />
      </Grid>
      <Grid item xs={12} style={{ width: '100%' }}>
        <Button
          type="submit"
          className="mb-5"
          disabled={!isValid}
          children={<FormattedMessage id="AUTH.FORGOT.SEND_CODE" />}
        />
        <Link to="/auth/login" style={{ color: 'inherit', textDecoration: 'none', width: 'inherit' }}>
          <Button type="button" color="info" children={<FormattedMessage id="AUTH.FORGOT.BACK" />} />
        </Link>
      </Grid>
    </Form>
  );
};
