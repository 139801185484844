import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { addNewFilters } from '../../../setup/redux/actions';

interface DropdownProps {
  options: string[];
  title: string;
  chartName: string;
}

/**
 * Renders a Dropdown that once selected aa value between options will send be send to ReduxState
 * @param { string } props.title the title of the dropdown options
 * @param { string[] } props.options options that can be selected, in order to send to redux
 */
export const StatisticsDropdown: React.FC<DropdownProps> = ({ title, options, chartName }) => {
  const intl = useIntl();
  const [selected, setSelected] = useState(options[0]);
  const [openMenu, setOpenMenu] = useState(false);
  const dispatch = useDispatch();
  const handleChange = (event: SelectChangeEvent) => {
    setSelected(event.target.value);
  };

  const DropdownTimeOptions: Record<string, string> = {
    month: intl.formatMessage({ id: 'STATS.MONTH' }),
    day: intl.formatMessage({ id: 'STATS.DAY' }),
    week: intl.formatMessage({ id: 'STATS.WEEK' }),
  };

  useEffect(() => {
    dispatch(
      addNewFilters(
        {
          [chartName]: {
            from: '',
            to: '',
            timePeriod: selected,
          },
        },
        'statistics',
      ),
    );
  }, [selected]);

  return (
    <>
      <button
        type="button"
        className="btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color- border-0 me-n3"
        onClick={() => setOpenMenu(!openMenu)}
      >
        <MenuIcon color="info" />
      </button>
      <div
        className={`menu menu-sub menu-sub-dropdown w-250px w-md-300px ${openMenu ? ' show' : ''}`}
        style={{
          top: '5em',
          right: '0em',
          marginLeft: 'auto',
          position: 'absolute',
        }}
      >
        <div className="px-7 py-5">
          <div className="fs-5 text-dark fw-bolder">
            <FormattedMessage id="STATS.FILTER" values={{ title }} />
          </div>
        </div>
        <div className="px-7 py-5">
          <div className="mb-10">
            <label className="form-label fw-bold">
              <FormattedMessage id="STATS.STATUS" />
            </label>
            <div>
              <Select value={selected} label="title" onChange={handleChange}>
                {options &&
                  options.map((option: string) => <MenuItem value={option}>{DropdownTimeOptions[option]}</MenuItem>)}
              </Select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
