import { useEffect, useState } from 'react';

import { usePrevious } from './usePrevious';

export enum ExpiryOptions {
  day = 'day',
  month = 'month',
}

/**
 * Creates a expiration date in the format YYYY/MM/DD
 * @param expires
 * @returns the expiration date in the format YYYY/MM/DD
 */
function getExpirationDate(expires?: ExpiryOptions): string | null {
  if (!expires) {
    return null;
  }

  const currentDate = new Date();
  const expirationDate = new Date();

  if (expires === ExpiryOptions.day) {
    expirationDate.setDate(currentDate.getDate() + 1);
  } else if (expires === ExpiryOptions.month) {
    expirationDate.setMonth(currentDate.getMonth() + 1);
  }

  const year = expirationDate.getUTCFullYear();
  const month = expirationDate.getUTCMonth() + 1;
  const day = expirationDate.getUTCDate();

  const expirationDateString = `${year}/${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}`;

  return expirationDateString;
}

/**
 * This hook creates a setState function that will store the value in localStorage, and adds a expiration date on the value if needed.
 * @param key key to point at the value in localStorage
 * @param defaultValue default value to return if the value is not found in localStorage
 * @param expires if true, the value will be stored with an expiration date of 1 day
 * @returns [state, setState] where value is the value from localStorage or the default value, and setState is a function that will update the value in localStorage
 */
export function useLocalStorage<T>(
  key: LocalStorageKey | string,
  expires?: ExpiryOptions,
): [T | undefined, (value: T) => void] {
  const handleStateIfEmpty = () => {
    const storedValue = localStorage.getItem(key);

    if (storedValue !== null) {
      const data = JSON.parse(storedValue);

      return data as T;
    }
    return undefined;
  };
  const [state, setState] = useState<T | undefined>(handleStateIfEmpty());

  const prevState = usePrevious(state);
  const prevKey = usePrevious(key);

  useEffect(() => {
    if (state) {
      const expirationDate = getExpirationDate(expires);
      const valueToStore = {
        ...state,
        ...(expirationDate ? { expires: expirationDate } : {}),
      };

      if (prevState !== state) {
        localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    }
  }, [state, expires]);

  useEffect(() => {
    if (key !== prevKey) {
      setState(handleStateIfEmpty());
    }
  }, [key]);

  return [state, setState];
}

/**
 * This function checks if the expiration date is in the past. if the value is expired it should refresh the value on the state
 * @param expirationDate expiration date in the format YYYY/MM/DD
 * @returns true if the expiration date is in the past, false otherwise
 */
export function isLocalDataExpired(expirationDate?: string): boolean {
  if (!expirationDate) {
    return true;
  }

  const today = new Date();
  const expirationDateObj = new Date(Date.parse(expirationDate));

  return today > expirationDateObj;
}

export enum LocalStorageKey {
  REPUSHED_IMAGES_30_DAYS_STATISTICS = 'repushedImages30DaysStatistics',
  EDITED_IMAGES_30_DAYS_STATISTICS = 'editedImages30DaysStatistics',
  REPUSHED_IMAGE_RATIO_PER_ORDER_LAST_MONTH = 'repushedImageRatioPerOrderLastMonth',
  EDITED_IMAGE_RATIO_PER_ORDER_LAST_MONTH = 'editedImageRatioPerOrderLastMonth',
  FLAWLESS_ORDERS_STATISTICS = 'flawlessOrdersStatistics',
  I18N_CONFIG_KEY = 'i18nConfig',
}
