import { Action } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';

import { UserModel } from '../../../app/pages/auth/interfaces';
import { IActionReturn } from '../actions';

export interface ActionWithPayload<T> extends Action {
  entity: unknown;
  payload?: T;
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  ShowError: '[Show Error] Action',
};

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  loginError: false,
};

export interface IAuthState {
  user?: UserModel;
  loginError?: boolean;
  accessToken?: string;
}
// Save user information on browser storage using redux && redux persist
export const reducer = persistReducer(
  { storage, key: 'v100-demo1-auth', whitelist: ['user', 'accessToken'] },
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken;

        return { accessToken, user: undefined };
      }

      case actionTypes.Register: {
        const accessToken = action.payload?.accessToken;

        return { accessToken, user: undefined };
      }

      case actionTypes.ShowError: {
        const showError = action.payload?.loginError;

        return { ...state, loginError: showError };
      }

      case actionTypes.Logout: {
        return { ...initialAuthState, loginError: true };
      }

      case actionTypes.UserRequested: {
        return { ...state, user: undefined };
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user;

        return { ...state, loginError: false, user };
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user;

        return { ...state, user };
      }

      default:
        return state;
    }
  },
);

export const actions = {
  login: (accessToken: string): IActionReturn<{ accessToken: string }> => ({
    type: actionTypes.Login,
    payload: { accessToken },
  }),
  register: (accessToken: string): IActionReturn<{ accessToken: string }> => ({
    type: actionTypes.Register,
    payload: { accessToken },
  }),
  // eslint-disable-next-line @typescript-eslint/ban-types
  logout: (): IActionReturn<{}> => ({ type: actionTypes.Logout, payload: {} }),
  requestUser: (): { type: string } => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: UserModel): IActionReturn<{ user: UserModel }> => ({
    type: actionTypes.UserLoaded,
    payload: { user },
  }),
  setUser: (user: UserModel): IActionReturn<{ user: UserModel }> => ({ type: actionTypes.SetUser, payload: { user } }),
  showError: (loginError: boolean): IActionReturn<{ loginError: boolean }> => ({
    type: actionTypes.ShowError,
    payload: { loginError },
  }),
};

export function* saga(): unknown {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });
}
