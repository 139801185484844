import config from 'react-global-configuration';

export const singUpUser = (userData: IUser): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/signup`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userData),
    mode: 'cors',
    credentials: 'include',
  });

export const getMe = (): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/me`, {
    mode: 'cors',
    credentials: 'include',
  });

export const modifyUser = (userId: string, userData: IUser): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/users/${userId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userData),
    mode: 'cors',
    credentials: 'include',
  });
