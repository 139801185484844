import config from 'react-global-configuration';

import { IBanner } from './models/banner';
import { generateRequestOptions } from './helpers';

export const getBanners = (abort: AbortSignal): Promise<Response> => {
  const url = `${config.get('ApiUrl').Rest}/banner`;

  return fetch(url, generateRequestOptions('GET', abort));
};

export const getBanner = (id: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/banner/by-id/${id}`, {
    mode: 'cors',
    credentials: 'include',
  });

export const createBanner = (bannerData: IBanner): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/banner`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(bannerData),
    mode: 'cors',
    credentials: 'include',
  });

export const modifyBanner = (bannerId: string, bannerData: IBanner): Promise<Response> =>
  fetch(`${config.get(`ApiUrl`).Rest}/banner/${bannerId}`, {
    method: `PUT`,
    headers: { 'Content-Type': `application/json` },
    body: JSON.stringify(bannerData),
    mode: `cors`,
    credentials: `include`,
  });
