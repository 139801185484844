import { Dispatch, FC, Fragment, SetStateAction, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { ThemeProvider } from '@mui/material/styles';
import createTheme from '@mui/material/styles/createTheme';

import { ChartTimeQueriesContext } from '../../../app/components/statistics/CarsPerStyleGuideChart';
import { QueryTimeFrame, QueryTimePeriod } from '../../../setup/api/statistics';
import { enumToArray } from '../../../setup/helpers/convertEnumToArray';
import { parseEnumKeyToReadableString } from '../../../setup/helpers/parseObjectKeySyntaxToReadableString';

const timeFrameOptions = enumToArray<QueryTimeFrame>(QueryTimeFrame);
const timePeriodOptions = enumToArray<QueryTimePeriod>(QueryTimePeriod);
const dropdownTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minWidth: '100%',
        },
      },
    },
  },
});

type DropDownProps<QueryType> = {
  timeQueryValue: QueryTimeFrame | QueryTimePeriod;
  setTimeQueryValue: Dispatch<SetStateAction<QueryType>>;
  timeQueries: Array<{ value: QueryType }>;
};

const QueryDropDown = <P extends string>({ timeQueryValue, setTimeQueryValue, timeQueries }: DropDownProps<P>) => (
  <MenuItem>
    <Select value={timeQueryValue} onChange={({ target }) => setTimeQueryValue(target.value as P)}>
      {timeQueries.map(({ value }) => (
        <MenuItem key={value} value={value}>
          {parseEnumKeyToReadableString(value)}
        </MenuItem>
      ))}
    </Select>
  </MenuItem>
);
/*
 * FIXME: this dropdown is handling 3 states, 2 in the component and 1 in context,
 * since in React 17 the component will reload every time a state is updated it has to handle the states first and then send the new state to the context,
 * the correct implementation should be to manage two state in context, but for this to work correctly React should be updated to React ^18
 * otherwise the component will send requests to the backend before both context are updated
 */
export const StatisticsDropDownOnContext: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { timeQueries, setTimeQueries } = useContext(ChartTimeQueriesContext);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(timeQueries.timeFrame);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(timeQueries.timePeriod);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleConfirm = () => {
    setTimeQueries({
      timeFrame: selectedTimeFrame,
      timePeriod: selectedTimePeriod,
    });
    handleClose();
  };

  return (
    <>
      <ThemeProvider theme={dropdownTheme}>
        <Button aria-controls="dropdown-menu" aria-haspopup="true" variant="text" color="primary" onClick={handleOpen}>
          <MenuIcon color="info" />
        </Button>
        <Menu
          id="dropdown-menu"
          className="d-flex"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <QueryDropDown<QueryTimeFrame>
            timeQueryValue={selectedTimeFrame}
            setTimeQueryValue={setSelectedTimeFrame}
            timeQueries={timeFrameOptions}
          />
          <QueryDropDown<QueryTimePeriod>
            timeQueryValue={selectedTimePeriod}
            setTimeQueryValue={setSelectedTimePeriod}
            timeQueries={timePeriodOptions}
          />
          <MenuItem>
            <Button variant="contained" onClick={handleConfirm}>
              <FormattedMessage id="ACTION.CONFIRM" />
            </Button>
          </MenuItem>
        </Menu>
      </ThemeProvider>
    </>
  );
};
