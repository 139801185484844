import React, { ChangeEvent, ClipboardEvent, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import { Button, Checkbox, Switch, TextField } from '@mui/material';

import { AppSettingName } from '../../../app/pages/admin/views/appSettings/AppSettingsList';
import { Loader } from '../Louder/Louder';
import { ConfirmDeletionModal } from '../Modals/ConfirmDeletionModal';

import { DatagridView } from './Datagrid.style';

import './DataGrid.css';

type Props<Item> = {
  data: Array<Item>;
  headings: [string, string][];
  renderRow: any;
  selectable?: boolean;
  selected?: Record<string, unknown>;
  setSelected?: React.Dispatch<React.SetStateAction<Record<string, unknown>>>;
  getIdFromItem?: (item: Record<string, unknown>) => string;
  deletable?: boolean;
  loading?: boolean;
  handleDelete?: any;
  link?: string;
  createdAt?: boolean;
  updatedAt?: boolean;
  tags?: string | boolean;
  modalURL?: string;
  useToggleFunction?: (item: Item) => void;
  useTextFieldFunction?: <T extends AppSettingName>(valueName: T, value: string) => void;
  saveTextFieldFunction?: (item: Item) => void;
};
/**
 *
 * @param props.data Array payload with multiple entities
 * @param props.headings tuple of string arrays with the key object name and the column name EXAMPLE: ['customerName', 'Customer Name']
 * @param props.renderRow renders the row structure on the data grid, by mapping the heading
 * @param props.link should include a string with the entity name, this will be used to access the item information on click
 * @returns list with options and personalized columns
 */

const Datagrid = <P extends Record<string, any>>({
  // FIXME: createdAt, updatedAt should be removed and customer button should be added as customer button(Customer button must be fixed too because is not loading correctly)
  data = [],
  headings,
  renderRow,
  tags,
  selectable,
  selected = {},
  setSelected,
  deletable,
  loading,
  handleDelete,
  link,
  createdAt,
  updatedAt,
  getIdFromItem,
  modalURL,
  useToggleFunction,
  useTextFieldFunction,
  saveTextFieldFunction,
}: Props<P>) => {
  const history = useHistory();
  const renderHeader = (headings: string[][]) =>
    headings.map(([key, header]) => (
      <th key={key} className="grid-header">
        {header}
      </th>
    ));
  const handleChangeSelected = useCallback(
    (id: string | number) => {
      if (setSelected) {
        return setSelected({
          ...selected,
          [id]: !selected[id],
        });
      }
    },

    [selected],
  );

  return (
    <DatagridView>
      <div className="grid-wrapper" data-testid="datagrid">
        <table className="grid-table" style={{ minWidth: '700px' }}>
          {loading && Loader()}
          <thead>
            <tr>
              {selectable && (
                <th className="main-checkbox column-center">
                  <Checkbox data-testid="checkbox" />
                </th>
              )}
              {renderHeader(headings)}
              {createdAt && (
                <th className="grid-header">
                  <FormattedMessage id="DATA.CREATED" />
                </th>
              )}
              {updatedAt && (
                <th className="grid-header">
                  <FormattedMessage id="DATA.UPDATED" />
                </th>
              )}
              {tags && (
                <th className="grid-header">
                  <FormattedMessage id="DATA.EDITOR" />
                </th>
              )}
              {deletable && (
                <th className="grid-header">
                  <FormattedMessage id="ACTION.DELETE" />
                </th>
              )}
              {useToggleFunction && (
                <th className="grid-header">
                  <FormattedMessage id="APP.SETTINGS.SET_VALUE" />
                </th>
              )}
            </tr>
          </thead>
          {loading ? (
            <tbody>
              <tr>
                <td style={{ width: '100%', height: 520 }} />
              </tr>
            </tbody>
          ) : (
            <tbody>
              {data?.map(
                (item: P) =>
                  item?.id && (
                    <tr
                      key={item.id as unknown as string}
                      className="table-row h-40px listHover"
                      onClick={() => {
                        if (modalURL) {
                          history.push(`/${modalURL}/${item.id}`);
                        } else if (link) {
                          history.push(`/${link}/${item?.id}`);
                        }
                      }}
                    >
                      {selectable && (
                        <td className="selectable column-center">
                          <Checkbox
                            checked={Boolean(selected[item.id])}
                            onChange={() => handleChangeSelected(item.id)}
                            aria-label="checkbox"
                          />
                        </td>
                      )}
                      {renderRow(headings, item, link, getIdFromItem)}

                      {createdAt && (
                        <td
                          onClick={e => {
                            e.stopPropagation();
                          }}
                        >
                          {
                            <div className="container-fluid">
                              <Moment className="row" format="YYYY-MM-DD " date={`${item.createdAt}`} />
                              {/* FIXME: the two extra hours should be deleted when they are fixed on the server */}
                              <Moment className="row" format="HH:mm:ss" date={`${item.createdAt}`} />
                            </div>
                          }
                        </td>
                      )}
                      {updatedAt && (
                        <td>
                          <Moment format="HH:mm:ss" date={`${item.updatedAt}`} />
                        </td>
                      )}

                      {tags && item.tags && (
                        <td>
                          <span className="badge pl-0 badge badge-danger" style={{ backgroundColor: item.tags.color }}>
                            {item.tags.name}
                          </span>
                        </td>
                      )}
                      {deletable && (
                        <td
                          onClick={e => {
                            e.stopPropagation();
                          }}
                        >
                          <ConfirmDeletionModal
                            handleDelete={handleDelete}
                            itemId={item.id}
                            entity={link || 'entity'}
                            entityName={item.name}
                          />
                        </td>
                      )}
                      {useToggleFunction && item.valueType === 'boolean' && (
                        <td
                          onClick={e => {
                            e.stopPropagation();
                          }}
                        >
                          <Switch
                            // eslint-disable-next-line eqeqeq
                            checked={item.value == 'true'}
                            onChange={() => {
                              useToggleFunction(item);
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </td>
                      )}
                      {useTextFieldFunction && item.valueType === 'string' && (
                        <td
                          onClick={e => {
                            e.stopPropagation();
                          }}
                        >
                          <TextField
                            id={item.name}
                            placeholder={item.value}
                            variant="standard"
                            onInput={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
                              useTextFieldFunction<AppSettingName>(item.name, value)
                            }
                          />
                        </td>
                      )}
                      {saveTextFieldFunction && item.valueType === 'string' && (
                        <td
                          onClick={e => {
                            e.stopPropagation();
                          }}
                        >
                          <Button variant="text" onClick={() => saveTextFieldFunction(item)}>
                            <FormattedMessage id="ACTION.SAVE" />
                          </Button>
                        </td>
                      )}
                    </tr>
                  ),
              )}
            </tbody>
          )}
        </table>
      </div>
    </DatagridView>
  );
};

export { Datagrid };
