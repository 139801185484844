/*
console.log(isValidPhoneNumber('+45 52-22-22')); // Output: true
console.log(isValidPhoneNumber('++45 52--22')); // Output: false
console.log(isValidPhoneNumber('+1234')); // Output: true
console.log(isValidPhoneNumber('12-34')); // Output: true
console.log(isValidPhoneNumber('ABC123')); // Output: false
console.log(isValidPhoneNumber('+1231231-23123')); // Output: true
console.log(isValidPhoneNumber('+12312-312-3123')); // Output: true
*/

export function isValidPhoneNumber(value: string | number | null): boolean {
  if (value) {
    const phoneNumber = value?.toString();
    let hasPlusSymbol = false;
    let previousCharIsDash = false;

    for (const char of phoneNumber) {
      if (char === '+') {
        if (hasPlusSymbol || previousCharIsDash) {
          return false;
        }
        hasPlusSymbol = true;
        previousCharIsDash = false;
      } else if (char === '-') {
        if (previousCharIsDash || phoneNumber.indexOf('+') === -1) {
          return false;
        }
        previousCharIsDash = true;
      } else if (!/[0-9\s]/.test(char)) {
        return false;
      } else {
        previousCharIsDash = false;
      }
    }

    return true;
  }

  return false;
}
