import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { ImageListType } from 'react-images-uploading';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { createCarType, getCarType, modifyCarType } from '../../../setup/api';
import { addUtility } from '../../../setup/api/utilities';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { reloadTableFromModal } from '../../../setup/redux/actions';
import { ImageDropZone } from '../../../ui/components/ImageDropzone/ImageDropZone';
import { MainGridContainer } from '../../../ui/components/MainGridContainer/MainGridContainer';
import { ModalCarswip } from '../../../ui/components/Modal';

const createCarTypeSchema = (intl: IntlShape) =>
  Yup.object({
    carTypeName: Yup.string()
      .required(
        intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED' }, { name: intl.formatMessage({ id: 'CAR_TYPE.NAME' }) }),
      )
      .label('name'),
  });

const inits: ICarType = {
  carTypeName: ' ',
  needsAngleCorrection: false,
  category: 'exterior',
};

interface IProps {
  button?: boolean;
}
/**
 *  CarType modal, load car type information on params.id or empty form to create car type entity
 * @param button if true it will include a button with empty form to create car type
 */
const CreateCarTypeModal: FC<IProps> = ({ button }) => {
  const intl = useIntl();
  const { request, isLoading } = useFetch();
  const [carTypeIcon, setCarTypeIcon] = useState<Array<any>>([]);
  const [initValues, setInitialData] = useState(inits);
  const { id } = useParams<TParams>();
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const modalToggle = () => {
    setIsOpen(!isOpen);
    history.push(`/${'admin/car-types'}`);
  };

  useEffect(() => {
    if (id) {
      request(getCarType, id).then((res: ICarType) => {
        setInitialData(res);
        setIsOpen(true);
      });
    }
  }, [id]);

  const updateCarType = (values: any) => {
    if (carTypeIcon[0]?.data_url) {
      addUtility(carTypeIcon[0], 'car_type_icon').then(res => {
        request(modifyCarType, id, { ...values, icon: res.id });
        setIsOpen(false);
        dispatch(reloadTableFromModal({ reload: true }, 'cartype'));
      });
    } else {
      delete values.icon;
      request(modifyCarType, id, values).then(() => {
        setIsOpen(false);
        dispatch(reloadTableFromModal({ reload: true }, 'cartype'));
      });
    }
  };

  return (
    <>
      {button && (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            setInitialData(inits);
            history.push(`/${'admin/car-types'}`);
            setIsOpen(true);
          }}
        >
          <FormattedMessage id="CAR_TYPE.ADD" />
        </button>
      )}

      <ModalCarswip open={isOpen} onClose={modalToggle} title={intl.formatMessage({ id: 'CAR_TYPE' })}>
        <MainGridContainer>
          <Formik
            validationSchema={createCarTypeSchema(intl)}
            initialValues={initValues}
            onSubmit={values => {
              if (id) {
                updateCarType(values);
              } else if (carTypeIcon[0]?.data_url) {
                addUtility(carTypeIcon[0], 'car_type_icon').then(res => {
                  request(createCarType, { ...values, icon: res.id }).then(() => {
                    setIsOpen(false);
                    dispatch(reloadTableFromModal({ reload: true }, 'cartype'));
                  });
                });
              }
            }}
          >
            {(props: FormikProps<ICarType>) => (
              <Form onSubmit={props.handleSubmit} className="form" noValidate>
                <div className="w-100">
                  <div className="fv-row mb-10 ">
                    <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                      <span className="required">
                        <FormattedMessage id="CAR_TYPE.NAME" />:
                      </span>
                    </label>
                    <Field
                      type="text"
                      className={`form-control form-control-lg userInputFields ${
                        props.touched.carTypeName && props.errors.carTypeName ? 'is-invalid' : 'form-control-solid'
                      }`}
                      name="carTypeName"
                      placeholder={intl.formatMessage({ id: 'CAR_TYPE.NAME' })}
                    />
                    <div style={{ color: 'red', marginTop: '-10px' }}>
                      <ErrorMessage name="carTypeName" />
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-between fv-row mb-10">
                    <span className="align-items-center fs-5 fw-bold required">
                      <FormattedMessage id="CAR_TYPE.ICON" />
                    </span>

                    <ImageDropZone
                      images={[props.initialValues?.icon]}
                      maxNumber={1}
                      setImages={setCarTypeIcon as unknown as Dispatch<SetStateAction<ImageListType | undefined>>}
                      selfDrop={false}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-end flex-stack pt-10">
                  {/* Begins submit button */}
                  {isLoading ? (
                    <button type="button" className="btn btn-lg btn-primary d-flex">
                      <span className="indicator-label ">
                        <FormattedMessage id="ACTION.PENDING.SAVE" />
                      </span>
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-lg btn-primary d-flex">
                      <span className="indicator-label">
                        <FormattedMessage id={id ? 'ACTION.UPDATE' : 'ACTION.CREATE'} />
                      </span>
                      <AddCircleIcon />
                    </button>
                  )}
                  {/* Ends submit button */}
                </div>
              </Form>
            )}
          </Formik>
        </MainGridContainer>
      </ModalCarswip>
    </>
  );
};

export { CreateCarTypeModal };
