import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ClearIcon from '@mui/icons-material/Clear';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Button, ListItem, ListItemIcon, ListItemText, Switch, TextField } from '@mui/material';

import { IntegrationType } from '../../../constants';
import { deleteIntegration, updateIntegration } from '../../../setup/api/remoteIntegration';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { alertPayload, useFetchWithAlert } from '../../../setup/hooks/useFetchWithAlert';

type Props = {
  integration: RemoteIntegration;
  handleUpdate: (integration: RemoteIntegration) => void;
  handleRemove: (id: string) => void;
};
/**
 *
 *  Display integration provider and its corres ponding user account
 *
 * @param { string } title integration title that will be shown on page
 * @param { IntegrationProviderRow } integrationProvider the specific provider for the integration
 * @param { IIntegration | undefined} integrationData in case the integration exists, this will be the inegration data
 * @param { IntegrationType } integrationType type of integration FTP | ROBOT | Integration,
 *
 *
 * @returns IntegrationProvider enables users to:
 *
 * - Add new integration into an account
 * - Change user and password of a provider
 * - Display status of the robot or integration
 */

export const IntegrationProviderRow: React.FC<Props> = ({ handleRemove, handleUpdate, integration }) => {
  const intl = useIntl();
  const fetchWithAlert = useFetchWithAlert(alertPayload(intl.formatMessage({ id: 'INTEGRATION.UPDATED' })));
  const fetch = useFetch();
  const [isInEditMode, setEditMode] = useState(false);

  const handleToggle = (value: boolean) => {
    fetch
      .request(updateIntegration, {
        ...integration,
        isActive: value,
      })
      .then(res => {
        if (res) {
          handleUpdate(res);
        }
      });
  };

  const isEmptyOrUndefined = (value: string) => value === '' || value === undefined || value === null;

  return (
    <>
      <ListItem className="justify-content-between">
        <div className="d-flex align-content-center align-items-center">
          <ListItemIcon>
            <RemoveCircleIcon
              onClick={() => {
                fetchWithAlert.request(deleteIntegration, integration.id).then(() => {
                  handleRemove(integration.id);
                });
              }}
              sx={{
                color: 'red',
                marginRight: '2em',
                cursor: 'pointer',
              }}
            />
            <DirectionsCarFilledIcon />
          </ListItemIcon>

          <div style={{ minWidth: '100px', marginLeft: '5px' }}>
            <ListItemText primary={integration.provider} style={{ marginRight: '2em' }} />
          </div>
          <Switch
            edge="end"
            onChange={() => {
              handleToggle(!integration.isActive);
            }}
            checked={integration.isActive}
            inputProps={{
              'aria-labelledby': 'switch-list-label-wifi',
            }}
          />
          <div style={{ marginLeft: '100px' }}>
            <FormattedMessage id="INTEGRATION.TYPE" values={{ name: integration.integrationType }} />
          </div>
        </div>

        {(integration.integrationType !== IntegrationType.FTP.toString() ||
          integration.integrationType !== IntegrationType.BLOB.toString()) &&
          integration.isActive && (
            <div className="d-flex align-items-center">
              <TextField
                style={{ marginRight: '2em' }}
                label={intl.formatMessage({ id: 'INTEGRATION.USER' })}
                defaultValue={integration.Username}
                disabled={!isInEditMode}
                size="small"
                onChange={e => {
                  handleUpdate({
                    ...integration,
                    Username: e.target.value,
                  });
                }}
              />

              <TextField
                style={{ marginRight: '2em' }}
                label={intl.formatMessage({ id: 'INTEGRATION.PWD' })}
                defaultValue={integration.Password}
                size="small"
                disabled={!isInEditMode}
                type="password"
                onChange={e => {
                  handleUpdate({
                    ...integration,
                    Password: e.target.value,
                  });
                }}
              />

              {isInEditMode ? (
                <>
                  <Button
                    onClick={() => {
                      fetchWithAlert.request(updateIntegration, { ...integration });
                      setEditMode(false);
                    }}
                    disabled={isEmptyOrUndefined(integration.Username) && isEmptyOrUndefined(integration.Password)}
                  >
                    <SaveAsIcon />
                  </Button>
                  <Button
                    onClick={() => {
                      setEditMode(false);
                    }}
                  >
                    <ClearIcon />
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      setEditMode(true);
                    }}
                  >
                    <EditIcon />
                  </Button>
                  <Button disabled />
                </>
              )}
            </div>
          )}
      </ListItem>
    </>
  );
};
