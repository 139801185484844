import Logo from '../../../_carswip/assets/img/carswipLogo.jpg';

// Those are the route names that must be displayed on the frontend
export enum DisplayRoutes {
  AI_NO_ENHANCER = 'AI (NO ENHANCER)',
  AI = 'AI',
  ENHANCER = 'ENHANCER',
  BYPASS = 'BYPASS',
  FEATURE_IMAGE = 'FEATURED',
  LOGO_ONLY = 'logo_only',
}
// Those are the route name that are sent and received from the backend
export enum RequestRoutes {
  AI = 'full',
  ENHANCER = 'enhancer',
  BYPASS = 'bypass',
  AI_NO_ENHANCER = 'ai_no_enh',
  FEATURED = 'FEATURED',
  LOGO_ONLY = 'logo_only',
}
export enum ConfigurationImageType {
  FEATURED = 'FEATURED',
  IMAGE = 'IMAGE',
}

export const defaultMarginValues = {
  top: 15,
  right: 5,
  bottom: 5,
  left: 5,
};

export const convertRouteTo = (route: RequestRoutes): DisplayRoutes => {
  switch (route) {
    case RequestRoutes.AI:
      return DisplayRoutes.AI;

    case RequestRoutes.ENHANCER:
      return DisplayRoutes.ENHANCER;

    case RequestRoutes.BYPASS:
      return DisplayRoutes.BYPASS;

    case RequestRoutes.AI_NO_ENHANCER:
      return DisplayRoutes.AI_NO_ENHANCER;

    case RequestRoutes.FEATURED:
      return DisplayRoutes.FEATURE_IMAGE;

    case RequestRoutes.LOGO_ONLY:
      return DisplayRoutes.LOGO_ONLY;

    default:
      return DisplayRoutes.AI_NO_ENHANCER;
  }
};

export const convertRouteToRequest = (route: DisplayRoutes) => {
  switch (route) {
    case DisplayRoutes.AI:
      return RequestRoutes.AI;

    case DisplayRoutes.ENHANCER:
      return RequestRoutes.ENHANCER;

    case DisplayRoutes.BYPASS:
      return RequestRoutes.BYPASS;

    case DisplayRoutes.AI_NO_ENHANCER:
      return RequestRoutes.AI_NO_ENHANCER;

    case DisplayRoutes.FEATURE_IMAGE:
      return RequestRoutes.FEATURED;

    case DisplayRoutes.LOGO_ONLY:
      return RequestRoutes.LOGO_ONLY;

    default:
      return RequestRoutes.AI_NO_ENHANCER;
  }
};
export type TConfigurationMedia =
  | 'customAngleOverlay'
  | 'customFrameOverlay'
  | 'featuredImage'
  | 'customBackground'
  | 'customLogo'
  | 'customFloor';

export const getConfigurationImageDisplay = (configuration: IConfiguration | any): string => {
  const angleOverlay = configuration.customAngleOverlay;
  const { featuredImage } = configuration;

  if (angleOverlay) {
    return angleOverlay.thumbnailUrl;
  }
  if (featuredImage) {
    return featuredImage.thumbnailUrl;
  }
  return Logo;
};
