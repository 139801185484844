import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Chip, CircularProgress, ListItemButton } from '@mui/material';

import { getOrderImages, repushImages } from '../../../../setup/api';
import { devideImages } from '../../../../setup/helpers/devideImages';
import { useFetch } from '../../../../setup/hooks/fetch.hook';
import { CarouselQA } from '../../../../ui/components/Carousel/CarouselQA';

import { CancelEdition } from './CancelEdition';
import { RepushingSettingButton } from './RepushSettings';
import { SendToEdition } from './SendToEdition';

import './MultipleImageView.css';

const repushTypes = [
  {
    name: 'AI',
    method: 'full',
  },
  {
    name: 'BYPASS',
    method: 'bypass',
  },
  {
    name: 'ENHANCER',
    method: 'enhancer',
  },
  {
    name: 'AI NO ENHANCER',
    method: 'ai_no_enh',
  },
  {
    name: 'LOGO_ONLY',
    method: 'logo_only',
  },
];

type IProps = {
  orderInfo: IOrder;
  updateImagesForOrder?: (imageId: string, image: IImage) => void;
};

type TCarouselImageType = {
  original: IImage;
  optimized: IImage;
};

interface CarouselQAProps {
  images: TCarouselImageType;
}
const CarouselImages: React.FC<CarouselQAProps> = ({ images }) => {
  const [imageError, setImageError] = useState<boolean>(false);

  return (
    <>
      <img
        style={{ width: '50%', height: '100%' }}
        key={images.original?.id}
        src={images.original?.thumbnailUrl}
        alt=""
      />

      {images.optimized && !imageError && (
        <img
          style={{ width: '50%', height: '100%' }}
          key={images.optimized?.id}
          src={images.optimized?.thumbnailUrl}
          alt="Loading..."
          onError={e => {
            if (images.optimized.thumbnailUrl === e.currentTarget.src) {
              e.currentTarget.src = images.optimized.url;
            } else {
              setImageError(true);
            }
          }}
        />
      )}

      {imageError && (
        <>
          <CircularProgress
            style={{
              position: 'absolute',
              width: '133px',
              height: '133px',
              top: '39%',
              marginLeft: '23%',
            }}
          />
          <img key="img-error-loading" style={{ width: '50%', height: '100%' }} />
        </>
      )}

      {!images.optimized && !imageError && <img key="img-error-missing" style={{ width: '50%', height: '100%' }} />}
    </>
  );
};

/**
 * This component is the default view for the {@link UserRole.ADMIN } && {@link UserRole.QA} and allows the user to check images grouped by its position
 * on the QA Carousel
 * @param props.orderInfo receive from parent the order information
 */
const QaView: React.FC<IProps> = ({ orderInfo, updateImagesForOrder }) => {
  const { id } = useParams<TParams>();
  const [imageId, setImageIndex] = useState(0);
  const { request } = useFetch();
  const [openRepush, setOpenRepush] = useState(false);
  const [optimizedImages, setOptimizedImages] = useState<Array<IImage> | null>(null);
  const [originalImages, setOriginalImages] = useState<Array<any> | null>(null);

  const getImagesData = (id: string) => {
    request(getOrderImages, id).then(data => {
      const IMGS = devideImages(data.original, data.optimized);

      setOriginalImages(IMGS);

      setOptimizedImages(data.optimized);
      if (updateImagesForOrder) {
        if (data.original[imageId]) {
          updateImagesForOrder(data.original[imageId].id, data.original[imageId]);
        }
        if (data.optimized[imageId]) {
          updateImagesForOrder(data.optimized[imageId].id, data.optimized[imageId]);
        }
      }
    });
  };

  const handleRepushImages = (method: string, imageId: string) => {
    request(repushImages, {
      orderId: orderInfo.id,
      imageArray: [imageId],
      method,
      imageCategory: 'exterior',
      backgroundColor: orderInfo.styleGuide?.interiorColor ?? '#ffffff',
    }).then(() => {
      setOpenRepush(false);
    });
  };

  useEffect(() => {
    getImagesData(id || orderInfo.id);
  }, []);

  // TODO: must refactor
  return (
    <div>
      <div className="emptystate__container mb-5">
        {originalImages && originalImages.length > 0 && optimizedImages && (
          <>
            <div key="QA-actions-container" style={{ padding: '2px' }}>
              {originalImages[imageId].optimized && originalImages[imageId].optimized.approved ? (
                <div className="editor-button-group">
                  <div className="btn-group" role="group" aria-label="Basic example">
                    <button
                      key="QA-repush-button"
                      className="btn btn-warning"
                      type="button"
                      onClick={() => {
                        handleRepushImages(
                          originalImages[imageId].original?.optimisedVia,
                          originalImages[imageId].optimized?.id,
                        );
                      }}
                    >
                      <FormattedMessage id="ORDER.QA.REPUSH" />
                    </button>
                    <button
                      key="QA-add-button"
                      className="btn btn-warning "
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-kt-menu-trigger="click"
                      data-kt-menu-attach="parent"
                      data-kt-menu-placement="bottom-end"
                      data-kt-menu-flip="bottom"
                      onClick={() => {
                        setOpenRepush(!openRepush);
                      }}
                    >
                      <AddIcon />
                    </button>
                    <ul
                      className={openRepush ? 'show dropdown-menu ' : 'dropdown-menu'}
                      role="menu"
                      data-kt-menu="true"
                      aria-labelledby="dropdownMenuButton2"
                    >
                      {repushTypes.map((type: { name: string; method: string }) => (
                        <ListItemButton
                          key={`QA-${type.method}-button`}
                          sx={{ pl: 4 }}
                          className="ms-3 menu-item mb-3 order-status-list-items"
                          onClick={() => {
                            handleRepushImages(type.method, originalImages[imageId].optimized?.id);
                          }}
                        >
                          <Chip
                            style={{
                              cursor: 'pointer',
                              width: '100%',
                              borderRadius: '0px 5px 5px 0px',
                              border: '1px solid #fff',
                              height: '20px',
                            }}
                            label={type.name}
                            variant="filled"
                          />
                        </ListItemButton>
                      ))}
                    </ul>
                    <RepushingSettingButton
                      imageId={originalImages[imageId].original?.id}
                      orderId={orderInfo.id}
                      styleGuide={orderInfo.styleGuide}
                      position={originalImages[imageId].original?.position}
                    />
                  </div>
                  <SendToEdition
                    optimizedImage={originalImages[imageId].optimized}
                    callback={() => getImagesData(id || orderInfo.id)}
                  />
                </div>
              ) : (
                <CancelEdition
                  optimizedImageId={originalImages[imageId].optimized.id}
                  callback={() => getImagesData(id || orderInfo.id)}
                />
              )}
            </div>
            <div key="QA-carousel-container" className="w-100 " style={{ backgroundColor: 'black', paddingTop: '1em' }}>
              <div>
                <CarouselQA returnOptimizedImageIndex={setImageIndex}>
                  {originalImages.map((image: TCarouselImageType) => (
                    <CarouselImages images={image} />
                  ))}
                </CarouselQA>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export { QaView };
