import { FC, useEffect, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { createBanner, getBanner, modifyBanner } from '../../../setup/api';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { reloadTableFromModal } from '../../../setup/redux/actions';
import { MainGridContainer } from '../../../ui/components/MainGridContainer/MainGridContainer';
import { ModalCarswip } from '../../../ui/components/Modal';

const createBannerSchema = (intl: IntlShape) =>
  Yup.object({
    message: Yup.string()
      .required(
        intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED' }, { name: intl.formatMessage({ id: 'BANNER.MESSAGE' }) }),
      )
      .label('message'),
  });

interface IBannerFrom {
  message: string;
  isActive: boolean;
}
const inits: IBannerFrom = {
  message: '',
  isActive: false,
};

interface IProps {
  button?: boolean;
}

function createPlatformField() {
  return (
    <div className="fv-row mb-10 ">
      <label className="d-flex align-items-center fs-5 fw-bold mb-2">
        <span className="required">
          <FormattedMessage id="BANNER.ACTIVE" />
        </span>
      </label>
      <Field name="isActive" className="mr-2 leading-tight" type="checkbox" />
    </div>
  );
}

const CreateBannerModal: FC<IProps> = ({ button }) => {
  const intl = useIntl();
  const { request } = useFetch();
  const [initValues, setInitialData] = useState<IBannerFrom>(inits);
  const { id } = useParams<TParams>();
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const modalToggle = () => {
    setIsOpen(!isOpen);
    history.push('/admin/banners');
  };

  useEffect(() => {
    if (id) {
      request(getBanner, id).then((res: IBannerFrom) => {
        setInitialData(res);
        setIsOpen(true);
      });
    }
  }, [id]);

  return (
    <>
      {button && (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            setInitialData(inits);
            history.push('/admin/banners');
            setIsOpen(true);
          }}
        >
          <FormattedMessage id="BANNER.ADD" />
        </button>
      )}

      <ModalCarswip open={isOpen} onClose={modalToggle} title={intl.formatMessage({ id: 'BANNER' })}>
        <MainGridContainer>
          <Formik
            validationSchema={createBannerSchema(intl)}
            initialValues={initValues}
            onSubmit={values => {
              if (id) {
                request(modifyBanner, id, values).then(() => {
                  setIsOpen(false);
                  dispatch(reloadTableFromModal({ reload: true }, 'banner'));
                });
              } else {
                request(createBanner, { ...values }).then(() => {
                  setIsOpen(false);
                  dispatch(reloadTableFromModal({ reload: true }, 'banner'));
                });
              }
            }}
          >
            {(props: FormikProps<IBannerFrom>) => (
              <Form onSubmit={props.handleSubmit} className="form" noValidate>
                <div className="w-100">
                  <div className="fv-row mb-10 ">
                    <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                      <span className="required">
                        <FormattedMessage id="BANNER.MESSAGE" />:
                      </span>
                    </label>
                    <Field
                      type="text"
                      className={`form-control form-control-lg  userInputFields ${
                        props.touched.message && props.errors.message ? 'is-invalid' : 'form-control-solid'
                      }`}
                      name="message"
                      placeholder={intl.formatMessage({ id: 'BANNER.MESSAGE.PLACEHOLDER' })}
                    />
                    <div style={{ color: 'red', marginTop: '-10px' }}>
                      <ErrorMessage name="message" />
                    </div>
                  </div>
                  {createPlatformField()}
                </div>

                <div className="d-flex justify-content-end flex-stack pt-10">
                  <button type="submit" className="btn btn-lg btn-primary d-flex">
                    <span className="indicator-label ">
                      <FormattedMessage id={id ? 'ACTION.UPDATE' : 'ACTION.CREATE'} />
                    </span>
                    <AddCircleIcon />
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </MainGridContainer>
      </ModalCarswip>
    </>
  );
};

export { CreateBannerModal };
