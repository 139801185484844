/**
 * This function organize the images of the order and is used on the QA carousel to display the correct optimized an original image at the same index
 * @param original the original images from the order
 * @param optimized  the optimized images form the order
 * @returns all order images sorted by position
 */

export const devideImages = (original: Array<IImage>, optimized: Array<IImage>) => {
  const sortedImages = original.map((image: IImage, index: number) => {
    if (optimized[index]) {
      return {
        original: image,
        optimized: optimized.filter((optimizedImage: IImage) => optimizedImage.position === image.position)[0],
      };
    }
    return {
      original: image,
      optimized: optimized.filter((optimizedImage: IImage) => optimizedImage.position === image.position)[0],
    };
  });

  return sortedImages;
};
