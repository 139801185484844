import React, { FC, useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';

import { PageTitle } from '../../../_carswip/layout/core';
import { UserRole } from '../../../constants';
import { getUsers } from '../../../setup/api';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { Datagrid } from '../../../ui/components/Datagrid/Datagrid';
import { FilterComponent } from '../../../ui/components/Filters/FilterComponent';
import { TFilterProps } from '../../../ui/components/Filters/FilterDropDown';
import { User } from '../../../ui/helpers';
import { renderTestRowsUsers } from '../../../ui/helpers/tables/renderRows';

const userRoleTranslations: Record<UserRole, string> = {
  [UserRole.ADMIN]: 'MENU.ADMIN',
  [UserRole.DEALERSHIP]: 'ACCOUNT.DELERSHIP',
  [UserRole.MANAGEMENT]: 'ACCOUNT.USER_ROLE.MANAGEMENT',
  [UserRole.QA]: 'ACCOUNT.USER_ROLE.QA',
};

const customUserFilters = (intl: IntlShape): TFilterProps[] => [
  {
    name: 'User Role',
    query: 'userRole',
    options: Object.values(UserRole).map((value: UserRole) => ({
      label: intl.formatMessage({ id: userRoleTranslations[value] }),
      value,
    })),
  },
];

type UsersInfo = { users: IUser[]; totalUsers: number };

const CustomersPage: FC = () => {
  const intl = useIntl();
  const { request, isLoading, abortController } = useFetch();
  const [usersInfo, setUsersInfo] = useState<UsersInfo>({ users: [], totalUsers: 0 });
  const queriesFromRouter = useLocation<string>().search;
  const { userRole } = User();

  const userHeadings: [string, string][] = [
    ['email', intl.formatMessage({ id: 'ACCOUNT.EMAIL' })],
    ['displayName', intl.formatMessage({ id: 'ACCOUNT.DEALERSHIP' })],
  ];

  const userHeadingsOnAdmin: [string, string][] = [
    ...userHeadings,
    ['address_country', intl.formatMessage({ id: 'ACCOUNT.COUNTRY' })],
    ['address_city', intl.formatMessage({ id: 'ACCOUNT.CITY' })],
    ['contactPerson_name', intl.formatMessage({ id: 'ACCOUNT.CONTACT_PERSON' })],
    ['contactPerson_phoneNumber', intl.formatMessage({ id: 'ACCOUNT.PHONE' })],
    ['cognitoSub', intl.formatMessage({ id: 'ACCOUNT.LOGIN' })],
  ];

  const fetchUsers = async () => {
    request(getUsers, queriesFromRouter, abortController.signal).then(res => {
      if (res) {
        setUsersInfo({ users: res[0], totalUsers: res[1] });
      }
    });
  };

  useEffect(() => {
    fetchUsers();
    return () => {
      abortController.abort();
    };
  }, [queriesFromRouter]);

  return (
    <Grid container padding={4} className="card">
      <Grid xs={12} marginTop={3}>
        <FilterComponent
          showSearchFilter
          showPagination
          showCountryFilter
          entity="users"
          customFilterQueries={customUserFilters(intl)}
          count={usersInfo.totalUsers}
        />
      </Grid>
      <Grid xs={12} marginTop={3}>
        <Datagrid
          loading={isLoading}
          data={usersInfo.users}
          headings={userRole === UserRole.ADMIN ? userHeadingsOnAdmin : userHeadings}
          link="profile"
          createdAt
          renderRow={renderTestRowsUsers}
        />
      </Grid>
    </Grid>
  );
};

export const CustomersWrapper: React.FC = () => {
  const intl = useIntl();

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.CUSTOMERS' })}</PageTitle>
      <CustomersPage />
    </>
  );
};
