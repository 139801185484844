import { ImageDisplay } from '../../../../../ui/components/Image/ImageDisplay';

const isThumbnail = (key: string) =>
  [
    'logo_thumbnail',
    'background_thumbnail',
    'floor_thumbnail',
    '_thumbnailfloor',
    '_thumbnail',
    'defaultAngleOverlay',
    'defaultFrameOverlay',
  ].includes(key);

export const renderTestsRow = (
  headings: [string, string][],
  item: Record<string, { thumbnailUrl: string; url: string }>,
) =>
  headings.map(([key]) => (
    <td key={key}>
      {isThumbnail(key) ? (
        <div className="me-2">
          <ImageDisplay src={item[key]?.thumbnailUrl || item[key]?.url} />
        </div>
      ) : (
        item[key]
      )}
    </td>
  ));
