import React, { Dispatch, FC, KeyboardEvent, ReactElement, ReactNode, SetStateAction, useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CircleIcon from '@mui/icons-material/Circle';

import './Carousel.css';

interface IProps {
  children: React.ReactNode;
  returnOptimizedImageIndex: Dispatch<SetStateAction<number>>;
}

/**
 *  Displays original and optimized images and allows to navigate to the order's images
 *
 * @param { React.ReactNode } props.children returns the images that are wrapped up by the component
 * @param { TRemoteProvider } props.returnOptimizedImageIndex drill props to get current image index
 * @param { boolean } props.buttons if true, it will load navigation buttons on the carousel
 *
 * @returns Carousel with 2 images original and optimized by index, it is used on:
 *
 * - Coordinator's list
 * - QA view from order details
 */

export const CarouselQA: FC<IProps> = props => {
  const { children, returnOptimizedImageIndex } = props;
  const [currentImagePosition, setCurrentImagePosition] = useState(0);
  const updateIndex = (newIndex: number) => {
    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = React.Children.count(children) - 1;
    }

    setCurrentImagePosition(newIndex);
  };
  const handleNavigationOnKeyDown = (e: KeyboardEvent): void => {
    if (e.key === 'ArrowLeft') {
      updateIndex(currentImagePosition - 1);
    } else if (e.key === 'ArrowRight') {
      updateIndex(currentImagePosition + 1);
    }
  };
  const handleNavigationOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const bounds = e.currentTarget.getBoundingClientRect();
    const halfWidthFromElement = e.currentTarget.getBoundingClientRect().width / 2;
    const currentMousePositionOnElementX = e.clientX - bounds.left;

    if (currentMousePositionOnElementX < halfWidthFromElement) {
      updateIndex(currentImagePosition - 1);
    } else if (currentMousePositionOnElementX > halfWidthFromElement) {
      updateIndex(currentImagePosition + 1);
    }
  };

  return (
    <div
      className="carouselQA"
      tabIndex={0}
      onContextMenu={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onKeyDown={handleNavigationOnKeyDown}
      onClick={handleNavigationOnClick}
    >
      <>
        <button
          className="btn btn-primary coursel-btn-left carousel-buttons"
          type="button"
          onClick={() => {
            updateIndex(currentImagePosition - 1);
          }}
        >
          <ArrowBackIosIcon />
        </button>
        <button
          className="btn btn-primary coursel-btn-rigth carousel-buttons"
          type="button"
          onClick={() => {
            updateIndex(currentImagePosition + 1);
          }}
        >
          <ArrowForwardIosIcon />
        </button>
      </>
      <div className="inner" style={{ transform: `translateX(-${currentImagePosition * 100}%)` }}>
        {React.Children.map(children, (child: ReactNode, index) => {
          if (currentImagePosition === index) {
            returnOptimizedImageIndex(index);
          }
          return React.cloneElement(child as ReactElement<{ width: string }, string>, {
            width: '100%',
          });
        })}
      </div>
      <div className="indicators">
        <div className="row" style={{ fontSize: '40px' }}>
          {React.Children.map(children, (_, index) => {
            if (index === currentImagePosition) {
              return <CircleIcon fontSize="inherit" color="primary" />;
            }
            return (
              <CircleIcon
                fontSize="inherit"
                color="inherit"
                onClick={() => {
                  updateIndex(index);
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
