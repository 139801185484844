import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '@mui/material';

import { createSalesMan, getSalesManById, modifySalesMan as updateSalesMan } from '../../../setup/api';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { reloadTable, reloadTableFromModal } from '../../../setup/redux/actions';
import { MainGridContainer } from '../../../ui/components/MainGridContainer/MainGridContainer';
import { ModalCarswip } from '../../../ui/components/Modal';
import { User } from '../../../ui/helpers';

import { SalesManForm } from './SalesManForm';

export const enum ModalType {
  READ = 'read',
  UPDATE = 'update',
  CREATE = 'create',
}

interface IProps {
  button?: boolean;
  open?: boolean;
  type: ModalType;
  salesManId?: string;
  loadListOnSave?: boolean;
}

interface IFormValues {
  salesMan: ISalesManPayload[];
  name: string | null;
  email: string | null;
  phone: number | null;
  userId?: string | null;
}

interface ISalesManPayload {
  phone: number | string;
  email: string;
  name: string;
}
const inits = {
  name: null,
  email: null,
  phone: null,
};

const SalesManModal: React.FC<IProps> = ({ button, open = false, type, salesManId, loadListOnSave }) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const [initialData, setInitialData] = useState<ISalesManPayload | any>(inits);
  const { request } = useFetch();
  const user = User();
  const [isOpen, setIsOpen] = useState(open);
  const location = useLocation();
  const salesPersonExists = location.pathname.split('/')[2];
  const modalToggle = () => {
    setIsOpen(!isOpen);
    if (history.location.pathname.includes('salesmen')) {
      // this is necessary to avoid the modal to be opened when the user clicks on the back button
      history.push(`/${'salesmen'}`);
    }
  };
  const getSalesMan = (id: string) => {
    request(getSalesManById, id).then(data => {
      setInitialData(data);
      setIsOpen(true);
    });
  };

  const readSalesMan = async (id: string) =>
    request(getSalesManById, id).then((salesMan: ISalesManPayload) => {
      setInitialData(salesMan);
      setIsOpen(open);
    });

  const handleSubmit = (values: IFormValues) => {
    if (salesPersonExists) {
      // update
      request(updateSalesMan, salesPersonExists, values).then(() => {
        dispatch(reloadTableFromModal({ reload: true }, 'salesmen'));
      });
    } else {
      // create
      const payload = { userId: user.id, salesMan: [values] };

      request(createSalesMan, payload).then(() => {
        dispatch(reloadTableFromModal({ reload: true }, 'salesmen'));
      });
    }

    setIsOpen(false);
    modalToggle();
  };

  useEffect(() => {
    if (salesPersonExists && !salesManId) {
      getSalesMan(salesPersonExists);
    }
  }, [salesPersonExists]);

  useEffect(() => {
    if (salesManId && open) {
      readSalesMan(salesManId);
    }
  }, [salesManId, open]);

  return (
    <>
      {button && (
        <Button
          variant="contained"
          onClick={() => {
            setInitialData(inits);
            if (loadListOnSave) {
              history.push(`/${'salesmen'}`);
            }
            dispatch(reloadTable({ reload: false }, 'salesmen'));
            setIsOpen(true);
          }}
        >
          <FormattedMessage id="SALESMAN.ADD" />
        </Button>
      )}

      <ModalCarswip open={isOpen} onClose={modalToggle} title={intl.formatMessage({ id: 'SALESMAN.TITLE' })}>
        <MainGridContainer>
          {type === ModalType.READ ? (
            <SalesManForm handleSubmit={handleSubmit} initialData={initialData} />
          ) : (
            <SalesManForm
              handleSubmit={handleSubmit}
              initialData={initialData}
              button={salesPersonExists ? 'ACTION.UPDATE' : 'ACTION.CREATE'}
            />
          )}
        </MainGridContainer>
      </ModalCarswip>
    </>
  );
};

export { SalesManModal };
