import { IUserPayload } from '../../../pages/account/CreateDealership';

export const parseTextToObject = (text: string) => {
  const accountInfotmation = text.split('Name	Email	Phone')[0];
  const salesMan = text.split('Name	Email	Phone')[1];
  const contactInformation = accountInfotmation.split(
    '(Will be used by all salespeople at your dealership to log in to Carswip)',
  )[0];
  const dealershipInformation = accountInfotmation.split(
    '(Will be used by all salespeople at your dealership to log in to Carswip)',
  )[1];
  const contactInformationLines = contactInformation
    .replace('Contact Person information', '')
    .replace('Dealership Information', '')
    .replace('Name', 'contact')
    // .replace('Name', 'Dealership Name')
    .replace('New e-mail for future login to Carswip **', '')
    .replace('Example: info@yourdealership.com', '')
    .replace('Name', 'dealershipName')
    .split('\n');
  const loginEmail = dealershipInformation.split('\n')[1];
  const dealershipLines = dealershipInformation.split('\n').slice(2, dealershipInformation.length - 1);
  const lines = contactInformationLines.concat(dealershipLines);
  const salesMenArray = salesMan
    .split('\n')
    .slice(1, salesMan.length - 1)
    .map((salesManLine: string) => {
      const salesManLineSplit = salesManLine.split('	');

      return {
        name: salesManLineSplit[0],
        email: salesManLineSplit[1],
        phone: salesManLineSplit[2],
      };
    });
  const parsedObject: any = {
    loginEmail,
    salesMan: salesMenArray,
  };

  lines.forEach(line => {
    const [key, value] = line.split('\t');

    parsedObject[key] = value;
  });

  const renamedObject: IUserPayload = {
    email: loginEmail,
    displayName: parsedObject.dealershipName,
    salesMan: salesMenArray as any,
    contactPerson: {
      phoneNumber: parsedObject.Phone,
      name: parsedObject.contact,
      email: parsedObject.Email,
      title: parsedObject.Title,
    },
    address: {
      address: parsedObject.Address,
      city: parsedObject.City,
      country: parsedObject.Country,
      postalCode: parsedObject['Postal code'],
      companyId: parsedObject['Company ID / VAT Number'],
    },
    userName: loginEmail,
  };

  // This is the completed parse object without any salesman
  return renamedObject;
};
