/* eslint-disable no-fallthrough */
import { Dispatch, FC, SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { StyledComponent } from '@emotion/styled';
import { FormControl, MenuItem, SelectChangeEvent, SelectProps, Theme } from '@mui/material';
import { MUIStyledCommonProps } from '@mui/system';

import { OrderQAStatus, OrderStatus } from '../../../constants';
import { changeOrderStatus } from '../../../setup/api';
import { alertPayload, useFetchWithAlert } from '../../../setup/hooks/useFetchWithAlert';
import { customButtonColors } from '../../helpers';

import { CustomDropdown } from './CustomDropDown';

// Wrapped OrderStatus & OrderQAStatus since both are used together many times on the component
type TOrderStatusAndQAStatus = OrderStatus | OrderQAStatus;

interface IProps {
  status: TOrderStatusAndQAStatus;
  setOrderStatus: Dispatch<SetStateAction<{ status: OrderStatus; QAstatus: OrderQAStatus }>>;
  statusOptions: string[];
}

// 👇 this type is necessary in order to assign the correct payload to the styled component, that is why is re-assigned with this type that by default the generic would be 'unknown'
type TCustomDropDown<T> = StyledComponent<
  SelectProps<T> & MUIStyledCommonProps<Theme>,
  Record<string, unknown>,
  Record<string, unknown>
>;
const CustomDropDownOnStatusType = CustomDropdown as TCustomDropDown<TOrderStatusAndQAStatus>;
/**
 * @param {TOrderStatusAndQAStatus} status current order status
 * @returns color from button palette which assigns different colors on different  QAstatus and status
 */
const pickBasedOnOrderStatus = (status: TOrderStatusAndQAStatus) => {
  const { green, grey, orange, red, blue, purple, yellow } = customButtonColors;

  switch (status) {
    case OrderStatus.UPLOADING:
      return blue;

    case OrderStatus.IN_PROGRESS:
      return orange;

    case OrderStatus.CARSWIP_QA:

    case OrderQAStatus.QA:
      return purple;

    case OrderStatus.DELIVERING:
      return customButtonColors.deep_green;

    case OrderStatus.READY_TO_DOWNLOAD:
      return yellow;

    case OrderQAStatus.EDITOR:
      return red;

    case OrderStatus.COMPLETED:

    case OrderQAStatus.APPROVED:
      return green;

    default:
      return grey;
  }
};

/**
 * FIXME: this is a hot fix and it should be removed, currently the order update is not sending back
 * the correct response when the order is updated, once that is fixed this functions should be removed
 *
 * By getting the {@link OrderStatus } or {@link OrderQAStatus} it should load the other one in the cases it is necessary,
 * FX: if an order is change to 'Ready to download' the QAStatus should be changed to 'Approved'
 * @param {TOrderStatusAndQAStatus}  status the order status that is meant to change from the event dropdown
 * @returns a payload with {@link OrderStatus } and {@link OrderQAStatus} in one object
 *
 */
const getStatusPayloadFromStatus = (status: TOrderStatusAndQAStatus) => {
  switch (status) {
    case OrderStatus.UPLOADING:

    case OrderStatus.CARSWIP_QA:

    case OrderStatus.IN_PROGRESS:
      return { status, QAstatus: OrderQAStatus.QA };

    case OrderStatus.DELIVERING:

    case OrderStatus.READY_TO_DOWNLOAD:

    case OrderStatus.COMPLETED:
      return { status, QAstatus: OrderQAStatus.APPROVED };

    case OrderQAStatus.QA:

    case OrderQAStatus.EDITOR:
      return { status: OrderStatus.CARSWIP_QA, QAstatus: status };

    case OrderQAStatus.APPROVED:
      return { status: OrderStatus.READY_TO_DOWNLOAD, QAstatus: status };

    default:
      return { status };
  }
};

/**
 * This dropdown is styled as a button,  since it should display different colors on each status
 * handles a single request that updates the order QAstatus and status by receiving only one the values, and updating the order after the request is successful
 * @param props.status current order status
 * @param props.statusOptions options Array with all the options that can be selected or displayed, must be
 * and enum converted to array and it must match to one enum on the backend
 * @param props.setOrderStatus the setState from the order that will update both QAstatus and order status
 * @returns Orders status dropdown
 */
export const OrderStatusDropDown: FC<IProps> = ({ status, setOrderStatus, statusOptions }) => {
  const intl = useIntl();
  const dropDownStyleOnStatus = {
    backgroundColor: pickBasedOnOrderStatus(status),
    minWidth: '200px',
  };
  const { id } = useParams<TParams>();
  const { request } = useFetchWithAlert(alertPayload(intl.formatMessage({ id: 'ORDER.STATUS.UPDATED' })));

  return (
    <FormControl style={{ padding: '10px' }}>
      <CustomDropDownOnStatusType
        value={status}
        onChange={(e: SelectChangeEvent) => {
          const status = e.target.value as TOrderStatusAndQAStatus;

          request(changeOrderStatus, id, getStatusPayloadFromStatus(status)).then(({ status, QAstatus }: IOrder) => {
            // Updates and lifts the state up
            setOrderStatus({ status, QAstatus });
          });
        }}
        disableUnderline
        variant="standard"
        style={dropDownStyleOnStatus}
      >
        {statusOptions.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </CustomDropDownOnStatusType>
    </FormControl>
  );
};
