import { TConfigurationMedia } from '../../app/pages/styleGuidesV2/utils';
import { AngleCategory } from '../../constants';

/**
 * Takes the key of an object and return a string with first letter as capital and spacing between th words
 * @param {string} keyName  key from objects as string
 * @returns {string} transforms "objectKey" into "Object Key"
 */
export const parseObjectKeyToReadableString = (keyName: string): string => {
  const splitByCapitalLetter = keyName.split(/(?=[A-Z])/);
  const joinedArrayWithSpaces = splitByCapitalLetter.join(' ');
  const addUpperCaseToFirstLetter = joinedArrayWithSpaces.charAt(0).toUpperCase();
  const completedReadableString = addUpperCaseToFirstLetter + joinedArrayWithSpaces.substring(1);

  return completedReadableString;
};
/**
 *  Takes a constant value or key and converts it into a readable string, to display on frontend
 * @param {string} keyName the constant that must be parsed
 * @returns {string}  transforms 'ORDER_EVENT' into 'Order Events'
 */
export const parseEnumKeyToReadableString = (keyName: string): string => {
  const splitBy = keyName.split('_');
  const arrayWithout: string[] = [];

  splitBy.forEach((word: string) => {
    if (word !== '_') {
      const lowerLetters = word.toLowerCase();
      const capitalLetter = lowerLetters.charAt(0).toUpperCase();
      const completedReadableString = capitalLetter + lowerLetters.substring(1);

      arrayWithout.push(completedReadableString);
    }
  });
  const joinedArrayWithSpaces = arrayWithout.join(' ');

  return joinedArrayWithSpaces;
};

export const parseObjectKeyToAngleCategory = (angleCategory: string): AngleCategory => {
  switch (angleCategory) {
    case 'Exterior':
      return AngleCategory.EXTERIOR;

    case 'Interior':
      return AngleCategory.INTERIOR;

    default:
      return AngleCategory.EXTERIOR;
  }
};

/**
 * @param {TConfigurationMedia } mediaType  key from objects as string, media type
 * @returns {IUtilityTypes} string type to save in database
 */
export const parseObjectKeyToUtilityMediaType = (mediaType: TConfigurationMedia): IUtilityTypes => {
  switch (mediaType) {
    case 'customAngleOverlay':
      return 'angle_overlay';

    case 'customFrameOverlay':
      return 'frame_overlay';

    case 'featuredImage':
      return 'feature_image';

    case 'customLogo':
      return 'logo';

    case 'customFloor':
      return 'floor';

    case 'customBackground':
      return 'background';

    default:
      throw new Error('Invalid media type');
  }
};
