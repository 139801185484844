import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Moment from 'react-moment';
import { Link, useLocation } from 'react-router-dom';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Button from '@mui/material/Button';

import { OrderQAStatus, OrderStatus, TagCategory, TagEntity } from '../../../constants';
import { addTagToEntity, approveQAOrderStatus, modifyOrder } from '../../../setup/api';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { User } from '../../../ui/helpers';
import { QaView } from '../order/views/QA';

import { SingleImageEditor } from './SingleImageEditor';

import '../../pages/editing/EditingPage.scss';

type Props = {
  order: IOrder;
  refreshOrderList: () => void;
  updateOrder: (order: IOrder) => void;
  showApprove?: boolean;
};

type OrderPickStatus = {
  picked: boolean;
  coordinator: boolean;
  editor: boolean;
};

const EditorSubMenu = ({ order, refresher }: { order: IOrder; refresher: () => void }) => (
  <ul className="task-items editorSubList row" style={{ maxWidth: '100%' }}>
    {order.images.map((image: IImage) => (
      <SingleImageEditor originalImage={image} order={order} refresher={refresher} />
    ))}
  </ul>
);

const CoordinatorSubMenu = ({ order, updateOrder }: { order: IOrder; updateOrder: (order: IOrder) => void }) => (
  <ul className="task-items editorSubList row" style={{ maxWidth: '100%' }}>
    <QaView
      orderInfo={order}
      updateImagesForOrder={(imageId: string, image: IImage) => {
        const updatedOrder = order;
        const imageToUpdate = updatedOrder.images.find(x => x.id === imageId);

        if (imageToUpdate) {
          updatedOrder.images[updatedOrder.images.indexOf(imageToUpdate)] = image;
        }
        updateOrder(updatedOrder);
      }}
    />
  </ul>
);
/**
 *  EditorOrderRow
 *
 *  Loads an order for the QA in a form of an openable row, once open, this renders a different view for editors and coordinators by checking URL queries
 *
 *
 * @param { IOrder } props.order order information {@link IOrder}
 * @param { () => void } props.refreshOrderList should be a callback that refreshes the order list once a task is picked
 *
 * @returns Row with order information with 2 different views
 *
 * - {@link QaView} with carousel for Coordinators
 * - {@link SingleImageEditor} with image gallery that allows coordinator to download media and uploaded corrected images
 */
export const EditorOrderRow = ({ order, refreshOrderList, updateOrder, showApprove }: Props) => {
  const intl = useIntl();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [noteIsHovered, setNoteIsHovered] = useState(false);
  const [isApproveButtonDisabled, setIsApproveButtonDisabled] = useState(false);

  const [orderPickStatus, setOrderPickStatus] = useState<OrderPickStatus>({
    picked: false,
    coordinator: false,
    editor: false,
  });

  const getOrderPickStatusByTagAndLocation = (order: IOrder, location: string): OrderPickStatus => {
    if (location === '?list=coordinator' && order.tags.find(x => x.category === TagCategory.COORDINATOR)) {
      return { picked: true, coordinator: true, editor: false };
    }

    if (location === '' && order.tags.find(x => x.category === TagCategory.EDITOR)) {
      return { picked: true, coordinator: false, editor: true };
    }

    return { picked: false, coordinator: false, editor: false };
  };

  useEffect(() => {
    setOrderPickStatus(getOrderPickStatusByTagAndLocation(order, location.search));
  }, [location.search, order]);

  const { request } = useFetch();

  const editorTag = User().tags?.find(tag => tag.category === TagCategory.EDITOR);
  const coordinatorTag = User().tags?.find(tag => tag.category === TagCategory.COORDINATOR);

  const handleApprove = () => {
    setIsApproveButtonDisabled(true);

    if (window.confirm(intl.formatMessage({ id: 'ORDER.EDITOR.CONFIRM.APPROVE' }))) {
      request(approveQAOrderStatus, order.id, {
        status: OrderStatus.READY_TO_DOWNLOAD,
        QAstatus: OrderQAStatus.APPROVED,
      })
        .then(refreshOrderList)
        .then(() => {
          setIsOpen(false);
        })
        .finally(() => {
          setIsApproveButtonDisabled(false);
        });
    } else {
      setIsApproveButtonDisabled(false);
    }
  };

  return (
    <>
      <li
        className="item type1"
        style={{ flexDirection: 'column', maxHeight: 'fit-content' }}
        onDoubleClick={() => {
          if (location.search === '' || order.tags.find(x => x.category === TagCategory.COORDINATOR)) {
            setIsOpen(!isOpen);
          }
        }}
      >
        <div
          className="content-container"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div className="task">
            <div
              style={{
                height: '140px',
                width: '140px',
                background: 'black',
              }}
            >
              <img src={order.images[0]?.thumbnailUrl} alt="" className="QA-image" />
            </div>
            <div className="ms-5">{order.orderName}</div>
          </div>

          <div className="status">
            <span className="me-4" style={{ color: 'black' }}>
              <FormattedMessage id="ORDER.EDITOR.PRIORITY" />
            </span>
            <span className="badge badge-pill badge-danger">
              {order?.tags?.find(x => x.category === TagCategory.PRIORITY)?.name}
            </span>
          </div>
          <div className="dates">
            <div>{order?.user?.userName}</div>
          </div>

          <div className="dates">
            <div className="ms-2 me-auto">
              <Moment format="DD/MM/YYYY" date={`${order?.createdAt}`} />
              <br />
              <Moment format="HH:mm:ss" date={`${order?.createdAt}`} />
            </div>
          </div>
          <div style={{ display: 'inline-grid' }}>
            <Link to={`/order/${order.id}/select/`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <Button variant="contained" type="button" style={{ background: 'green', width: '150px', margin: '5px' }}>
                <FormattedMessage id="ORDER.EDITOR.GO_TO" />
              </Button>
            </Link>
            {orderPickStatus.picked ? (
              <>
                <Button
                  style={{ width: '150px', margin: '5px' }}
                  variant="contained"
                  onClick={() => setIsOpen(!isOpen)}
                  children={intl.formatMessage({ id: isOpen ? 'ORDER.EDITOR.CLOSE' : 'ORDER.EDITOR.REVIEW' })}
                />
                {showApprove && (
                  <Button
                    variant="contained"
                    style={{ width: '150px', margin: '5px', backgroundColor: '#07bc0c', color: 'white' }}
                    onClick={handleApprove}
                  >
                    <FormattedMessage id="ORDER.EDITOR.SEND.APPROVE" />
                  </Button>
                )}
              </>
            ) : (
              <Button
                style={{ width: '150px', margin: '5px' }}
                variant="contained"
                onClick={() => {
                  if (location.search === '?list=coordinator') {
                    request(addTagToEntity, order.id, TagEntity.ORDER, coordinatorTag?.id).then(refreshOrderList);
                  } else {
                    request(addTagToEntity, order.id, TagEntity.ORDER, editorTag?.id).then(refreshOrderList);
                  }
                }}
              >
                <span className="fs-bold fs-7 fw-bold">
                  <FormattedMessage id="ORDER.EDITOR.PICK" />
                </span>
              </Button>
            )}

            {orderPickStatus.picked && orderPickStatus.coordinator ? (
              order.images.some(x => x.approved === false) ? (
                <Button
                  color="error"
                  style={{ width: '150px', margin: '5px' }}
                  onClick={() =>
                    window.confirm(intl.formatMessage({ id: 'ORDER.EDITOR.CONFIRM.EDIT' })) &&
                    request(modifyOrder, order.id, { status: OrderStatus.CARSWIP_QA, QAstatus: OrderQAStatus.EDITOR })
                      .then(refreshOrderList)
                      .then(() => setIsOpen(false))
                  }
                  variant="contained"
                >
                  <FormattedMessage id="ORDER.EDITOR.SEND.EDIT" />
                </Button>
              ) : (
                <Button
                  variant="contained"
                  disabled={isApproveButtonDisabled}
                  style={{ width: '150px', margin: '5px', backgroundColor: '#07bc0c', color: 'white' }}
                  onClick={handleApprove}
                >
                  <FormattedMessage id="ORDER.EDITOR.SEND.APPROVE" />
                </Button>
              )
            ) : null}
          </div>
        </div>
        {order.styleGuide?.customerNote && (
          <div
            onMouseEnter={() => setNoteIsHovered(true)}
            onMouseLeave={() => setNoteIsHovered(false)}
            style={{
              maxWidth: '1212px',
              alignSelf: 'baseline',
              fontStyle: 'italic',
              boxSizing: 'content-box',
              border: 'solid 1px rgb(255 230 236)',
              padding: '3px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              backgroundColor: 'rgb(255 230 236)',
              borderLeft: 'solid 4px red',
              ...(noteIsHovered && { overflow: 'visible', whiteSpace: 'normal' }),
            }}
          >
            <WarningAmberIcon style={{ color: 'red', fontSize: '2rem', marginRight: '5px' }} />
            <span style={{ fontWeight: '500' }}>
              <FormattedMessage id="ORDER.EDITOR.NOTE" />
            </span>
            {order?.styleGuide?.customerNote}
          </div>
        )}
      </li>

      {isOpen ? (
        location.search === '?list=coordinator' ? (
          <CoordinatorSubMenu order={order} updateOrder={updateOrder} />
        ) : (
          <EditorSubMenu order={order} refresher={refreshOrderList} />
        )
      ) : (
        <div />
      )}
    </>
  );
};
