import { Avatar, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { Language } from '../../../constants';

export type LanguageDropdownProps = {
  options: Language[];
  value: string;
  onChange: (event: SelectChangeEvent) => void;
};

export const LanguageDropdown = ({ onChange, options, value }: LanguageDropdownProps) => {
  const selectedItem = options.find(o => o.code === value);
  const label = selectedItem?.code;

  return (
    <FormControl style={{ width: '100%' }} size="small">
      <Select
        value={label}
        onChange={onChange}
        label
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '44px' }}
      >
        {options.map(option => (
          <MenuItem key={option.code} value={option.code}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Avatar alt={option.name} src={option.flag} sx={{ height: '30px', width: '30px' }} />
              {/* <div style={{ marginLeft: '10px', fontWeight: 'bold' }}>{option.name}</div> */}
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
