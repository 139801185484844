import { FC, useEffect } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import Stack from '@mui/material/Stack';
import { format, subDays } from 'date-fns';

import { UserRole } from '../../../constants';
import { parseObjectKeyToReadableString } from '../../../setup/helpers/parseObjectKeySyntaxToReadableString';
import { useQueryParams } from '../../../setup/hooks/useQueryParams';
import { User } from '../../helpers';

import { convertStringQueryToTextFieldReadableDate } from './helpers';

enum DateFilter {
  FROM = 'from',
  TO = 'to',
}

type Props = {
  dateFilterType: DateFilter;
};
/**
 * Returns a date n days ago in the format "YYYY-MM-DD"
 * @param {number} n - the number of days to subtract from the current date
 * @returns {string} - the date in the format "YYYY-MM-DD"
 */
const getNDaysAgoFromCurrentDay = (n: number) => {
  const currentDate = new Date();
  // Subtract n days from the current date
  const nDaysAgo = subDays(currentDate, n);
  // Format the date as a string in the format "YYYY-MM-DD"
  const formattedDate = format(nDaysAgo, 'yyyy-MM-dd');

  return formattedDate;
};
const defaultHourMinutesAndSeconds = ' 00:00:00.0000';
const currentDateMinusFourteenDays = getNDaysAgoFromCurrentDay(14).toString();

export const dateFilterTypes: DateFilter[] = Object.values(DateFilter);

export const DatePickerFilter: FC<Props> = ({ dateFilterType }) => {
  const [urlQueries, setDateQuery] = useQueryParams();
  const { userRole } = User();
  const convertValueToValidMuiString = () => {
    if (dateFilterType === DateFilter.FROM && urlQueries.from === undefined) {
      return '';
    }
    // The default value of to is an empty string
    if (dateFilterType === DateFilter.TO && urlQueries.to === undefined) {
      return '';
    }
    return convertStringQueryToTextFieldReadableDate(urlQueries[dateFilterType]);
  };
  const getFromDefaultValuesOnQuery = () => {
    const defaultValue = currentDateMinusFourteenDays + defaultHourMinutesAndSeconds;

    // Only load default value if UserRole is Admin or QA
    if (userRole === UserRole.DEALERSHIP || userRole === UserRole.MANAGEMENT) {
      return '';
    }

    return setDateQuery(dateFilterType, defaultValue);
  };
  const getDefaultValueIfTargetIsEmpty = (type: DateFilter) => {
    if (dateFilterType === DateFilter.FROM) {
      return setDateQuery(type, '');
    }

    return setDateQuery(type, '');
  };

  useEffect(() => {
    if (dateFilterType === DateFilter.FROM && urlQueries.from === undefined) {
      getFromDefaultValuesOnQuery();
    }
  }, []);

  return (
    <Stack spacing={0} component="form" className="d-flex">
      <TextField
        label={parseObjectKeyToReadableString(dateFilterType)}
        type="date"
        value={convertValueToValidMuiString()}
        onChange={({ target }) => {
          if (!target.value) {
            // Will add default date if the the value is cleared
            return getDefaultValueIfTargetIsEmpty(dateFilterType);
          }

          return setDateQuery(dateFilterType, target.value + defaultHourMinutesAndSeconds);
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      ,
    </Stack>
  );
};
