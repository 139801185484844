import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert } from '@mui/material';
import { setTimeout } from 'timers';

import { IUserPayload } from '../../../pages/account/CreateDealership';

import { parseTextToObject } from './utils';

interface IPasteboxProps {
  getPastedData: (data: IUserPayload) => void;
}

/**
 *  Display text box where can be pasted a GMAIL Form and returns parsed text to  {@link CreateDealership} form
 *
 * @param {(data: IUserPayload) => void} props.getPastedData callback that returns the parsed text in form of object to the dealership form
 *
 * @returns Text box that parsed formatted text to JSON into the {@link CreateDealership} form
 */
const PasteBox: FC<IPasteboxProps> = ({ getPastedData }) => {
  const intl = useIntl();
  const [paste, setPaste] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  return (
    <>
      <label className="d-flex align-items-center fs-5 fw-bold mb-5">
        <span>
          <FormattedMessage id="PASTE_BOX.TITLE" />
        </span>
        <i
          className="fas fa-exclamation-circle ms-2 fs-7"
          data-bs-toggle="tooltip"
          title={intl.formatMessage({ id: 'PASTE_BOX.SUBTITLE' })}
        />
      </label>
      {error && (
        <Alert variant="outlined" severity="error" className="mb-5">
          <FormattedMessage id="PASTE_BOX.INVALID_ALERT" />
        </Alert>
      )}
      <div className="col-12">
        <textarea
          style={{ minHeight: '500px' }}
          className={`form-control form-control-lg userInputFields ${error ? 'is-invalid' : 'form-control-solid '}`}
          name="Text1"
          cols={40}
          rows={5}
          onChange={e => {
            setPaste(e.target.value);
          }}
          placeholder={intl.formatMessage({ id: 'PASTE_BOX.EMAIL_PLACEHOLDER' })}
        />
        <button
          type="button"
          className="btn btn-lg btn-primary mt-3"
          onClick={() => {
            try {
              const parsedObject = parseTextToObject(paste);

              getPastedData(parsedObject);
            } catch (err) {
              setError(true);
              setTimeout(() => {
                setError(false);
              }, 10000);
            }
          }}
        >
          <FormattedMessage id="PASTE_BOX.CONVERT" />
        </button>
      </div>
    </>
  );
};

export { PasteBox };
