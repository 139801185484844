import { FC, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Alert, AlertTitle } from '@mui/material';
import { Auth } from 'aws-amplify';
import { Formik, FormikProps, FormikValues } from 'formik';
import * as Yup from 'yup';

import { RequestPasswordRecoveryForm } from '../../../components/auth/RequestPasswordRecoveryForm';
import { VerifyNewPasswordForm } from '../../../components/auth/VerifyNewPasswordForm';
import { getErrorMessage } from '../errorHandler';
import { ForgotPasswordProps } from '../interfaces';

const inits = {
  email: '',
  newPassword: '',
  verificationCode: '',
};

const verifyNewPasswordSchema = (intl: IntlShape) =>
  Yup.object().shape({
    newPassword: Yup.string()
      .min(10, `${intl.formatMessage({ id: 'AUTH.VALIDATION.MIN_LENGTH_FIELD' })} 8`)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%.,/_+^&*])(?=.{8,})/,
        intl.formatMessage({ id: 'AUTH.VALIDATION.PASSWORD_FORMAT' }),
      )
      .max(50, `${intl.formatMessage({ id: 'AUTH.VALIDATION.MAX_LENGTH_FIELD' })} 50`)
      .required(
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.REQUIRED' },
          { field: intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD' }) },
        ),
      ),
    verificationCode: Yup.string()
      .min(3, `${intl.formatMessage({ id: 'AUTH.VALIDATION.MIN_LENGTH_FIELD' })} 3`)
      .max(10, `${intl.formatMessage({ id: 'AUTH.VALIDATION.MAX_LENGTH_FIELD' })} 10`)
      .required(
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.REQUIRED' },
          { field: intl.formatMessage({ id: 'AUTH.REGISTER.CODE' }) },
        ),
      ),
  });

const forgotPasswordSchema = (intl: IntlShape) =>
  Yup.object().shape({
    email: Yup.string()
      .email(
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.WRONG_EMAIL_FORMAT' },
          { field: intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' }) },
        ),
      )
      .min(3, `${intl.formatMessage({ id: 'AUTH.VALIDATION.MIN_LENGTH_FIELD' })} 3`)
      .max(100, `${intl.formatMessage({ id: 'AUTH.VALIDATION.MAX_LENGTH_FIELD' })} 100`)
      .required(
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.REQUIRED' },
          { name: intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' }) },
        ),
      ),
  });

export const ForgotPassword: FC = () => {
  const intl = useIntl();
  const [error, setError] = useState<string | undefined>(undefined);
  const [renderVerificationForm, setRenderVerificationForm] = useState(false);
  const [verificationCodeSent, setVerificationCodeSent] = useState(false);
  const history = useHistory();

  const sendVerificationCode = ({ email }: FormikValues) => {
    Auth.forgotPassword(email).then(() => {
      setVerificationCodeSent(true);
      setRenderVerificationForm(true);
    });
  };

  const confirmNewPassword = ({ email, verificationCode, newPassword }: FormikValues) => {
    setVerificationCodeSent(false);
    Auth.forgotPasswordSubmit(email, verificationCode, newPassword)
      .then(() => {
        setError(undefined);
        history.push('/auth/login');
      })
      .catch(err => {
        setError(getErrorMessage(err.message));
      });
  };

  const submitForm = (values: FormikValues) => {
    setError(undefined);
    if (renderVerificationForm) {
      return confirmNewPassword(values);
    }
    return sendVerificationCode(values);
  };

  return (
    <Formik
      validationSchema={renderVerificationForm ? verifyNewPasswordSchema(intl) : forgotPasswordSchema(intl)}
      onSubmit={submitForm}
      validateOnMount
      validateOnChange
      initialValues={inits}
    >
      {(props: FormikProps<ForgotPasswordProps>) => (
        <div className="authentication-box">
          {error && (
            <Alert severity="error" className="mb-5" style={{ alignItems: 'center' }}>
              <AlertTitle>
                <FormattedMessage id="ERROR" />
              </AlertTitle>
              <FormattedMessage id={error} />
            </Alert>
          )}
          {verificationCodeSent && (
            <Alert severity="success" className="mb-5">
              <AlertTitle>
                <FormattedMessage id="SUCCESS" />
              </AlertTitle>
              <FormattedMessage id="AUTH.FORGOT.SENT" />
            </Alert>
          )}
          {renderVerificationForm ? (
            <VerifyNewPasswordForm {...props} verificationCode />
          ) : (
            <RequestPasswordRecoveryForm {...props} />
          )}
        </div>
      )}
    </Formik>
  );
};
