import { createStyleGuide, getStyleGuide, postStyleguideConfiguration } from '../../../setup/api';

/**
 * Generates a payload duplication from the given styleguide that can be send as new to the the backend as a copy (removing values like, createdAt, ID, etc)
 * @param styleGuide the style guide response
 * @returns style guide and configurations payload duplication that can be sent to backend without having constrain problems on the database
 */
const convertStyleGuideResponseToNewPayload = (styleGuide: any) => {
  const styleGuideCopy = {
    ...styleGuide,
    background: styleGuide.background?.id,
    floor: styleGuide.floor?.id,
    logo: styleGuide.logo?.id,
    name: `${styleGuide.name}-COPY`,
  };
  const configurationsCopy = styleGuide?.configurations?.map((configuration: any) => {
    const configurationCopy = {
      ...configuration,
      angle: configuration?.angle?.id,
      customAngleOverlay: configuration?.customAngleOverlay?.id,
      customFrameOverlay: configuration?.customFrameOverlay?.id,
      featuredImage: configuration?.featuredImage?.id,
    };

    delete configurationCopy.updatedAt;
    delete configurationCopy.createdAt;
    delete configurationCopy.id;
    return configurationCopy;
  });

  delete styleGuideCopy.id;
  delete styleGuideCopy.updatedAt;
  delete styleGuideCopy.createdAt;
  delete styleGuideCopy.configurations;

  return {
    styleGuideCopy,
    configurationsCopy,
  };
};

/**
 * This function gets a style guide id and post a copy of the chosen styleguide with all its configurations
 * @param {string} styleGuideId ID of the style guide that is going to be duplicated
 * @returns {string} ID of the new created style guide duplication
 */
export const copyStyleGuideConfiguration = async (styleGuideId: string) => {
  const styleGuide = await getStyleGuide(styleGuideId)
    .then(res => res.json())
    .then((styleGuideResponse: IStyleGuide) => styleGuideResponse);
  const { styleGuideCopy, configurationsCopy } = convertStyleGuideResponseToNewPayload(styleGuide);
  const newStyleGuideId = await createStyleGuide(styleGuideCopy).then(async (res: Response) => {
    const { id } = await res.json();

    return id;
  });

  await Promise.all(
    await configurationsCopy.map(async (configuration: any) => {
      configuration.styleGuide = newStyleGuideId;
      return postStyleguideConfiguration(configuration);
    }),
  );

  return newStyleGuideId;
};
