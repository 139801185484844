import { FC, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ApexCharts, { ApexOptions } from 'apexcharts';

import { parseObjectKeyToReadableString } from '../../../setup/helpers/parseObjectKeySyntaxToReadableString';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { StatisticsDropdown } from '../../../ui/components/Dropdown/StatisticsDropdown';
import { TimePeriod } from '../../pages/dashboard/DashboardWrapper';

type Props = {
  chartColor: string;
  strokeColor: string;
  entity: string;
  requestFunction: (timePeriod: TimePeriod, abort: AbortSignal) => Promise<Response>;
};

export const monthsArray = [
  'STATS.MONTH.JANUARY',
  'STATS.MONTH.FEBRUARY',
  'STATS.MONTH.MARCH',
  'STATS.MONTH.APRIL',
  'STATS.MONTH.MAY',
  'STATS.MONTH.JUNE',
  'STATS.MONTH.JULY',
  'STATS.MONTH.AUGUST',
  'STATS.MONTH.SEPTEMBER',
  'STATS.MONTH.OCTOBER',
  'STATS.MONTH.NOVEMBER',
  'STATS.MONTH.DECEMBER',
];

type ChartData = { maxValue: number; categories: string[]; series: number[] };

export const TotalAmountOfEntities: FC<Props> = ({ chartColor, strokeColor, entity, requestFunction }) => {
  const intl = useIntl();
  const [chartData, setChartData] = useState<ChartData>();
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { request, abortController } = useFetch();
  const status = useSelector(
    (state: any): TimePeriod =>
      state.filters.statistics?.[`${entity}${intl.formatMessage({ id: 'STATS' })}`]?.timePeriod,
  );

  const CardTitleTranslations: Record<string, string> = {
    month: intl.formatMessage({ id: 'STATS.MONTH' }),
    day: intl.formatMessage({ id: 'STATS.DAY' }),
    week: intl.formatMessage({ id: 'STATS.WEEK' }),
    Image: intl.formatMessage({ id: 'DATA.IMAGE' }),
    Order: intl.formatMessage({ id: 'DASHBOARD.CARD.ENTITY_ORDER' }),
  };

  const chartOptions = (
    chartColor: string,
    strokeColor: string,
    orderSeries: number[],
    maxValue: number,
    categories: string[],
    entityName: string,
  ): ApexOptions => ({
    series: [
      {
        name: intl.formatMessage({ id: 'STATS.TOTAL.ENTITY' }, { name: entityName }),
        data: orderSeries,
      },
    ],
    chart: {
      height: 350,
      type: 'bar',
      toolbar: { show: false },
    },
    plotOptions: { bar: { borderRadius: 10, dataLabels: { position: 'top' } } },
    dataLabels: {
      enabled: true,
      formatter: val => `${val}  `,
      offsetY: -20,
      style: { fontSize: '12px', colors: [chartColor] },
    },
    xaxis: {
      categories,
      position: 'top',
      axisBorder: { show: false },
      axisTicks: { show: false },
      crosshairs: { fill: { type: 'gradient' } },
      tooltip: { enabled: false },
    },
    yaxis: {
      max: maxValue + maxValue * 0.1,
      axisBorder: { show: false },
      axisTicks: { show: false },
      labels: { show: false, formatter: val => `${val}  ${entityName}` },
    },
    fill: {
      colors: undefined,
      opacity: 1,
      type: 'solid',
      gradient: {
        type: 'horizontal',
        shadeIntensity: 0,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 100],
        colorStops: [],
      },
      pattern: {
        style: 'verticalLines',
        width: 6,
        height: 6,
        strokeWidth: 2,
      },
    },
    colors: [chartColor],
    markers: {
      colors: [chartColor],
      strokeColors: [strokeColor],
      strokeWidth: 3,
    },
    title: {
      text: intl.formatMessage({ id: 'STATS.TIME_FRAME' }),
      floating: true,
      offsetX: 0,
      offsetY: 0,
      align: 'center',
      style: { color: 'white' },
    },
  });

  const getChartStatistics = (timePeriod: TimePeriod) => {
    request(requestFunction, timePeriod, abortController.signal).then(res => {
      if (res) {
        let labels: string[] = [];
        let maximumValue = 0;
        const parsedSeries = res.map((serie: { [key: string]: number; count: number }) => {
          if (serie.count > maximumValue) {
            maximumValue = serie.count;
          }
          if (serie[timePeriod]) {
            labels.push(serie[timePeriod].toString());
          }
          return serie.count;
        });

        if (timePeriod === 'month') {
          labels = labels.map(label => intl.formatMessage({ id: monthsArray[Number(label) - 1] }));
        }
        setChartData({
          categories: labels,
          series: parsedSeries,
          maxValue: maximumValue,
        });
      }
    });
  };

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    if (chartData && chartData.series && chartData.categories && chartData.maxValue > 0) {
      const chart = new ApexCharts(
        chartRef.current,
        chartOptions(chartColor, strokeColor, chartData?.series, chartData?.maxValue, chartData?.categories, entity),
      );

      if (chart) {
        chart.render();
      }

      return () => {
        if (chart) {
          chart.destroy();
        }
      };
    }
  }, [chartData]);

  useEffect(() => {
    getChartStatistics(status);
    return () => {
      abortController.abort();
    };
  }, [status]);

  return (
    <div className="card card-xl-stretch mb-xl-8">
      <div className="card-header border-0 py-5 bg-#c4001d" style={{ backgroundColor: '#fff' }}>
        <h3 className="card-title fw-bolder" style={{ color: chartColor }}>
          {`${CardTitleTranslations[parseObjectKeyToReadableString(entity)]} ${intl.formatMessage({
            id: 'STATS',
          })} -  ${CardTitleTranslations[status]}`}
        </h3>
        <div className="card-toolbar">
          <StatisticsDropdown
            title={intl.formatMessage({ id: 'STATS.TIME_FRAME' })}
            options={['month', 'week', 'day']}
            chartName={`${entity}${intl.formatMessage({ id: 'STATS' })}`}
          />
        </div>
      </div>
      <div className="card-body p-0">
        {/* begin::Chart */}
        <div
          ref={chartRef}
          className={`mixed-widget-2-chart card-rounded-bottom bg-${chartColor}`}
          style={{ backgroundColor: '#fff' }}
        />
        {/* end::Chart */}
      </div>
    </div>
  );
};
