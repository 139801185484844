import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, Route, Switch } from 'react-router-dom';

import { PageLink, PageTitle } from '../../../_carswip/layout/core';
import { UserRole } from '../../../constants';
import { User } from '../../../ui/helpers';

import { AngleList } from './views/angles/AngleList';
import { AppSettingsList } from './views/appSettings/AppSettingsList';
import { AppVersionList } from './views/appVersions/AppVersionList';
import { BannerList } from './views/banners/BannerList';
import { CarTypesList } from './views/carTypes/CarTypesList';
import { OrderDeliveryMessageList } from './views/orderDeliveryMessage/OrderDeliveryMessageList';
import { StyleGuideListV2 } from './views/styleGuideV2/StyleGuidesListV2';
import { TagList } from './views/tags/TagList';
import { AdminHeader } from './AdminHeader';

/**
 * Includes all the admin routes, that includes lists with the option to modify, search and create entities (style guide, cartype, tags, app version, etc)
 */
const AdminPage: React.FC = () => {
  const intl = useIntl();
  const { userRole } = User();

  const adminBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: 'MENU.ADMIN' }),
      path: '/admin/overview',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];

  return (
    <>
      <AdminHeader />
      {userRole === UserRole.ADMIN && (
        <Switch>
          <Route path="/admin/style-guides-v2">
            <PageTitle breadcrumbs={adminBreadCrumbs}>
              <FormattedMessage id="MENU.ADMIN.STYLE_GUIDES" />
            </PageTitle>
            <StyleGuideListV2 />
          </Route>

          <Route path="/admin/angles">
            <PageTitle breadcrumbs={adminBreadCrumbs}>
              <FormattedMessage id="MENU.ADMIN.ANGLES" />
            </PageTitle>
            <AngleList />
          </Route>
          <Route path="/admin/angle/:id">
            <PageTitle breadcrumbs={adminBreadCrumbs}>
              <FormattedMessage id="MENU.ADMIN.ANGLES" />
            </PageTitle>
            <AngleList />
          </Route>
          <Route path="/admin/car-types">
            <PageTitle breadcrumbs={adminBreadCrumbs}>
              <FormattedMessage id="MENU.ADMIN.CAR_TYPES" />
            </PageTitle>
            <CarTypesList />
          </Route>
          <Route path="/admin/car-type/:id">
            <PageTitle breadcrumbs={adminBreadCrumbs}>
              <FormattedMessage id="MENU.ADMIN.CAR_TYPES" />
            </PageTitle>
            <CarTypesList />
          </Route>
          <Route path="/admin/app-versions">
            <PageTitle breadcrumbs={adminBreadCrumbs}>
              <FormattedMessage id="MENU.ADMIN.VERSION" />
            </PageTitle>
            <AppVersionList />
          </Route>
          <Route path="/admin/app-version/:id">
            <PageTitle breadcrumbs={adminBreadCrumbs}>
              <FormattedMessage id="MENU.ADMIN.VERSION" />
            </PageTitle>
            <AppVersionList />
          </Route>
          <Route path="/admin/tags">
            <PageTitle breadcrumbs={adminBreadCrumbs}>
              <FormattedMessage id="MENU.ADMIN.TAGS" />
            </PageTitle>
            <TagList />
          </Route>
          <Route path="/admin/tag/:id">
            <PageTitle breadcrumbs={adminBreadCrumbs}>
              <FormattedMessage id="MENU.ADMIN.TAGS" />
            </PageTitle>
            <TagList />
          </Route>
          <Route path="/admin/banners">
            <PageTitle breadcrumbs={adminBreadCrumbs}>
              <FormattedMessage id="MENU.ADMIN.BANNERS" />
            </PageTitle>
            <BannerList />
          </Route>
          <Route path="/admin/banner/:id">
            <PageTitle breadcrumbs={adminBreadCrumbs}>
              <FormattedMessage id="MENU.ADMIN.BANNERS" />
            </PageTitle>
            <BannerList />
          </Route>
          <Route path="/admin/app-settings">
            <PageTitle breadcrumbs={adminBreadCrumbs}>
              <FormattedMessage id="MENU.ADMIN.SETTINGS" />
            </PageTitle>
            <AppSettingsList />
          </Route>
          <Route path="/admin/order-delivery-message">
            <PageTitle breadcrumbs={adminBreadCrumbs}>
              <FormattedMessage id="MENU.ADMIN.ORDER" />
            </PageTitle>
            <OrderDeliveryMessageList />
          </Route>
          <Redirect from="/admin" to="/admin/style-guides-v2" />
        </Switch>
      )}

      {userRole === UserRole.QA && (
        <Switch>
          <Route path="/admin/style-guides-v2">
            <PageTitle breadcrumbs={adminBreadCrumbs}>
              <FormattedMessage id="MENU.ADMIN.STYLE_GUIDES" />
            </PageTitle>
            <StyleGuideListV2 />
          </Route>
          <Redirect from="/admin" to="/admin/style-guides-v2" />
        </Switch>
      )}
    </>
  );
};

export const AdminWrapper: React.FC = () => (
  <>
    <PageTitle breadcrumbs={[]}>
      <FormattedMessage id="MENU.ADMIN" />
    </PageTitle>
    <AdminPage />
  </>
);
