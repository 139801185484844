import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { FormattedMessage, useIntl } from 'react-intl';

import { getDeviceStatistics } from '../../../setup/api/statistics';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { DashboardCard, DashboardCardHeader } from '../../../ui/components/MainGridContainer/DashboardGrid';
import { TitleTag } from '../../../ui/components/Title/TitleTag';

type DeviceStatisticsResult = {
  mobile: number;
  webApp: number;
};

export const DeviceStatistics: React.FC = () => {
  const intl = useIntl();
  const { request, abortController } = useFetch();
  const [statistics, setStatistics] = useState<DeviceStatisticsResult>();

  useEffect(() => {
    request(getDeviceStatistics).then(res => {
      if (res) {
        setStatistics(res);
      }
      return () => {
        abortController.abort();
      };
    });
  }, []);

  const options = { labels: [intl.formatMessage({ id: 'STATS.MOBILE' }), intl.formatMessage({ id: 'STATS.WEBAPP' })] };
  const series = [statistics?.mobile, statistics?.webApp];

  return (
    <div>
      {statistics && (
        <DashboardCard>
          <DashboardCardHeader>
            <TitleTag children={<FormattedMessage id="STATS.DEVICE" />} />
          </DashboardCardHeader>
          <Chart
            style={{ display: 'flex', justifyContent: 'center' }}
            options={options}
            series={series}
            type="pie"
            width="380"
          />
        </DashboardCard>
      )}
    </div>
  );
};
