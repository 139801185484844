import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

import { getAveragePicturePerOrder } from '../../../setup/api/statistics';
import { useFetch } from '../../../setup/hooks/fetch.hook';

import { StatisticsForTotalCount } from './StatisticsForTotalCount';

export const AveragePicturePerCar: FC = () => {
  const intl = useIntl();
  const { request, abortController } = useFetch();
  const [averagePicturePerCar, setAveragePicturePerCar] = useState<{ avg: string }[]>();

  const getAveragePicturePerCar = () => {
    request(getAveragePicturePerOrder, abortController.signal).then(res => {
      const parsed = Number(res[0].avg) * 10;
      const rounded = Math.round(parsed) / 10;

      res[0].avg = rounded.toString();

      setAveragePicturePerCar(res);
    });
  };

  useEffect(() => {
    getAveragePicturePerCar();
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <div className="col-3 ">
      {averagePicturePerCar && (
        <StatisticsForTotalCount
          className="card-xl-stretch mb-xl-8"
          iconMUI={<AddAPhotoIcon color="action" />}
          color="warning"
          iconColor="white"
          title={averagePicturePerCar && averagePicturePerCar[0].avg}
          description={intl.formatMessage({ id: 'STATS.AVG_PIC_CAR' })}
        />
      )}
    </div>
  );
};
