import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { UserRole } from '../../constants';
import { RootState } from '../../setup';
import { FallbackView } from '../../ui/components/FallbackView/FallbackView';

import { adminRoutes, privateRoutes, QARoutes } from './constants';

export const LocationDisplay = (): JSX.Element => {
  // Location must be loaded here for testing purposes
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const location = useLocation();

  return <div data-testid="location-display" />;
};

function createRoutes(routes: [string, React.ComponentType<unknown>][]) {
  return routes.map(([path, jsxPageElement]) => <Route path={path} component={jsxPageElement} />);
}
/**
 * This components load all private routes (once user is logged in) by user role, checking user information on redux
 */
export function PrivateRoutes(): JSX.Element {
  const userRole = useSelector((state: RootState) => state?.auth?.user?.userRole);

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Redirect from="/auth" to="/dashboard" />
        <Redirect exact from="/" to="/dashboard" />
        {createRoutes(privateRoutes)}
        {userRole === UserRole.ADMIN && createRoutes(adminRoutes)}
        {userRole === UserRole.QA && createRoutes(QARoutes)}
        <Redirect to="error/404" />
      </Switch>
      <LocationDisplay />
    </Suspense>
  );
}
