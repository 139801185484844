import config from 'react-global-configuration';

import { generateRequestOptions } from './helpers';

export const getPermissions = (abort: AbortSignal): Promise<Response> => {
  const url = `${config.get('ApiUrl').Rest}/permissions`;

  return fetch(url, generateRequestOptions(`GET`, abort));
};

export const getPermissionsByUserId = (userId: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/permissions/get-permissions-for-user-by-user-id/${userId}`, {
    method: `GET`,
    headers: { 'Content-Type': `application/json` },
    mode: `cors`,
    credentials: `include`,
  });
