import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Chart, ChartItem } from 'chart.js';

import { getDealershipOrderStatistics } from '../../../setup/api/statistics';
import { useFetch } from '../../../setup/hooks/fetch.hook';

const OrderGauge: React.FC<{ userId: string }> = ({ userId }) => {
  const intl = useIntl();
  const { request } = useFetch();
  const [gaugeData, setGaugeData] = useState<{ currentWeek: number; lastWeek: number; maxScore?: number } | undefined>(
    undefined,
  );

  const getData = () => {
    request(getDealershipOrderStatistics, userId, 'week').then(res => {
      if (res) {
        let currentWeek = 0;
        let maximunScore = 1;

        if (res.weeklyOrders[res.weeklyOrders.length - 2]) {
          const reduceTopWeek = res.weeklyOrders.reduce((acc: number, current: { count: number }) =>
            acc > current.count ? acc : current.count,
          );

          maximunScore = reduceTopWeek;
        }
        if (res.weeklyOrders[res.weeklyOrders.length - 1]) {
          currentWeek = res?.weeklyOrders[res.weeklyOrders.length - 1].count;
        }
        setGaugeData({
          currentWeek,
          lastWeek: maximunScore - currentWeek,
          maxScore: maximunScore,
        });
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const ctx = document.getElementById('myChart') as HTMLCanvasElement;
    const context = ctx.getContext('2d');

    if (gaugeData) {
      new Chart(context as unknown as ChartItem, {
        type: 'doughnut',
        data: {
          labels: [
            intl.formatMessage({ id: 'STATS.CARS.CURRENT_WEEK' }),
            intl.formatMessage({ id: 'STATS.CARS.LEFT' }),
          ],
          datasets: [
            {
              data: [gaugeData.currentWeek, gaugeData.lastWeek],
              backgroundColor: ['green', '#ffd600'],
            },
          ],
        },
        options: {
          // Start angle in degrees
          rotation: 270,
          // Sweep angle in degrees
          circumference: 180,
        },
      });
    }
  }, [gaugeData]);

  return (
    <div className="card mb-xl-8">
      <div className="card-body d-flex flex-column">
        {gaugeData && gaugeData.lastWeek && (
          <h2 style={{ alignSelf: 'center', justifyContent: 'center' }}>
            <FormattedMessage id="STATS.TOP_SCORE" values={{ score: gaugeData?.maxScore }} />
          </h2>
        )}
        <canvas id="myChart" />
      </div>
    </div>
  );
};

export { OrderGauge };
