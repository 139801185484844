import { FC } from 'react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { ModalCarswip } from '../Modal';
import { ModalSubtitle } from '../Title/ModalSubtitle';

type RemoveOrderFromQueueModalProps = {
  setIsOpen: (value: React.SetStateAction<string | undefined>) => void;
  isOpen?: string;
  removeOrderDeliveryMessage: () => void;
};

export const RemoveOrderFromQueueModal: FC<RemoveOrderFromQueueModalProps> = ({
  isOpen,
  setIsOpen,
  removeOrderDeliveryMessage,
}) => {
  const intl = useIntl();

  return (
    <ModalCarswip
      title={intl.formatMessage({ id: 'ORDER.REMOVE.CONFIRM' })}
      open={Boolean(isOpen)}
      onClose={() => setIsOpen(undefined)}
      fullWidth={false}
    >
      <ModalSubtitle children={intl.formatMessage({ id: 'ORDER.REMOVE.WARNING' })} />
      <Box display="flex" justifyContent="flex-end">
        <Button
          children={intl.formatMessage({ id: 'ORDER.REMOVE.CONFIRM' })}
          variant="contained"
          color="error"
          onClick={removeOrderDeliveryMessage}
        />
      </Box>
    </ModalCarswip>
  );
};
