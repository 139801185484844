import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import ApexCharts, { ApexOptions } from 'apexcharts';

import { getCSSVariableValue } from '../../../_carswip/assets/ts/_utils';
import { TagCategory } from '../../../constants';
import {
  getQAActivitiesByTimePeriod,
  IQueriesTimeStatistics,
  QueryTimeFrame,
  QueryTimePeriod,
} from '../../../setup/api/statistics';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { StatisticsDropdown } from '../../../ui/components/Dropdown/StatisticsDropdown';
import { DashboardCard } from '../../../ui/components/MainGridContainer/DashboardGrid';

type IOptionSeries = {
  name: string;
  data: number[];
};

interface IChartProps {
  title: string;
  subTitle: string;
  timeFrame: QueryTimeFrame;
  chartColor: string;
  chartHeight: string;
  tagCategoryFromQA: TagCategory;
}

const Chart: React.FC<IChartProps> = ({ title, chartColor, timeFrame, chartHeight, tagCategoryFromQA }) => {
  const intl = useIntl();
  const chartRef = useRef<HTMLDivElement | null>(null);
  const [series, setSeries] = useState<IOptionSeries[] | undefined>(undefined);
  const status = useSelector((state: any): QueryTimePeriod => state.filters?.statistics?.qaStatistics?.timePeriod);
  const { request, abortController } = useFetch();

  const chartOptions = (chartColor: string, chartHeight: string, series: IOptionSeries[]): ApexOptions => {
    const labelColor = getCSSVariableValue('--bs-gray-500');
    const borderColor = getCSSVariableValue('--bs-gray-200');
    const secondaryColor = getCSSVariableValue('--bs-gray-300');
    const thirdColor = getCSSVariableValue('--bs-red');
    const fourthColor = getCSSVariableValue('--bs-green');
    const baseColor = getCSSVariableValue(`--bs-${chartColor}`);

    return {
      series,
      chart: {
        fontFamily: 'inherit',
        type: 'bar',
        height: chartHeight,
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50%',
          borderRadius: 5,
        },
      },
      legend: { show: false },
      dataLabels: { enabled: false },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: [intl.formatMessage({ id: 'STATS.QA.MEMBER' })],
        axisBorder: { show: false },
        axisTicks: { show: false },
        labels: { style: { colors: labelColor, fontSize: '12px' } },
      },
      yaxis: { labels: { style: { colors: labelColor, fontSize: '12px' } } },
      fill: { type: 'solid' },
      states: {
        normal: { filter: { type: 'none', value: 0 } },
        hover: { filter: { type: 'none', value: 0 } },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: { type: 'none', value: 0 },
        },
      },
      tooltip: {
        style: { fontSize: '12px' },
        y: { formatter: val => `${val} ${intl.formatMessage({ id: 'STATS.ORDERS' })}` },
      },
      colors: [baseColor, secondaryColor, thirdColor, fourthColor],
      grid: {
        padding: { top: 10 },
        borderColor,
        strokeDashArray: 4,
        yaxis: { lines: { show: true } },
      },
      noData: {
        text: intl.formatMessage({ id: 'ACTION.PENDING' }),
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: '#000000',
          fontSize: '14px',
          fontFamily: 'Helvetica',
        },
      },
    };
  };

  const getQAOrderEditedByUser = async (queries: IQueriesTimeStatistics): Promise<IOptionSeries[]> =>
    request(getQAActivitiesByTimePeriod, queries, abortController.signal)
      .then((res: Array<{ tags_name: string; totalorderswithevents: number }>) => {
        if (res) {
          return res.map((users: { tags_name: string; totalorderswithevents: number }) => ({
            name: users.tags_name,
            data: [users.totalorderswithevents],
          }));
        }
        return [];
      })
      .catch();

  useEffect(() => {
    if (series) {
      const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, series));

      chart.render();
      return () => {
        if (chart) {
          chart.destroy();
        }
      };
    }
  }, [series]);

  useEffect(() => {
    getQAOrderEditedByUser({
      timePeriod: status,
      timeFrame,
      tagCategoryFromQA,
    }).then((chartData: IOptionSeries[]) => {
      setSeries(chartData);
    });
    return () => {
      abortController.abort();
    };
  }, [status]);

  const timeFrameTranslations: Record<string, string> = {
    day: intl.formatMessage({ id: 'STATS.DAY' }),
    week: intl.formatMessage({ id: 'STATS.WEEK' }),
    month: intl.formatMessage({ id: 'STATS.MONTH' }),
    Last: intl.formatMessage({ id: 'STATS.LAST' }),
    Current: intl.formatMessage({ id: 'STATS.CURRENT' }),
  };

  const translatedStatus = timeFrameTranslations[status];
  const timeFramed = `${timeFrame.charAt(0).toUpperCase()}${timeFrame.slice(1)}`;
  const translatedTimeFrame = timeFrameTranslations[timeFramed];

  return (
    <div className="card-body p-0 d-flex flex-column overflow-hidden">
      {/* begin::Hidden */}
      <div className="d-flex justify-content-between px-9 pt-9 pb-3">
        <div className="d-flex">
          <div className="me-2">
            <span className="fw-bolder text-gray-800 d-block fs-3">{title}</span>
            <span className="text-gray-400 fw-bold">{`${translatedTimeFrame} ${translatedStatus} `}</span>
          </div>

          <div className={`fw-bolder fs-3 text-${chartColor}`}>
            {intl.formatMessage(
              { id: 'STATS.TOTAL' },
              { number: (series && series.reduce((a, b) => a + b.data[0], 0)) ?? 0 },
            )}
          </div>
        </div>
        <div>
          {timeFrame === QueryTimeFrame.LAST && (
            <StatisticsDropdown
              title={intl.formatMessage({ id: 'STATS.TIME_FRAME' })}
              options={['day', 'week', 'month']}
              chartName="qaStatistics"
            />
          )}
        </div>
      </div>
      {/* end::Hidden */}
      {/* begin:: Chart */}
      <div ref={chartRef} className="mixed-widget-10-chart" />
      {/* end::Chart */}
    </div>
  );
};
const StatisticsOrdersEditedByQAMember: React.FC = () => {
  const intl = useIntl();

  return (
    <>
      <Grid item xs={6}>
        <DashboardCard>
          <Chart
            title={intl.formatMessage({ id: 'STATS.ORDERS.EDITOR' })}
            subTitle={intl.formatMessage({ id: 'STATS.ORDERS.CURRENT_MONTH' })}
            tagCategoryFromQA={TagCategory.EDITOR}
            chartColor="primary"
            timeFrame={QueryTimeFrame.CURRENT}
            chartHeight="300px"
          />
        </DashboardCard>
      </Grid>
      <Grid item xs={6}>
        <DashboardCard>
          <Chart
            title={intl.formatMessage({ id: 'STATS.ORDERS.EDITOR' })}
            subTitle={intl.formatMessage({ id: 'STATS.ORDERS.CURRENT_MONTH' })}
            chartColor="primary"
            timeFrame={QueryTimeFrame.LAST}
            tagCategoryFromQA={TagCategory.EDITOR}
            chartHeight="300px"
          />
        </DashboardCard>
      </Grid>

      <Grid item xs={6}>
        <DashboardCard>
          <Chart
            title={intl.formatMessage({ id: 'STATS.ORDERS.COORDINATOR' })}
            subTitle={intl.formatMessage({ id: 'STATS.ORDERS.CURRENT_MONTH' })}
            chartColor="primary"
            tagCategoryFromQA={TagCategory.COORDINATOR}
            timeFrame={QueryTimeFrame.CURRENT}
            chartHeight="300px"
          />
        </DashboardCard>
      </Grid>
      <Grid item xs={6}>
        <DashboardCard>
          <Chart
            title={intl.formatMessage({ id: 'STATS.ORDERS.COORDINATOR' })}
            subTitle={intl.formatMessage({ id: 'STATS.ORDERS.CURRENT_MONTH' })}
            tagCategoryFromQA={TagCategory.COORDINATOR}
            chartColor="primary"
            timeFrame={QueryTimeFrame.LAST}
            chartHeight="300px"
          />
        </DashboardCard>
      </Grid>
    </>
  );
};

export { StatisticsOrdersEditedByQAMember };
