import styled from '@emotion/styled';

export const DatagridView = styled.div`
  .grid-wrapper {
    overflow: auto;
    flex-grow: 1;
  }

  .main-checkbox {
    padding-bottom: 14px;
    padding-left: 4px;
  }

  .grid-table {
    width: 100%;
    color: #464e5f;
    background-color: transparent;
    border-collapse: collapse;
    text-align: left;
  }

  .table-row:hover {
    background-color: #f3f6f9;
    cursor: pointer;
  }

  .table-row {
    border-bottom: 1px solid #ecf0f3;

    & td:not(:first-child) {
      padding: 0 1.25rem;
    }

    & td:last-child {
      padding: 0;
    }
  }

  .table-row-no-click {
    border-bottom: 1px solid #ecf0f3;

    & td:not(:first-child) {
      padding: 0 1.25rem;
    }
  }

  .selectable {
    padding-left: 0.6rem;
    vertical-align: middle;

    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
    }
  }

  .selectable-header {
    padding: 0 0 0.5rem 0.6rem;

    .MuiCheckbox-root {
      padding: 5px 9px 9px;
    }
  }

  .completable {
    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
      color: #00000060;
    }
  }

  .grid-header {
    color: #9e9e9e;
    font-weight: 500;
    font-size: 14px;
    padding: 0 1.25rem 1rem;
    white-space: nowrap;
    width: 300px;
    text-align: left;
  }

  .grid-header:first-child {
    padding-left: 0;
  }

  .grid-header:last-child {
    padding: 0 0 1rem;
  }

  .nospaces-header {
    color: #9e9e9e;
    font-weight: 500;
    font-size: 14px;
    padding-bottom: 1rem;
    white-space: nowrap;
    text-align: left;
  }

  .column-center {
    text-align: center;
  }

  .action-icon {
    color: rgba(0, 0, 0, 0.54);
  }

  .share-button {
    padding: 6px 14px;
    color: white;
    background: #599d36;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
    text-transform: uppercase;
    border: 0;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    min-width: 100px;
    outline: none;
    font-size: 12px;
    font-weight: 600;
  }

  .certificate {
    margin-left: 10px;
  }
`;
