import { useSelector } from 'react-redux';

import { RootState } from '../../../../setup';

import { MenuItem } from './MenuItem';

/**
 * Renders menu by user role
 */
export function MenuInner() {
  const userRole = useSelector((state: RootState) => state.auth.user?.userRole);

  return (
    <>
      <MenuItem key="MENU.DASHBOARD" title="MENU.DASHBOARD" to="/dashboard" />
      <MenuItem key="MENU.ORDERS" title="MENU.ORDERS" to="/orders" />
      {userRole === 'QA' && (
        <>
          <MenuItem key="MENU.CUSTOMERS" title="MENU.CUSTOMERS" to="/customers" />
          <MenuItem key="MENU.ADMIN" title="MENU.ADMIN" to="/admin" />
          <MenuItem key="MENU.QA" title="MENU.QA" to="/editing-page" />
        </>
      )}
      {userRole === 'Admin' && (
        <>
          <MenuItem key="MENU.CUSTOMERS" title="MENU.CUSTOMERS" to="/customers" />
          <MenuItem key="MENU.ADMIN" title="MENU.ADMIN" to="/admin" />
          <MenuItem key="MENU.QA" title="MENU.QA" to="/editing-page" />
        </>
      )}
      <MenuItem key="MENU.SALESMEN" title="MENU.SALESMEN" to="/salesmen" />
    </>
  );
}
