import React, { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Moment from 'react-moment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Checkbox, IconButton, Menu, MenuItem } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import { useFetch } from '../../../setup/hooks/fetch.hook';
import { Loader } from '../Louder/Louder';

import { DatagridView } from './Datagrid.style';

import './DataGrid.css';

interface CustomButtonProps extends CustomRequestProps {
  button: (id: string, request: (id: string) => Promise<void>, disabled: boolean) => JSX.Element;
}

interface CustomRequestProps {
  actionName: string;
  requestData: (id: string) => Promise<void>;
}

type Props = {
  data: Array<{
    //
  }>;
  headings: [string, string][];
  renderRow: any;
  selectable?: boolean;
  selected?: string[];
  setSelected?: Dispatch<SetStateAction<Array<string>>>;
  getIdFromItem?: (item: Record<string, unknown>) => string;
  loading?: boolean;
  link?: string;
  createdAt?: boolean;
  updatedAt?: boolean;
  buttonCustom?: CustomButtonProps;
  customMenu?: CustomRequestProps[];
};

type CustomMenuProps = {
  customButtons: CustomRequestProps[];
  item: Record<string, string>;
};
/**
 *
 * @param props.customButtons renders a row and an action for an item
 * @param props.item item on the list that is selected
 * @returns MUI customized button with menu
 */
const CustomMenu: FC<CustomMenuProps> = ({ customButtons, item }) => {
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const { request } = useFetch();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={e => {
          handleClick(e);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
      >
        {customButtons &&
          customButtons.map(button => (
            <MenuItem
              onClick={() => {
                request(button.requestData, item.id);
              }}
            >
              {button.actionName}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

/**
 * @param props.data Array payload with multiple entities
 * @param props.headings tuple of string arrays with the key object name and the column name EXAMPLE: ['customerName', 'Customer Name']
 * @param props.renderRow renders the row structure on the data grid, by mapping the heading
 * @param props.link should include a string with the entity name, this will be used to access the item information on click
 * @param props.customMenu renders a option column that can be used to perform actions on an item
 * @returns list with options and personalized columns
 */
export const DataGridV2: FC<Props> = ({
  // FIXME: createdAt, updatedAt should be removed and customer button should be added as customer button(Customer button must be fixed too because is not loading correctly)
  data = [],
  headings,
  renderRow,
  buttonCustom,
  selectable,
  selected = [],
  setSelected,
  loading,
  link,
  createdAt,
  updatedAt,
  customMenu,
  getIdFromItem,
}) => {
  const renderHeader = (headings: string[][]) =>
    headings.map(([key, header]) => (
      <th key={key} className="grid-header">
        {header}
      </th>
    ));
  const handleChangeSelected = useCallback(
    (id: string) => {
      if (setSelected) {
        let isAlreadySelected = false;
        const removedSelection: string[] = [];

        selected.map((item: string) => {
          if (item === id) {
            return (isAlreadySelected = true);
          }
          removedSelection.push(item);
          return item !== id;
        });

        if (!isAlreadySelected) {
          return setSelected([...selected, id]);
        }
        return setSelected(removedSelection);
      }
    },

    [selected],
  );
  const isItemSelected = (idsArray: string[], itemIdToCheck: string) => {
    let itemSelected = false;

    if (idsArray.some((id: string) => id === itemIdToCheck)) {
      itemSelected = true;
    }

    // idsArray.map((id: string) => {
    //   if (id === itemIdToCheck) {
    //     itemSelected = true;
    //   }
    // });

    return itemSelected;
  };

  return (
    <DatagridView>
      <div className="grid-wrapper" data-testid="datagrid">
        <table className="grid-table">
          {loading && Loader()}
          <thead>
            <tr>
              {selectable && (
                <th className="main-checkbox column-center">
                  <Checkbox data-testid="checkbox" />
                </th>
              )}
              {renderHeader(headings)}
              {buttonCustom && <th className="grid-header">{buttonCustom.actionName}</th>}
              {customMenu && (
                <th className="grid-header">
                  <FormattedMessage id="DATA.OPTIONS" />
                </th>
              )}
              {createdAt && (
                <th className="grid-header">
                  <FormattedMessage id="DATA.CREATED" />
                </th>
              )}
              {updatedAt && (
                <th className="grid-header">
                  <FormattedMessage id="DATA.UPDATED" />
                </th>
              )}
            </tr>
          </thead>
          {loading ? (
            <tbody>
              <tr>
                {selectable && (
                  <td>
                    <Skeleton variant="rectangular" width="100%" height={520} />
                  </td>
                )}

                {headings.map((item: (React.Key | null | undefined)[]) => (
                  <td key={item[0]}>
                    <Skeleton variant="rectangular" width="100%" height={520} />
                  </td>
                ))}
                {buttonCustom && (
                  <td>
                    <Skeleton variant="rectangular" width="100%" height={520} />
                  </td>
                )}
                {customMenu && (
                  <td>
                    <Skeleton variant="rectangular" width="100%" height={520} />
                  </td>
                )}
                {createdAt && (
                  <td>
                    <Skeleton variant="rectangular" width="100%" height={520} />
                  </td>
                )}
                {updatedAt && (
                  <td>
                    <Skeleton variant="rectangular" width="100%" height={520} />
                  </td>
                )}
              </tr>
            </tbody>
          ) : (
            <tbody>
              {data.map(
                (item: Record<string, string>) =>
                  item?.id && (
                    <tr key={item.id as unknown as string} className="table-row h-40px listHover">
                      {selectable && (
                        <td className="selectable column-center">
                          <Checkbox
                            checked={isItemSelected(selected, item.id)}
                            onChange={() => handleChangeSelected(item.id)}
                            aria-label="checkbox"
                          />
                        </td>
                      )}
                      {renderRow(headings, item, link, getIdFromItem)}
                      {buttonCustom && (
                        <td>
                          {buttonCustom.button(item.id, buttonCustom.requestData, isItemSelected(selected, item.id))}
                        </td>
                      )}
                      {customMenu && (
                        <td>
                          <CustomMenu customButtons={customMenu} item={item} />
                        </td>
                      )}
                      {createdAt && (
                        <td>
                          <div className="container-fluid">
                            <Moment className="row" format="YYYY-MM-DD" date={`${item.createdAt}`} />
                            {/* FIXME: the two extra hours should be deleted when they are fixed on the server */}
                            <Moment className="row" format="HH:mm:ss" date={`${item.createdAt}`} />
                          </div>
                        </td>
                      )}
                      {updatedAt && (
                        <td>
                          <Moment format="HH:mm:ss" date={`${item.updatedAt}`} />
                        </td>
                      )}
                    </tr>
                  ),
              )}
            </tbody>
          )}
        </table>
      </div>
    </DatagridView>
  );
};
