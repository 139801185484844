import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Button, Grid } from '@mui/material';

import { getChildAccountDataFromUser, getUsers, postChildAccounts } from '../../../setup/api';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { alertPayload, useFetchWithAlert } from '../../../setup/hooks/useFetchWithAlert';
import { DataGridV2 } from '../../../ui/components/Datagrid/DataGridV2';
import { VerticalDivider } from '../../../ui/components/Divider/VerticalDivider';
import { FilterComponent } from '../../../ui/components/Filters/FilterComponent';
import { renderTestRowsUsers } from '../../../ui/helpers/tables/renderRows';

const listHeaders: Array<[string, string]> = [
  ['displayName', 'Name'],
  ['email', 'Email'],
];

const removeChildAccountsFromUserButton = (id: string, request: (id: string) => Promise<void>, disabled: boolean) => (
  <Button
    variant="contained"
    color="error"
    disabled={!disabled}
    onClick={() => {
      request(id);
    }}
  >
    <RemoveCircleOutlineIcon />
    <FormattedMessage id="CHILD.ACTION.REMOVE" />
  </Button>
);
const addChildAccountsFromUserButton = (id: string, request: (id: string) => Promise<void>, disabled: boolean) => (
  <Button
    variant="contained"
    color="primary"
    disabled={disabled}
    onClick={() => {
      request(id);
    }}
  >
    <AddIcon /> <FormattedMessage id={disabled ? 'CHILD.ACTION.DISABLED' : 'CHILD.ACTION.ADD'} />
  </Button>
);

export const ChildAccountSettings: React.FC = () => {
  const intl = useIntl();
  const { id } = useParams<{ id: string }>();
  const [userChildAccounts, setUserChildAccounts] = useState<IUser[]>([]);
  const [styleGuideList, setStyleGuideList] = useState<IStyleGuide[]>([]);
  const [selectedChildAccounts, setSelectedChildAccounts] = useState<string[]>([]);
  const [totalChildAccounts, setTotalChildAccounts] = useState<number>();
  const { request, abortController } = useFetch();
  const addFetch = useFetchWithAlert(alertPayload(intl.formatMessage({ id: 'CHILD.ALERT.ADDED' })));
  const removeFetch = useFetchWithAlert(alertPayload(intl.formatMessage({ id: 'CHILD.ALERT.REMOVED' })));
  const queriesFromRouter = useLocation().search;
  const loadChildAccountFromUser = () => {
    request(getChildAccountDataFromUser, id).then((childAccounts: IUser[]) => {
      if (childAccounts) {
        const selected: string[] = [];

        childAccounts?.forEach(({ id }: any) => {
          if (id) {
            selected.push(id);
          }
        });
        setUserChildAccounts(childAccounts);

        if (selected) {
          setSelectedChildAccounts(selected);
        }
      }
    });
  };
  const addChildAccountToUsers = (accountId: string) =>
    addFetch.request(postChildAccounts, id, [...selectedChildAccounts, accountId]).then(() => {
      loadChildAccountFromUser();
    });
  const addChildAccountsButtonProp = {
    requestData: async (accountId: string) => {
      addChildAccountToUsers(accountId);
    },
    button: addChildAccountsFromUserButton,
    actionName: intl.formatMessage({ id: 'CHILD.ACTION.ADD' }),
  };
  const removeChildAccountsButtonProp = {
    requestData: async (accountId: string) => {
      const removeLastAccount = selectedChildAccounts.filter(id => id !== accountId);

      removeFetch.request(postChildAccounts, id, removeLastAccount).then(() => {
        loadChildAccountFromUser();
      });
    },
    button: removeChildAccountsFromUserButton,
    actionName: intl.formatMessage({ id: 'CHILD.ACTION.REMOVE' }),
  };

  const loadAllDealerships = () =>
    request(getUsers, queriesFromRouter, abortController.signal).then(res => {
      if (res) {
        setStyleGuideList(res[0]);
        setTotalChildAccounts(res[1]);
      }
    });

  useEffect(() => {
    loadAllDealerships();
    return () => {
      abortController.abort();
    };
  }, [queriesFromRouter]);
  /**
   * Get child accounts from user
   */
  useEffect(() => {
    loadChildAccountFromUser();
  }, []);

  return (
    <div className="content d-flex flex-column flex-column-fluid">
      <Grid container paddingTop={6.5} className="card">
        <Grid container justifyContent="space-around" alignItems="center">
          <Grid item xs={5}>
            <FilterComponent showSearchFilter showPagination entity="users" count={totalChildAccounts} />
          </Grid>
          <Grid item xs={5} justifyContent="end" display="flex" />
        </Grid>
        <Grid container justifyContent="space-around" spacing={2} marginBottom="10em">
          <Grid item xs={5} sx={{ marginTop: '5em' }}>
            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
              <span>
                <FormattedMessage id="CHILD.TITLE.ALL" />
              </span>
            </label>

            <DataGridV2
              data={styleGuideList}
              setSelected={setSelectedChildAccounts}
              selected={selectedChildAccounts}
              buttonCustom={addChildAccountsButtonProp}
              headings={listHeaders}
              renderRow={renderTestRowsUsers}
            />
          </Grid>
          <VerticalDivider sx={{ marginLeft: '-10em', marginRight: '-10em', marginBottom: '-10em' }} />
          <Grid item xs={5} sx={{ marginTop: '5em' }}>
            <label className="d-flex align-items-center fs-5 fw-bold mt-1">
              <span>
                <FormattedMessage id="CHILD.TITLE.SELECTED" />
              </span>
            </label>
            <DataGridV2
              data={userChildAccounts}
              buttonCustom={removeChildAccountsButtonProp}
              setSelected={setSelectedChildAccounts}
              selected={selectedChildAccounts}
              headings={listHeaders}
              renderRow={renderTestRowsUsers}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
