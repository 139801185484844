import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTextMuiField = styled(TextField)(({ theme }) => ({
  boxShadow: 'none !important',
  backgroundColor: '#f5f8fa',
  borderColor: '#f5f8fa',
  color: '#5e6278',
  '&.active, &:active, &.focus, &:focus, .dropdown.show > &': {
    backgroundColor: '#eef3f7',
    borderColor: '#eef3f7',
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.short,
    }),
  },
  minHeight: 'calc(1.5em + 1.65rem + 2px)',
  width: '100%',
  fontSize: '1.1rem',
  fontWeight: 500,
  lineHeight: 1.5,
  backgroundClip: 'padding-box',
  appearance: 'none',
  border: '0px solid #e4e6ef',
  borderRadius: '0.475rem',
}));
