import config from 'react-global-configuration';
import axios, { AxiosResponse } from 'axios';

function urlToFile(base64: string) {
  return fetch(base64)
    .then(res => res.arrayBuffer())
    .then(buf => new Blob([buf]));
}

/**
 * This function:
 * - Requests the backend to create a new UtilityMedia row in the database. (Backend responds with the created entity and a PreSigned Post URL and Fields)
 * - Sends the image file directly to S3
 * - Returns the newly created UtilityMedia Object.
 */

export const addUtility = async (utilityData: ImageBlob, query: IUtilityTypes) => {
  const arr = utilityData.data_url.split(',') as string[];

  if (!arr[0] && !arr[0] !== null) {
    throw Error('The Image is not available');
  }
  const match = arr[0].match(/:(.*?);/) as RegExpMatchArray;
  const mime = match[1] as string;
  const response: AxiosResponse = await axios.post(
    `${config.get('ApiUrl').Rest}/media`,
    JSON.stringify({
      fileExtension: mime.split('/')[1],
      mediaType: query,
    }),
    {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  const preSignedUrl = response.data.url;
  const preSignedFields = response.data.fields;
  const createdUtilityMedia = response.data.utilityMedia;
  const formData: FormData = new FormData();

  Object.entries(preSignedFields).forEach(([k, v]) => {
    formData.append(k, v as unknown as string);
  });

  formData.append('file', await urlToFile(utilityData.data_url));
  await fetch(preSignedUrl, {
    method: 'POST',
    mode: 'no-cors',
    body: formData,
  });

  return createdUtilityMedia;
};

export const uploadImages = (imageFiles: ImageBlob[], orderId: string) => {
  imageFiles.forEach((image: ImageBlob) => {
    const formData = new FormData();

    formData.append('file', image.file);
    formData.append('order', orderId);
    fetch(`${config.get('ApiUrl').PublicRest}/images`, {
      method: 'POST',
      body: formData,
      mode: 'cors',
      credentials: 'include',
    }).then(res => {
      if (res.ok) {
        window.location.href = `${process.env.REACT_APP_URL}orders`;
      }
    });
  });
};
