import { Dispatch, FC, SetStateAction, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ThemeProvider } from '@mui/material';
import Button from '@mui/material/Button';

import { OrderQAStatus, OrderStatus, TagEntities } from '../../../constants';
import { approveQAOrderStatus } from '../../../setup/api';
import { alertPayload, useFetchWithAlert } from '../../../setup/hooks/useFetchWithAlert';
import { OrderStatusDropDown } from '../../../ui/components/Dropdown/OrderStatusDropDown';
import { RemoteIntegrationDropDown } from '../../../ui/components/Dropdown/RemoteIntegrationDropDown';
import { TagManager } from '../../../ui/components/TagManager/TagManager';
import { CustomizedButtonTheme } from '../../../ui/components/Themes/CustomizedCarswipButton';

interface TProps {
  orderInformation: IOrder;
}

// 👇 convert enums into arrays
const orderStatusesArray = Object.values(OrderStatus);
const orderQAStatusesArray = Object.values(OrderQAStatus);
const isOrderApproved = (status: OrderQAStatus) => status === OrderQAStatus.APPROVED;

type TOrderApprovalButton = {
  setOrderStatus: Dispatch<SetStateAction<{ status: OrderStatus; QAstatus: OrderQAStatus }>>;
  orderId: string;
};
const OrderApprovalButton: FC<TOrderApprovalButton> = ({ setOrderStatus, orderId }) => {
  const [canApprove, setCanApprove] = useState(true);
  const intl = useIntl();
  const { request } = useFetchWithAlert(alertPayload(intl.formatMessage({ id: 'ORDER.MENU.UPDATE' })));
  const approvalPayload = {
    status: OrderStatus.READY_TO_DOWNLOAD,
    QAstatus: OrderQAStatus.APPROVED,
  };

  return (
    <div style={{ padding: '10px' }}>
      <Button
        color="success"
        variant="contained"
        disabled={!canApprove}
        onClick={() => {
          setCanApprove(false);
          request(approveQAOrderStatus, orderId, approvalPayload.QAstatus).then(() => {
            // Updates and lifts the state up, this does not return anything so to update in real time I need to hard code the order status
            setOrderStatus(approvalPayload);
            setTimeout(() => {
              setCanApprove(true);
            }, 1000);
          });
        }}
      >
        <FormattedMessage id="ORDER.EDITOR.SEND.APPROVE" />
      </Button>
    </div>
  );
};
/**
 * FIXME: unnecessary derivation from props to state, might need a little refactoring to remove setOrderStatus
 * since this would repeat the logic from the parent component (maybe useContext on parent of {@link OrderMenu}
 * or refactor {@link OrderStatusDropDown} to handle the state inside)
 *
 * Order menu is shown on order detail pages, and should only load components that modify the order entity,
 * and pass order information from and to props, but not handle any order request in here.
 *
 * !IMPORTANT:
 * if there is a need to add more than 3 callback through components' props
 * or the callback is drilled through more than 1 children down
 * then the logic should apply Redux, or useContext
 * @param {IOrder} props.orderInformation the current order from the page
 * @returns order menu with all the order options (Delivery, status, tags, etc)
 */
export const OrderMenu: FC<TProps> = ({ orderInformation }) => {
  const { status, QAstatus, id, orderName } = orderInformation;
  const [orderStatus, setOrderStatus] = useState<{ status: OrderStatus; QAstatus: OrderQAStatus }>({
    status,
    QAstatus,
  });

  return (
    <div
      style={{
        justifyContent: 'space-between',
        fontSize: '1.15rem',
        fontWeight: '500',
        alignItems: 'center',
        display: 'inline-flex',
      }}
    >
      <a>
        <FormattedMessage id="ORDER.MENU.NAME" />
        <div className="text-gray-700" style={{ display: 'inline-flex' }}>
          {orderName}
        </div>
      </a>
      <div style={{ display: 'inline-flex', position: 'absolute', right: 0 }}>
        <ThemeProvider theme={CustomizedButtonTheme}>
          <OrderStatusDropDown
            status={orderStatus.status}
            setOrderStatus={setOrderStatus}
            statusOptions={orderStatusesArray}
          />

          <OrderStatusDropDown
            status={orderStatus.QAstatus}
            setOrderStatus={setOrderStatus}
            statusOptions={orderQAStatusesArray}
          />
          {
            // Approval button should only be available is the order is not approved
            !isOrderApproved(orderStatus.QAstatus) && (
              <OrderApprovalButton orderId={id} setOrderStatus={setOrderStatus} />
            )
          }

          <TagManager entityId={id} entityType={TagEntities.ORDER} />
          <RemoteIntegrationDropDown />
        </ThemeProvider>
      </div>
    </div>
  );
};
