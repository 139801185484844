import { ActionWithPayload } from '../auth/AuthRedux';

export interface IFORMS {
  type: string;
  position: number;
  margins: {
    top: number;
    left: number;
    bottom: number;
    right: number;
  };
  angle: string;
  routeTo: string;

  angleOverlay?: Blob;
  frameOverlay?: Blob;
  featureImage?: Blob;
}
export interface Configurations {
  forms: IFORMS[];
  uploadedConfigurations: number;
  uploadedMedia: number;
}

const initialState: Configurations = {
  forms: [],
  uploadedConfigurations: 0,
  uploadedMedia: 0,
};

export const ADD_FORM = 'ADD_FORM';
export const REMOVE_FORMS = 'REMOVE_FORMS';
export const CONFIGURATION_UPLOADED = 'CONFIGURATION_UPLOADED';
export const STYLE_GUIDE_MEDIA_UPLOADED = 'STYLE_GUIDE_MEDIA_UPLOADED';

export const styleGuideConfigurationsReducer = (
  state: Configurations = initialState,
  action: ActionWithPayload<Configurations>,
): { forms: (IFORMS | Configurations | undefined)[]; uploadedConfigurations: number; uploadedMedia: number } => {
  switch (action.type) {
    case ADD_FORM: {
      const form = action.payload;

      return {
        ...state,
        forms: [...state.forms, form],
      };
    }

    case CONFIGURATION_UPLOADED: {
      return {
        ...state,
        uploadedConfigurations: state.uploadedConfigurations + 1,
      };
    }

    case STYLE_GUIDE_MEDIA_UPLOADED: {
      return {
        ...state,
        uploadedMedia: state.uploadedMedia + 1,
      };
    }

    case REMOVE_FORMS: {
      return {
        ...state,
        forms: [],
        uploadedConfigurations: 0,
        uploadedMedia: 0,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
