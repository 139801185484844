/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import ApexCharts, { ApexOptions } from 'apexcharts';

import { getOrderTimeLineStats } from '../../../setup/api/statistics';
import { useFetch } from '../../../setup/hooks/fetch.hook';

type Props = {
  className: string;
  title: string;
};

type OrderTimeLineResult = {
  uploadAverageTime: number;
  inProgressAverageTime: number;
  carswipQaAverageTime: number;
  deliveryAverageTime: number;
  totalOrders: number;
};

export const OrderTimelineChart: React.FC<Props> = ({ title, className }) => {
  const intl = useIntl();
  const [series, setSeries] = useState<OrderTimeLineResult>();
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { request } = useFetch();

  const chartOptions = (series: OrderTimeLineResult): ApexOptions => ({
    series: [
      {
        name: intl.formatMessage({ id: 'STATS.MINUTES' }),
        data: [
          series.uploadAverageTime,
          series.inProgressAverageTime,
          series.carswipQaAverageTime,
          series.deliveryAverageTime,
        ],
      },
    ],
    chart: { height: 350, type: 'bar' },
    plotOptions: { bar: { borderRadius: 4, horizontal: false } },
    dataLabels: { enabled: true },
    xaxis: {
      categories: [
        intl.formatMessage({ id: 'STATS.ORDER.STATUS.UPLOADING' }),
        intl.formatMessage({ id: 'STATS.ORDER.STATUS.PIPELINE' }),
        intl.formatMessage({ id: 'STATS.ORDER.STATUS.QA' }),
        intl.formatMessage({ id: 'STATS.ORDER.STATUS.DELIVERING' }),
      ],
      title: {
        text: intl.formatMessage({ id: 'STATS.AVG.14_DAYS' }, { total: series.totalOrders }),
      },
    },
  });

  const getSeries = async () => {
    request(getOrderTimeLineStats).then(res => {
      if (res) {
        setSeries(res);
      }
    });
  };

  // Gets invoked only when rendering the component for first time.
  useEffect(() => {
    getSeries();
  }, []);

  // Gets invoked when series changes have happened
  useEffect(() => {
    if (series) {
      if (!chartRef.current) {
        return;
      }
      const chart = new ApexCharts(chartRef.current, chartOptions(series));

      if (chart) {
        chart.render();
      }
      return () => {
        if (chart) {
          chart.destroy();
        }
      };
    }
  }, [series]);

  return (
    <div className={`card ${className}`}>
      <div
        className={`card-header border-0 py-5 bg-${'#c4001d'}`}
        style={{ backgroundColor: '#fff', justifyContent: 'end' }}
      >
        <div className="card-toolbar" />
      </div>
      {/* begin::Body */}
      <div className="card-body d-flex flex-column p-0 ">
        {/* begin::Stats */}
        <div className="card-p pb-0">
          <div className="d-flex flex-stack flex-wrap">
            <div className="me-2">
              <a className="text-dark text-hover-primary fw-bolder fs-3">{title}</a>
              <div className="text-muted fs-7 fw-bold"> </div>
            </div>
          </div>
        </div>
        {/* end::Stats */}
        {/* begin::Chart */}
        <div ref={chartRef} className="mixed-widget-7-chart card-rounded-bottom" />
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  );
};
