import { FC } from 'react';
import { Divider, SxProps, Theme } from '@mui/material';

import './divider.css';

export const VerticalDivider: FC<{ sx?: SxProps<Theme> }> = ({ sx }) => (
  <Divider sx={sx} className="divider-without-padding" orientation="vertical" flexItem>
    <div />
  </Divider>
);
