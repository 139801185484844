/* eslint-disable eqeqeq */
// eslint-disable-next-line @typescript-eslint/ban-types

/**
 * Converts boolean, null and undefined into string
 * @param value object value
 */
export function stringifyValue(value: {
  //
}): string {
  if (value == null || value == undefined) {
    return '';
  }
  if (value.toString() == 'true') {
    return 'Yes';
  }
  if (value.toString() == 'false') {
    return 'No';
  }
  return value.toString();
}
