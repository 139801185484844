import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import SettingsIcon from '@mui/icons-material/Settings';

import { addImageToSelection } from '../../../../setup/redux/actions';
import { RepushModal } from '../../repush/RepushModal';

interface Props {
  imageId: string;
  orderId: string;
  styleGuide: IStyleGuide;
  position?: number;
}

/**
REPUSH MODAL
 */
export const RepushingSettingButton: FC<Props> = ({ imageId, orderId, styleGuide, position }) => {
  const dispatch = useDispatch();
  const [isOpenRepushedSettings, setIsOpenRepushedSettings] = useState(false);

  return (
    <>
      <button
        className="btn btn-warning me-5"
        type="button"
        onClick={() => {
          dispatch(addImageToSelection(imageId));
          setIsOpenRepushedSettings(!isOpenRepushedSettings);
        }}
      >
        <SettingsIcon />
      </button>
      <RepushModal
        usedStyleGuide={styleGuide}
        isRepushedOpen={isOpenRepushedSettings}
        orderId={orderId}
        position={position}
      />
    </>
  );
};
