import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import Grid from '@mui/material/Grid';

import { getStyleGuides } from '../../../../../setup/api/styleGuide';
import { useFetch } from '../../../../../setup/hooks/fetch.hook';
import { DatagridView } from '../../../../../ui/components/Datagrid/Datagrid.style';
import { FilterComponent } from '../../../../../ui/components/Filters/FilterComponent';
import { Loader } from '../../../../../ui/components/Louder/Louder';
import { CreateStyleGuideButton } from '../../../../components/styleGuides/CreateStyleGuideButton';

import { Headers } from './Headers';
import { Row } from './StyleGuideRow';
import { TableContents } from './TableContents';

export const StyleGuideListV2: FC = () => {
  const intl = useIntl();
  const { request, isLoading, abortController } = useFetch();
  const queriesFromRouter = useLocation<string>().search;
  const [styleGuides, setStyleGuides] = useState<IStyleGuide[]>([]);
  const [totalStyleGuide, setTotalStyleGuides] = useState(0);

  const styleGuideListHeaders = [
    { key: 'name', label: intl.formatMessage({ id: 'STYLE_GUIDE.NAME' }) },
    { key: 'logo_thumbnail', label: intl.formatMessage({ id: 'STYLE_GUIDE.CONFIGURATION.LOGO' }) },
    { key: 'background_thumbnail', label: intl.formatMessage({ id: 'STYLE_GUIDE.MEDIA_TYPE.BACKGROUND' }) },
    { key: 'floor_thumbnail', label: intl.formatMessage({ id: 'STYLE_GUIDE.MEDIA_TYPE.FLOOR' }) },
    { key: 'delete', label: intl.formatMessage({ id: 'ACTION.DELETE' }) },
    { key: 'duplicate', label: '-' },
  ];

  const fetchStyleGuides = () => {
    request(getStyleGuides, queriesFromRouter, abortController.signal)
      .then((styleGuidesResponse: any) => {
        if (styleGuidesResponse) {
          setTotalStyleGuides(styleGuidesResponse[1]);
          setStyleGuides(styleGuidesResponse[0]);
        }
      })
      .catch();
  };

  useEffect(() => {
    fetchStyleGuides();
    return () => {
      abortController.abort();
    };
  }, [queriesFromRouter]);

  return (
    <Grid container padding={4} className="card">
      <Grid xs={10} marginTop={3}>
        <FilterComponent showSearchFilter showPagination entity="styleGuides" count={totalStyleGuide} />
      </Grid>
      <Grid xs={2} marginTop={3} justifyContent="end" alignItems="baseline" display="flex">
        <CreateStyleGuideButton />
      </Grid>
      <Grid xs={12} marginTop={3}>
        <DatagridView>
          <TableContents>
            {isLoading && Loader()}
            <Headers headers={styleGuideListHeaders} />
            {styleGuides.map((styleGuide: IStyleGuide) => (
              <Row
                styleGuide={styleGuide}
                headerKeys={styleGuideListHeaders.map(x => x.key)}
                removeStyleGuide={(removed: IStyleGuide) => {
                  const newStyleGuides = styleGuides.filter(x => x.id !== removed.id);

                  setStyleGuides(newStyleGuides);
                }}
              />
            ))}
          </TableContents>
        </DatagridView>
      </Grid>
    </Grid>
  );
};
