import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

type TStatus = 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'error';

/**
 * Customized hook for fetching data, this hook processes different errors by popping an alert and parses the response data to JSON
 * @returns Loading time and request processor
 */
export const useSimpleFetch = () => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState<TStatus>();
  const request = useCallback(async (apiCall, ...params) => {
    let tryTimes = 0;

    while (tryTimes < 1) {
      try {
        setLoading(true);
        const response = await apiCall(...params);

        if (response.status === 200) {
          setStatus('success');
        }
        if (response.status === 500) {
          setStatus('error');
        }
        if (response.status === 404) {
          setStatus('secondary');
        }

        const jsonData = await response.json();

        if (!response.ok) {
          throw new Error((jsonData || {}).message);
        }

        return jsonData;
      } catch (err: unknown) {
        if (tryTimes === 0) {
          await Auth.currentSession()
            .then(data => {
              console.log(data);
            })
            .catch(() => {
              history.push('/logout');
            });
        }
      } finally {
        tryTimes++;
        setLoading(false);
      }
    }
  }, []);

  return {
    status,
    isLoading,
    request,
  };
};
