import { PayloadAction } from '@reduxjs/toolkit';

interface IReloadTable {
  reload: boolean;
}

const initialState: IReloadTable = {
  reload: false,
};

export const RELOAD_TAG_DISPLAY = 'RELOAD_TAG_DISPLAY';

export const reloadTagsReducer = (state: IReloadTable = initialState, action: PayloadAction<IReloadTable, string>) => {
  switch (action.type) {
    // Change value to reload a different list
    case RELOAD_TAG_DISPLAY: {
      return {
        ...state,
        reload: action.payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
