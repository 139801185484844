import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import * as auth from '../../../../setup/redux/auth/AuthRedux';

export const Logout: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(auth.actions.logout());
    Auth.signOut().catch(error => {
      console.log('error confirming logout', error);
    });
    document.location.reload();
  }, [dispatch]);

  return (
    <Switch>
      <Redirect to="/auth/login" />
    </Switch>
  );
};
