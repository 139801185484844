import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import { PageTitle } from '../../../_carswip/layout/core';
import { updateUser } from '../../../setup/api';
import { getPermissions, getPermissionsByUserId } from '../../../setup/api/permissions';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { alertPayload, useFetchWithAlert } from '../../../setup/hooks/useFetchWithAlert';
import { addEntityUserButton, removeEntityUserButton } from '../../../ui/components/Button/DataGridButtons';
import { DataGridV2 } from '../../../ui/components/Datagrid/DataGridV2';
import { VerticalDivider } from '../../../ui/components/Divider/VerticalDivider';
import { renderTestRowsUsers } from '../../../ui/helpers/tables/renderRows';

const PermissionsPage: FC = () => {
  const intl = useIntl();
  const [permissions, setPermissions] = useState<IPermission[]>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
  const [userPermissions, setUserPermission] = useState<IPermission[]>([]);
  const { request } = useFetch();
  const { id } = useParams<{ id: string }>();

  const gridPermissionHeadings: [string, string][] = [['name', intl.formatMessage({ id: 'PERMISSIONS.NAME' })]];

  const addFetch = useFetchWithAlert(alertPayload(intl.formatMessage({ id: 'PERMISSIONS.ADDED' })));
  const removeFetch = useFetchWithAlert(alertPayload(intl.formatMessage({ id: 'PERMISSIONS.REMOVED' })));
  const reLoadUserInformation = () => {
    request(getPermissionsByUserId, id).then(permissions => {
      setUserPermission(permissions);
      const selected = permissions.map((permission: IPermission) => permission.id);

      setSelectedPermissions(selected);
    });
  };
  const removePermissionProp = {
    requestData: async (permissionId: string) => {
      const removeLastAccount = selectedPermissions.filter(id => id !== permissionId);
      const selected = removeLastAccount.map(permissionId => ({ id: permissionId }));

      removeFetch.request(updateUser, { permissions: selected }, id).then(() => {
        reLoadUserInformation();
      });
    },
    button: removeEntityUserButton,
    actionName: intl.formatMessage({ id: 'PERMISSIONS.REMOVE' }),
  };
  const addPermissionProps = {
    requestData: async (permissionId: string) => {
      const selected = selectedPermissions.map(permissionId => ({ id: permissionId }));

      addFetch.request(updateUser, { permissions: [...selected, { id: permissionId }] }, id).then(() => {
        reLoadUserInformation();
      });
    },
    button: addEntityUserButton,
    actionName: intl.formatMessage({ id: 'PERMISSIONS.ADD' }),
  };

  useEffect(() => {
    request(getPermissions).then(res => {
      setPermissions(res);
    });
    reLoadUserInformation();
  }, []);

  return (
    <div className="content d-flex flex-column flex-column-fluid">
      <div className="card pt-5">
        <Grid container justifyContent="space-around" spacing={2} marginBottom="10em">
          <Grid item xs={5} sx={{ marginTop: '5em' }}>
            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
              <span>
                <FormattedMessage id="PERMISSIONS" />
              </span>
            </label>

            <DataGridV2
              data={permissions}
              buttonCustom={addPermissionProps}
              selected={selectedPermissions}
              setSelected={setSelectedPermissions}
              headings={gridPermissionHeadings}
              renderRow={renderTestRowsUsers}
            />
          </Grid>
          <VerticalDivider sx={{ marginLeft: '-10em', marginRight: '-10em', marginBottom: '-10em' }} />
          <Grid item xs={5} sx={{ marginTop: '5em' }}>
            <label className="d-flex align-items-center fs-5 fw-bold mt-1">
              <span>
                <FormattedMessage id="PERMISSIONS.ACCOUNT" />
              </span>
            </label>
            <DataGridV2
              data={userPermissions}
              buttonCustom={removePermissionProp}
              selected={selectedPermissions}
              headings={gridPermissionHeadings}
              renderRow={renderTestRowsUsers}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export const PermissionsWrapper: FC = () => (
  <>
    <PageTitle breadcrumbs={[]}>
      <FormattedMessage id="PERMISSIONS" />
    </PageTitle>
    <PermissionsPage />
  </>
);
