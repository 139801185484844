import React, { useState } from 'react';
import { Button, CircularProgress } from '@mui/material';

interface IButtonLoaderProps {
  loading: boolean;
  // Must contained a function inside an arrow function, since different request has different parameter
  request: () => any;
  text?: string;
  selfLoader?: boolean;
  style?: React.CSSProperties;
  color?: 'primary' | 'secondary' | 'inherit' | 'error';
  variant?: 'text' | 'outlined' | 'contained';
  status?: number;
  disabled?: boolean;
}
/**
 * Customizable button that load while request is been processed
 * @param props.loading if true will show a circle spinning instead of the button text
 * @param props.request callback with the fetch request
 * @param param.style CSS data cto customize button
 */
export const ButtonLoader: React.FC<IButtonLoaderProps> = ({
  loading,
  request,
  text,
  selfLoader,
  style,
  color,
  variant,
  children,
  disabled,
}) => {
  const [loader, setLoader] = useState<boolean>(loading);

  return selfLoader ? (
    <Button
      variant={variant || 'contained'}
      color={color || 'primary'}
      style={style || undefined}
      disabled={disabled}
      onClick={() => {
        if (loading === false) {
          setLoader(true);

          request().then(() => {
            setLoader(false);
          });
        } else {
          console.log('already loading');
        }
      }}
    >
      {!loader && children}
      {loader ? <CircularProgress size="1.5rem" style={{ color: variant ? 'black' : 'white' }} /> : text}
    </Button>
  ) : (
    <Button
      variant="contained"
      color="primary"
      size="large"
      disabled={disabled}
      style={style || { minWidth: '200px' }}
      onClick={() => {
        if (loading === false) {
          request();
        } else {
          console.log('already loading');
        }
      }}
    >
      {' '}
      {!loading && children}
      {loading ? <CircularProgress size="1.5rem" style={{ color: 'white' }} /> : text}
    </Button>
  );
};
