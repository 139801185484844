import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { checkIsActive } from '../../../../ui/helpers';

type Props = {
  to: string;
  title: string;
};

export const MenuItem: FC<Props> = ({ to, title }) => {
  const { pathname } = useLocation();

  return (
    <div className="menu-item me-lg-1">
      <Link className={`menu-link py-3 ${checkIsActive(pathname, to) && 'active'}`} to={to}>
        <span className="menu-title">
          <FormattedMessage id={title} />
        </span>
      </Link>
    </div>
  );
};
