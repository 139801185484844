import { CSSProperties, Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

import { AppSettingName } from '../../../app/pages/admin/views/appSettings/AppSettingsList';
import { BRAND } from '../../../constants';
import { AppSetting, getAppSettings } from '../../../setup/api/appSettings';
import { useFetch } from '../../../setup/hooks/fetch.hook';

export const Footer: FC = () => {
  const { request, abortController } = useFetch();
  const [contactNumberInt, setContactNumberInt] = useState<string>('');
  const [contactEmail, setContactEmail] = useState<string>('');
  const [contactNumberDk, setContactNumberDk] = useState<string>('');

  const fetchAppVersions = async () => {
    const actions: Record<AppSettingName, Dispatch<SetStateAction<string>>> = {
      CONTACT_NUMBER_INT: setContactNumberInt,
      CONTACT_NUMBER_DK: setContactNumberDk,
      CONTACT_EMAIL: setContactEmail,
    };

    (await request(getAppSettings, abortController.signal)).forEach(({ name, value }: AppSetting) => {
      actions[name] && actions[name](value);
    });
  };

  useEffect(() => {
    fetchAppVersions();
    return () => {
      abortController.abort();
    };
  }, []);
  const styles: { [key: string]: CSSProperties } = {
    footerContainer: {
      backgroundColor: '#fff',
      margin: '0% 7%',
      borderRadius: '0.475rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      bottom: 0,
      width: '86%',
      boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
      flex: '0 0 auto',
    },
    footerContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      width: '100%',
      padding: '20px',
    },
    footerText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      width: '50%',
    },
    footerLink: {
      marginRight: '15px',
      textDecoration: 'none',
      color: '#009ef7',
    },
    footerContact: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
      width: '50%',
    },
    contactItem: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '20px',
    },
    contactItemText: {
      marginLeft: '10px',
    },
  };

  return (
    <div style={styles.footerContainer}>
      <div style={styles.footerContent}>
        <div style={styles.footerText}>
          <span>{new Date().getFullYear()} &copy;</span>
          <div style={styles.footerLink}>{BRAND}</div>
          <a style={styles.footerLink} href="https://carswip.com/privacy-policy-app/" target="_blank" rel="noreferrer">
            <FormattedMessage id="FOOTER.PRIVACY" />
          </a>
          <a style={styles.footerLink} href="https://carswip.com/cookie-policy-app/" target="_blank" rel="noreferrer">
            <FormattedMessage id="FOOTER.COOKIE" />
          </a>
        </div>
        <div style={styles.footerContact}>
          <div>
            <FormattedMessage id="FOOTER.CONTACT" />:
          </div>
          <a href={`mailto:${contactEmail}`} style={styles.contactItem}>
            <EmailIcon />
            <div style={styles.contactItemText}>{contactEmail}</div>
          </a>
          <a href={`tel:${contactNumberDk}`} style={styles.contactItem}>
            <LocalPhoneIcon style={styles.contactItemText} />
            <div style={styles.contactItemText}>{contactNumberDk}</div>
          </a>
          <a href={`tel:${contactNumberInt}`} style={styles.contactItem}>
            <LocalPhoneIcon />
            <div style={styles.contactItemText}>{contactNumberInt}</div>
          </a>
        </div>
      </div>
    </div>
  );
};
