import { FC, useEffect, useRef, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { Checkbox, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Field, Form, Formik, FormikProps } from 'formik';
import { Select } from 'formik-mui';

import Logo from '../../../../_carswip/assets/img/carswipLogo.jpg';
import { OptimisationMethod } from '../../../../constants';
import {
  addUtility,
  getAllAngleNames,
  getStyleguideConfigurationDetails,
  postStyleguideConfiguration,
  putStyleguideConfiguration,
  removeStyleguideConfiguration,
} from '../../../../setup/api';
import { enumToArray } from '../../../../setup/helpers/convertEnumToArray';
import {
  parseObjectKeyToReadableString,
  parseObjectKeyToUtilityMediaType,
} from '../../../../setup/helpers/parseObjectKeySyntaxToReadableString';
import { useFetch } from '../../../../setup/hooks/fetch.hook';
import { addUploadedConfiguration, setStyleguideConfigurationForm } from '../../../../setup/redux/actions';
import { ImageDropZone } from '../../../../ui/components/ImageDropzone/ImageDropZone';
import {
  ConfigurationImageType,
  convertRouteTo,
  convertRouteToRequest,
  defaultMarginValues,
  DisplayRoutes,
  getConfigurationImageDisplay,
  TConfigurationMedia,
} from '../../../pages/styleGuidesV2/utils';

const margins = [
  'STYLE_GUIDE.CONFIGURATION.MARGINS.TOP',
  'STYLE_GUIDE.CONFIGURATION.MARGINS.RIGHT',
  'STYLE_GUIDE.CONFIGURATION.MARGINS.BOTTOM',
  'STYLE_GUIDE.CONFIGURATION.MARGINS.LEFT',
];

interface Props {
  configurationInfo: IStyleGuideConfigurations;
  deleteCallback?: () => void;
  styleGuideId: string | undefined;
}

const marginFields = (intl: IntlShape) => (
  <div className="col-12 align-items-center d-flex mb-5">
    <label className="fs-7 fw-bold mb-2 userInputLabel required">
      <FormattedMessage id="REPUSH.SETTINGS.MARGINS" />:
    </label>
    {margins.map((margin: string) => (
      <>
        <label className="fs-9 fw-bold ms-2 me-5 userInputLabel">{intl.formatMessage({ id: margin })}:</label>
        <Field
          type="number"
          className="form-control form-control-lg form-control-solid"
          name={`margins.${margin.split('.')[3].toLowerCase()}`}
        />
      </>
    ))}
  </div>
);

interface IConfigurationMediaProps {
  styleGuideConfiguration: IStyleGuideConfigurations;
  mediaType: TConfigurationMedia;
  configurationId?: string;
}

const ConfigurationMedia: FC<IConfigurationMediaProps> = ({ mediaType, styleGuideConfiguration, configurationId }) => {
  const { request } = useFetch();
  const dispatch = useDispatch();
  const { styleGuideId } = useParams<{ styleGuideId: string }>();
  const [initialMedia, setInitialMedia] = useState(styleGuideConfiguration[mediaType]?.url);
  const [media, setMedia] = useState<ImageBlob[] | undefined>();
  const intl = useIntl();

  useEffect(() => {
    if (configurationId && media && media[0] && media[0].data_url) {
      addUtility(media[0], parseObjectKeyToUtilityMediaType(mediaType)).then(async (utilityMedia: IUtility) =>
        request(putStyleguideConfiguration, configurationId, { [mediaType]: utilityMedia.id }).then(() => {
          dispatch(addUploadedConfiguration());
        }),
      );
    } else {
      dispatch(addUploadedConfiguration());
    }
  }, [configurationId]);

  useEffect(() => {
    if (styleGuideId && media && media[0] && media[0].data_url && initialMedia !== media[0].data_url) {
      addUtility(media[0], parseObjectKeyToUtilityMediaType(mediaType)).then(async (utilityMedia: IUtility) =>
        request(putStyleguideConfiguration, configurationId, { [mediaType]: utilityMedia.id }).then(() => {
          dispatch(addUploadedConfiguration);
        }),
      );
      setInitialMedia(media[0].data_url);
    }
    if (initialMedia && media && !media[0]) {
      request(putStyleguideConfiguration, configurationId, { [mediaType]: null });
    }
  }, [media]);

  return (
    <div className="row">
      <div className="col-12">
        <div className="d-flex align-items-center justify-content-between fs-5 fw-bold mb-4">
          <span className="required" style={{ width: '200px' }}>
            {parseObjectKeyToReadableString(mediaType)}
          </span>
          <div style={{ display: 'inline-flex', justifyContent: 'flex-end' }}>
            <ImageDropZone
              maxNumber={1}
              images={[styleGuideConfiguration[mediaType] as IUtility]}
              selfDrop={false}
              setImages={setMedia}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Renders element with configuration info
 * @param props.styleGuideId checks if the styleguide id is available so it can be attached on request if the value is true
 * @param props.configurationInfo loads configuration info from parent component
 * @param props.deleteCallback function callback to remove configuration from the list, refreshing the parent component
 */
const Configuration: FC<Props> = ({ configurationInfo, deleteCallback, styleGuideId }) => {
  const intl = useIntl();
  const inits: IConfiguration = {
    id: configurationInfo.id,
    styleGuide: styleGuideId,
    margins: configurationInfo.margins
      ? JSON.parse(configurationInfo.margins as unknown as string)
      : defaultMarginValues,
    routeTo: convertRouteTo(configurationInfo.routeTo),
    type: configurationInfo.type ? configurationInfo.type : ConfigurationImageType.IMAGE,
    position: configurationInfo.position,
    angle: configurationInfo && configurationInfo.angle !== null ? configurationInfo.angle : { id: '', name: '' },
    withLogo: configurationInfo.withLogo,
    horizonLineHeight: configurationInfo.horizonLineHeight,
    afterFrameFree: configurationInfo.afterFrameFree,
    positionAfterFrameFree: configurationInfo.positionAfterFrameFree,
  };
  const [initialValues, setInitialValues] = useState<IConfiguration>(inits);
  const id = useParams<{ styleGuideId: string }>().styleGuideId;
  const isThereAStyleGuide = id && id.length > 1;
  const { request } = useFetch();
  const [angles, setAngles] = useState<Array<IAngle> | []>([]);
  const [angle, setAngle] = useState<IAngle | null>(configurationInfo.angle ? configurationInfo.angle : null);
  const [routeTo, setRouteTo] = useState<TypeOptimizationMethod>(initialValues.routeTo);
  const [displayImage, setDisplayImage] = useState<string>(Logo);
  const [configurationId, setConfigurationId] = useState<string>();
  const [configurationMedia, setConfigurationMedia] = useState<TConfigurationMedia[]>([
    'customAngleOverlay',
    'customFrameOverlay',
    'customBackground',
    'customLogo',
    'customFloor',
  ]);
  const [modifyPosition, setModifyPosition] = useState<boolean>(true);
  const [type, setType] = useState<ConfigurationImageType>(initialValues.type);
  const dispatch = useDispatch();
  const ref = useRef<FormikProps<IConfiguration>>(null);
  const configurations = useSelector((state: any) => state.styleGuideConfigurations.forms);

  const refreshConfiguration = (id: string) => {
    request(getStyleguideConfigurationDetails, id).then(configuration => {
      setInitialValues(configuration);
    });
  };
  const updateConfiguration = (id: string, payload: IConfiguration) => {
    payload.angle = payload.angle.id;
    if (payload.featuredImage) {
      payload.angle = null;
    }
    payload.routeTo = convertRouteToRequest(payload.routeTo);
    request(putStyleguideConfiguration, id, payload).then(() => {
      //
    });
  };
  const deleteConfiguration = (id: string) => {
    request(removeStyleguideConfiguration, id);
  };
  const optimizationMethodArray = enumToArray(DisplayRoutes);
  const imageTypeArray = enumToArray(ConfigurationImageType);

  const fetchAngles = async () => {
    request(getAllAngleNames).then(angles => {
      setAngles(angles);

      if (!configurationInfo.id) {
        /*
         * SetAngle(angles[0].id);
         * setInitialValues({
         *     ...initialValues,
         *     angle: angles[0] as unknown as IAngle,
         * });
         * const overlay = angles[0].defaultAngleOverlay;
         */
        // SetDisplayImage( overlay && overlay?.url || ' ');
      } else if (angle) {
        const overlay = angles.find((angleId: { id: string }) => angleId.id === angle.id).defaultAngleOverlay?.url;

        setDisplayImage(overlay as unknown as string);
      }
    });
  };
  const loadConfigurationMedia = (mediaType: ConfigurationImageType) => {
    if (mediaType === ConfigurationImageType.FEATURED) {
      setConfigurationMedia(['featuredImage']);
    } else {
      setConfigurationMedia([
        'customAngleOverlay',
        'customFrameOverlay',
        'customBackground',
        'customLogo',
        'customFloor',
      ]);
    }
  };
  const sendSubmitFormsToState = () => {
    if (ref.current !== null) {
      if (ref.current.values !== null) {
        const { values } = ref.current;

        dispatch(setStyleguideConfigurationForm(values));
      }
    }
  };

  useEffect(() => {
    fetchAngles();
    if (configurationInfo.featuredImage) {
      setDisplayImage(configurationInfo.featuredImage.url);
    }
    if (configurationInfo.angle !== null) {
      setInitialValues({ ...initialValues, angle: configurationInfo.angle });
    }
  }, []);

  useEffect(() => {
    loadConfigurationMedia(type);
  }, [type]);

  useEffect(() => {
    setDisplayImage(getConfigurationImageDisplay(configurationInfo));
    if (styleGuideId) {
      sendSubmitFormsToState();
    }
  }, [styleGuideId]);

  useEffect(() => {
    if (styleGuideId) {
      let values;

      if (initialValues.type === 'IMAGE') {
        values = { ...initialValues, styleGuide: styleGuideId };
      } else {
        values = {
          ...initialValues,
          styleGuide: styleGuideId,
          routeTo: 'FEATURED',
          angle: null,
        };
      }

      delete values?.angle?.defaultAngleOverlay;

      request(postStyleguideConfiguration, values).then((configurationResponse: { id: string }) => {
        setConfigurationId(configurationResponse.id);
      });
    }
  }, [configurations]);

  return (
    <div className="col-12 mb-10">
      <Card sx={{ display: 'flex' }}>
        <CardMedia
          component="img"
          style={{ maxWidth: '150px', maxHeight: '112px' }}
          sx={{ objectFit: 'cover' }}
          image={displayImage}
        />
        <Box className="w-100" sx={{ display: 'flex', flexDirection: 'column' }}>
          <Formik
            innerRef={ref}
            initialValues={initialValues}
            // TODO : remove
            onSubmit={() => console.log('submit')}
            enableReinitialize
          >
            {subFormik => (
              <Form
                className="form"
                onSubmit={e => {
                  e.preventDefault();
                  subFormik.handleSubmit();
                }}
              >
                <CardContent sx={{ flex: '1 0 auto' }}>
                  {modifyPosition ? (
                    <Typography
                      component="div"
                      variant="h5"
                      onDoubleClick={() => {
                        setModifyPosition(!modifyPosition);
                      }}
                    >
                      {initialValues.position}
                    </Typography>
                  ) : (
                    <Field
                      onDoubleClick={() => {
                        if (isThereAStyleGuide) {
                          request(putStyleguideConfiguration, configurationInfo.id, {
                            position: subFormik.values.position,
                          }).then(() => {
                            setModifyPosition(!modifyPosition);

                            if (configurationInfo.id) {
                              refreshConfiguration(configurationInfo?.id);
                            }
                          });
                        } else {
                          setInitialValues({
                            ...initialValues,
                            position: subFormik.values.position,
                          });
                        }
                      }}
                      type="number"
                      className="form-control form-control-lg form-control-solid"
                      name="position"
                    />
                  )}
                  <Typography variant="subtitle1" color="text.secondary" component="div">
                    <FormattedMessage
                      id="STYLE_GUIDE.CONFIGURATION.MARGINS"
                      values={{ margins: configurationInfo.margins ?? '{}' }}
                    />
                  </Typography>
                </CardContent>
                <div className="container">
                  <div className="row mb-5">
                    <div className="col-12 align-items-center d-flex mb-5">
                      <label className="fs-7 fw-bold mb-2 userInputLabel required">
                        <FormattedMessage id="STYLE_GUIDE.CONFIGURATION.TYPE" />
                      </label>

                      <Field
                        name="type"
                        value={type}
                        component={Select}
                        sx={{
                          width: '100px',
                          '& label.Mui-focused': {
                            display: 'none',
                            height: '40px',
                          },
                          '& legend': {
                            display: 'none',
                          },
                        }}
                      >
                        {imageTypeArray.map((mediaType: { key: string; value: string }) => {
                          const type = mediaType.value;

                          return (
                            <MenuItem
                              key={type}
                              value={type}
                              onClick={() => {
                                setType(type as ConfigurationImageType);
                                if (type === ConfigurationImageType.FEATURED) {
                                  setRouteTo(OptimisationMethod.FEATURE_IMAGE);
                                  setInitialValues({
                                    ...initialValues,
                                    routeTo: OptimisationMethod.FEATURE_IMAGE,
                                    angle: 'null',
                                    type,
                                  });
                                }
                              }}
                            >
                              {type}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2 ">
                      <label className="fs-7 fw-bold mb-2 userInputLabel required">
                        <FormattedMessage id="STYLE_GUIDE.CONFIGURATION.ADD_LOGO" />:
                      </label>
                    </div>
                    <div className="col-2 mb-2 pb-0">
                      <Field
                        name="withLogo"
                        checked={initialValues.withLogo}
                        component={Checkbox}
                        sx={{
                          marginTop: '-0.6em',
                          marginBottom: '1em',
                          marginLeft: '-5em',
                        }}
                        inputProps={{ 'aria-label': intl.formatMessage({ id: 'STYLE_GUIDE.CONFIGURATION.ADD_LOGO' }) }}
                        label={<FormattedMessage id="STYLE_GUIDE.CONFIGURATION.ADD_LOGO" />}
                        onChange={(event: { target: { checked: boolean } }) =>
                          setInitialValues({
                            ...initialValues,
                            withLogo: event.target.checked,
                          })
                        }
                      />
                    </div>
                    {type === ConfigurationImageType.FEATURED && (
                      <>
                        <div className="col-2 ">
                          <label className="fs-7 fw-bold mb-2 userInputLabel">
                            <FormattedMessage id="STYLE_GUIDE.CONFIGURATION.AFTER_FRAME_FREE" />:
                          </label>
                        </div>
                        <div className="col-2 mb-2 pb-0">
                          <Field
                            name="afterFrameFree"
                            checked={initialValues.afterFrameFree}
                            component={Checkbox}
                            sx={{
                              marginTop: '-0.6em',
                              marginBottom: '1em',
                              marginLeft: '-5em',
                            }}
                            inputProps={{
                              'aria-label': intl.formatMessage({ id: 'STYLE_GUIDE.CONFIGURATION.AFTER_FRAME_FREE' }),
                            }}
                            label={<FormattedMessage id="STYLE_GUIDE.CONFIGURATION.AFTER_FRAME_FREE" />}
                            onChange={(event: { target: { checked: boolean } }) =>
                              setInitialValues({
                                ...initialValues,
                                afterFrameFree: event.target.checked,
                              })
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                  {type === ConfigurationImageType.FEATURED && (
                    <div className="row mb-5">
                      <div className="row mb-5">
                        <>
                          <label className="fs-9 fw-bold ms-2 me-5 userInputLabel">
                            {intl.formatMessage({ id: 'STYLE_GUIDE.CONFIGURATION.POSITION_AFTER_FRAME_FREE' })}:
                          </label>
                          <Field
                            type="number"
                            className="form-control form-control-lg form-control-solid"
                            name="positionAfterFrameFree"
                            value={initialValues.positionAfterFrameFree}
                            onChange={(event: { target: { value: number } }) =>
                              setInitialValues({
                                ...initialValues,
                                positionAfterFrameFree: event.target.value,
                              })
                            }
                          />
                        </>
                      </div>
                    </div>
                  )}
                  {type === ConfigurationImageType.IMAGE && (
                    <div className="row mb-5">
                      <div className="row mb-5">
                        <>
                          <label className="fs-9 fw-bold ms-2 me-5 userInputLabel">
                            {intl.formatMessage({ id: 'REPUSH.SETTINGS.LINE' })}:
                          </label>
                          <Field
                            type="number"
                            className="form-control form-control-lg form-control-solid"
                            name="horizonLineHeight"
                            value={initialValues.horizonLineHeight}
                            onChange={(event: { target: { value: number } }) =>
                              setInitialValues({
                                ...initialValues,
                                horizonLineHeight: event.target.value,
                              })
                            }
                          />
                        </>
                      </div>
                      <div className="row mb-5"> {marginFields(intl)}</div>
                      <div className="row mb-5 d-flex">
                        <div className="col-6 mt-5 align-items-center" style={{ display: 'inline-flex' }}>
                          <label className="fs-7 fw-bold mb-2 userInputLabel required" style={{ width: '60px' }}>
                            <FormattedMessage id="STYLE_GUIDE.CONFIGURATION.ANGLES" />
                          </label>
                          <Field
                            as="select"
                            style={{ width: '20em' }}
                            name="angle"
                            sx={{
                              '& label.Mui-focused': { display: 'none', height: '40px' },
                              '& legend': { display: 'none' },
                            }}
                            required
                            value={configurationInfo.angle?.id ? initialValues?.angle?.id : angle?.id}
                            component={Select}
                          >
                            {angles &&
                              angles.length > 0 &&
                              angles.map(
                                (angle: IAngle) =>
                                  angle && (
                                    <MenuItem
                                      key={angle.id}
                                      value={angle?.id}
                                      onClick={() => {
                                        const overlay = angle.defaultAngleOverlay;

                                        setDisplayImage((overlay && overlay?.url) ?? ' ');
                                        setAngle(angle as unknown as IAngle);
                                        setInitialValues({
                                          ...initialValues,
                                          angle,
                                        });
                                      }}
                                    >
                                      {angle.name}
                                    </MenuItem>
                                  ),
                              )}
                          </Field>
                        </div>
                        <div
                          className="col-6 mt-5 align-items-center"
                          style={{ display: 'inline-flex', justifyContent: 'end' }}
                        >
                          <label className="fs-7 fw-bold mb-2 userInputLabel required" style={{ width: '60px' }}>
                            <FormattedMessage id="STYLE_GUIDE.CONFIGURATION.ROUTE_TO" />
                          </label>
                          <Field
                            sx={{
                              '& label.Mui-focused': {
                                display: 'none',
                                height: '40px',
                              },
                              '& legend': { display: 'none' },
                            }}
                            style={{ width: '20em' }}
                            name="routeTo"
                            value={routeTo}
                            component={Select}
                          >
                            {optimizationMethodArray.map((optimizationMethod: { value: string }) => {
                              const method = optimizationMethod.value;
                              return (
                                <MenuItem
                                  key={method}
                                  value={method}
                                  onClick={() => {
                                    setRouteTo(method as TypeOptimizationMethod);
                                    setInitialValues({ ...initialValues, routeTo: method });
                                  }}
                                >
                                  {method}
                                </MenuItem>
                              );
                            })}
                          </Field>
                        </div>
                      </div>
                    </div>
                  )}
                  {configurationMedia.map((media: TConfigurationMedia) => (
                    <ConfigurationMedia
                      styleGuideConfiguration={configurationInfo}
                      mediaType={media}
                      configurationId={configurationId || configurationInfo.id}
                    />
                  ))}
                  <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', pl: 1, pb: 1 }}>
                    {angle !== null && initialValues.routeTo !== OptimisationMethod.FEATURE_IMAGE && (
                      <IconButton>
                        {isThereAStyleGuide && (
                          <SaveIcon
                            type="button"
                            onClick={() => {
                              subFormik.handleSubmit();
                              if (configurationInfo.id.length > 1) {
                                updateConfiguration(configurationInfo.id, subFormik.values);
                              } else {
                                setConfigurationId(configurationInfo.id);
                              }
                            }}
                          />
                        )}
                      </IconButton>
                    )}
                    {initialValues.routeTo === OptimisationMethod.FEATURE_IMAGE && (
                      <IconButton>
                        {isThereAStyleGuide && (
                          <SaveIcon
                            type="button"
                            onClick={() => {
                              subFormik.handleSubmit();
                              if (configurationInfo.id.length > 1) {
                                updateConfiguration(configurationInfo.id, subFormik.values);
                              } else {
                                setConfigurationId(configurationInfo.id);
                              }
                            }}
                          />
                        )}
                      </IconButton>
                    )}
                    <IconButton>
                      <CancelIcon
                        onClick={() => {
                          if (deleteCallback !== undefined) {
                            deleteCallback();
                          }
                          if (isThereAStyleGuide) {
                            deleteConfiguration(configurationInfo.id);
                          }
                        }}
                      />
                    </IconButton>
                  </Box>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Card>
    </div>
  );
};

export { Configuration };
