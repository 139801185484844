import grey from '@mui/material/colors/grey';
import createTheme from '@mui/material/styles/createTheme';

import { customButtonColors } from '../../../ui/helpers/colors';

export const AuthTheme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        size: 'large',
        variant: 'contained',
      },
      styleOverrides: {
        containedSizeLarge: {
          minWidth: '100% !important',
        },
        containedInfo: {
          backgroundColor: customButtonColors.grey,
          '&:hover': {
            backgroundColor: grey[700],
            color: '#fff',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: '1rem',
          width: '100%',
          backgroundColor: '#F5F5F5',
        },
      },
    },
  },
});
