import config from 'react-global-configuration';

import { TEntityType, TTagDeletePayload, TTagPayload } from './models/tag';
import { generateRequestOptions } from './helpers';

export interface ITagQueries {
  Entity: string | undefined;
  Category: string;
  page: string;
  search: string;
}

export const getTags = (queries: string, abort: AbortSignal): Promise<Response> => {
  const url = `${config.get('ApiUrl').Rest}/tags${queries}`;

  return fetch(url, generateRequestOptions('GET', abort));
};

export const createTags = (tagData: TTagPayload): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/Tags`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(tagData),
    mode: 'cors',
    credentials: 'include',
  });
export const updateTag = (tagData: TTagPayload, tagId: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/tags/${tagId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(tagData),
    mode: 'cors',
    credentials: 'include',
  });

export const addTagToEntity = (entityId: string, entity: TEntityType, tagId: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/tags/${entity}/${entityId}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    credentials: 'include',
    body: JSON.stringify({ tagId }),
  });

export const getTagsFromEntity = (entity: string, entityId: TEntityType): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/tags/get/${entity}/${entityId}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    credentials: 'include',
  });

export const deleteTagFromEntity = (payload: TTagDeletePayload): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/tags/`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
    mode: 'cors',
    credentials: 'include',
  });

export const getTagById = (tagId: string): Promise<Response> =>
  fetch(`${config.get('ApiUrl').Rest}/tags/${tagId}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    credentials: 'include',
  });
