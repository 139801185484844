import { createTheme } from '@mui/material';

import { customButtonColors } from '../../helpers';

export const CustomizedButtonTheme = createTheme({
  palette: {
    primary: {
      main: customButtonColors.indigo,
      contrastText: '#fff',
    },
    secondary: {
      main: customButtonColors.grey,
      contrastText: '#fff',
    },
    success: {
      main: customButtonColors.green,
      contrastText: '#fff',
    },
    info: {
      main: customButtonColors.orange,
      contrastText: '#fff',
    },
  },
  typography: {
    button: {
      fontSize: '1.25 rem',
      fontWeight: '600',
      padding: '10px',
    },
  },
});
