import { createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { ImageListType, ImageType } from 'react-images-uploading';
import { FormattedMessage, useIntl } from 'react-intl';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button } from '@mui/material';
import { Form, Formik, FormikProps, useFormikContext } from 'formik';
import { from } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { UAParser } from 'ua-parser-js';
import * as Yup from 'yup';

import { PageTitle } from '../../../_carswip/layout/core';
import { Permissions } from '../../../carswip_shared_models/src/web_view_models/permissions.model';
import { UserRole } from '../../../constants';
import { RootState } from '../../../setup';
import { createOrder, getSalesMenByUserId, getStyleGuidesByUserId, getUser } from '../../../setup/api';
import { uploadImageToOrderObservable } from '../../../setup/helpers/uploadImages';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { alertPayload } from '../../../setup/hooks/useFetchWithAlert';
import { StyledTextMuiField } from '../../../ui/components/Field/StyledTextMuiField';
import { SelectFieldFromObjects } from '../../../ui/components/FieldSelect/FieldSelect';
import { ImageDropZone } from '../../../ui/components/ImageDropzone/ImageDropZone';
import { MainGridContainer } from '../../../ui/components/MainGridContainer/MainGridContainer';
import { RequiredSpan } from '../../../ui/components/RequiredField/RequiredFieldSpan';
import { uploading, User } from '../../../ui/helpers';
import { ErrorComponent } from '../../components/errors/ErrorComponent';
import { ImageUpload } from '../../components/order/createOrder/ImageUpload';
import { LoadedImagesRow } from '../../components/order/createOrder/LoadedImagesRow';
import { SelectDealership } from '../../components/order/createOrder/SelectDealership';
import { UploadingImagesModal } from '../../components/order/createOrder/UploadingImagesModal';
import { ModalType, SalesManModal } from '../../components/salesMan/SalesManModal';

export type CreateOrderForm = {
  user: { id: string };
  orderName: string;
  styleGuide?: IStyleGuide;
  salesMan?: ISalesMan;
  userAgent: string;
  images?: ImageType[];
};

// Gets the user agent payload
const parser = new UAParser(navigator.userAgent);
const { browser, os } = parser.getResult();
const userAgent = `WebApp (${browser.name} ${browser.version}, ${os.name} ${os.version})`;

// Create context that holds images and setImages function
type ImagesContextType = {
  images: ImageType[];
  setImages: Dispatch<SetStateAction<ImageType[]>>;
  uploadedImages: number | undefined;
};
export const ImageUploadContext = createContext<ImagesContextType>({
  images: [],
  setImages: () => undefined,
  uploadedImages: undefined,
});

const SelectSalesMan: FC<{
  salesMan: ISalesMan[];
  isImpersonatedAccount: boolean;
  isLoggedInAsSalesPerson: boolean;
}> = ({ salesMan, isImpersonatedAccount, isLoggedInAsSalesPerson }) => {
  const [modal, setModal] = useState(false);

  return (
    <>
      {isLoggedInAsSalesPerson ? null : (
        <>
          <RequiredSpan>
            <FormattedMessage id="ORDER.CREATOR.SALESMAN.SELECT" />
          </RequiredSpan>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '2em',
            }}
          >
            <SelectFieldFromObjects<ISalesMan>
              name="salesMan"
              keyName="name"
              sx={{ width: '50%' }}
              options={salesMan}
            />
            <Button
              disabled={isImpersonatedAccount}
              variant="contained"
              sx={{ width: '15%', height: '80%' }}
              onClick={() => setModal(!modal)}
              children="Add Sales Person"
            />
            {modal && <SalesManModal open type={ModalType.CREATE} />}
          </Box>
        </>
      )}
    </>
  );
};

const SelectStyleGuide: FC<{ styleGuides: IStyleGuide[] }> = ({ styleGuides }) => (
  <>
    <RequiredSpan>
      <FormattedMessage id="ORDER.CREATOR.STYLEGUIDE.SELECT" />
    </RequiredSpan>
    <Box sx={{ width: '100%', marginBottom: '2em' }}>
      <SelectFieldFromObjects<IStyleGuide> keyName="name" name="styleGuide" options={styleGuides} />
    </Box>
  </>
);

type SelectImagesProps = {
  loading: boolean;
  submitForm: () => void;
  styleGuides: IStyleGuide[];
};

const SelectImages: FC<SelectImagesProps> = ({ loading, submitForm, styleGuides }) => {
  const formik = useFormikContext<CreateOrderForm>();
  const { images, setImages, uploadedImages } = useContext(ImageUploadContext);
  const selectedStyleGuide = styleGuides?.find(x => x.id === formik?.values.styleGuide);
  const [frameFreeImages, setFrameFreeImages] = useState<ImageType[]>([]);
  const [framedImages, setFramedImages] = useState<ImageType[]>([]);

  useEffect(() => {
    setImages([]);
    setFramedImages([]);
    setFrameFreeImages([]);
  }, [formik.values.styleGuide]);

  useEffect(() => {
    formik.setFieldValue('images', images);
  }, [images]);

  useEffect(() => {
    setImages([...framedImages, ...frameFreeImages]);
  }, [frameFreeImages, framedImages]);

  return (
    <Box sx={{ width: '100%' }}>
      {formik?.values.styleGuide
        ? selectedStyleGuide?.configurations
            ?.sort((a, b) => a.position - b.position)
            .map((configuration, index) => {
              const setImagesForConfiguration = (newImages?: ImageListType) => {
                const newImagesForConfiguration = [...framedImages];

                if (!newImages || newImages.length === 0) {
                  newImagesForConfiguration[index] = { data_url: '' };
                } else {
                  newImagesForConfiguration[index] = newImages[0];
                }

                setFramedImages(newImagesForConfiguration);
              };

              return (
                <div
                  key={`framed-img-${index}-${framedImages[index]?.file?.name}`}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div style={{ alignSelf: 'center' }}>
                    <LazyLoadImage
                      style={{ maxWidth: '150px', maxHeight: '115px' }}
                      src={configuration.angle?.defaultAngleOverlay?.thumbnailUrl}
                    />
                    <RequiredSpan style={{}}>{configuration.angle?.name}</RequiredSpan>
                  </div>
                  {/* Add upload field that can upload an image and update formik images array at a specific position */}

                  <div style={{ maxHeight: '150px', alignSelf: 'center', display: 'flex' }}>
                    {framedImages.length > 0 && framedImages[index] ? (
                      <ImageDropZone
                        setImages={setImagesForConfiguration}
                        images={[framedImages[index]]}
                        selfDrop={false}
                        maxNumber={1}
                        download={false}
                      />
                    ) : (
                      <ImageDropZone
                        setImages={setImagesForConfiguration}
                        images={[]}
                        selfDrop={false}
                        maxNumber={1}
                        download={false}
                      />
                    )}
                  </div>
                </div>
              );
            })
        : null}
      <RequiredSpan>
        <FormattedMessage id="ORDER.CREATOR.FRAME_FREE" />
      </RequiredSpan>
      <ImageUpload images={frameFreeImages} setImages={setFrameFreeImages} />

      <LoadedImagesRow
        images={frameFreeImages}
        onImageRemove={index => {
          const imagesCopy = [...frameFreeImages];

          imagesCopy.splice(index, 1);
          setFrameFreeImages(imagesCopy);
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          pt: 10,
        }}
      >
        <UploadingImagesModal images={images} uploadedImages={uploadedImages} />
        {!loading ? (
          <Button
            variant="contained"
            color="primary"
            disabled={
              !formik.isValid ||
              images.length === 0 ||
              selectedStyleGuide?.configurations?.some((x, index) => !images[index] || images[index]?.data_url === '')
            } // Disable the button if the form is invalid
            onClick={() => {
              submitForm();
            }}
            children={<FormattedMessage id="ORDER.CREATOR.SEND" />}
          />
        ) : (
          <LoadingButton
            loading
            color="primary"
            variant="outlined"
            children={<FormattedMessage id="ORDER.CREATOR.LOADING" />}
          />
        )}
      </Box>
    </Box>
  );
};

const OrderNameField: FC = () => {
  const { errors, touched, getFieldProps } = useFormikContext<CreateOrderForm>();

  return (
    <>
      <RequiredSpan>
        <FormattedMessage id="ORDER.VIEW.ORDER_NAME" />
      </RequiredSpan>
      <StyledTextMuiField
        style={{ marginBottom: '1em' }}
        type="text"
        {...getFieldProps('orderName')}
        error={touched.orderName && Boolean(errors.orderName)}
        helperText={touched.orderName && errors.orderName}
        name="orderName"
      />
    </>
  );
};

/**
 * Renders a from that for create orders and upload images,
 * while admins can also have a list with all style guide and user
 */
export const CreateOrderPage: FC = () => {
  const intl = useIntl();
  const { request } = useFetch();
  const authenticatedUser = User();
  const history = useHistory();
  const [images, setImages] = useState<ImageType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadedImages, setUploadedImages] = useState<number | undefined>();
  const shouldReloadSalesManDropDown = useSelector((state: RootState) => state.reloadTableModal.salesmen.reload);
  const [impersonatedAccount, setImpersonatedAccount] = useState<IUser | undefined>(undefined);
  const [authenticatedAccount, setAuthenticatedAccount] = useState<IUser | undefined>(undefined);
  const [salesPeople, setSalesPeople] = useState<ISalesMan[]>([]);
  const [styleGuides, setStyleGuides] = useState<IStyleGuide[]>([]);
  const [formValues] = useState<CreateOrderForm>({
    user: { id: impersonatedAccount ? impersonatedAccount.id : authenticatedUser.id },
    orderName: '',
    styleGuide: styleGuides?.[0],
    salesMan: salesPeople?.[0],
    userAgent,
    images: [],
  });

  const triggerAlertOnceOrderIsUploaded = () => {
    const { message, options } = alertPayload(intl.formatMessage({ id: 'ORDER.SUCCESS.UPLOADED' }));

    uploading(message, options);
  };

  const createOrderSchema = Yup.object().shape({
    orderName: Yup.string()
      .required(
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.REQUIRED' },
          { name: intl.formatMessage({ id: 'ORDER.VIEW.ORDER_NAME' }) },
        ),
      )
      .min(3, `${intl.formatMessage({ id: 'AUTH.VALIDATION.MIN_LENGTH_FIELD' })} 3`)
      .label('orderName'),
    styleGuide: Yup.string()
      .required(
        intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED' }, { name: intl.formatMessage({ id: 'STYLE_GUIDE' }) }),
      )
      .label('styleGuide'),
    salesMan: Yup.string()
      .when([], {
        is: () => !authenticatedUser.loggedInSalesPersonInfo,
        then: Yup.string().required(
          intl.formatMessage(
            { id: 'AUTH.VALIDATION.REQUIRED' },
            { name: intl.formatMessage({ id: 'ORDER.SALESMAN' }) },
          ),
        ),
        otherwise: Yup.string(),
      })
      .label('salesMan'),
    images: Yup.array<ImageType>()
      .required(
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.REQUIRED' },
          { name: intl.formatMessage({ id: 'ORDER.VIEW.IMAGES' }) },
        ),
      )
      .label('images')
      .min(
        1,
        `${intl.formatMessage({ id: 'AUTH.VALIDATION.MIN_LENGTH_FIELD' })} 1 ${intl.formatMessage({
          id: 'ORDER.VIEW.IMAGES',
        })}`,
      ),
  });

  useEffect(() => {
    request(getUser, authenticatedUser.id)
      .then(data => {
        setAuthenticatedAccount(data);
        request(getStyleGuidesByUserId, authenticatedUser.id).then(styleGuides => setStyleGuides(styleGuides));
      })
      .catch(error => {
        // Handle the error appropriately
        console.log(error);
      });

    request(getSalesMenByUserId, authenticatedUser.id)
      .then(data => setSalesPeople(data))
      .catch(error => {
        // Handle the error appropriately
        console.log(error);
      });
  }, [shouldReloadSalesManDropDown]);

  useEffect(() => {
    if (!impersonatedAccount) {
      return;
    }
    request(getUser, impersonatedAccount.id)
      .then(data => {
        setSalesPeople(data.salesMan);
        setStyleGuides(data.styleGuides);
      })
      .catch(error => {
        // Handle the error appropriately
        console.log(error);
      });
  }, [impersonatedAccount, shouldReloadSalesManDropDown]);

  if (
    !authenticatedUser?.permissions?.find((x: string) => x.toLowerCase() === Permissions.CREATE_ORDERS.toLowerCase())
  ) {
    return (
      <ErrorComponent
        title={intl.formatMessage({ id: 'ERROR.401' })}
        message={intl.formatMessage({ id: 'ERROR.401.MSG' })}
      />
    );
  }

  const handleStatusAfterImageIsUploaded =
    () =>
    ({ index, success }: { index: number; success: boolean }) => {
      if (success) {
        const total = index + 1;

        setUploadedImages(total);
      }
    };

  const changeStatusAfterOrderIsUploaded = () => ({
    complete: () => {
      triggerAlertOnceOrderIsUploaded();
      setLoading(false);
      history.push('/orders/success');
    },
  });

  const uploadImagesAndAttachToOrder = (orderId: string) => {
    from(images?.map((image, index) => uploadImageToOrderObservable(image, orderId, index)))
      .pipe(
        concatMap(observable => observable),
        tap(handleStatusAfterImageIsUploaded()),
      )
      .subscribe(changeStatusAfterOrderIsUploaded());
  };

  const submitForm = (values: CreateOrderForm) => {
    if (images.length !== 0 && loading === false) {
      setLoading(true);
      delete values.images;

      if (authenticatedUser.loggedInSalesPersonInfo) {
        values.salesMan = salesPeople.find(x => x.email === authenticatedUser.loggedInSalesPersonInfo?.email);
        if (!values.salesMan) {
          window.alert(intl.formatMessage({ id: 'ORDER.SALESMAN.NONE' }));
          // Handle the error appropriately   }
        }
      }

      request(createOrder, {
        ...values,
        user: { id: impersonatedAccount ? impersonatedAccount.id : authenticatedUser.id },
        takenImagesCount: images.length,
      }).then(order => uploadImagesAndAttachToOrder(order.id));
    }
  };

  return authenticatedAccount ? (
    <ImageUploadContext.Provider
      value={{
        images,
        setImages,
        uploadedImages,
      }}
    >
      <MainGridContainer>
        <PageTitle>
          <FormattedMessage id="ORDER.NEW" />
        </PageTitle>
        <Formik
          validationSchema={createOrderSchema}
          validateOnMount
          validateOnChange
          initialValues={formValues}
          onSubmit={submitForm}
        >
          {(props: FormikProps<CreateOrderForm>) => (
            <Form noValidate style={{ padding: '2em 3em' }}>
              {authenticatedUser.loggedInSalesPersonInfo && (
                <p style={{ fontSize: 20, paddingBottom: '2em', fontWeight: '425' }}>
                  <FormattedMessage
                    id="ORDER.SALUTE"
                    values={{
                      name: authenticatedUser.loggedInSalesPersonInfo.name.split(' ')[0],
                    }}
                  />
                </p>
              )}
              <OrderNameField />
              {authenticatedUser.userRole === UserRole.ADMIN && (
                <SelectDealership
                  setImpersonationAccount={setImpersonatedAccount}
                  selectedAccount={impersonatedAccount ?? authenticatedAccount}
                />
              )}
              <SelectSalesMan
                isLoggedInAsSalesPerson={Boolean(authenticatedUser.loggedInSalesPersonInfo)}
                isImpersonatedAccount={impersonatedAccount !== undefined}
                salesMan={
                  authenticatedUser.userRole === UserRole.ADMIN
                    ? salesPeople
                    : authenticatedUser.loggedInSalesPersonInfo
                    ? salesPeople?.filter(x => x.email === authenticatedUser?.loggedInSalesPersonInfo?.email)
                    : salesPeople ?? []
                }
              />
              <SelectStyleGuide styleGuides={styleGuides ?? []} />
              <SelectImages loading={loading} submitForm={props.handleSubmit} styleGuides={styleGuides} />
            </Form>
          )}
        </Formik>
      </MainGridContainer>
    </ImageUploadContext.Provider>
  ) : null;
};
