import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@mui/material';

import { PageTitle } from '../../../_carswip/layout/core';
import { getCoordinatorOrdersRequest, getEditorOrderRequest } from '../../../setup/api';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { User } from '../../../ui/helpers';
import { EditorOrderRow } from '../../components/editing/EditorOrderRow';

import './EditingPage.scss';

/**
 * This page include three list with orders depending on location query of the url
 * the component fetch user information from redux adn identify the user tag which is used to fetch the correct list of orders for the QA
 * @returns coordinator or editors lists, once with picked tasks by the user for the user and a general list with unassigned task that have the same
 * list with unassigned task
 */

// TODO: this whole component must be redone - Editor and Coordinator list can reuse same html elements, orders and actions.

const EditingPage: React.FC = () => {
  const { tags } = User();
  const editorTag = tags?.find((tag: { category: string }) => tag.category === 'QA_EDITOR');
  const coordinatorTag = tags?.find((tag: { category: string }) => tag.category === 'QA_COORDINATOR');

  const location = useLocation();
  const { request } = useFetch();

  const [editorOrders, setEditorOrders] = useState<Orders>();
  const [coordinatorOrders, setCoordinatorOrders] = useState<Orders>();
  const [loadingUnassignedTasks, setLoadingUnassignedTasks] = useState(false);

  const getCoordinatorOrders = () => {
    if (loadingUnassignedTasks) {
      return;
    }
    request(getCoordinatorOrdersRequest, coordinatorTag?.name)
      .then(result =>
        setCoordinatorOrders({
          assignedOrders: result.ownOrders,
          unnasignedOrders: result.unassignedOrders,
        }),
      )
      .catch(err => {
        console.log(err, 'failed');
      });
  };

  const getEditorOrders = () => {
    if (loadingUnassignedTasks) {
      return;
    }

    request(getEditorOrderRequest, editorTag?.name)
      .then(result =>
        setEditorOrders({
          assignedOrders: result.ownOrders,
          unnasignedOrders: result.unassignedOrders,
        }),
      )
      .catch(err => {
        console.log(err, 'failed');
      });
  };

  // UseEffect based on location
  useEffect(() => {
    if (location.search === '?list=coordinator') {
      getCoordinatorOrders();
    } else if (location.search === '' && editorTag) {
      getEditorOrders();
    }
  }, [location]);

  return (
    <div className="content d-flex flex-column flex-column-fluid">
      <div className="card">
        <div className="card-header border-0 pt-6">
          <div className="card-title">
            <div className="d-flex align-items-center position-relative my-1">
              <div className="d-flex overflow-auto h-55px">
                <ul
                  className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"
                  style={{ display: 'flex', marginLeft: '1.4em' }}
                >
                  <li className="nav-item">
                    <Link
                      to="editing-page"
                      className={`nav-link text-active-primary me-6 ${location.search === '' ? 'active' : ''}`}
                    >
                      <FormattedMessage id="QA.EDITOR" />
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="editing-page?list=coordinator"
                      className={`nav-link text-active-primary me-6 ${
                        location.search === '?list=coordinator' ? 'active' : ''
                      }`}
                    >
                      <FormattedMessage id="QA.COORDINATOR" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Button
            variant="contained"
            color="warning"
            disabled={loadingUnassignedTasks}
            style={{
              height: '30px',
              width: '150px',
              marginRight: '25px',
              alignSelf: 'center',
            }}
            onClick={() => {
              if (!loadingUnassignedTasks) {
                setLoadingUnassignedTasks(true);
                if (location.search === '?list=coordinator') {
                  getCoordinatorOrders();
                } else {
                  getEditorOrders();
                }

                setTimeout(() => setLoadingUnassignedTasks(false), 5000);
              }
            }}
          >
            <FormattedMessage id="QA.REFRESH" />
          </Button>
        </div>
        <div className="card-body pt-10">
          <section className="">
            <ul className="task-items editorList">
              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                <span>
                  <FormattedMessage id="QA.YOURS" />
                </span>
              </label>
              {location.search === '?list=coordinator'
                ? coordinatorOrders?.assignedOrders?.map(order => (
                    <EditorOrderRow
                      order={order}
                      refreshOrderList={getCoordinatorOrders}
                      updateOrder={(updatedOrder: IOrder) => {
                        const update = { ...coordinatorOrders, updatedOrder };

                        setCoordinatorOrders(update);
                      }}
                    />
                  ))
                : editorOrders?.assignedOrders?.map(order => (
                    <EditorOrderRow
                      order={order}
                      refreshOrderList={getEditorOrders}
                      updateOrder={(updatedOrder: IOrder) => {
                        const update = { ...editorOrders, updatedOrder };

                        setEditorOrders(update);
                      }}
                      showApprove={!order.images.length}
                    />
                  ))}
            </ul>
          </section>

          <section className="mt-5">
            <ul className="task-items editorList mt-5">
              <div className="d-flex">
                <label className="d-flex align-items-center fs-5 fw-bold mb-2 me-5">
                  <span>
                    <FormattedMessage id="QA.UNASSIGNED" />
                  </span>
                </label>
              </div>
              {location.search === '?list=coordinator'
                ? coordinatorOrders?.unnasignedOrders?.map(order => (
                    <EditorOrderRow
                      order={order}
                      refreshOrderList={getCoordinatorOrders}
                      updateOrder={(updatedOrder: IOrder) => {
                        const update = { ...coordinatorOrders, updatedOrder };

                        setCoordinatorOrders(update);
                      }}
                    />
                  ))
                : editorOrders?.unnasignedOrders?.map(order => (
                    <EditorOrderRow
                      order={order}
                      refreshOrderList={getEditorOrders}
                      updateOrder={(updatedOrder: IOrder) => {
                        const update = { ...editorOrders, updatedOrder };

                        setEditorOrders(update);
                      }}
                    />
                  ))}
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
};

export const EditingWrapper: React.FC = () => (
  <>
    <PageTitle breadcrumbs={[]}>
      <FormattedMessage id="QA" />
    </PageTitle>
    <EditingPage />
  </>
);
