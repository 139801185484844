import { FC } from 'react';
import { useIntl } from 'react-intl';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { countries } from '../../../setup/helpers/countryList';
import { useQueryParams } from '../../../setup/hooks/useQueryParams';

import { getQueryOrEmptyString } from './helpers';

const defaultValue = {
  label: '',
  code: '',
  phone: '',
};

export const CountryFilter: FC = () => {
  const intl = useIntl();
  const [queries, setQueries] = useQueryParams();

  const findValueOnCountryList = () => countries.find(country => country.label === queries.country);

  return (
    <Autocomplete
      options={countries}
      autoHighlight
      getOptionLabel={({ label }) => label}
      value={queries.country ? findValueOnCountryList() : defaultValue}
      onChange={(e, value) => setQueries('country', value ? value?.label : '')}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <img
            loading="lazy"
            width="20"
            style={{ marginRight: '0.5em' }}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
          />
          {option.label}
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label={intl.formatMessage({ id: 'ACCOUNT.COUNTRY' })}
          value={getQueryOrEmptyString(queries.country)}
          inputProps={{
            ...params.inputProps,
            // Disable autocomplete and autofill
            autoComplete: 'new-password',
          }}
        />
      )}
    />
  );
};
