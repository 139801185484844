/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_carswip/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_carswip/assets/css/style.rtl.css'
 *
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { Provider } from 'react-redux';
/*
 * Axios
 * import axios from 'axios'
 */
import { Chart, registerables } from 'chart.js';
/*
 * Redux
 * https://github.com/rt2zz/redux-persist
 */
import { PersistGate } from 'redux-persist/integration/react';

import { I18nProvider } from './_carswip/i18n/i18nProvider';
// Apps
import { App } from './app/App';
// Import * as _redux from './setup'
import store, { persistor } from './setup/redux/Store';

import './_carswip/assets/sass/style.scss';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const PUBLIC_URL = process.env.PUBLIC_URL as string;
/**
 * Creates 'axios-mock-adapter' instance for provided 'axios' instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

// /* const mock = */ _redux.mockAxios(axios)
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
// _redux.setupAxios(axios, store)
Chart.register(...registerables);

ReactDOM.render(
  <>
    <Helmet>{process.env.NODE_ENV !== 'production' && <meta name="robots" content="noindex" />}</Helmet>
    <I18nProvider>
      <Provider store={store}>
        {/* Asynchronously persist redux stores and show 'SplashScreen' while it's loading. */}
        <PersistGate
          persistor={persistor}
          loading={
            <div>
              <FormattedMessage id="ACTION.PENDING" />
            </div>
          }
        >
          <React.StrictMode>
            <App basename={PUBLIC_URL} />
          </React.StrictMode>
        </PersistGate>
      </Provider>
    </I18nProvider>
  </>,
  document.getElementById('root'),
);
