import { Dispatch, FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Button, Chip, colors, Grid } from '@mui/material';

import { getUser, getUsers } from '../../../../setup/api/users';
import { useFetch } from '../../../../setup/hooks/fetch.hook';
import { Datagrid } from '../../../../ui/components/Datagrid/Datagrid';
import { FilterComponent } from '../../../../ui/components/Filters/FilterComponent';
import { RequiredSpan } from '../../../../ui/components/RequiredField/RequiredFieldSpan';

interface IStyleGuideGridProps {
  setImpersonationAccount: Dispatch<IUser>;
  selectedAccount: IUser;
}

export const SelectDealership: FC<IStyleGuideGridProps> = ({ setImpersonationAccount, selectedAccount }) => {
  const intl = useIntl();
  const [users, setUsers] = useState<IUser[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<Record<string, unknown>>({});
  const [userCount, setUserCount] = useState<number>();
  const queriesFromRouter = useLocation().search;
  const [openGrid, setOpenGrid] = useState(false);
  const { request } = useFetch();

  const userHeadings: [string, string][] = [
    ['email', intl.formatMessage({ id: 'ACCOUNT.EMAIL' })],
    ['displayName', intl.formatMessage({ id: 'ACCOUNT.DEALERSHIP' })],
  ];

  const renderTestRowsUsers = (userHeadings: [Array<string>], item: IUser) =>
    userHeadings.map(([key]) => (
      <td key={key} className="listHover">
        {item[key]}
      </td>
    ));

  const getUserById = async (id: string) => {
    request(getUser, id).then((userInformation: IUser) => {
      setImpersonationAccount(userInformation);
    });
  };

  useEffect(() => {
    request(getUsers, queriesFromRouter).then(users => {
      if (users) {
        setUserCount(users[1]);
        return setUsers(users[0]);
      }
    });
  }, [queriesFromRouter]);

  useEffect(() => {
    if (Object.keys(selectedUsers).length > 0) {
      setOpenGrid(false);
      getUserById(Object.keys(selectedUsers)[0]);
    }
  }, [selectedUsers]);

  return (
    <div className="mt-5 me-5" style={{ marginBottom: '2em' }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1em' }}>
        <RequiredSpan>
          <FormattedMessage id="ORDER.CREATOR.DEALERSHIP" />
        </RequiredSpan>
        <Chip
          label={selectedAccount.displayName}
          color="success"
          sx={{
            fontSize: '1rem',
            fontWeight: 'bold',
            height: '2em',
            backgroundColor: colors.green[500],
            marginLeft: '1em',
          }}
        />
      </div>
      {!openGrid ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setSelectedUsers({});
            setOpenGrid(!openGrid);
          }}
          children={<FormattedMessage id="ORDER.CREATOR.DEALERSHIP.SELECT" />}
        />
      ) : (
        <>
          <Grid xs={12} marginTop={3}>
            <FilterComponent showSearchFilter showPagination entity="users" count={userCount} />
          </Grid>
          <Datagrid
            data={users}
            selectable
            setSelected={setSelectedUsers}
            selected={selectedUsers}
            headings={userHeadings}
            createdAt
            renderRow={renderTestRowsUsers}
          />
        </>
      )}
    </div>
  );
};
