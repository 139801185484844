import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, ButtonGroup } from '@mui/material';
import Button from '@mui/material/Button';

import { RootState } from '../../../setup';
import { getImageById, repushImages } from '../../../setup/api';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { MainGridContainer } from '../../../ui/components/MainGridContainer/MainGridContainer';
import { ModalCarswip } from '../../../ui/components/Modal';

import { RepushPipelineOptions } from './RepushPipelineOptions';
import { RepushSettings } from './RepushSettings';

type Props = {
  usedStyleGuide: IStyleGuide;
  button?: boolean;
  isRepushedOpen?: boolean;
  orderId?: string;
  position?: number;
};

const boxStyle = {
  justifyContent: 'center',
  display: 'flex',
  marginTop: '1em',
  alignItems: 'center',
};

const DefaultPipelineValues = {
  userControlCropEnabled: undefined,
  userControlForceAddLogo: undefined,
  userControlRotationFixEnabled: undefined,
  userControlYellowFixEnabled: undefined,
  userControlForceBGRemove: undefined,
};

/**
 * This component takes selection of images from Redux and allows the user to customized setting that
 * will be send to edition, it allows the user to select between different optimization methods and style guides
 * @param {boolean} button if tue will display a button that will trigger the modal
 * @returns request to edit all the selected pictures with  customized settings
 */
export const RepushModal: FC<Props> = ({ usedStyleGuide, button, isRepushedOpen = false, orderId, position }) => {
  const intl = useIntl();
  const [anchorEl] = useState<null | HTMLElement>(null);
  const [loader, setLoader] = useState(false);
  const open = Boolean(anchorEl);
  const selectedImages = useSelector((state: RootState) => state.images.images);
  const [imageIdsCopy, setImageIdsCopy] = useState<string[]>([...selectedImages]);
  const { id } = useParams<TParams>();
  const { request } = useFetch();
  const [isOpen, setIsOpen] = useState(isRepushedOpen);
  const [openSettings, setOpenSettings] = useState(false);
  const [images, setImages] = useState<IImage[] | null>([]);
  const [styleGuide, setStyleGuide] = useState<IStyleGuide>(usedStyleGuide);
  const [repushPayload, setRepushPayload] = useState<RepushPayload>({
    orderId: orderId ?? id,
    imageArray: imageIdsCopy,
    method: 'full',
    backgroundColor: styleGuide?.interiorColor ?? '#ffffff',
    imageCategory: 'exterior',
    isImageRepushedWithSettings: false,
    horizonLineHeight:
      styleGuide?.configurations?.find((config: IStyleGuideConfigurations) => config.position === position)
        ?.horizonLineHeight || 50,
    ...DefaultPipelineValues,
  });

  const handleRepushImages = (data: RepushPayload) => {
    setRepushPayload({
      ...repushPayload,
      ...data,
      imageArray: imageIdsCopy,
    });
    setLoader(true);
    if (typeof data.margins === 'string') {
      data.margins = JSON.parse(data.margins as string);
    }
    request(repushImages, data)
      .then(() => {
        setLoader(false);
      })
      .then(() => {
        setIsOpen(false);
      });
  };

  const modalToggle = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setImages(null);
    }
  };

  const loadImages = () => {
    Promise.all(
      selectedImages.map((image: IImage) =>
        request(getImageById, image).then((imageResponse: IImage) => imageResponse),
      ),
    ).then((selectedImages: IImage[]) => {
      setImages(selectedImages);
    });
  };

  useEffect(() => {
    setImageIdsCopy([...selectedImages]);
    loadImages();
  }, [isOpen]);

  useEffect(() => {
    setRepushPayload({ ...repushPayload, imageArray: selectedImages });
  }, [isOpen, isRepushedOpen]);

  useEffect(() => {
    setIsOpen(isRepushedOpen);
  }, [isRepushedOpen]);

  return (
    <>
      {button && (
        <Button
          id="basic-button"
          style={{ margin: '1em 1em 1em 1em', border: '1px' }}
          color="primary"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          onClick={() => {
            setIsOpen(true);
            setRepushPayload({ ...repushPayload, ...DefaultPipelineValues });
          }}
        >
          <FormattedMessage id="REPUSH.ACTION" />
        </Button>
      )}

      <ModalCarswip open={isOpen} onClose={modalToggle} title={intl.formatMessage({ id: 'REPUSH.MODAL.TITLE' })}>
        <MainGridContainer>
          <div className="container">
            <div>
              <h1>
                <FormattedMessage id="REPUSH.SELECTED" />
              </h1>
              <div className="d-flex">
                {images &&
                  images.map((image: IImage) => (
                    <div
                      style={{
                        width: '220px',
                        height: '220px',
                        background: 'black',
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '1em',
                        border: 'grey 3px',
                        borderStyle: 'solid',
                      }}
                    >
                      <img style={{ width: '100%', height: '80%' }} src={image.thumbnailUrl} alt={image.mediaType} />
                    </div>
                  ))}
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="mt-5 mb-5 align-items-center justify-content-center d-flex">
                <h1 className="me-5 mb-0">
                  <FormattedMessage id="REPUSH.STYLEGUIDE" />
                </h1>
                <div>
                  <span className="badge badge-light-success fs-7 fw-bolder">
                    {styleGuide ? styleGuide.name : <FormattedMessage id="REPUSH.STYLEGUIDE.DEFAULT" />}
                  </span>
                </div>
              </div>
            </div>
            <Box sx={boxStyle}>
              <ButtonGroup
                className="justify-content-center"
                variant="contained"
                size="large"
                color="info"
                aria-label="outlined button group"
              >
                <Button
                  onClick={() => {
                    setOpenSettings(!openSettings);
                    repushPayload.isImageRepushedWithSettings = true;
                  }}
                >
                  <FormattedMessage id="REPUSH.PIPELINE.SETTINGS" />
                </Button>
                <Button
                  onClick={() => {
                    const newRepushPayload: RepushPayload = {
                      ...repushPayload,
                      method: 'full',
                      imageCategory: '',
                      isImageRepushedWithSettings: false,
                    };
                    handleRepushImages(newRepushPayload);
                  }}
                >
                  <FormattedMessage id="REPUSH.PIPELINE.AI" />
                </Button>
                <Button
                  onClick={() => {
                    const newRepushPayload: RepushPayload = {
                      ...repushPayload,
                      method: 'bypass',
                      imageCategory: '',
                      isImageRepushedWithSettings: false,
                    };
                    handleRepushImages(newRepushPayload);
                  }}
                >
                  <FormattedMessage id="REPUSH.PIPELINE.BYPASS" />
                </Button>
                <Button
                  onClick={() => {
                    const newRepushPayload: RepushPayload = {
                      ...repushPayload,
                      method: 'enhancer',
                      imageCategory: '',
                      isImageRepushedWithSettings: false,
                    };

                    handleRepushImages(newRepushPayload);
                  }}
                >
                  <FormattedMessage id="REPUSH.PIPELINE.ENHANCE" />
                </Button>
                <Button
                  onClick={() => {
                    const newRepushPayload: RepushPayload = {
                      ...repushPayload,
                      method: 'logo_only',
                      imageCategory: '',
                      isImageRepushedWithSettings: false,
                    };

                    handleRepushImages(newRepushPayload);
                  }}
                >
                  <FormattedMessage id="REPUSH.PIPELINE.LOGO" />
                </Button>
              </ButtonGroup>
              <RepushPipelineOptions repushPayload={repushPayload} setRepushPayload={setRepushPayload} />
            </Box>

            {openSettings ? (
              <RepushSettings
                setRepushPayload={setRepushPayload}
                handleRepushImages={handleRepushImages}
                setStyleGuide={setStyleGuide}
                styleGuide={styleGuide}
                repushPayload={repushPayload}
                loader={loader}
                images={images ?? []}
              />
            ) : null}
          </div>
        </MainGridContainer>
      </ModalCarswip>
    </>
  );
};
