import { CSSProperties, FC, useState } from 'react';
import HideImageIcon from '@mui/icons-material/HideImage';
import { styled } from '@mui/material/styles';

type ImageDisplayProps = {
  src: string;
  style?: CSSProperties;
};

const StyledImage = styled('img')({
  maxWidth: '70px',
  minHeight: '55px',
  backgroundColor: 'black',
  transition: 'transform .2s !important',
  padding: '0 !important',
  '&:hover': {
    transform: 'scale(2) !important',
    backgroundColor: 'black',
    padding: '0 !important',
  },
});

const StyledHideImageIcon = styled(HideImageIcon)({
  minWidth: '70px',
  minHeight: '55px',
  transition: 'transform .2s !important',
  padding: '10px !important',
  backgroundColor: '#F1F1F1',
});

export const ImageDisplay: FC<ImageDisplayProps> = props => {
  const [imageIsAvailable, setImageIsAvailable] = useState(true);

  return imageIsAvailable ? (
    <StyledImage
      {...props}
      onError={() => {
        setImageIsAvailable(false);
      }}
    />
  ) : (
    <StyledHideImageIcon />
  );
};
