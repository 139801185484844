import { IntlShape } from 'react-intl';

export function getPercentageDifference(value1: number, value2: number): number {
  const difference = value2 - value1;
  const percentage = (difference / value1) * 100;
  // round to two decimal
  const percentageRounded = Number(percentage.toFixed(2));

  return percentageRounded;
}

export function formatDateForSQL(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function formatDetectedAngle(angle?: string) {
  return angle?.split('_').reduce((acc, ang) => `${acc} ${ang[0].toUpperCase()}${ang.slice(1)}`, '');
}

export function getPreviousMonth(date: Date) {
  const previousDate = new Date(date.getFullYear(), date.getMonth() - 1);

  return formatDateForSQL(previousDate);
}

export function getDifferenceBetweenDatesInHours(date1: Date, date2: Date, intl: IntlShape) {
  const format = (id: string) => intl.formatMessage({ id: `ORDER.VIEW.${id}` });
  const hours: number = (new Date(date1).getTime() - new Date(date2).getTime()) / (1000 * 60 * 60);
  const minutes: string = ((hours % 1) * 60).toFixed(0);
  return `${hours.toFixed(0)} ${format('HOURS')} ${minutes} ${format('MINUTES')}`;
}
