export type Props = {
  header: string;
  value: string;
};

export const OrderInformationDetailRow: React.FC<Props> = ({ header, value }) => (
  <li className="list-group-item d-flex justify-content-between align-items-start">
    <div className="ms-2 me-auto">
      <div className="fw-bold">{header}</div>
      {value}
    </div>
  </li>
);
