interface Configurations {
  styleGuide: IStyleGuide | null;
}

const initialState: Configurations = {
  styleGuide: null,
};

interface IAction {
  type: string;
  payload?: IStyleGuide;
}

export const GET_STYLE_GUIDE_INFO = 'GET_STYLE_GUIDE_INFO';

export const getStyleGuideReducer = (state: Configurations = initialState, action: IAction) => {
  switch (action.type) {
    case GET_STYLE_GUIDE_INFO: {
      return {
        ...state,
        styleGuideInformation: action.payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
