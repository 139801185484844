import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Grid, TextField } from '@mui/material';
import { Form, FormikProps } from 'formik';

import { ForgotPasswordProps } from '../../pages/auth/interfaces';

export const LoginForm: FC<FormikProps<ForgotPasswordProps>> = ({
  getFieldProps,
  touched,
  errors,
  handleSubmit,
  values,
}) => {
  const intl = useIntl();

  return (
    <Form
      onSubmit={handleSubmit}
      onKeyDown={e => {
        if (!values.email || !values.password) {
          return;
        }
        if (e.key === 'Enter' && values.email.length > 0 && values.password.length > 0) {
          handleSubmit(e);
        }
      }}
    >
      <span className="authentication-title">
        <FormattedMessage id="AUTH.LOGIN.TITLE" />
      </span>
      <Grid item xs={12} style={{ width: '100%', marginTop: '1em' }}>
        <TextField
          type="string"
          placeholder={intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' })}
          {...getFieldProps('email')}
          error={touched.email && Boolean(errors.email)}
          helperText={touched.email && errors.email}
        />
      </Grid>
      <Grid item xs={12} style={{ width: '100%' }}>
        <TextField
          type="password"
          placeholder={intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD' })}
          {...getFieldProps('password')}
          error={touched.password && Boolean(errors.password)}
          helperText={touched.password && errors.password}
          className="mb-10"
        />
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          width: '100%',
          padding: '1.25rem',
          color: 'rgb(25, 118, 210)',
        }}
      >
        <Grid item xs={12} textAlign="center" textTransform="uppercase">
          <FormattedMessage id="AUTH.FORGOT.TITLE" />
          &nbsp;
          <Link to="/forget-password">
            <FormattedMessage id="AUTH.FORGOT.RESET" />
          </Link>
        </Grid>
      </Grid>
    </Form>
  );
};
