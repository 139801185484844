import { Dispatch, FC, SetStateAction } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Button } from '@mui/material';
import { ErrorMessage, Field, FormikProps } from 'formik';
import { v4 } from 'uuid';

import { isValidEmail } from '../../../setup/helpers/emailValidator';

type Props = {
  open: boolean;
  setSalesMen: Dispatch<SetStateAction<ISalesMan[]>>;
  setOpenSalesManForm: (value: boolean) => void;
  formikProps: FormikProps<any>;
};

const fieldsStyle = {
  paddingRight: '2rem',
};

export const SettingsSalesManForm: FC<Props> = (componentProps: Props) => {
  const intl = useIntl();
  const { open, setSalesMen, setOpenSalesManForm, formikProps } = componentProps;

  return !open ? null : (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '4px',
          paddingTop: '1rem',
          paddingBottom: '1rem',
          boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.5)',
          marginBottom: '2rem',
        }}
      >
        <div style={{ ...fieldsStyle }}>
          <label className="d-flex align-items-center fs-5 fw-bold mb-2">
            <span className="required">
              <FormattedMessage id="SALESMAN.NAME" />:
            </span>
          </label>
          <Field
            type="text"
            className={`form-control form-control-lg  userInputFields ${
              formikProps.touched.salesManName && formikProps.errors.salesManName ? 'is-invalid' : 'form-control-solid'
            }`}
            name="salesManName"
            placeholder={intl.formatMessage({ id: 'SALESMAN.NAME' })}
          />
          <div style={{ color: 'red', marginTop: '-10px' }}>
            <ErrorMessage name="salesManName" />
          </div>
        </div>
        <div style={{ ...fieldsStyle }}>
          <label className="d-flex align-items-center fs-5 fw-bold mb-2">
            <span className="required">
              <FormattedMessage id="SALESMAN.EMAIL" />:
            </span>
          </label>
          <Field
            type="email"
            className={`form-control form-control-lg  userInputFields ${
              formikProps.touched.salesManEmail && formikProps.errors.salesManEmail
                ? 'is-invalid'
                : 'form-control-solid'
            }`}
            normalize={(value: string) => value.trim()}
            name="salesManEmail"
            placeholder={intl.formatMessage({ id: 'SALESMAN.EMAIL' })}
          />
          <div style={{ color: 'red', marginTop: '-10px' }}>
            <ErrorMessage name="salesManEmail" />
          </div>
        </div>
        <div style={{ ...fieldsStyle }}>
          <label className="d-flex align-items-center fs-5 fw-bold mb-2">
            <span className="required">
              <FormattedMessage id="SALESMAN.PHONE" />:
            </span>
          </label>
          <Field
            type="number"
            className={`form-control form-control-lg  userInputFields ${
              formikProps.touched.salesManPhone && formikProps.errors.salesManPhone
                ? 'is-invalid'
                : 'form-control-solid'
            }`}
            name="salesManPhone"
            placeholder={intl.formatMessage({ id: 'SALESMAN.PHONE' })}
          />
          <div style={{ color: 'red', marginTop: '-10px' }}>
            <ErrorMessage name="salesManPhone" />
          </div>
        </div>
        <div style={{ padding: '1rem', alignSelf: 'center', textAlign: 'center', textTransform: 'uppercase' }}>
          <Button
            color="primary"
            disabled={!isValidEmail(formikProps.values.salesManEmail)}
            variant="contained"
            onClick={() => {
              setSalesMen(
                ps =>
                  [
                    ...ps,
                    {
                      name: formikProps.values.salesManName,
                      email: formikProps.values.salesManEmail,
                      phone: formikProps.values.salesManPhone,
                      cognitoSub: '',
                      id: v4(),
                    },
                  ] as ISalesMan[],
              );
              setOpenSalesManForm(false);
              toast.info(intl.formatMessage({ id: 'SALESMAN.TOAST.UPDATE' }));
            }}
          >
            <FormattedMessage id="CHILD.ACTION.ADD" />
          </Button>
          <Button onClick={() => setOpenSalesManForm(false)}>
            <FormattedMessage id="ACTION.CLOSE" />
          </Button>
        </div>
      </div>
    </>
  );
};
